import React, { useEffect, useState } from "react";
import {
  View,
  Image,
  ActivityIndicator,
  ImageBackground,
  StyleSheet,
  useWindowDimensions,
  Linking,
} from "react-native";
import moment from "moment";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { connect } from "react-redux";
import * as RootNavigation from "../RootNavigation.js";
import { useFocusEffect } from "@react-navigation/native";
import { colors, typography, dimensions, logo } from "../utils/theme";
import { LinearGradient } from "expo-linear-gradient";
import QRCode from "react-native-qrcode-svg";
import { DateTimeLocation } from "../components/social";
import Text from "../components/Text/TextLight";
import TextBold from "../components/Text/TextBold";
import TextBS from "../components/Text/TextBoldSmall";
import EventPage from "./desktop/EventPage.js";
import {
  setGlobalInitializing,
  setGlobalLoading,
  // fetchAllEvents,
  fetchSingleEvent,
} from "../actions";

const phoneFrame = require("../assets/svg/framer.svg");

const day = (seconds) => moment(seconds * 1000).format("Do");

const dateFormatted = ({ startTime, endTime }) => {
  if (day(startTime) === day(endTime)) {
    return `${moment(startTime.seconds * 1000).format("dddd Do MMM YYYY")}`;
  } else {
    return `${moment(startTime.seconds * 1000).format(
      "Do MMM YYYY"
    )} - ${moment(endTime.seconds * 1000).format("Do MMM YYYY")}`;
  }
};

const DateLocation = (props) => {
  const { date } = props.eventFocus;
  return (
    <DateTimeLocation
      {...props}
      oneColor={{ color: colors.black }}
      showTimes={day(date?.startTime?.seconds) === day(date?.endTime?.seconds)}
    />
  );
};

const Poster = (props) => {
  const { titleImage } = props.eventFocus;
  const [loading, setLoading] = useState(true);

  return (
    <ImageBackground
      resizeMode="cover"
      style={styles.BGimageStyle}
      source={{ uri: titleImage }}
      onLoadEnd={() => setLoading(false)}
    >
      <LinearGradient
        colors={["transparent", "rgba(0,0,0,0.0)", "rgba(0,0,0,0.0)"]}
        style={{
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 15,
          padding: 15,
        }}
      >
        {loading && (
          <ActivityIndicator
            size="large"
            color={colors.black}
            style={{ opacity: 0.6 }}
          />
        )}
      </LinearGradient>
    </ImageBackground>
  );
};

const QrCodeScreen = (props) => {
  const { scanLink } = props;
  return (
    <View
      style={{
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <ImageBackground
          source={{ uri: phoneFrame }}
          resizeMode="contain"
          style={{ height: 600, width: 250 }}
        >
          <View style={styles.qrContainer}>
            <View style={{ marginVertical: 20, alignItems: "center" }}>
              <TextBold style={{ fontSize: 18 }}>Buy tickets!</TextBold>

              <TextBold style={{ fontSize: 18 }}>
                <span style={{ color: colors.shade4 }}>Scan </span>
                <span style={{ color: colors.balance }}>QR code</span>
              </TextBold>
            </View>

            <View
              style={{
                backgroundColor: "white",
                padding: 10,
                borderRadius: 10,
              }}
            >
              <QRCode value={scanLink} size={200} />
            </View>

            <View
              style={{
                marginVertical: 20,
                alignItems: "center",
              }}
            >
              <TextBold style={{ fontSize: 18 }}>
                <span style={{ color: colors.shade4 }}>Event </span>
                <span style={{ color: colors.balance }}>wallet!</span>{" "}
              </TextBold>
              {/* <TextBold style={{ fontSize: 18 }}>Scan QR code</TextBold> */}
            </View>
          </View>
        </ImageBackground>

        {!!props.eventFocus && (
          <View style={{ width: "80%", padding: 40 }}>
            <Poster {...props} />
            <TextBold style={{ width: "70%" }}>
              {props.eventFocus.event_title}
            </TextBold>
            <DateLocation item={props.eventFocus} {...props} />
            {/* <TextBold>Scan QR code</TextBold> */}

            {/* <Text>{scanLink}</Text> */}
          </View>
        )}
      </View>
      <View
        style={{
          width: "60%",
          // flexDirection: "column",
          // justifyContent: "flex-start",
        }}
      >
        <Image
          style={{
            height: 20,
            width: 100,
            marginVertical: 10,
            // backgroundColor: "yellow",
          }}
          source={{ uri: require("../assets/svg/ad_logo_dark.svg") }}
          resizeMode="contain"
        />
        <Text style={{ color: colors.shade4 }}>
          AirDosh is a mobile-first wallet platform. Please use your mobile
          phone to scan the QR code. This will open the mobile wallet web app.
          You will be able to purchase tickets using VISA, Mastercard or
          Snapscan. No elaborate sign up, simply login in using One Time Pin
          sent to you by SMS.
        </Text>
      </View>
    </View>
  );
};

function DesktopWallet(props) {
  const [scanLink, setScanLink] = useState("https://wallet.airdosh.co.za/");
  useFocusEffect(
    React.useCallback(() => {
      // Do something when the screen is focused
      const { orgId, event_id } = props.route;

      if (!!orgId && !!event_id)
        var unsubscribeEventFocus =
          orgId && !!event_id && props.fetchSingleEvent(orgId, event_id);

      return () => {
        // Do something when the screen is unfocused
        !!unsubscribeEventFocus && unsubscribeEventFocus();
      };
    }, [props.route])
    // keep this "props.route" dependency in there
    // https://stackoverflow.com/questions/64186894/react-native-navigation-navigate-params-not-updating
  );

  useEffect(() => {
    Linking.getInitialURL().then((res) => setScanLink(res));
    props.setGlobalInitializing(false);
    props.setGlobalLoading(false);
  }, []);

  return (
    <View
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: colors.bg,
      }}
    >
      <QrCodeScreen scanLink={scanLink} {...props} />
      {/* <EventPage {...props} /> */}
    </View>
  );
}

const mapStateToProps = (state) => {
  const { eventFocus } = state.config;

  return { eventFocus };
};

export default connect(mapStateToProps, {
  setGlobalInitializing,
  setGlobalLoading,
  // fetchAllEvents,
  fetchSingleEvent,
})(DesktopWallet);

const styles = StyleSheet.create({
  qrContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "space-around",
    padding: 15,
    marginVertical: 40,
    borderRadius: 10,
    // backgroundColor: "yellow",
  },
  BGimageStyle: {
    width: 200,
    height: 300,
    marginVertical: 20,
    // height: "100%",
    // resizeMode: "contain",
    borderRadius: 15,
    overflow: "hidden",
    shadowColor: "rgba(0,0,0,0.2)",
    shadowOffset: { width: 0, height: 7 },
    shadowOpacity: 1,
    shadowRadius: 30,
  },
});
