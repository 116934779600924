import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { View, StyleSheet, ScrollView, Pressable, Linking } from 'react-native';
// import { Button } from "react-native-elements";
import ButtonX from '../../../components/buttons/ButtonX';
import { dimensions, colors, typography } from '../../../utils/theme';
import { CheckBox, Icon } from 'react-native-elements';
import ModalHeader1 from '../../../components/headers/Header1';
import TextBS from '../../../components/Text/TextBoldSmall';
import InputMat from '../../../components/common/MaterialInput';

import { updateUserPass } from '../../../actions';
import { TouchableOpacity } from 'react-native-gesture-handler';

function OwnerFormModal(props) {
  const { user, onModalClose, showOwnerModal, itemToUpdate, isCovidScreen } = props;

  const [loading, setLoading] = useState(false);
  const [email, setEmailInput] = useState('');
  const [name, setFirstName] = useState('');
  const [surname, setLastName] = useState('');
  const [cellphoneNumber, setCellphoneNumber] = useState('');
  const [passport, setPassport] = useState('');
  const [temperature, setTemperature] = useState('');
  const [agree, setAgree] = useState(false);
  const [ref, setRef] = useState(null);
  const isFormValid =
    name.length && surname.length && email.length && email.includes('@') && email.includes('.');
  const hotline = '17242133657';
  const helpLink = `https://wa.me/${hotline}/?text=${encodeURI(
    'I need assistance with transferring ownership of passes..',
  )}`;

  useEffect(() => {
    itemToUpdate !== null && hydrateInputs(itemToUpdate);
  }, [itemToUpdate]);

  useEffect(() => {
    if (showOwnerModal === false) {
      setFirstName('');
      setLastName('');
      setEmailInput('');
      setCellphoneNumber('');
      setPassport('');
      setTemperature('');
    } else {
    }
  }, [showOwnerModal]);

  const emptyOwner = {
    owner: {
      name: '',
      surname: '',
      email: '',
    },
  };

  const hydrateInputs = (item = emptyOwner) => {
    // console.log("Item passed for hydrate", item);
    if (item?.owner?.name?.length || item?.owner?.surname?.length || item?.owner?.email?.length) {
      setFirstName(item.owner.name);
      setLastName(item.owner.surname);
      setEmailInput(item.owner.email);
    } else {
      setFirstName('');
      setLastName('');
      setEmailInput('');
    }
  };

  return (
    <View
      style={{
        backgroundColor: colors.bg,
        borderRadius: 10,
        maxHeight: '100%',
      }}
    >
      <View
        style={{
          flex: 1,
          backgroundColor: colors.bg1,
          padding: 15,
          borderRadius: 10,
          width: '100%',
        }}
      >
        <form
          onSubmit={(event) => {
            event.preventDefault();
            isFormValid && setLoading(true);
            // if no user name and surname on userObj yet, then update it now
            // (!user.name?.length || !user.surname?.length) &&
            //   props.updateCustomer({ ...user, name, surname });
            isFormValid &&
              props
                .updateUserPass({
                  user,
                  qr_id: itemToUpdate.qr_id,
                  key: 'owner',
                  value: {
                    // phone: user.cellphoneNumber,
                    name,
                    surname,
                    email,
                    cellphoneNumber: user.cellphoneNumber,
                    passport,
                    temperature,
                    agree,
                  },
                })
                .then(() => {
                  onModalClose();
                  setLoading(false);
                });
          }}
        >
          <View>
            <ModalHeader1
              heading="Ticket allocation"
              subHeading="Name + contact info"
              description="Enter the details of the ticket user"
              onClose={onModalClose}
            />
          </View>

          <View style={{ marginBottom: 20, marginTop: -15 }}>
            <TextBS style={{ color: colors.notice }}>
              Please note: <br />
              The entered details are used for access purposes only. This action does not send or
              transfer the ticket. For transfer of ownership, contact AirDosh support
              <Pressable onPress={() => Linking.openURL(helpLink)}>
                <TextBS style={{ color: colors.black, fontWeight: 'bold' }}> here.</TextBS>
              </Pressable>
            </TextBS>
          </View>

          <ScrollView>
            <InputMat
              required
              label="First name"
              placeholder="Enter ticket owner first name"
              onChangeText={setFirstName}
              value={name}
              addContainerStyle={styles.inputText}
              checkValidation={(val) => !val.length && 'Required'}
            />
            <InputMat
              required
              label="Last name"
              placeholder="Enter ticket owner last name"
              onChangeText={setLastName}
              value={surname}
              addContainerStyle={styles.inputText}
              checkValidation={(val) => !val.length && 'Required'}
            />
            <InputMat
              required
              label="Email address"
              placeholder="Enter ticket owner email address"
              onChangeText={setEmailInput}
              value={email}
              autoComplete="email"
              autoCompleteType="email"
              addContainerStyle={styles.inputText}
              keyboardType="email-address"
              checkValidation={(val) =>
                (val.length < 3 || !val.includes('@') || !val.includes('.')) &&
                'Enter a valid email address'
              }
            />

            {isCovidScreen && (
              <View>
                <InputMat
                  label="Mobile number"
                  placeholder="Enter ticket owner mobile number"
                  onChangeText={setCellphoneNumber}
                  value={cellphoneNumber}
                  addContainerStyle={styles.inputText}
                  keyboardType="phone-pad"
                />
                <InputMat
                  required
                  label="ID / Passport"
                  placeholder="Local ID or Passport number"
                  onChangeText={setPassport}
                  value={passport}
                  addContainerStyle={styles.inputText}
                  validation={['required']}
                  checkValidation={(val) => !val.length && 'Required'}
                />
                <InputMat
                  required
                  label="Temperature"
                  placeholder="Enter screening temperature"
                  onChangeText={setTemperature}
                  value={temperature}
                  addContainerStyle={styles.inputText}
                  keyboardType="numeric"
                />
                <TouchableOpacity
                  onPress={() => setAgree(!agree)}
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: colors.bg1,
                    borderRadius: 10,
                    marginVertical: 15,
                    padding: 15,
                  }}
                >
                  {agree ? (
                    <Icon
                      name="radio-button-checked"
                      type="material"
                      color={colors.pay}
                      size={25}
                      iconStyle={{ marginRight: 10 }}
                    />
                  ) : (
                    <Icon
                      name="radio-button-unchecked"
                      type="material"
                      color={colors.secondary}
                      size={25}
                      iconStyle={{ marginRight: 10 }}
                    />
                  )}
                  <TextBS
                    style={{
                      fontSize: 14,
                      color: agree ? 'white' : colors.secondary,
                    }}
                  >
                    Agree to terms of entry
                  </TextBS>
                </TouchableOpacity>
              </View>
            )}

            <View>
              <TextBS style={styles.message}></TextBS>
            </View>
          </ScrollView>

          <View>
            <button
              style={{
                border: 'none',
                background: 'none',

                margin: 0,
                padding: 0,
              }}
              type="submit"
              disabled={!isFormValid}
            >
              <ButtonX
                type="solid"
                title="Submit"
                loading={loading}
                buttonStyle={{
                  height: 60,
                  borderRadius: 10,
                  margin: 0,
                  marginTop: 20,
                  backgroundColor: !isFormValid ? colors.shade2 : colors.balance,
                }}
              />
            </button>
          </View>
        </form>
      </View>
    </View>

    //   <Modal
    //     isVisible={showEmailModal}
    //     onBackdropPress={() => setShowEmailModal(false)}
    //   >
    //     <View
    //       style={{
    //         backgroundColor: "white",
    //         paddingHorizontal: 20,
    //         paddingVertical: 30,
    //         borderRadius: 10,
    //         width: "100%",
    //       }}
    //     >
    //       <InputMat
    //         label="Email"
    //         placeholder="Enter email address"
    //         onChangeText={setEmailInput}
    //         value={email}
    //         addContainerStyle={styles.inputText}
    //         keyboardType="email-address"
    //         autoFocus={true}
    //       />
    //       <Button
    //         title="EMAIL PASS"
    //         buttonStyle={{
    //           height: 60,
    //           borderRadius: 30,
    //           marginVertical: 20,
    //           backgroundColor: colors.highlight,
    //         }}
    //         titleStyle={{ fontWeight: "bold", fontSize: 14 }}
    //         onPress={() => {
    //           takeScreenShot({ ref, emailInput }).then((deliveryStatus) => {
    //             // console.log("deliveryStatus", deliveryStatus);
    //             setEmailLoading(false);
    //             setEmailDelivered(deliveryStatus);
    //             deliveryStatus &&
    //               props.updateUserPass({
    //                 user,
    //                 qr_id: qrIDtoUpdate,
    //                 key: "action_email",
    //                 value: true,
    //               });
    //             props.userLogAction({
    //               user,
    //               action: {
    //                 label: `User emailed pass to ${emailInput}`,
    //                 notes: [`QR ID - ${qrIDtoUpdate}`],
    //               },
    //             });
    //           });
    //           setShowEmailModal(false);
    //           setEmailLoading(true);
    //         }}
    //       />
    //       <View>
    //         <TextBS style={styles.message}>
    //           You are about to share an event pass. Please remain vigilant, and
    //           do not let your pass be copied.
    //         </TextBS>
    //       </View>
    //     </View>
    //   </Modal>
  );
}
{
  /* {<Button onPress={() => setShowEmailModal(true)}>ShowModal</Button>} */
}
{
  /* {<SignInWidget altTitle=" " />} */
}

const mapStateToProps = (state) => {
  const { user, userPasses } = state.users;

  return {
    user,
  };
};

export default connect(mapStateToProps, {
  updateUserPass,
})(OwnerFormModal);

const styles = StyleSheet.create({
  backButton: {
    position: 'absolute',
    top: 20,
    right: 20,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    height: 40,
    width: 40,
    borderRadius: 20,
    shadowColor: 'rgba(0,0,0,0.4)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 20,
  },
  inputText: {
    // marginTop: 15,
    // marginBottom: 5,
    width: '100%',
    textAlign: 'center',
    fontSize: 18,
    outlineStyle: 'none',
    outlineWidth: 0,
    outlineColor: 'transparent',
  },
  message: {
    fontSize: 14,
    paddingHorizontal: 40,
    color: colors.secondary,
    textAlign: 'center',
  },
});
