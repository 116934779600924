import React, { useState, useRef, useEffect } from "react";
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  StyleSheet,
} from "react-native";
import { Button } from "react-native-elements";
import { Feather } from "@expo/vector-icons";
import { dimensions, colors, typography } from "../../utils/theme";
import BackArrow from "../../components/common/BackArrow";
import { Card } from "../../components/common";
import ModalHeader1 from "../../components/headers/Header1";
import TextBoldSmall from "../../components/Text/TextBoldSmall";

export default function ({ onClearModal }) {
  return (
    <Card style={styles.modalContainer} childStyle={{ alignItems: "center" }}>
      <ModalHeader1
        heading="Limited Access"
        subHeading="Oops"
        onClose={onClearModal}
      />

      <TextBoldSmall
        style={{
          textAlign: "center",
          width: "80%",
          color: colors.textSolid,
          opacity: 0.7,
        }}
      >
        The wallet is available to invited guests only, some features may not be
        accessible to you. If this is in error, you may restart the process by
        clicking the link provided in the inital invite SMS. Alternatively you
        may browse around the app.
      </TextBoldSmall>

      <Button
        title="OK"
        type="solid"
        buttonStyle={{
          height: 50,
          width: 150,
          margin: 10,
          marginVertical: 20,
          borderRadius: 10,
          backgroundColor: colors.balance,
        }}
        titleStyle={{
          fontWeight: "bold",
          fontSize: 14,
          color: "white",
        }}
        onPress={onClearModal}
      />
      {
        // <BackArrow onPress={onClearModal} />
      }
    </Card>
  );
}

const styles = StyleSheet.create({
  modalContainer: {
    //flex: 1,
    // height: "95%",
    // width: "100%",
    // backgroundColor: "white",
    // borderTopRightRadius: 15,
    // borderTopLeftRadius: 15,
    // margin: -20,
    // marginTop: 10,
    // //marginHorizontal: -20,
    // paddingTop: 30,
    alignItems: "center",
    alignSelf: "center",
    // justifyContent: "space-between"
  },
});
