import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { View, TouchableOpacity, StyleSheet } from "react-native";
import { Button } from "react-native-elements";
import { Ionicons } from "@expo/vector-icons";
import { Card } from "../../../../components/common";
import ModalHeader1 from "../../../../components/headers/Header1";
import { dimensions, colors, typography } from "../../../../utils/theme";
// import TextBold from "../../../components/Text/TextBold";
// import TextBoldSmall from "../../../components/Text/TextBoldSmall";

const frameHeight = dimensions.width / 4;
const bRadius = 20;

export default function ({ children, color }) {
  return (
    <View
      style={{
        height: dimensions.width / 1.3,
        width: dimensions.width / 1.3,
        justifyContent: "space-between",
        marginVertical: 80,
      }}>
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <View
          style={[
            styles.frameCorner,
            styles.borderTopLeft,
            { borderColor: color },
          ]}
        />
        <View
          style={[
            styles.frameCorner,
            styles.borderTopRight,
            { borderColor: color },
          ]}
        />
      </View>
      <View style={styles.children}>{children}</View>

      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <View
          style={[
            styles.frameCorner,
            styles.borderBottomLeft,
            { borderColor: color },
          ]}
        />
        <View
          style={[
            styles.frameCorner,
            styles.borderBottomRight,
            { borderColor: color },
          ]}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  frameCorner: {
    height: frameHeight,
    width: frameHeight,
    borderRadius: bRadius,
  },
  children: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  borderTopLeft: {
    borderTopWidth: 2,
    borderLeftWidth: 2,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  borderTopRight: {
    borderRightWidth: 2,
    borderTopWidth: 2,
    borderTopLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  borderBottomLeft: {
    borderLeftWidth: 2,
    borderBottomWidth: 2,
    borderBottomRightRadius: 0,
    borderTopLeftRadius: 0,
  },
  borderBottomRight: {
    borderBottomWidth: 2,
    borderRightWidth: 2,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
});
