import { firebase, firestore, functions, auth } from '../../../firebase/config';
import React, { useState, useRef, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { StyleSheet, View, TouchableOpacity, SafeAreaView } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import TextBold from '../../components/Text/TextBold';
import Text from '../../components/Text/TextLight';
import TextBS from '../../components/Text/TextBoldSmall';
import InputMat from '../../components/common/MaterialInput';
import ButtonX from '../../components/buttons/ButtonX';
import ModalHeader1 from '../../components/headers/Header1';
import { dimensions, colors, typography } from '../../utils/theme';
import { showAuthLoadingModal, getCreateUserObj, linkAuthProvider } from '../../actions';

const Timer = ({ seconds, onResendPress, timeLeft, setTimeLeft }) => {
  useEffect(() => {
    // exit early when we reach 0
    if (timeLeft <= 0) return setTimeLeft(null);

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  return (
    <SafeAreaView>
      <View style={{ alignItems: 'center' }}>
        <Text style={{ color: colors.textSolid }}> Didn't receive an SMS?</Text>
        {!timeLeft ? null : (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text style={{ color: colors.textSolid }}>You may retry in </Text>
            <TextBold
              style={{
                width: 28,
                fontSize: 16,
                marginBottom: -2,
                color: colors.textSolid,
                textAlign: 'center',
              }}
            >
              {timeLeft}
            </TextBold>
            <Text style={{ color: colors.textSolid }}> seconds</Text>
          </View>
        )}
        <TouchableOpacity
          style={{ padding: 10 }}
          onPress={() => {
            onResendPress();
            setTimeLeft(timerCount); // reset the timer to start again from XX seconds
            window?.recaptchaVerifier?.clear();
          }}
          disabled={timeLeft}
        >
          {/* 
          // removed to make room for the whatsaap and sms retry buttons
          <Text style={{ color: timeLeft ? colors.shade2 : colors.balance }}>Start again</Text>
           */}
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
};

function EnterOTPForm(props) {
  const { navigation } = props;
  const { user, associated, existingAuthType, isSignUp, phoneNumber, email, name, surname } =
    props?.route?.params;
  const { params } = props?.route;
  const timerCount = 45;

  const [loading, setLoading] = useState(false);
  const [authError, setAuthError] = useState(null);
  const [code, setCode] = useState('');
  const [otpType, setOtpType] = useState('google'); // 'whatsapp' or 'twilioSms'
  // initialize timeLeft with the seconds prop
  const [timeLeft, setTimeLeft] = useState(timerCount);
  const captchaRef = useRef(null);
  const dispatch = useDispatch();
  var CheckCode = functions.httpsCallable('checkCode');

  useFocusEffect(
    React.useCallback(() => {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('reCAPTCHA-container', {
        size: 'invisible',
      });
      firebase.auth().useDeviceLanguage();
      return () => window.recaptchaVerifier.clear();
    }, [props.route]),
    // keep this "props.route" dependency in there
    // https://stackoverflow.com/questions/64186894/react-native-navigation-navigate-params-not-updating
  );

  useEffect(() => {
    try {
      if (window.recaptchaVerifier) {
        const appVerifier = window.recaptchaVerifier;

        // TODO: this the number that has to be correctly formatted
        // console.log('EnterOTPForm', { finalNumberMustBeCorrectlyFormatted: phoneNumber });

        firebase
          .auth()
          .signInWithPhoneNumber(phoneNumber, appVerifier)
          .then((confirmationResult) => {
            window.confirmationResult = confirmationResult;
            setLoading(false);
          })
          .catch((error) => {
            console.error(error);
            setAuthError(error.message);
            setLoading(false);
            setTimeLeft(0);
          });
      }
    } catch (err) {
      setLoading(false);
      setAuthError(err.message);
    }
  }, [window.recaptchaVerifier]);

  const regularSignIn = async () => {
    const handleSignInSuccess = async () => {
      dispatch(showAuthLoadingModal(true));
      setLoading(false);

      if (existingAuthType === 'phone') {
        navigation.navigate('Auth', {
          screen: 'LinkCredentials',
          params: { existingAuthType: 'phone', email },
        });
      } else {
        const eventLastVisted = await AsyncStorage.getItem('EVENT_LAST_VISITED');
        const { orgId, event_id } = JSON.parse(eventLastVisted);

        if (!!orgId && !!event_id) {
          navigation.navigate('Event Page', { orgId, event_id });
        } else {
          navigation.navigate('Wallet');
        }
      }
    };

    try {
      if (otpType === 'google') {
        await window.confirmationResult.confirm(code);
        handleSignInSuccess();
      }
      if (otpType === 'whatsapp' || otpType === 'twilioSms') {
        const { data } = await CheckCode({ phoneNumber, code, isSignUp: false });
        const { error, customToken } = data;

        if (!error) {
          await firebase.auth().signInWithCustomToken(customToken);
          handleSignInSuccess();
        }
      }
    } catch (error) {
      console.error(error);
      setAuthError(error.message);
      setLoading(false);
      dispatch(showAuthLoadingModal(false));
    }
  };

  const signUp = async () => {
    var newUser = {};
    const handleSignUp = async () => {
      await props.getCreateUserObj({
        newUser,
        blockUserAutoCreate: false,
        phoneNumber,
        email,
        name,
        surname,
      });

      setLoading(false);
      dispatch(showAuthLoadingModal(true));
      const eventLastVisted = await AsyncStorage.getItem('EVENT_LAST_VISITED');
      const { orgId, event_id } = JSON.parse(eventLastVisted);

      if (!!orgId && !!event_id) {
        navigation.navigate('Event Page', { orgId, event_id });
      } else {
        navigation.navigate('Wallet');
      }
    };

    try {
      if (otpType === 'google') {
        const result = await confirmationResult.confirm(code);
        newUser = result.user;
        handleSignUp();
      }
      if (otpType === 'whatsapp' || otpType === 'twilioSms') {
        const { data } = await CheckCode({ phoneNumber, code, isSignUp: true });
        const { error, customToken } = data;

        if (!error) {
          const result = await firebase.auth().signInWithCustomToken(customToken);
          newUser = result.user;
          handleSignUp();
        }
      }
    } catch (error) {
      setAuthError(error.message);
      setLoading(false);
      dispatch(showAuthLoadingModal(false));
    }
  };

  const confirmCode = async () => {
    setAuthError(null);
    setLoading(true);

    if ((user && !associated) || existingAuthType === 'phone') {
      // console.log('SIGNING IN');
      // SIGNING IN
      regularSignIn();
    } else if (!user && !associated) {
      // console.log('SIGNING UP');
      // SIGNING UP
      signUp();
    } else if (!!existingAuthType && associated && existingAuthType !== 'phone') {
      // console.log('LINKING CREDENTIALS', otpType);
      // LINKING CREDENTIALS
      let credential = null;
      dispatch(showAuthLoadingModal(true));

      const handleLinkWithCredential = async () => {
        // console.log('handleLinkWithCredential(); ', credential);
        // try {
        //   await auth.currentUser.linkWithCredential(credential);
        //   setLoading(false);
        //   navigation.navigate('EmailLinked');
        // } catch (err) {
        //   console.log('Account linking error', err);
        //   setAuthError(err.message);
        //   setLoading(true);
        //   dispatch(showAuthLoadingModal(false));
        // }
      };

      if (otpType === 'google') {
        credential = firebase.auth.PhoneAuthProvider.credential(
          window.confirmationResult.verificationId,
          code,
        );
        handleLinkWithCredential();
      }
      if (otpType === 'whatsapp' || otpType === 'twilioSms') {
        try {
          const { data } = await CheckCode({
            phoneNumber,
            code,
            isSignUp: false,
            isLinking: true,
            email,
          });
          setLoading(false);
          navigation.navigate('EmailLinked');
        } catch (err) {
          console.error(err);
          setAuthError(err.message);
          setLoading(true);
          dispatch(showAuthLoadingModal(false));
        }
      }
    } else {
      regularSignIn();
    }

    setCode(''); // reset code
  };

  const startAgian = () => {
    setAuthError(null);
    window?.recaptchaVerifier?.render().then(function (widgetId) {
      grecaptcha.reset(widgetId);
    });
  };

  const handleOtpRetry = async (type) => {
    setOtpType(type);
    setTimeLeft(timerCount);
    // console.log('handle Otp retry', type);

    var SendVerify = functions.httpsCallable('sendVerify');

    try {
      if (type === 'whatsapp') {
        await SendVerify({ channel: 'whatsapp', phoneNumber });
      }
      if (type === 'twilioSms') {
        await SendVerify({ channel: 'sms', phoneNumber });
      }
    } catch (error) {
      console.log('SendVerify ERROR: ', error);
    }
  };

  return (
    <View style={styles.container}>
      <View
        ref={captchaRef}
        nativeID="reCAPTCHA-container"
        style={{ backgroundColor: 'transparent' }}
      ></View>

      <ModalHeader1 heading="Enter OTP" subHeading="SMS has been sent" hideClose={true} />

      <form
        onSubmit={(event) => {
          event.preventDefault();
          confirmCode(); // action
        }}
        style={{ width: '100%' }}
      >
        <InputMat
          label="OTP"
          placeholder="enter One Time Pin"
          onChangeText={setCode}
          value={code}
          keyboardType="number-pad"
          textContentType="oneTimeCode"
          autoComplete="sms-otp"
          checkValidation={(val) => !val.length && 'Required'}
        />
        {authError && (
          <Text
            style={{
              textAlign: 'center',
              fontSize: 12,
              color: 'red',
            }}
          >
            {authError}
          </Text>
        )}

        <button
          style={{
            border: 'none',
            background: 'none',
            width: '100%',
            padding: 0,
          }}
          type="submit"
          disabled={code.length < 6}
        >
          <ButtonX
            type="solid"
            loading={loading}
            title="Sign In"
            addStyle={{
              borderRadius: 10,
              backgroundColor: code.length < 6 ? colors.shade2 : colors.balance,
              marginBottom: 20,
              marginTop: 10,
              width: '100%',
            }}
          />
        </button>
      </form>

      <Timer onResendPress={() => startAgian()} timeLeft={timeLeft} setTimeLeft={setTimeLeft} />

      <ButtonX
        type="solid"
        loading={loading}
        title="OTP by WhatsApp"
        addStyle={{
          borderRadius: 10,
          backgroundColor: timeLeft ? colors.shade2 : colors.balance,
          marginVertical: 10,
          marginTop: 10,
          width: '100%',
        }}
        disabled={timeLeft}
        onPress={() => handleOtpRetry('whatsapp')}
      />

      <ButtonX
        type="solid"
        loading={loading}
        title="Retry OTP by SMS"
        addStyle={{
          borderRadius: 10,
          backgroundColor: timeLeft ? colors.shade2 : colors.balance,
          marginVertical: 10,
          marginTop: 10,
          width: '100%',
        }}
        disabled={timeLeft}
        onPress={() => handleOtpRetry('twilioSms')}
      />

      <br />
      <TextBS style={{ color: colors.notice }}>
        WhatsApp option available once the countdown is complete.
        <br />
        <br />
        <br />
        <br />
      </TextBS>
    </View>
  );
}

export default connect(null, { linkAuthProvider, getCreateUserObj })(EnterOTPForm);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    paddingTop: 80,
    alignItems: 'center',
    backgroundColor: 'white',
  },
  message: {
    fontSize: 14,
    color: colors.secondary,
  },

  footer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
  },
});
