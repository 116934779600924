import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import {
  View,
  Image,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
} from "react-native";
import * as RootNavigation from "../../RootNavigation.js";
import { FontAwesome5 } from "@expo/vector-icons";
import { LinearGradient } from "expo-linear-gradient";
import { dimensions, colors, typography } from "../../utils/theme";
import ButtonX from "../../components/buttons/ButtonX";
import { Card, CardGradient, Typo3Way } from "../../components/common";
import Text from "../../components/Text/TextLight";
import TextBold from "../../components/Text/TextBold";
import TextBoldSmall from "../../components/Text/TextBoldSmall";
import TicketsQR from "../../assets/svg/ticketsIcon2.svg";

export default function (props) {
  const { navigation } = props;
  return (
    <CardGradient
      {...props}
      style={{
        padding: 15,
        // marginBottom: 0,
        // shadowColor: "rgba(0,0,0,0.2)",
        // shadowOffset: { width: 0, height: 8 },
        // shadowOpacity: 1,
        // shadowRadius: 20,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <FontAwesome5
            name="cocktail"
            size={28}
            color="rgba(255,255,255,0.7)"
            style={{ marginLeft: 10 }}
          />
          {/* <Image
            source={{ uri: TicketsQR }}
            resizeMode="contain"
            style={{
              height: 45,
              width: 45,
            }}
          /> */}

          <TextBoldSmall style={{ color: "white", marginLeft: 20 }}>
            Queue-less order
          </TextBoldSmall>
        </View>

        <ButtonX
          addStyle={{
            width: "25%",
            height: 50,
            backgroundColor: "rgba(255,255,255,0.2)",
          }}
          type="solid"
          title="Go"
          onPress={() => {
            navigation.navigate("Express Order");
            props.onPress();
          }}
        />
      </View>
    </CardGradient>
  );
}
