import React, { useState, useRef, useEffect } from 'react';
import { useWindowDimensions } from 'react-native';
import { connect, useSelector } from 'react-redux';
import {
  View,
  Image,
  TextInput,
  ScrollView,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
} from 'react-native';
import HTMLView from 'react-native-htmlview';
import { useFonts } from 'expo-font';
import Text from '../../components/Text/TextLight';
import { Button } from 'react-native-elements';
import RenderHtml from 'react-native-render-html';
import NavButtonBack from '../../components/common/NavButtonBack';

import { dimensions, colors, typography } from '../../utils/theme';

export default function (props) {
  const { eventFocus } = useSelector((state) => state.config);

  return (
    <View style={styles.modalContainer}>
      <NavButtonBack
        {...props}
        title="Organiser's refund policy"
        style={{
          zIndex: 10,
          borderTopRightRadius: 15,
          borderTopLeftRadius: 15,
          overflow: 'hidden',
        }}
        onBackPress={() => props.setShowTermsModal(false)}
      />
      <View style={{ height: 60 }} />

      <ScrollView contentContainerStyle={styles.scrollContainer}>
        <HTMLView
          value={
            '<div>' +
              (eventFocus?.policy?.refund_policy || '') +
              '<br /><br />' +
              (eventFocus?.policy?.org_policy || '') +
              '<br /><br /></div>' || ''
          }
          stylesheet={styles}
        />
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  div: { fontFamily: 'open-sans-reg' },
  modalContainer: {
    flex: 1,
    width: dimensions.width,
    backgroundColor: colors.bg,
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
    margin: -20,
    marginTop: 10,
  },
  scrollContainer: {
    fontFamily: 'inter-bold',
    flex: 1,
    width: '100%',
    padding: 15,
    paddingTop: 20,

    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  textParagraph: {
    textAlign: 'center',
    width: '90%',
    color: colors.textSolid,
    paddingBottom: 40,
  },
});
