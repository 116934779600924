import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import {
  View,
  Image,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
} from "react-native";
import { dimensions, colors, typography } from "../../utils/theme";
import { Feather } from "@expo/vector-icons";
import TextSemi from "../Text/TextS";

export default function RemoveButton({ onRemove }) {
  return (
    <TouchableOpacity
      onPress={onRemove}
      style={[styles.incContainer, { alignItems: "center" }]}
    >
      <Feather
        name="trash-2"
        size={18}
        color={colors.alert}
        style={{ marginRight: 10 }}
      />
      <TextSemi style={{ color: colors.alert }}>Remove</TextSemi>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  incContainer: {
    height: 50,
    width: dimensions.width / 2.8,
    borderRadius: 10,
    margin: 10,
    backgroundColor: colors.bg2,
    flexDirection: "row",
    justifyContent: "center",
    //
    borderWidth: 1,
    borderColor: colors.shade1,
    shadowColor: "rgba(0,0,0,0)", // cancel shadow
    // shadowColor: "rgba(0,0,0,0.2)",
    // shadowOffset: { width: 0, height: 0 },
    // shadowOpacity: 0.5,
    // shadowRadius: 15,
  },
});
