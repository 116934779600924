import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { View, TouchableOpacity, StyleSheet } from "react-native";
import { Button } from "react-native-elements";
import { Ionicons } from "@expo/vector-icons";
import { Card, Typo3Way } from "../../../components/common";
import ScanFrame from "./extras/ScanFrame";
import ModalHeader1 from "../../../components/headers/Header1";
import { dimensions, colors, typography } from "../../../utils/theme";
import TextBold from "../../../components/Text/TextBold";
import TextBoldSmall from "../../../components/Text/TextBoldSmall";

const FailedScan = (props) => {
  const { onOk } = props;
  return (
    <View style={{ alignItems: "center" }}>
      <Typo3Way
        subTitle="Tag Link"
        title="Failed"
        body="Your RFID card may already be in use, be invalid or faulty. Make your way to the nearest help station to resolve the problem."
      />
      {
        //   <ScanFrame color={colors.alert}>
        //   <Ionicons name="md-close-circle" size={60} color={colors.alert} />
        // </ScanFrame>
      }
      <Ionicons
        name="md-close-circle"
        size={60}
        color={colors.alert}
        style={{ margin: 20 }}
      />
      <TextBoldSmall style={{ color: colors.alert, fontSize: 18, margin: 15 }}>
        Tag already in use!
      </TextBoldSmall>
      <Button
        title="OK"
        type="outline"
        buttonStyle={{
          height: 50,
          width: 180,
          margin: 10,
          borderRadius: 25,
          borderWidth: 2,
          borderColor: colors.alert,
          //backgroundColor: colors.pay,
          //alignSelf: "flex-start"
        }}
        titleStyle={{ fontWeight: "bold", fontSize: 14, color: colors.alert }}
        onPress={onOk}
      />
    </View>
  );
};

export default function (props) {
  return (
    <Card>
      <FailedScan {...props} />
    </Card>
  );
}
