import { firestore, functions } from '../../firebase/config';
import { Linking } from 'react-native';
import * as RootNavigation from '../RootNavigation.js';
import generateId from '../utils/lengthID';
import {
  SHOW_BALANCE_SPINNER,
  SHOW_IN_PROGRESS,
  PAYMENT_RECEIVED_TICKET,
  PAYMENT_RECEIVED_TOPUP,
  PAYMENT_FAILED,
  SHOW_IN_PROGRESS_MODAL,
  SHOW_PAYMENT_PENDING_WIDGET,
  SHOW_HAPPY_PAY_MODAL,
} from '../actions/types';
import { showInProgress, showProgressModal } from './CartActions';
import { setAuthError } from './AuthActions';

const { APP_MANIFEST } = process.env;
const { baseUrl, merchId, yocoPublicKey } = APP_MANIFEST.extra;

const now = new Date();
//
//const userRef = firestore.collection("customers");
const pendingRef = (uid) => firestore.collection('PENDING_PAYMENTS').doc(uid);
const userRewardsref = (uid) => firestore.collection('customers').doc(uid).collection('rewards');
//

//   HELPER functions   //   HELPER functions   //   HELPER functions   //   HELPER functions
const spinner = (bool) => (dispatch) => dispatch({ type: SHOW_BALANCE_SPINNER, bool });
const inProgress = (bool) => (dispatch) => dispatch({ type: SHOW_IN_PROGRESS, bool });
export const toggleHappyModal = (bool) => (dispatch) =>
  dispatch({ type: SHOW_HAPPY_PAY_MODAL, bool });

const paymentReceived =
  ({ paymentType, qlessOrderNumber }) =>
  (dispatch) => {
    if (paymentType === 'splitTicket' || paymentType === 'buyTicket') {
      dispatch({ type: PAYMENT_RECEIVED_TICKET }); // this also cancels the progress modal, and clears the subcart
      RootNavigation.navigate('Passes');
    } else if (paymentType === 'topUp') {
      dispatch({ type: PAYMENT_RECEIVED_TOPUP });
      RootNavigation.navigate('Wallet');
    } else if (paymentType === 'qlessOrder') {
      dispatch({ type: PAYMENT_RECEIVED_TOPUP, qlessOrderNumber });
      RootNavigation.goBack();
    }
  };
export const paymentFail = (bool) => ({ type: PAYMENT_FAILED, bool }); // this cancels the progress modal, but NOT the subcart

//  ON ZERO PAY  //  ON ZERO PAY  //  ON ZERO PAY  //  ON ZERO PAY  //  ON ZERO PAY  //  ON ZERO PAY

export const onPayZero = (props) => async (dispatch, useState) => {
  const PayZero = functions.httpsCallable('payZero');

  const { paymentType, user, amount, subCart } = props;

  dispatch(spinner(true)); // start balance spinner //true for all "paymentType"
  dispatch(inProgress(true));

  try {
    const res = await PayZero(props);
    dispatch(
      paymentReceived({
        paymentType,
      }),
    );
    dispatch(spinner(false));
  } catch (err) {
    console.log('PayZero ERROR ', err);
    dispatch(spinner(false));
    dispatch(paymentFail(true));
    dispatch(setAuthError('Could not complete order'));
  }
};

export const onYocoCheckout = (props) => async (dispatch, useState) => {
  const { user } = useState().users;
  const { paymentType, amount, subCart, orgId, id, type } = props;
  const YocoCheckout = functions.httpsCallable('yocoCheckout');
  dispatch(spinner(true));
  const cancelUrl = await Linking.getInitialURL();
  const successUrl = baseUrl + 'payment/success';
  const failureUrl = baseUrl + 'payment/failed';

  const yocoCheckoutParams = {
    isDev: process.env.NODE_ENV === 'development',
    cancelUrl,
    successUrl,
    failureUrl,
    amount: Math.round(amount * 100),
    user,
    paymentType,
    // why not use "rewardsAgent" value
    rewardUid: !!props?.route?.params?.uid ? props?.route?.params?.uid : null,
    // userId: user._id,
    // cellNo: user.cellphoneNumber,
    // email: user.email,
    subCart,
  };

  try {
    console.log('yocoCheckoutParams', yocoCheckoutParams);
    const { data } = await YocoCheckout(yocoCheckoutParams);
    const { yocoRes, error } = data;
    const { redirectUrl, id, metadata } = yocoRes;
    // console.log({ redirectUrl, id, metadata });

    Linking.openURL(redirectUrl);
  } catch (error) {
    console.log('YocoCheckout Error: ', error);
    console.error(error);
  }
};

//   ON YOCO PAY   //   ON YOCO PAY   //   ON YOCO PAY   //   ON YOCO PAY   //   ON YOCO PAY
export const onYocoPay = (props) => (dispatch, useState) => {
  // paymentTypes: "buyTicket", "splitTicket", "topUp"

  const { user } = useState().users;

  const { paymentType, amount, subCart, orgId, id, type } = props;

  dispatch(spinner(true)); // start balance spinner //true for all "paymentType"
  // paymentType === 'buyTicket' && dispatch(inProgress(true));

  var YocoSplitPay = functions.httpsCallable('yocoSplitPay');
  var YocoPay = functions.httpsCallable('yocoPay');

  var yoco = new YocoSDK({
    publicKey: yocoPublicKey,
  });

  yoco.showPopup({
    amountInCents: Math.round(amount * 100),
    currency: 'ZAR',
    customer: {
      phone: user.cellphoneNumber,
    },
    metadata: {
      userId: user._id,
      cellNo: user.cellphoneNumber,
      email: user.email,
    },
    callback: function (result) {
      if (result.error) {
        const errorMessage = result.error.message;
        console.log('Yoco error: ' + errorMessage);
      } else {
        paymentType === 'splitTicket' && dispatch(inProgress(true));
        paymentType === 'buyTicket' && dispatch(inProgress(true));
        paymentType === 'topUp' &&
          dispatch(
            showProgressModal({
              bool: true,
              progressTotalAmount: amount,
              progressMode: 'top_up',
            }),
          ); // "amount" for display purpose only
        paymentType === 'qlessOrder' &&
          dispatch(
            showProgressModal({
              bool: true,
              progressMode: 'qless',
              progressTotalAmount: amount,
            }),
          );

        YocoPay({
          isDev: process.env.NODE_ENV === 'development',
          token: result.id,
          amount: Math.round(amount * 100),
          // type,
          user,
          userId: user._id,
          cellNo: user.cellphoneNumber,
          email: user.email,
          subCart,
          paymentType,
          // why not use "rewardsAgent" value
          rewardUid: !!props?.route?.params?.uid ? props?.route?.params?.uid : null,
        })
          .then((result) => {
            const { response, yocoError, values } = result.data;
            // "values" from function promises
            dispatch(spinner(false)); // stop balance spinner

            !!yocoError &&
              dispatch(paymentFail(true)) &&
              dispatch(setAuthError(JSON.parse(yocoError).displayMessage));
            // console.log("JSON.parse(yocoError): ", JSON.parse(yocoError));

            if (!!response && !values) {
              // stop progress spinner, reset subCart
              dispatch(
                paymentReceived({
                  paymentType,
                }),
              );
            }
            if (!!response && !!values) {
              // stop progress spinner, reset subCart
              dispatch(
                paymentReceived({
                  paymentType,
                  qlessOrderNumber: (!!values && values.reduce((a, b) => b.orderNumber, 0)) || null,
                }),
              );
            }
          })
          .catch((err) => {
            dispatch(spinner(false));
            dispatch(showInProgress(false));
            console.error(err);
          });
      }
    },
    onCancel: function () {
      console.log('Yoco payment cancelled!');
      dispatch(spinner(false));
    },
    image:
      'https://firebasestorage.googleapis.com/v0/b/cashless-c137a.appspot.com/o/ADround.png?alt=media&token=8a03c20d-5a89-47cb-8535-0c67a7f34806',
  });
};

export const onZapperPay = (props) => (dispatch) => {
  const { user, paymentType, amount, subCart } = props;
  const orderId = generateId(15);
  const productId = 'productId';
  const paymentId = 'paymentId';
  const affiliate = 'affiliate';
  const eventName = 'eventName';

  const zapperUrl = encodeURI(
    `http://2.zap.pe?t=6&i=51499:65013:7[34|${amount}|11,33n|${orderId}|10:8[0n|${user._id}|10|customerId,0n|${productId}|10|productId,0n|${paymentId}|10|paymentId,0n|${affiliate}|10|affiliateId:10[39|ZAR,38|${eventName}`, //
  );
  const callbackUrl = encodeURI('https://airdosh.web.app/');
  Linking.openURL(
    `https://www.zapper.com/payWithZapper/?qr=${zapperUrl}&successCallbackURL=${callbackUrl}`,
  );
  setTimeout(() => {
    RootNavigation.goBack();
  }, 500);
};

export const onSnapScanPay = (props) => (dispatch) => {
  const {
    user,
    paymentType,
    amount,
    subCart,
    //orgId,
    //event_id
  } = props;
  // const merchId = "w0BABvEj"; // <- Live key  // TEST key -> "fFXg8XUa"
  const orderId = generateId(15);
  const productId = 'productId';
  const paymentId = 'paymentId';
  const affiliate = 'affiliate';
  const eventName = 'eventName';
  const statementReference = user.cellphoneNumber; // + "_" + orgId + "_" + event_id;
  // console.log("Props in Snapscan", statementReference);

  if (paymentType === 'buyTicket' || paymentType === 'splitTicket') {
    // Linking.openURL(
    //   `https://pos.snapscan.io/qr/${merchId}?id=${orderId}&statementReference=${statementReference}&userId=${
    //     user._id
    //   }&cellNo=${user.cellphoneNumber}&type=${paymentType}&amount=${
    //     amount * 100
    //   }&strict=true`
    // );
    // console.log('snapscan object', {
    //   createdAt: new Date(),
    //   paymentType,
    //   provider: 'snapscan',
    //   orderId,
    //   user,
    //   userId: user._id,
    //   cellNo: user.cellphoneNumber,
    //   email: user.email,
    //   amount: Math.round(amount * 100),
    //   subCart, //: reducedSubcart,
    //   rewardUid: !!props?.route?.params?.uid ? props?.route?.params?.uid : null,
    //   //   shouldRewardAffiliate({ user, subCart }) &&
    //   //   props?.route?.params?.uid) ||
    //   // null,
    // });

    pendingRef(orderId).set({
      createdAt: new Date(),
      paymentType,
      provider: 'snapscan',
      orderId,
      user,
      userId: user._id,
      cellNo: user.cellphoneNumber,
      email: user.email,
      amount: Math.round(amount * 100),
      subCart, //: reducedSubcart,
      rewardUid: !!props?.route?.params?.uid ? props?.route?.params?.uid : null,
      //   shouldRewardAffiliate({ user, subCart }) &&
      //   props?.route?.params?.uid) ||
      // null,
    });

    Linking.openURL(
      `https://pos.snapscan.io/qr/${merchId}?id=${orderId}&statementReference=${statementReference}&userId=${
        user._id
      }&cellNo=${user.cellphoneNumber}&type=${paymentType}&amount=${Math.round(
        amount * 100,
      )}&strict=true`,
    );

    setTimeout(() => {
      RootNavigation.navigate('Passes');
    }, 500);

    dispatch({
      type: SHOW_PAYMENT_PENDING_WIDGET,
      bool: true,
      message:
        'You have a SNAP SCAN payment pending. Once succesfully completed, your purchased items will appear in your wallet. If failed, you may start again.',
    });
  }
  if (paymentType === 'topUp') {
    // Linking.openURL(
    //   `https://pos.snapscan.io/qr/${merchId}?id=${orderId}&userId=${
    //     user._id
    //   }&cellNo=${user.cellphoneNumber}&type=${paymentType}&amount=${
    //     amount * 100
    //   }&strict=true`
    // );
    pendingRef(orderId)
      .set({
        createdAt: new Date(),
        paymentType,
        provider: 'snapscan',
        orderId,
        userId: user._id,
        cellNo: user.cellphoneNumber,
        email: user.email,
        amount: Math.round(parseInt(amount) * 100),
      })
      .then(() => {
        setTimeout(() => {
          RootNavigation.goBack();
        }, 500);
        dispatch({
          type: SHOW_PAYMENT_PENDING_WIDGET,
          bool: true,
          message:
            'You have a SNAP SCAN TOP UP pending, once succesfully completed your Top Up will appear in your wallet. If cancelled, you may try again.',
        });
      });
    Linking.openURL(
      `https://pos.snapscan.io/qr/${merchId}?id=${orderId}&userId=${user._id}&cellNo=${
        user.cellphoneNumber
      }&type=${paymentType}&amount=${Math.round(amount * 100)}&strict=true`,
    );
  }
};

export const onAirDoshPay = (props) => (dispatch) => {
  const { user, paymentType, amount, subCart } = props;

  //var AirDoshPay = functions.httpsCallable("airDoshPay");
  var AirDoshPay = functions.httpsCallable('airdoshPay');
  dispatch(spinner(true)); // start balance spinner //true for all "paymentType"
  dispatch(inProgress(true));

  AirDoshPay({ user, paymentType, amount, subCart }).then((result) => {
    dispatch(spinner(false)); // stop balance spinner
    dispatch(paymentReceived({ paymentType })); // stop progress spinner, reset subCart
  });
};

export const onHappyPay = (props) => async (dispatch, useState) => {
  const { user } = useState().users;
  const { paymentType, amount, subCart, orgId, id, type } = props;
  var createHappyOrder = functions.httpsCallable('createHappyPayOrder');

  dispatch({ type: SHOW_BALANCE_SPINNER, bool: true });

  const res = await createHappyOrder({
    amount,
    type,
    user,
    subCart,
    paymentType,
    // why not use "rewardsAgent" value
    rewardUid: !!props?.route?.params?.uid ? props?.route?.params?.uid : null,
  });

  const { d } = JSON.parse(res.data);
  const { errorMessage, redirectUrl, success } = d;

  if (!success) {
    console.log('Happy Pay Failed');
    dispatch({ type: SHOW_BALANCE_SPINNER, bool: false });
  } else {
    dispatch({ type: SHOW_HAPPY_PAY_MODAL, bool: true, redirectUrl });
    dispatch({ type: SHOW_BALANCE_SPINNER, bool: false });
  }
};
