import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import {
  View,
  FlatList,
  Image,
  Dimensions,
  Animated,
  Text,
  StyleSheet,
  Platform,
} from 'react-native';
import * as Animatable from 'react-native-animatable';
import PosterItem1 from './PosterItem1';
import { dimensions, colors } from '../../utils/theme';

// Carousel inspired by this article
//https://medium.com/code-duo/autoscrolling-list-in-react-native-532a4a1041f9

const ImageCarousel = (props) => {
  const { addStyle, carouselData, productList } = props;
  const { titleImage } = props.eventFocus;

  const [expand, setExpand] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      props.onMounted(true);
    }, 500);
  }, []);

  const [scrollViewWidth, setScrollViewWidth] = React.useState(dimensions.width);
  const boxWidth = dimensions.width;
  const boxDistance = scrollViewWidth - boxWidth;
  const halfBoxDistance = boxDistance / 2;
  //
  const scrollX = useRef(new Animated.Value(0)).current;
  const SPACING = 5;
  const ITEM_SIZE = dimensions.width * 0.7; // Platform.OS === "ios" ? width * 0.72 : width * 0.74;
  const EMPTY_ITEM_SIZE = (dimensions.width - ITEM_SIZE) / 2;

  const pan = React.useRef(new Animated.ValueXY()).current;

  return (
    <Animatable.View animation="zoomInUp" duration={500}>
      <FlatList
        horizontal
        data={[{ url: titleImage }]}
        style={{
          backgroundColor: 'transparent',
          width: dimensions.width,
          paddingVertical: 15,
          marginVertical: -15,
        }}
        contentInsetAdjustmentBehavior="never"
        snapToAlignment="center"
        decelerationRate="fast"
        automaticallyAdjustContentInsets={false}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        scrollEventThrottle={1}
        snapToInterval={dimensions.width}
        contentInset={{
          left: halfBoxDistance,
          right: halfBoxDistance,
        }}
        contentOffset={{ x: halfBoxDistance * -1, y: 0 }}
        onScroll={Animated.event([{ nativeEvent: { contentOffset: { x: pan.x } } }], {
          useNativeDriver: false,
        })}
        keyExtractor={(item, index) => `${index}-${item}`}
        pagingEnabled={true}
        renderItem={({ item, index }) => (
          <Animated.View
            style={{
              transform: [
                {
                  scale: pan.x.interpolate({
                    inputRange: [
                      (index - 1) * boxWidth - halfBoxDistance,
                      index * boxWidth - halfBoxDistance,
                      (index + 1) * boxWidth - halfBoxDistance, // adjust positioning
                    ],
                    outputRange: [
                      0.8,
                      1 + index / 1000,
                      0.8, //- (index + 1 / 100)
                    ], // scale down when out of scope
                    extrapolate: 'clamp',
                  }),
                },
              ],
              // marginRight:
              // carouselData.length - 1 === index ? 0 : -70 + (index + 15),
            }}
          >
            <PosterItem1 item={item} />
          </Animated.View>
        )}
      />
    </Animatable.View>
  );
};

const mapStateToProps = (state) => {
  const { productList } = state.store;
  const { userPasses } = state.users;
  return {
    productList,
    userPassesIds: userPasses.map((pass) => pass.prod_id),
  };
};
export default connect(mapStateToProps, {})(ImageCarousel);

const styles = StyleSheet.create({
  imageShadow: {
    shadowColor: 'rgba(0,0,0,1)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 1,
    shadowRadius: 1,
  },
});
