import { firebase, firestore, auth } from '../../firebase/config';
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  View,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
  SafeAreaView,
  ScrollView,
} from 'react-native';
import Lottie from 'react-lottie';
import animationData from '../assets/lottie/paper-plane';
import Modal from 'modal-enhanced-react-native-web';
import { ButtonGroup } from 'react-native-elements';
import * as Animatable from 'react-native-animatable';
import Text from '../components/Text/TextLight';
import TextBold from '../components/Text/TextBold';
import TextBS from '../components/Text/TextBoldSmall';
import InputMat from '../components/common/MaterialInput';
import { Button } from 'react-native-elements';
import ButtonX from '../components/buttons/ButtonX';
import BackArrow from '../components/common/BackArrow';
import { Card } from '../components/common';
import ModalHeader1 from '../components/headers/Header1';
import MergeAccountsModal from '../views/Modals/auth/MergeAccountsModal';
// import OAuthOptions from "./OAuthOptions";
import { dimensions, colors, typography } from '../utils/theme';
import {
  foundUser,
  showAuthLoadingModal,
  setAuthError,
  showAuthModal,
  linkAuthProvider,
  linkProviderMergeAccount,
} from '../actions';

const userRef = firestore.collection('customers');

const formatPhoneNumber = (stuff) => {
  const { phoneNumber, setPhoneNumber } = stuff;
  phoneNumber.charAt(0) === '0' && setPhoneNumber(phoneNumber.replace('0', '+27'));
};

const Timer = ({ seconds, onResendPress, timeLeft, setTimeLeft }) => {
  useEffect(() => {
    // exit early when we reach 0
    if (timeLeft <= 0) return setTimeLeft(null);

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  return (
    <View>
      <View style={{ alignItems: 'center' }}>
        <Text style={{ color: colors.textSolid }}> Didn't receive an SMS?</Text>
        {!timeLeft ? null : (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text style={{ color: colors.textSolid }}>You may retry in </Text>
            <TextBold
              style={{
                width: 28,
                fontSize: 16,
                marginBottom: -2,
                color: colors.textSolid,
                textAlign: 'center',
              }}
            >
              {timeLeft}
            </TextBold>
            <Text style={{ color: colors.textSolid }}> seconds</Text>
          </View>
        )}
        <TouchableOpacity
          style={{ padding: 10 }}
          onPress={() => {
            onResendPress();
            setTimeLeft(60); // reset the timer to start again from 60s
            window.recaptchaVerifier2?.clear();
          }}
          disabled={timeLeft}
        >
          {
            // removed temporarily to make room for the whatsapp and sms retry buttons
          }
          {/* <Text style={{ color: timeLeft ? colors.shade2 : colors.balance }}>Start again</Text> */}
        </TouchableOpacity>
      </View>
    </View>
  );
};

const SignInPhoneForm = ({ stuff }) => {
  const inputRef = useRef(null);
  const { authError, phoneNumber, setPhoneNumber, loading, signInMethod, sendVerification } = stuff;
  return (
    <View>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          formatPhoneNumber({ ...stuff });
          sendVerification();
        }}
      >
        <View>
          <InputMat
            required
            thisRef={inputRef}
            label="Phone Number"
            placeholder="e.g. +27823334444"
            onChangeText={setPhoneNumber}
            value={phoneNumber}
            onBlur={() => formatPhoneNumber({ ...stuff })}
            // autoFocus={!parsedCelllNo}
            keyboardType="phone-pad"
            autoComplete="tel"
            autoCompleteType="tel"
            // autoFocus={true}
            checkValidation={(val) => val.length < 10 && 'Mobile number too short'}
          />
          {!!authError && (
            <Text
              style={{
                marginTop: 15,
                textAlign: 'center',
                fontSize: 12,
                color: 'red',
              }}
            >
              {authError}
            </Text>
          )}
        </View>

        <button
          style={{
            border: 'none',
            background: 'none',
            width: '100%',
            padding: 0,
          }}
          type="submit"
          disabled={phoneNumber.length < 10}
        >
          <ButtonX
            type="solid"
            loading={loading}
            title="Sign in with OTP"
            addStyle={{
              borderRadius: 10,
              backgroundColor: phoneNumber.length < 10 ? colors.shade2 : colors.balance,
              marginVertical: 20,
              marginTop: 10,
              width: '100%',
            }}
            titleStyle={{ fontWeight: 'bold', fontSize: 16 }}
          />
        </button>
        <View>
          <Text
            style={{
              textAlign: 'center',
              fontSize: 12,
              color: colors.textSolid,
              marginVertical: 20,
            }}
          >
            You will receive a verification One Time Pin via SMS. Standard rates apply.
          </Text>
        </View>
      </form>
    </View>
  );
};

// function hasNumber(myString) {
//   return /\d/.test(myString);
// }

const EnterOTPForm = ({ stuff }) => {
  const {
    loading,
    setStep,
    onModalCancel,
    code,
    setCode,
    confirmCode,
    timeLeft,
    setTimeLeft,
    authError,
    startAgian,
  } = stuff;
  return (
    <View
      style={{
        flex: 1,
        width: '100%',
        alignItems: 'center',
        paddingTop: 80,
      }}
    >
      <ModalHeader1
        hideClose={true}
        heading="Enter OTP"
        subHeading="SMS has been sent"
        onClose={() => {
          setStep(1);
          onModalCancel(false);
        }}
      />
      <form
        onSubmit={(event) => {
          event.preventDefault();
          confirmCode();
        }}
        style={{ width: '100%' }}
      >
        <InputMat
          label="OTP"
          placeholder="enter One Time Pin"
          onChangeText={setCode}
          value={code}
          keyboardType="number-pad"
          textContentType="oneTimeCode"
          autoComplete="sms-otp"
          checkValidation={(val) =>
            (!val.length && 'Required') ||
            (val.length < 6 && 'OTP too short') ||
            (!/\d/.test(val) && 'Enter only numbers')
          }
        />
        {authError && (
          <Text
            style={{
              textAlign: 'center',
              fontSize: 12,
              color: 'red',
            }}
          >
            {authError}
          </Text>
        )}
        <button
          style={{
            border: 'none',
            background: 'none',
            width: '100%',
            padding: 0,
          }}
          type="submit"
          disabled={code.length < 6}
        >
          <ButtonX
            type="solid"
            loading={loading}
            title="Sign In"
            addStyle={{
              borderRadius: 10,
              backgroundColor: code.length < 6 ? colors.shade2 : colors.balance,
              marginVertical: 20,
              marginTop: 10,
              width: '100%',
            }}
          />
        </button>
      </form>

      <Timer onResendPress={() => startAgian()} timeLeft={timeLeft} setTimeLeft={setTimeLeft} />
    </View>
  );
};

const SuccessMessage = ({ closeModalClear }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: colors.bg1,
        // paddingHorizontal: 20,
        width: '100%',
      }}
    >
      <View style={{ margin: -20 }}>
        <Lottie options={defaultOptions} height={220} width={dimensions.width} />
      </View>
      <div>
        <div style={{ paddingVertical: 20 }}>
          <span>
            {' '}
            <TextBS style={[styles.message, { color: colors.black, fontSize: 20 }]}>
              Verification
            </TextBS>
          </span>
          <span>
            {' '}
            <TextBS style={[styles.message, { color: colors.pay, fontSize: 20 }]}>
              email sent!
            </TextBS>
          </span>
        </div>
        <TextBS style={[styles.message, { color: colors.shade3 }]}>
          To complete sign in, please check your email inbox, and click the verification link -
          'sign in to AirDosh'
        </TextBS>
      </div>

      <ButtonX
        type="solid"
        title="OK"
        addStyle={{
          borderRadius: 10,
          marginVertical: 20,
          marginTop: 10,
          width: '100%',
        }}
        titleStyle={{ fontWeight: 'bold', fontSize: 14 }}
        onPress={closeModalClear}
      />
    </View>
  );
};

const closeModalClear = () => {
  onModalCancel(false);
  authError !== null && setAuthError(null) && setStep(1);
  setSendSucces(false);
};

function RecaptchaPage(props) {
  const { navigation } = props;
  const { onModalCancel, showAuthModal } = props;
  const { user, authError, setAuthError, isSignUp, parsedCelllNo, parsedEmail } = props; // mapped from state

  // const inputRef = useRef(null);
  const captchaRef_2 = useRef(null);

  const [loading, setLoading] = useState(false);
  const [showMergeModal, setMergeModal] = useState(false);
  const [mergeCredential, setMergeCredential] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(parsedCelllNo || '+27');
  const [code, setCode] = useState('');
  const [verificationId, setVerificationId] = useState(null);
  const [step, setStep] = useState(1); //1
  // initialize timeLeft with the seconds prop
  const [timeLeft, setTimeLeft] = useState(60);
  const [captchaAttempt, setCaptchaAttempt] = useState(1);

  useEffect(() => {
    window.recaptchaVerifier2 = new firebase.auth.RecaptchaVerifier('reCAPTCHA-container_2', {
      size: 'invisible',
    });
    firebase.auth().useDeviceLanguage();
    return () => !!window.recaptchaVerifier2 && window.recaptchaVerifier2?.clear();
  }, []);

  // useEffect(() => {
  //   // this is to force update the parsed Email addy to the input as i couldn't get it to work normally, bad day.
  //   !!parsedCelllNo && setPhoneNumber(parsedCelllNo);
  //   !!parsedEmail && setEmail(parsedEmail);
  // }, [parsedCelllNo, parsedEmail]);

  // Function to be called when requesting for a verification code
  const phoneProvider = new firebase.auth.PhoneAuthProvider();

  const startAgian = ({ setAuthError, setStep }) => {
    setAuthError(null);
    setStep(1);
    !!window.recaptchaVerifier2 && window.recaptchaVerifier2.clear();
  };

  const sendVerification = async () => {
    setAuthError(null);
    setLoading(true);
    // console.log('Recaptcha Page', { finalNumberMustBeCorrectlyFormatted: phoneNumber });

    try {
      const appVerifier = window.recaptchaVerifier2;

      firebase
        .auth()
        .signInWithPhoneNumber(phoneNumber, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setStep(2);
          setLoading(false);
        })
        .catch((error) => {
          console.log('Sign in error', error);
          setAuthError(error.message);
          setLoading(false);
        });
    } catch (err) {
      console.error('sendVerification ERROR', err);
      setLoading(false);
      setAuthError(err.message);
    }
  };

  // Function to be called when confirming the verification code that we received
  const confirmCode = async (props) => {
    setAuthError(null);
    setLoading(true);

    var credential = firebase.auth.PhoneAuthProvider.credential(
      window.confirmationResult.verificationId,
      code,
    );
    props
      .linkAuthProvider(credential)
      .then((usercred) => {
        var user = usercred?.user;
        setLoading(false);
        props.showAuthLoadingModal(true);
        setStep(1);
      })
      .catch((error) => {
        console.log('Account linking error', error);
        setLoading(false);
        setMergeModal(true);
        setMergeCredential(credential);
        props.showAuthLoadingModal(false);
      });

    setCode('');
  };

  return (
    <View style={styles.modalContainer}>
      <View
        key={captchaAttempt}
        ref={captchaRef_2}
        nativeID="reCAPTCHA-container_2"
        style={{ background: 'white' }}
      ></View>

      <View
        style={{
          flex: 1,
          width: '100%',
        }}
      >
        {step === 1 && (
          <View>
            <ModalHeader1
              hideClose={true}
              heading="Sign In"
              subHeading="Password-less"
              description="Sign up with email + mobile number. Use password-less OTP or Email and Password for future sign-ins."
            />
            <View>
              <SignInPhoneForm
                stuff={{
                  authError,
                  phoneNumber,
                  setPhoneNumber,
                  loading,
                  sendVerification,
                }}
              />

              {/* { sendSuccess && (
              <SuccessMessage closeModalClear={closeModalClear} />
            )} */}
            </View>
          </View>
        )}
        {step === 2 && (
          <EnterOTPForm
            stuff={{
              loading,
              setStep,
              onModalCancel,
              code,
              setCode,
              confirmCode,
              timeLeft,
              setTimeLeft,
              authError,
              startAgian,
            }}
          />
        )}
      </View>
      <Modal isVisible={showMergeModal} onBackdropPress={() => setMergeModal(false)}>
        <MergeAccountsModal
          {...props}
          loading={loading}
          onModalCancel={() => setMergeModal(false)}
          onOk={() => {
            setLoading(true);
            props.linkProviderMergeAccount(mergeCredential).then(() => setLoading(false));
          }}
        />
      </Modal>
    </View>
  );
}

const mapStateToProps = (state) => {
  const { user } = state.users;
  const { authError } = state.auth;
  const { eventFocus } = state.config;

  return {
    user,
    authError,
    eventFocus,
  };
};

export default connect(mapStateToProps, {
  foundUser,
  showAuthLoadingModal,
  showAuthModal,
  setAuthError,
  // getCreateUserObj,
  linkAuthProvider,
  linkProviderMergeAccount,
})(RecaptchaPage);

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    padding: 20,
    paddingTop: 80,
    alignItems: 'center',
    backgroundColor: 'white',
  },
  message: {
    fontSize: 14,
    color: colors.secondary,
  },

  footer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
  },
  // inputText: {
  //   marginHorizontal: 0, // cancels out whatever there is
  // },
});
