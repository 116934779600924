import React from "react";
import { TouchableOpacity } from "react-native";
import { createStackNavigator } from "@react-navigation/stack";
import { FontAwesome } from "@expo/vector-icons";
import Passes from "../views/Passes/Passes";
// import ProductsList from "../views/Products/ProductsList";

import { colors, typography, dimensions, logo } from "../utils/theme";

const Stack = createStackNavigator();

//https://reactnavigation.org/docs/stack-navigator/#options
//Products NAVIGATION
export default function passesNav({ navigation }) {
  return (
    <Stack.Navigator
      screenOptions={{
        headerStyle: {
          backgroundColor: colors.primaryDark,
          shadowOffset: {
            height: 0,
          },
        },
        headerTitleAlign: "center",
        headerTitleStyle: {
          fontWeight: "bold",
          fontSize: 17,
          color: colors.primaryText,
        },
      }}
    >
      <Stack.Screen
        name="Passes"
        component={Passes}
        options={{
          headerShown: false,
        }}
      />
    </Stack.Navigator>
  );
}
//<Stack.Screen name="AddProduct" component={AddProduct} />
