import {
  LOGGING,
  SET_GLOBAL_LOADING,
  TOGGLE_INVITE_STATUS_SUCCESS,
  UPDATE_THEME_IMAGE,
  UPDATE_REFUND_POLICY,
  SET_SHOW_NEW_CONTENT,
  SET_EVENT_FOCUS,
  UPDATE_EVENT_FOCUS,
  //
  ADD_TO_SUBCART_SUCCESS,
  UPDATE_SUBCART_SUCCESS,
} from "../actions/types";

const INITIAL_STATE = {
  // orgId: "", // temporary
  // event_id: "", // temporary
  initialUrl: "",
  eventFocus: null,
  loading: false,
  gLoading: false,
  //this info is obtained if an organiserId was passed in deep link, and simply filters out all other global user/products/marketplace etc.
  // eventName: "",
  // organiserId: "",
  isCashless: false,
  isPrivate: false,
  inviteVerified: true,
  isEmailPasses: false,
  theme: {
    backgroundImage: require("../assets/images/chick.png"), //require("../assets/images/5l.jpg"),
    colors: {},
  },
  // privacy_policy: "",
  tag_price: 20,
  showLoadNewContent: false,
  isInstagram: false,
  carouselData: [
    {
      id: "expressOrder",
      title: "Express order",
      description: "Skip the queue, order from your phone",
      image: "https://www.enableds.com/products/appkit/images/pictures/7t.jpg",
      //"https://www.enableds.com/products/appkit/images/pictures/9t.jpg"
    },
    {
      id: 2,
      title: "Discover",
      description: "Explore events and offerings available",
      image: "https://www.enableds.com/products/appkit/images/pictures/6t.jpg",
    },
    {
      id: 3,
      title: "Special Offers",
      description: "View all deals available to you",
      image: "https://www.enableds.com/products/appkit/images/pictures/4t.jpg",
    },
    {
      id: 4,
      title: "Find your friends",
      description: "See what your friends are buying",
      image: "https://www.enableds.com/products/appkit/images/pictures/3t.jpg",
    },
  ],
};

const configReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGGING:
      return { ...state, loading: true };
    case SET_GLOBAL_LOADING:
      return { ...state, gLoading: action.bool };
    case ADD_TO_SUBCART_SUCCESS:
      return { ...state, isPrivate: action.payload.is_private || false };
    case UPDATE_SUBCART_SUCCESS:
      return { ...state, isPrivate: action.payload.is_private || false };
    case TOGGLE_INVITE_STATUS_SUCCESS:
      return { ...state, inviteVerified: action.payload };
    case UPDATE_THEME_IMAGE:
      state.theme.backgroundImage = action.payload;
      return { ...state };
    case SET_SHOW_NEW_CONTENT:
      return { ...state, showLoadNewContent: action.bool };
    case UPDATE_REFUND_POLICY:
      return {
        ...state,
        //privacy_policy: action.payload
      };

    case SET_EVENT_FOCUS:
      return { ...state, eventFocus: action.payload };
    case UPDATE_EVENT_FOCUS:
      return {
        ...state,
        eventFocus: { ...state.eventFocus, ...action.payload },
      };
    case "initUrl":
      return { ...state, initialUrl: action.initialUrl };
    case "setInstagram":
      return { ...state, isInstagram: action.isInstagram };
    default:
      return state;
  }
};

export default configReducer;
