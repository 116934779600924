import { StyleSheet } from "react-native";
import { dimensions, colors, typography } from "../../utils/theme";

const sm = 10;
const md = 15;
const lg = 20;
const xl = 25;
const xxl = 30;

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  bottomNavBar: {
    flexDirection: "row",
    margin: 10,
    paddingVertical: 10,
    paddingHorizontal: 30,
    width: dimensions.width / 2,
    justifyContent: "space-between",
    backgroundColor: "white",
    opacity: 0.5,
    borderRadius: 50,
  },
  header: {
    flex: 1,
    alignItems: "center",
    justifyContent: "space-between",
    alignSelf: "stretch",
    justifyContent: "space-between",
    alignItems: "center",
    elevation: 4,
  },

  title: {
    color: colors.primary,
    fontSize: 28,
  },
  titleOverGradient: {
    position: "absolute",
    left: 20,
    bottom: 10,
    backgroundColor: "transparent",
    fontSize: 25,
    marginBottom: 0,
    marginLeft: 0,
  },
  name: {
    fontSize: 26,
    fontWeight: "500",
    marginBottom: 20,
    marginTop: 20,
    color: colors.highlight,
    fontFamily: typography.primary,
  },
  //
  z1: { zIndex: 1 },
  abs: { position: "absolute" },

  //Dimensions
  fullWidth: { width: "100%" },
  halfHeight: { height: dimensions.height / 2 },
  fullHeight: { height: dimensions.height },
  width90: { width: "90%" },
  //flexbox

  itemsCenter: { alignItems: "center" },
  justifyBetween: { justifyContent: "space-between" },

  rowCenter: {
    flexDirection: "row",
    alignItems: "center",
  },
  rowCenterEnd: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  rowCenterAround: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },
  rowCenterBetween: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  //shadows
  shadow1: {
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 1,
    shadowRadius: 20,
  },

  // spacing & layout
  //sm
  pasm: {
    padding: sm,
  },
  pxsm: {
    paddingHorizontal: sm,
  },
  pysm: {
    paddingVertical: sm,
  },
  ptsm: {
    paddingTop: sm,
  },
  pbsm: {
    paddingBottom: sm,
  },
  plsm: {
    paddingLeft: sm,
  },
  prsm: {
    paddingRight: sm,
  },
  //==
  mamd: {
    margin: sm,
  },
  mxmd: {
    marginHorizontal: sm,
  },
  mymd: {
    marginVertical: sm,
  },
  mtmd: {
    marginTop: sm,
  },
  mbmd: {
    marginBottom: sm,
  },
  mlmd: {
    marginLeft: sm,
  },
  mrmd: {
    marginRight: sm,
  },

  //md
  pamd: {
    padding: md,
  },
  pxmd: {
    paddingHorizontal: md,
  },
  pymd: {
    paddingVertical: md,
  },
  ptmd: {
    paddingTop: md,
  },
  pbmd: {
    paddingBottom: md,
  },
  plmd: {
    paddingLeft: md,
  },
  prmd: {
    paddingRight: md,
  },
  //--
  mamd: {
    margin: md,
  },
  mxmd: {
    marginHorizontal: md,
  },
  mymd: {
    marginVertical: md,
  },
  mtmd: {
    marginTop: md,
  },
  mbmd: {
    marginBottom: md,
  },
  mlmd: {
    marginLeft: md,
  },
  mrmd: {
    marginRight: md,
  },
});
