import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { TouchableOpacity, StyleSheet, Text, View } from "react-native";
import { withTheme } from "react-native-elements";
//
import { Card, Typo2Way, Typo3Way } from "../../components/common";
import PayOption from "../../components/PaymentOptions";
import ButtonX from "../../components/buttons/ButtonX";
import TextBoldSmall from "../../components/Text/TextBoldSmall";
// import {  } from "@expo/vector-icons";
// import HoverView from "../../components/common/HoverView"; // just pass hoverStyle={}
// import { H1 } from "../../components/text";
// import ButtonX from "../../components/buttons/ButtonX";
import {
  showAltPaymentOptions,
  onYocoPay,
  onZapperPay,
  onSnapScanPay,
} from "../../actions";

const formatter = (number) =>
  number.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

const payIconSize = 7;

function PayOptionsAlt(props) {
  //const { theme } = props;
  //const { primary, secondary, secondary2, white, black } = theme.colors;
  // TODO - see if this orgId and event ID is actually valid
  const { user, subCart, subCartTotal } = props;
  const userCredit = user?.credit + user?.pendingCredit;
  const splitBalance = subCartTotal - userCredit;

  const [paymentMethod, setPaymentMethod] = useState("card");

  return (
    <Card>
      <Typo2Way subTitle="Select an option for" title="Split Payment" />
      <TextBoldSmall>
        You are using your current wallet credit of R {formatter(userCredit)}.
        For the remaining R {formatter(subCartTotal - userCredit)}, please
        select a payment option below.
      </TextBoldSmall>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          marginTop: 15,
          //backgroundColor: "yellow"
        }}
      >
        <PayOption
          name="snapscan"
          size={payIconSize}
          selected={paymentMethod === "snapscan"}
          onPress={() => setPaymentMethod("snapscan")}
        />
        {/* <PayOption
          name="zapper"
          size={payIconSize}
          selected={paymentMethod === "zapper"}
          onPress={() => setPaymentMethod("zapper")}
        /> */}
        <PayOption
          name="card"
          size={payIconSize}
          selected={paymentMethod === "card"}
          onPress={() => setPaymentMethod("card")}
        />
      </View>
      <ButtonX
        type="solid"
        title={"Pay Now"}
        addStyle={{ marginTop: 20 }}
        onPress={() => {
          paymentMethod === "card" &&
            props.onYocoPay({
              orgId: subCart[0].orgId,
              id: subCart[0].event_id,
              type: subCart[0].type,
              user,
              paymentType: "splitTicket",
              amount: splitBalance,
              subCart,
            });
          user &&
            paymentMethod === "zapper" &&
            props.onZapperPay({
              user,
              paymentType: "splitTicket",
              amount: splitBalance,
              subCart,
            });
          user &&
            paymentMethod === "snapscan" &&
            props.onSnapScanPay({
              user,
              // orgId,
              // event_id,
              paymentType: "splitTicket",
              amount: splitBalance,
              subCart,
            });
          props.showAltPaymentOptions(false);
          // user && props.showCart(false);
          // if (!user) {
          //   setSignInNotice(true);
          // } else {
          //   paymentMethod === "card"
          //     ? onUploadPress() // card payment bound to the current render() menthod (it's above, in this file)
          //     : //props.showInProgress(true);
          //       props.payUpload(
          //         user,
          //         paymentMethod,
          //         subCartTotal,
          //         subCart
          //         // item
          //       ); // others are reusable function()
          // }
        }}
      />
    </Card>
  );
}

const mapStateToProps = (state) => {
  const { subCart, product } = state.store;
  const { user } = state.users;
  // const { orgId, event_id } = state.config;

  const subCartFees =
    subCart
      .map((prod) => prod.qty * prod.booking_fee)
      .reduce((acc, i) => acc + i, 0) || 0;

  return {
    user,
    subCart,
    subCartTotal:
      subCart
        .map((prod) => prod.qty * prod.price)
        .reduce((acc, i) => acc + i, 0) + subCartFees,
    // subCartFees,
    event: product,
  };
};

export default withTheme(
  connect(mapStateToProps, {
    showAltPaymentOptions,
    onYocoPay,
    onZapperPay,
    onSnapScanPay,
  })(PayOptionsAlt)
);

const styles = StyleSheet.create({
  container: {
    borderRadius: 15,
    margin: 15,
    padding: 15,
    flexDirection: "row",
    alignItems: "center",
  },
});
