import { firebase, firestore, auth } from '../../../../firebase/config';
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { View, TouchableOpacity, StyleSheet, ActivityIndicator } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { Button } from 'react-native-elements';
import ButtonX from '../../../components/buttons/ButtonX';
import { Card } from '../../../components/common';
import Text from '../../../components/Text/TextLight';
// import TextB from "../../../components/Text/TextBold";
import TextBS from '../../../components/Text/TextBoldSmall';
import InputMat from '../../../components/common/MaterialInput';
import ModalHeader1 from '../../../components/headers/Header1';
import { dimensions, colors, typography } from '../../../utils/theme';
import { setAuthError, showAuthModal, sendEmailLink } from '../../../actions';

const GutterMD = () => <View style={{ height: 20 }} />;

const MergeDetails = ({ loading, onOk, authError, setAuthError }) => {
  return (
    <View
      style={{
        flex: 1,
        backgroundColor: colors.bg1,
        // paddingHorizontal: 20,
        width: '100%',
      }}
    >
      <form
        onSubmit={(event) => {
          event.preventDefault();
          authError !== null && setAuthError(null);
          onOk();
        }}
      >
        <Text>
          You have multiple wallet accounts. This can happen when you previously signed up using a
          log-in method different to this one (phone/email). This resluted in you having two
          separate wallet accounts. Consolidate these two accounts to ensure your passes are kept
          safe in one wallet account.
        </Text>
        <GutterMD />
        {/* <InputMat
          required
          label="Email"
          placeholder="Enter email address"
          onChangeText={setEmail}
          value={email}
          autoComplete="email"
                autoCompleteType="email"
          addContainerStyle={styles.inputText}
          keyboardType="email-address"
          checkValidation={(val) =>
            (val.length < 3 || !val.includes("@") || !val.includes(".")) &&
            "Enter a valid email address"
          }
        /> */}

        {authError && <Text style={styles.errorText}>{authError}</Text>}
        <button
          style={{
            border: 'none',
            background: 'none',
            width: '100%',
            padding: 0,
          }}
          type="submit"
        >
          <ButtonX
            type="solid"
            title="Merge"
            loading={loading}
            buttonStyle={{
              height: 60,
              borderRadius: 10,
              marginVertical: 10,
              backgroundColor: colors.balance,
            }}
          />
        </button>
        {/* <View>
          <Text
            style={{
              textAlign: "center",
              fontSize: 12,
              color: colors.textSolid,
              marginVertical: 20,
            }}
          >
            A verification email will be sent to your inbox. To complete sign
            in, click the verification link.
          </Text>
        </View> */}
      </form>
    </View>
  );
};

function MergeAccountsModal(props) {
  const { authError, loading, onOk, parsedEmail } = props;
  const [email, setEmail] = useState('');
  // const [loading, setLoading] = useState(false); // merge loading set from parent
  const [sendSuccess, setSendSucces] = useState(false);

  const sendMail = () => {
    const { orgId, event_id } = props.eventFocus;
    setLoading(true);
    return props
      .sendEmailLink({
        userObj: { email },
      })
      .then(() => {
        setSendSucces(true);
        setLoading(false);
        props.onModalCancel(false);
        // props.showAuthModal({
        //   showModal: true,
        //   isSignUp: false,
        // });
      })
      .catch(() => {
        setLoading(fasle);
        console.log('Could not send email');
      });
  };

  const SuccessMessage = () => {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: colors.bg1,
          // paddingHorizontal: 20,
          width: '100%',
        }}
      >
        <TextBS style={[styles.message, { color: colors.pay }]}>
          Verification email sent! Please check your email inbox to complete sign in.
        </TextBS>
      </View>
    );
  };

  return (
    <Card>
      <View
        style={{
          backgroundColor: colors.bg,
          borderRadius: 10,
        }}
      >
        <ModalHeader1
          heading="Merge accounts"
          subHeading="Acount settings"
          onClose={() => {
            props.onModalCancel();
            authError !== null && setAuthError(null);
            setSendSucces(false);
            // clearTimeout(timeout);
          }}
        />
        {!!sendSuccess ? (
          <SuccessMessage />
        ) : (
          <MergeDetails
            {...{
              loading,
              onOk,
              authError,
              setAuthError,
            }}
          />
        )}
      </View>
    </Card>
  );
}

const mapStateToProps = (state) => {
  // const { user } = state.users;
  const { authError } = state.auth;
  const { eventFocus } = state.config;

  return {
    authError,
    eventFocus,
  };
};

export default connect(mapStateToProps, {
  sendEmailLink,
  showAuthModal,
  setAuthError,
})(MergeAccountsModal);

const styles = StyleSheet.create({
  backButton: {
    position: 'absolute',
    top: 20,
    right: 20,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    height: 40,
    width: 40,
    borderRadius: 20,
    shadowColor: 'rgba(0,0,0,0.4)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 20,
  },
  inputText: {
    // marginVertical: 10,
    width: '100%',
    textAlign: 'center',
    fontSize: 18,
    outlineStyle: 'none',
    outlineWidth: 0,
    outlineColor: 'transparent',
  },
  message: {
    fontSize: 14,
    width: '100%',
    color: colors.secondary,
    // textAlign: "center",
  },
  errorText: {
    marginTop: 15,
    textAlign: 'center',
    fontSize: 12,
    color: 'red',
  },
  footer: {
    paddingVertical: 15,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
