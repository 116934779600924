import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { TouchableHighlight, StyleSheet, Text, View } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { withTheme } from "react-native-elements";
import TextSemi from "../../components/Text/TextS";
import { dimensions, colors, typography } from "../../utils/theme";
import "./sticky.css";
import { showPendingPaymentWidget } from "../../actions";

function AlertWidget(props) {
  const gradientColors = ["#FFF", "#FFF"];
  const { theme } = props;
  // const { primary, secondary, secondary2, white, black } = theme.colors;
  const { showPendingPaymentMessage, pendingPaymentMessage } = props;

  return (
    <TouchableHighlight
      onPress={
        () => props.showPendingPaymentWidget(false, "") // bool, message
      }
      activeOpacity={0.6}
      underlayColor="transparent"
      style={styles.container}
    >
      <LinearGradient
        className="sticky"
        colors={gradientColors}
        start={{ x: 0, y: 1 }}
        end={{ x: 1, y: 0 }}
        style={{ flex: 1 }}
      >
        <TextSemi
          style={{ color: colors.primaryText, fontSize: 14, marginBottom: 10 }}
        >
          {pendingPaymentMessage}
        </TextSemi>

        <View style={styles.gotIt}>
          <TextSemi style={{ color: colors.primaryText, opacity: 0.7 }}>
            Got it!
          </TextSemi>
        </View>
      </LinearGradient>
    </TouchableHighlight>
  );
}

export default withTheme(
  connect(null, { showPendingPaymentWidget })(AlertWidget)
);

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    top: 0,
    backgroundColor: "white",
    borderRadius: 15,
    margin: 15,
    padding: 15,
    flexDirection: "row",
    alignItems: "center",
    shadowColor: "rgba(0,0,0,0.6)",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 10,
  },
  gotIt: {
    backgroundColor: colors.primary,
    borderRadius: 10,
    padding: 20,
    alignItems: "center",
    justifyContent: "center",
  },
});
