import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
  Linking,
} from "react-native";
import { useSelector } from "react-redux";
import { Button } from "react-native-elements";
import * as RootNavigation from "../../RootNavigation.js";
import { Fontisto, Feather, SimpleLineIcons } from "@expo/vector-icons";
import { LinearGradient } from "expo-linear-gradient";
import { dimensions, colors, typography } from "../../utils/theme";
import { Card, Typo2Way } from "../../components/common";
import TextBold from "../../components/Text/TextBold";
import TextBoldSmall from "../../components/Text/TextBoldSmall";

const mapImage = require("../../assets/images/map.png");

export default function (props) {
  const [more, setMore] = useState(false);
  const { eventFocus } = useSelector((state) => state.config);

  const { location } = eventFocus;
  return (
    <Card>
      <View>
        <Image
          source={{ uri: mapImage }}
          resizeMode="cover"
          style={styles.image}
        />
        <LinearGradient
          colors={["transparent", "rgba(0,0,0,0.4)"]}
          style={styles.gradient}
        >
          <Button
            title="GET DIRECTIONS"
            type="outline"
            buttonStyle={{
              height: 40,
              width: 150,
              borderRadius: 30,
              borderWidth: 2,
              borderColor: "white",
            }}
            titleStyle={{ fontWeight: "bold", fontSize: 12, color: "white" }}
            onPress={() => Linking.openURL(location?.mapLink)}
          />
        </LinearGradient>
      </View>

      <Typo2Way
        style={{ marginTop: 20 }}
        subTitle={location?.address || "Get Directions"}
        title="Event Location"
        body={location?.directions}
      />

      <TouchableOpacity onPress={() => setMore(!more)}>
        {more ? (
          <TextBoldSmall
            style={{
              color: colors.shade4,
              fontSize: 14,
              marginBottom: 10,
            }}
          >
            {location?.directions}
          </TextBoldSmall>
        ) : (
          <TextBoldSmall
            numberOfLines={3}
            ellipsizeMode="tail"
            style={{
              color: colors.shade4,
              fontSize: 14,
              marginBottom: 10,
            }}
          >
            {location?.directions}
          </TextBoldSmall>
        )}
        <View style={{ alignItems: "flex-end" }}>
          {more ? (
            <View style={styles.expandButton}>
              <TextBoldSmall style={styles.expandButtonText}>
                {" "}
                Less{" "}
              </TextBoldSmall>
            </View>
          ) : (
            <View style={styles.expandButton}>
              <TextBoldSmall style={styles.expandButtonText}>
                {" "}
                More{" "}
              </TextBoldSmall>
            </View>
          )}
        </View>
      </TouchableOpacity>
    </Card>
  );
}

const imageHieght = dimensions.width - 220;

const styles = StyleSheet.create({
  container: {
    //height: 550,
    width: dimensions.width - 30,
    borderRadius: 15,
    margin: 20,
    marginVertical: 10,
    backgroundColor: "white",
    //
    shadowColor: "rgba(0,0,0,0.4)",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 20,
  },
  image: {
    width: dimensions.width - 30,
    height: imageHieght,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    marginHorizontal: -15, // push the image border to the edge
    marginTop: -15, // push the image border to the edge
    //flex: 1,
    //backgroundColor: "yellow",
    //height: dimensions.width - 40,
    //justifyContent: "flex-end",
    // borderRadius: 15
  },
  gradient: {
    flex: 1,
    position: "absolute",
    top: 0,
    width: dimensions.width - 30,
    height: imageHieght,
    justifyContent: "flex-end",
    padding: 20,
    marginHorizontal: -15, // push the gradient border to the edge
    marginTop: -15, // push the gradient border to the edge
    // borderBottomLeftRadius: 9,
    // borderBottomRightRadius: 9
  },
  expandButton: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    backgroundColor: "rgba(0,0,0,0.05)",
    borderRadius: 8,
  },
  expandButtonText: {
    color: colors.textSolid,
  },
});
