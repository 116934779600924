import React, { useState, useRef, useEffect } from 'react';
// import { useSelector } from "react-redux";
import { connect } from 'react-redux';
import {
  View,
  ImageBackground,
  Pressable,
  ActivityIndicator,
  StyleSheet,
  ScrollView,
  Dimensions,
  Image,
  LayoutAnimation,
} from 'react-native';
import moment from 'moment';
// import { Button } from "react-native-elements";
import * as Animatable from 'react-native-animatable';
import * as RootNavigation from '../../RootNavigation.js';
import { Fontisto, SimpleLineIcons, Octicons, MaterialIcons } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { dimensions, colors } from '../../utils/theme';
import TextBold from '../Text/TextBold';
import TextBoldSmall from '../Text/TextBoldSmall';
import IncrementButton from '../buttons/ButtonInc';
import ButtonAdd from '../buttons/ButtonAdd';
import arrow_next_left from '../../assets/svg/arrow_next_left.svg';
import arrow_next_right from '../../assets/svg/arrow_next_right.svg';

import { toggleImagesLoading } from '../../actions';
const imageDim = dimensions.width;

const dateFormatted = (seconds) => moment(seconds * 1000).format('MMM Do YYYY');

const ScrollArrows = (props) => {
  const { isStart, isEnd } = props;
  return (
    <View
      style={{
        position: 'absolute',
        top: imageDim / 2,
        bottom: imageDim / 2,
        left: 0,
        right: 0,
        width: '100%',
        padding: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      {isStart && (
        <Animatable.View
          animation="slideInLeft"
          iterationCount="infinite"
          duration={1000}
          iterationDelay={2000}
        >
          <MaterialIcons name="double-arrow" size={18} color={colors.shade4} />
        </Animatable.View>
      )}
      {isEnd && (
        <Animatable.View
          animation="slideInRight"
          iterationCount="infinite"
          duration={1000}
          iterationDelay={1000}
        >
          <MaterialIcons name="double-arrow" size={18} color={colors.shade4} />
        </Animatable.View>
      )}
      {!isStart && !isEnd && (
        <Animatable.View
          animation="slideInLeft"
          iterationCount="infinite"
          duration={1000}
          iterationDelay={1000}
        >
          <Octicons name="triangle-right" size={18} color={colors.shade4} />
        </Animatable.View>
      )}
    </View>
  );
};

function PosterItem1(props) {
  const [loading, setLoading] = useState(true);
  const [autoHeight, setAutoHeight] = useState(0);
  const [qty, setQty] = useState(1);
  const { style, user, item, disabled, expand, isStart, isEnd } = props;

  const ratio = dimensions.width / 300;

  // auto height - this article helped
  // https://stackoverflow.com/questions/42170127/auto-scale-image-height-with-react-native
  useEffect(() => {
    Image.getSize(item?.url, (width, height) => {
      setAutoHeight((height * (dimensions.width - 30)) / width || 0);
    });
  }, []);

  return (
    <Pressable
      style={[
        {
          alignItems: 'center',
          width: imageDim,
          backgroundColor: 'transparent',
        },
        style,
      ]}
    >
      <ImageBackground
        resizeMode="contain"
        style={[
          styles.BGimageStyle,
          {
            width: dimensions.width - 30,
            height: autoHeight, //300 * ratio,
          },
        ]}
        source={{ uri: item.url }}
        onLoadEnd={() => setLoading(false)}
      >
        <LinearGradient
          colors={
            expand
              ? ['transparent', 'rgba(0,0,0,0.0)', 'rgba(0,0,0,0.0)']
              : ['transparent', 'rgba(0,0,0,0.0)', 'rgba(0,0,0,0.0)']
          }
          style={{
            justifyContent: 'flex-end',
            borderRadius: 15,
            padding: 15,
          }}
        >
          {!!loading && (
            <ActivityIndicator
              size="large"
              color={[colors.black, { opacity: 0.5 }]}
              style={{ padding: 30 }}
            />
          )}
          {/* <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginVertical: 20,
              opacity: 0.8,
            }}
          >
            {isStart ? (
              <View />
            ) : (
              <Image
                source={{ uri: arrow_next_left }}
                resizeMode="contain"
                style={[styles.iconStyle, {}]}
              />
            )}
            {isEnd ? (
              <View />
            ) : (
              <Image
                source={{ uri: arrow_next_right }}
                resizeMode="contain"
                style={styles.iconStyle}
              />
            )}
          </View> */}

          {/* {!expand && (
            <Button
              title="Book Now"
              buttonStyle={{
                borderColor: colors.bg,
                borderWidth: 2,
                alignSelf: "flex-end",
                borderRadius: 30,
                backgroundColor: "transparent", //colors.pay,
              }}
              containerStyle={{ paddingVertical: 10 }}
              titleStyle={{
                fontWeight: "bold",
                fontSize: 14,
                marginHorizontal: 10,
              }}
              // onPress={() => props.setExpand()}
            />
          )} */}

          <View>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {!!item?.startTime && (
                <TextBoldSmall
                  style={[
                    {
                      color: colors.primary,
                      marginRight: 5,
                    },
                    styles.textShadow,
                  ]}
                >
                  {dateFormatted(item?.startTime?.seconds)} |
                </TextBoldSmall>
              )}
            </View>

            {expand ? (
              <ScrollView
                style={{
                  // justifySelf: "flex-end",
                  //height: 100, //"30%",
                  maxHeight: 120,
                  // marginBottom: 0,
                  marginHorizontal: -15,
                  paddingHorizontal: 15,
                }}
              >
                <TextBoldSmall
                  style={{
                    width: '100%',
                    color: colors.primary,
                  }}
                >
                  {item.prod_description}
                </TextBoldSmall>
              </ScrollView>
            ) : (
              <TextBoldSmall style={{ width: '100%', color: colors.primary }} numberOfLines={2}>
                {item.prod_description}
              </TextBoldSmall>
            )}
          </View>
        </LinearGradient>
      </ImageBackground>
    </Pressable>

    //   <ScrollArrows />
    //  </Animatable.View>
  );
}

export default connect(null, {
  toggleImagesLoading,
})(PosterItem1);

const styles = StyleSheet.create({
  alertText: {
    textAlign: 'left',
    fontSize: 14,
    color: colors.alert,
    margin: 5,
  },
  textShadow: {
    textShadowColor: 'rgba(0,0,0,0.5)',
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 5,
  },
  BGimageStyle: {
    // flex: 1,
    // width: imageDim - 30,
    // justifyContent: 'flex-end',
    // marginTop: 15,
    // marginBottom: 25,
    borderRadius: 15,
    // borderBottomWidth: 1,
    // borderLeftWidth: 1,
    // borderWidth: 0.5,
    // borderColor: colors.shade1,
    // borderBottomColor: colors.shade2,
    // borderLeftColor: colors.shade2,
    overflow: 'hidden',
    shadowColor: 'black', // "rgba(0,0,0,0.2)",
    shadowOffset: { width: 0, height: 5 },
    shadowOpacity: 0.1,
    shadowRadius: 20,
  },
  imageShadow: {
    shadowColor: 'rgba(0,0,0,1)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 1,
    shadowRadius: 3,
  },
  iconStyle: {
    height: 35,
    width: 35,
  },
});
