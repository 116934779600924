import React, { useEffect, useRef } from 'react';
import { SafeAreaView, TouchableOpacity, TouchableHighlight, Text, View } from 'react-native';
import { colors, typography, dimensions, logo } from '../../utils/theme';
import * as RootNavigation from '../../RootNavigation.js';
import { Ionicons, Feather } from '@expo/vector-icons';
import { BlurView } from 'expo-blur';
import Badge from '../../components/social/Badge';
import TextBold from '../../components/Text/TextBold';
import TextBoldSmall from '../../components/Text/TextBoldSmall';

export default function (props) {
  const { style, title, headerRef, onShowCartPress, onBackPress, subCart, replaceIcon } = props;

  return (
    <div style={{ position: 'sticky', top: 0, zIndex: 10 }}>
      <div style={{ position: 'absolute', top: 0, zIndex: 10, width: '100%' }}>
        <SafeAreaView ref={headerRef} style={{ ...style }}>
          <BlurView intensity={15} tint="light">
            <TouchableHighlight
              activeOpacity={0.9}
              underlayColor="#DDDDDD"
              onPress={onBackPress}
              style={{
                shadowColor: 'rgba(0,0,0,0.2)', //"rgba(255,255,255,0.1)",
                shadowOffset: { width: 0, height: 0 },
                shadowOpacity: 0.5,
                shadowRadius: 20,
                backgroundColor: 'rgba(255,255,255, 0.8)',
              }}
            >
              <View
                style={{
                  width: '100%',
                  paddingHorizontal: 10,
                  backgroundColor: 'transparent', //colors.bg, //"rgba(255,255,255,0.9)",
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <View
                  style={{
                    padding: 15,
                    // paddingHorizontal: 20,
                  }}
                >
                  {!!replaceIcon ? (
                    replaceIcon()
                  ) : (
                    <Ionicons
                      name="ios-arrow-back"
                      size={24}
                      color={colors.textSolid}
                      style={{ minWidth: 40 }}
                    />
                  )}
                </View>

                <TextBoldSmall
                  numberOfLines={1}
                  ellipsizeMode="tail"
                  style={{
                    fontSize: 18,
                    alignText: 'center',
                    color: colors.textSolid,
                    // textShadowColor: "rgba(255,255,255,1)",
                    // textShadowOffset: { width: 0, height: 0 },
                    // textShadowRadius: 25,
                  }}
                >
                  {title}
                </TextBoldSmall>
                {subCart && subCart?.length !== 0 ? (
                  <View>
                    <Feather
                      name="shopping-cart"
                      size={24}
                      color={colors.textSolid}
                      style={{
                        padding: 20,
                        paddingHorizontal: 25,
                      }}
                      onPress={onShowCartPress}
                    />
                    <Badge count={subCart && subCart.reduce((acc, val) => acc + val.qty, 0)} />
                  </View>
                ) : (
                  <View style={{ width: 60 }} />
                )}
              </View>
            </TouchableHighlight>
          </BlurView>
        </SafeAreaView>
      </div>
    </div>
  );
}
//  style={{ }}

//<Text style={{ fontSize: 16, fontWeight: "bold" }}></Text>
