import React from 'react';
import { connect } from 'react-redux';
import { View, useWindowDimensions } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import { FontAwesome } from '@expo/vector-icons';
import BackHeader from '../components/common/NavButtonBack';
import Marketplace from '../views/Store/Marketplace';
import EventDetail from '../views/Store/EventDetail';
import MerchDetail from '../views/Store/MerchDetail';

import { colors, typography, dimensions, logo } from '../utils/theme';

const Stack = createStackNavigator();

//https://reactnavigation.org/docs/stack-navigator/#options
//Products NAVIGATION
function storeNav(props) {
  const { navigation } = props;
  const { subCart } = props;
  return (
    // <View style={{ height: dimensions.height }}>
    <Stack.Navigator
      screenOptions={{
        headerStyle: {
          backgroundColor: colors.primaryDark,
          shadowOffset: {
            height: 0,
          },
        },
        headerTitleAlign: 'center',
        headerTitleStyle: {
          fontWeight: 'bold',
          fontSize: 17,
          color: colors.primaryText,
        },
      }}
    >
      <Stack.Screen
        name="Marketplace"
        component={Marketplace}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="Event"
        component={EventDetail}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="MerchDetail"
        component={MerchDetail}
        options={{
          headerShown: false,
        }}
      />
    </Stack.Navigator>
    // </View>
  );
}
// options={{
//   header: props => (
//     <BackHeader
//       {...props}
//       title={props?.scene?.route?.params?.item?.event_title}
//       subCart={subCart}
//     />
//   ),
//   headerStyle: {
//     backgroundColor: "transparent",
//     borderBottomColor: "transparent"
//   }
//   // headerShown: false
// }}
//<Stack.Screen name="AddProduct" component={AddProduct} />
const mapStateToProps = (state) => {
  const { subCart } = state.store;
  return { subCart };
};

export default connect(mapStateToProps, {})(storeNav);
