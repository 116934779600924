import {
  LOGGING,
  TAG_LINK_SUCCESS_MODAL,
  TAG_LINK_FAIL_MODAL,
  SET_SHOW_TERMS_MODAL,
  SET_SHOW_NEW_CONTENT,
} from "../actions/types";

export const showTagLinkSuccesModal = (bool) => ({
  type: TAG_LINK_SUCCESS_MODAL,
  bool,
});

export const showTagLinkFailModal = (bool) => ({
  type: TAG_LINK_FAIL_MODAL,
  bool,
});

export const setShowTermsModal = (bool) => ({
  type: SET_SHOW_TERMS_MODAL,
  bool,
});

export const setShowNewContent = (bool) => ({
  type: SET_SHOW_NEW_CONTENT,
  bool,
});
