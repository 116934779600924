import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { View, TouchableOpacity, StyleSheet } from "react-native";
import { Button } from "react-native-elements";
import { Ionicons } from "@expo/vector-icons";
import { Card, Typo3Way } from "../../../components/common";
import ScanFrame from "./extras/ScanFrame";
import ModalHeader1 from "../../../components/headers/Header1";
import { dimensions, colors, typography } from "../../../utils/theme";
import TextBold from "../../../components/Text/TextBold";
import TextBoldSmall from "../../../components/Text/TextBoldSmall";

//   <Button
//     title="CANCEL"
//     type="outline"
//     buttonStyle={{
//       height: 50,
//       width: dimensions.width - 80,
//       borderRadius: 25,
//       borderWidth: 2,
//       borderColor: colors.notice,
//     }}
//     titleStyle={{
//       fontWeight: "bold",
//       fontSize: 14,
//       color: colors.notice,
//     }}
//     onPress={() => RootNavigation.goBack()}
//   />

const ConfirmationFrame = (props) => {
  const { onOk } = props;
  return (
    <View style={{ alignItems: "center" }}>
      <Typo3Way
        subTitle="Tag Link"
        title="Success"
        body="Your RFID card is succesfully linked to your wallet balance. You may now use your RFID card to transact at any point of sale."
      />

      {
        //   <ScanFrame color={colors.pay}>
        //   <Ionicons name="ios-checkmark-circle" size={60} color={colors.pay} />
        // </ScanFrame>
      }
      <Ionicons
        name="ios-checkmark-circle"
        size={60}
        color={colors.pay}
        style={{ margin: 20 }}
      />
      <TextBoldSmall style={{ color: colors.pay, fontSize: 18, margin: 15 }}>
        Tag link success!
      </TextBoldSmall>
      <Button
        title="OK"
        type="outline"
        buttonStyle={{
          height: 50,
          width: 180,
          margin: 10,
          borderRadius: 25,
          borderWidth: 2,
          borderColor: colors.pay,
          //backgroundColor: colors.pay,
          //alignSelf: "flex-start"
        }}
        titleStyle={{ fontWeight: "bold", fontSize: 14, color: colors.pay }}
        onPress={onOk}
      />
    </View>
  );
};

export default function (props) {
  return (
    <Card>
      <ConfirmationFrame {...props} />
    </Card>
  );
}
