import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import {
  View,
  Text,
  Image,
  ImageBackground,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
} from "react-native";
import { Button } from "react-native-elements";
import * as RootNavigation from "../../RootNavigation.js";
import { Fontisto, Feather, SimpleLineIcons } from "@expo/vector-icons";
import { LinearGradient } from "expo-linear-gradient";
import { dimensions, colors, typography } from "../../utils/theme";
import TextBold from "../../components/Text/TextBold";

export default function (props) {
  const { type, title, description, titleImage, date, location, price } =
    props.item;
  return (
    <TouchableOpacity
      onPress={() => RootNavigation.navigate("MerchDetail", { ...props })}
      style={[
        styles.container,
        {
          height: 450,
          //backgroundColor: "yellow",
          shadowColor: "rgba(0,0,0,0.4)",
          shadowOffset: { width: 0, height: 0 },
          shadowOpacity: 0.5,
          shadowRadius: 20,
          margin: 20,
        },
      ]}
    >
      <View style={styles.container}>
        <Image
          source={{ uri: titleImage }}
          resizeMode="cover"
          style={styles.image}
        />

        <LinearGradient
          colors={["transparent", "rgba(0,0,0,0.8)"]}
          style={styles.gradient}
        >
          <TextBold style={{ color: "white" }}>{title}</TextBold>
          <Text style={{ color: "white" }}>{description}</Text>
        </LinearGradient>
      </View>
      <View
        style={{ backgroundColor: "transparent", height: 155, padding: 15 }}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 10,
            // marginHorizontal: 15
          }}
        >
          <TextBold>R {price}</TextBold>
          <Button
            title="Buy now"
            buttonStyle={{
              height: 30,
              width: 100,
              // margin: 20,
              borderRadius: 30,
              backgroundColor: colors.pay,
              alignSelf: "flex-start",
            }}
            titleStyle={{ fontWeight: "bold", fontSize: 12 }}
            onPress={() => console.log("Book now!")}
          />
        </View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginVertical: 5,
          }}
        >
          <Feather
            name="calendar"
            size={16}
            color={colors.black}
            style={{ marginRight: 5 }}
          />
          <Text>{date}</Text>
        </View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginVertical: 5,
          }}
        >
          <SimpleLineIcons
            name="location-pin"
            size={16}
            color={colors.black}
            style={{ marginRight: 5 }}
          />
          <Text>{location?.address}</Text>
        </View>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: dimensions.width - 30,
    height: dimensions.width - 80,
    //alignItems: "center",
    borderRadius: 15,
  },
  image: {
    width: "100%",
    height: dimensions.width - 80,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    //flex: 1,
    //backgroundColor: "yellow",
    //height: dimensions.width - 40,
    //justifyContent: "flex-end",
    // borderRadius: 15
  },
  gradient: {
    flex: 1,
    position: "absolute",
    top: 0,
    width: dimensions.width - 30,
    height: dimensions.width - 80,
    justifyContent: "flex-end",
    padding: 20,
    // borderBottomLeftRadius: 9,
    // borderBottomRightRadius: 9
  },
});
