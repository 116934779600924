import React, { useEffect, useState } from "react";
// import { connect } from "react-redux";
import { TouchableOpacity, StyleSheet, SafeAreaView } from "react-native";
import { dimensions, colors, typography } from "../../utils/theme";
import { withTheme } from "react-native-elements";
import ButtonX from "../../components/buttons/ButtonX";
import TextBold from "../../components/Text/TextBold";
import TextBS from "../../components/Text/TextBoldSmall";
import Text from "../../components/Text/TextLight";
import { FontAwesome5 } from "@expo/vector-icons";
import "../../components/common/sticky.css";
// import HoverView from "../../components/common/HoverView"; // just pass hoverStyle={}
// import { H1 } from "../../components/text";
// import ButtonX from "../../components/buttons/ButtonX";

function OrderFooter(props) {
  const { is_qless } = props;
  const { selectedProducts, onPress, onClear, footerRef } = props;
  //const { primary, secondary, secondary2, white, black } = theme.colors;
  return (
    <SafeAreaView style={styles.footerContainer} ref={footerRef}>
      {!is_qless && (
        <TextBS style={styles.alertText}>
          "express order" is not available.
        </TextBS>
      )}

      <ButtonX
        type="solid"
        // loading={loading}
        disabled={!selectedProducts?.length || !is_qless}
        title="Clear"
        addStyle={{
          flex: 1,
          borderRadius: 10,
          margin: 10,
          marginRight: 0,
          backgroundColor: colors.shade1,
        }}
        titleStyle={{
          fontWeight: "bold",
          fontSize: 16,
          color: colors.primaryText,
        }}
        onPress={onClear}
      />
      <ButtonX
        type="solid"
        // loading={loading}
        disabled={!selectedProducts?.length || !is_qless}
        title="Pay"
        addStyle={{
          flex: 1,
          borderRadius: 10,
          margin: 10,
          backgroundColor:
            !selectedProducts?.length || !is_qless
              ? colors.shade1
              : colors.highlight,
        }}
        titleStyle={{
          fontWeight: "bold",
          fontSize: 16,
          color:
            !selectedProducts?.length || !is_qless ? colors.shade2 : "white",
        }}
        onPress={onPress}
      />
    </SafeAreaView>
  );
}

export default withTheme(OrderFooter);

const styles = StyleSheet.create({
  footerContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
  },
  alertText: {
    textAlign: "center",
    fontSize: 14,
    color: colors.alert,
    margin: 10,
    marginBottom: 0, // cancels out
  },
});
