import React, { useState } from 'react';
import moment from 'moment';
import { auth } from '../../../firebase/config';
import {
  ScrollView,
  TouchableOpacity,
  TouchableHighlight,
  ActivityIndicator,
  StyleSheet,
  View,
  Image,
} from 'react-native';
import TextBS from '../../components/Text/TextBoldSmall';
import Text from '../../components/Text/TextLight';
import { Avatar, Button, Chip } from 'react-native-elements';
import { connect } from 'react-redux';
import Modal from 'modal-enhanced-react-native-web';
import { Ionicons } from '@expo/vector-icons';
import UnlinkTagModal from '../../views/Modals/UnlinkTagModal';
import SignoutModal from '../../views/Modals/SignoutModal';
import CashoutModal from '../../views/Modals/CashoutModal';
import { dimensions, colors, typography } from '../../utils/theme';
import { Card, Typo2Way } from '../../components/common';
import { signOut, showEmailAuthModal, linkAuthProvider } from '../../actions';

const ItemButton = ({ title, onPress, containerStyle, textStyle }) => {
  return (
    <TouchableOpacity onPress={onPress} style={[styles.itemButton, containerStyle]}>
      <TextBS style={[{ color: colors.textSolid }, textStyle]}>{title}</TextBS>
    </TouchableOpacity>
  );
};

const AvatarProfile = (props) => {
  const { user } = props;

  return (
    <TouchableHighlight
      onPress={() => console.log('Edit profile')}
      activeOpacity={0.8}
      underlayColor="transparent"
      style={{
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 20,
      }}
    >
      <View
        style={{
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Avatar
            size={64}
            rounded
            title="Nn"
            containerStyle={{ backgroundColor: colors.shade2, marginRight: 20 }}
          />
          <View>
            <TextBS style={{ color: colors.shade3, fontSize: 12 }}>Signed in as</TextBS>
            <Text style={{ color: colors.textSolid }}>{!!user?.email && user?.email}</Text>
            <Text style={{ color: colors.textSolid }}>
              {!!user?.cellphoneNumber && user?.cellphoneNumber}
            </Text>

            {/* {(!user.email || !user.cellphoneNumber) && (
            <Chip
              title={`Add ${!user.email ? "email" : "mobile number"}`}
              onPress={() => console.log("Icon chip was pressed!")}
              containerStyle={{ marginVertical: 5 }}
            />
          )} */}
            {/* <TouchableOpacity
            style={{
              backgroundColor: colors.balance,
              width: 100,
              borderRadius: 15,
              paddingVertical: 5,
              paddingHorizontal: 10,
              marginVertical: 5,
            }}
          >
            <Text style={{ color: "white", fontSize: 12 }}>
              Add {!user.email ? "email" : "mobile number"}
            </Text>
          </TouchableOpacity> */}
          </View>
        </View>
        <Ionicons name="ios-arrow-forward" size={20} />
      </View>

      {/*   source={{ uri: null } || {}} */}
    </TouchableHighlight>
  );
};
const GutterMD = () => <View style={{ height: 20 }} />;

function Settings(props) {
  const { navigation, isCashless } = props;
  const { user } = props;
  var userFB = auth.currentUser;
  //
  const [showUnlinkModal, setShowUnlinkModal] = useState(false);
  const [showSignoutModal, setShowSignoutModal] = useState(false);
  const [showCashoutModal, setShowCashoutModal] = useState(false);

  //check if there is a pending credit
  // const pendingCredit =
  //   user.pendingCredit !== undefined ? user.pendingCredit : 0;
  // const cashoutMessage = `AirDosh message - You have cashed out your balance of R ${
  //   user.credit + pendingCredit
  // }. Within 48hrs you will receive an e-wallet SMS, giving you access to your refund.`;

  return (
    <View
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.bg,
      }}
    >
      <View style={{ height: 60 }} />
      <ScrollView style={{ padding: 20 }}>
        <Card>
          <AvatarProfile {...props} />
        </Card>
        <Card>
          <TextBS style={{ fontSize: 20 }}>Add sign-in method</TextBS>
          <GutterMD />
          <Text style={{ color: colors.shade5, fontSize: 14 }}>
            You may add more than one way to sign into a wallet account. This is helpful when you
            are unable to receive an OTP, you may use Email Link verification as an alternative.
          </Text>
          <GutterMD />
          {!userFB?.email && (
            <ItemButton
              title="Add email"
              containerStyle={{ backgroundColor: colors.black }}
              textStyle={{ color: 'white' }}
              onPress={() => props.showEmailAuthModal(true)}
            />
          )}
          {!userFB?.phoneNumber && (
            <ItemButton
              title="Add phone number"
              containerStyle={{ backgroundColor: colors.black }}
              textStyle={{ color: 'white' }}
              onPress={() => navigation.navigate('RecaptchaSettings')}
            />
          )}
        </Card>

        <Card>
          <TextBS style={{ fontSize: 20 }}>Change email or mobile number</TextBS>
          <GutterMD />
          <Text style={{ color: colors.shade5, fontSize: 14 }}>
            Changing the mobile number or email address associated with this account will require
            you to verify using OTP or Email Link.
          </Text>
        </Card>
        <ItemButton
          title="Sign Out"
          containerStyle={{ backgroundColor: colors.black }}
          textStyle={{ color: 'white' }}
          onPress={() => setShowSignoutModal(true)}
        />
      </ScrollView>

      <Modal isVisible={showUnlinkModal} onBackdropPress={() => setShowUnlinkModal(false)}>
        <UnlinkTagModal
          isAtagLinked={
            user?.activeTagId !== '' &&
            user?.activeTagId.charAt(0) === '0' &&
            user?.activeTagId.charAt(1) === 'x'
          }
          onUnlink={() => unlinkTag(user)}
          onClearModal={() => setShowUnlinkModal(false)}
        />
      </Modal>
      <Modal isVisible={showSignoutModal} onBackdropPress={() => setShowSignoutModal(false)}>
        <SignoutModal
          onSignout={() => {
            props.signOut(user);
            setShowSignoutModal(false);
            navigation.navigate('Wallet');
          }}
          onClearModal={() => setShowSignoutModal(false)}
        />
      </Modal>
    </View>
  );
}
const mapStateToProps = (state) => {
  const { inviteVerified, isCashless } = state.config;
  const { user } = state.users;

  return {
    user,
    inviteVerified,
    isCashless,
  };
};
export default connect(mapStateToProps, {
  signOut,
  showEmailAuthModal,
  linkAuthProvider,
})(Settings);

const styles = StyleSheet.create({
  itemButton: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 5,
    padding: 20,
    backgroundColor: colors.shade1,
    borderRadius: 10,
  },
});
