import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  View,
  Image,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
} from "react-native";
import { Button } from "react-native-elements";
import * as RootNavigation from "../../RootNavigation.js";
import { Fontisto, Feather, MaterialCommunityIcons } from "@expo/vector-icons";
import { dimensions, colors } from "../../utils/theme";
import ButtonX from "../buttons/ButtonX";
// import { Card, Typo3Way } from "../../components/common";
import TextBold from "../Text/TextBold";
import TextBoldSmall from "../Text/TextBoldSmall";
import Text from "../../components/Text/TextLight";
import TextSemi from "../Text/TextS";

import {} from "../../actions";

const location2tone = require("../../assets/svg/location2tone.svg");
const cal2tone = require("../../assets/svg/cal2tone.svg");
const clock2tone = require("../../assets/svg/clock2tone.svg");

const iconWidth = "7%";
const textWidth = "90%";

const day = (seconds) => moment(seconds * 1000).format("Do");

const dateFormatted = (date) => {
  if (!!date) {
    const { startTime, endTime } = date;

    if (day(startTime) === day(endTime)) {
      return `${moment(startTime.seconds * 1000).format("dddd Do MMM `YY")}`;
    } else {
      return `${moment(startTime.seconds * 1000).format(
        "Do MMM YYYY"
      )} - ${moment(endTime.seconds * 1000).format("Do MMM YYYY")}`;
    }
  } else {
    return "Date TBC";
  }
};

const DateTimeLocation = (props) => {
  const { oneColor, showTimes } = props;
  const { date, location } = props.item;

  return (
    <View style={{ marginVertical: 15 }}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginVertical: 5,
        }}
      >
        {/* <Image
          source={{ uri: cal2tone }}
          style={{ width: 22, height: 22, marginRight: 5 }}
        /> */}
        <Feather
          name="calendar"
          size={16}
          color={colors.primaryText}
          style={[{ width: iconWidth }, oneColor]}
        />
        <TextSemi
          style={[
            { color: colors.primaryText, fontSize: 14, width: textWidth },
            oneColor,
          ]}
        >
          {dateFormatted(date)}
        </TextSemi>
      </View>
      {showTimes && (
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginVertical: 5,
          }}
        >
          {/* <Image
            source={{ uri: clock2tone }}
            style={{ width: 22, height: 22, marginRight: 5 }}
          /> */}
          <Feather
            name="clock"
            size={16}
            color="black"
            style={[{ width: iconWidth }, oneColor]}
          />

          <TextSemi
            style={[
              {
                color: colors.primaryText,
                fontSize: 14,
                width: textWidth,
              },
              oneColor,
            ]}
          >
            {!!date &&
              moment(date?.startTime?.seconds * 1000).format("hh:mm a") +
                "-" +
                moment(date?.endTime?.seconds * 1000).format("hh:mm a")}
          </TextSemi>
        </View>
      )}
      {location?.address && (
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginVertical: 5,
          }}
        >
          {/* <Image
            source={{ uri: location2tone }}
            style={{ width: 24, height: 24, marginRight: 5 }}
          /> */}
          <Feather
            name="map-pin"
            size={16}
            color={colors.primaryText}
            style={[
              {
                width: iconWidth,
                //marginLeft: -0.5 //compensate for odd placement
              },
              oneColor,
            ]}
          />

          <TextSemi
            style={[
              {
                color: colors.primaryText,
                fontSize: 14,
                width: textWidth,
              },
              oneColor,
            ]}
          >
            {location?.address}
          </TextSemi>
        </View>
      )}
    </View>
  );
};

export { DateTimeLocation };
