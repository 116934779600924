import { firebase, firestore, functions, auth } from '../../../firebase/config';
import React, { useState, useRef, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

import Lottie from 'react-lottie';
import animationData from '../../assets/lottie/paper-plane';
import TextBS from '../../components/Text/TextBoldSmall';
import Text from '../../components/Text/TextLight';
import InputMat from '../../components/common/MaterialInput';
import ButtonX from '../../components/buttons/ButtonX';
import ModalHeader1 from '../../components/headers/Header1';
import PhoneInput from '../../components/PhoneInput';
import { dimensions, colors, typography } from '../../utils/theme';
import { updateAuthForm, sendEmailLink, getCreateUserObj } from '../../actions';

// const SuccessMessage = (props) => {
//   const defaultOptions = {
//     loop: true,
//     autoplay: true,
//     animationData: animationData,
//     rendererSettings: {
//       preserveAspectRatio: 'xMidYMid slice',
//     },
//   };

//   return (
//     <View style={styles.container}>
//       <View style={{ margin: -20 }}>
//         <Lottie options={defaultOptions} height={220} width={dimensions.width} />
//       </View>
//       <div>
//         <div style={{ paddingVertical: 20 }}>
//           <span>
//             {' '}
//             <TextBS style={[styles.message, { color: colors.black, fontSize: 20 }]}>
//               Verification
//             </TextBS>
//           </span>
//           <span>
//             {' '}
//             <TextBS style={[styles.message, { color: colors.pay, fontSize: 20 }]}>
//               email sent!
//             </TextBS>
//           </span>
//         </div>
//         <TextBS style={[styles.message, { color: colors.shade3 }]}>
//           To complete sign in, please check your email inbox, and click the verification link -
//           'sign in to AirDosh'
//         </TextBS>
//       </div>

//       <ButtonX
//         type="solid"
//         title="OK"
//         addStyle={{
//           borderRadius: 10,
//           marginVertical: 20,
//           marginTop: 10,
//           width: '100%',
//         }}
//         titleStyle={{ fontWeight: 'bold', fontSize: 14 }}
//         onPress={() => props.navigation.navigate('Wallet')}
//       />
//     </View>
//   );
// };

// TODO: fix this name of component to SignUpEmail
function SignInEmail(props) {
  const dispatch = useDispatch();
  setSendSucces;
  const { navigation } = props;
  const { params } = props.route;
  //TODO: change this to rather use email and password on local state
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRepassword] = useState('');
  // const { authError, phoneNumber, email, name, surname, password } = useSelector(
  //   (state) => state.auth,
  // );

  const [loading, setLoading] = useState(false);
  const [sendSuccess, setSendSucces] = useState(false);
  const [error, setError] = useState(null);
  const phoneInput = useRef(null);

  useEffect(() => {
    !!params.email && setEmail(params.email);
  }, []);

  // const sendEmail = async (formattedNumber) => {
  //   setLoading(true);
  //   return props
  //     .sendEmailLink({
  //       userObj: { phoneNumber: formattedNumber, email, name, surname },
  //       params: 'auth/isSignUp', // event/orgId/event_id
  //     })
  //     .then(() => {
  //       setSendSucces(true);
  //       setLoading(false);
  //     });
  // };

  const handleSignInEmail = async () => {
    setError(null); // rest if tehre was an error on previous attempt
    setLoading(true);
    try {
      const newUser = await auth.signInWithEmailAndPassword(email, password);

      setLoading(false);

      if (params?.existingAuthType === 'email') {
        navigation.navigate('Auth', {
          screen: 'LinkCredentials',
          params: {
            // isSignUp: true,
            existingAuthType: 'email',
            email,
            ...params,
          },
        });
      } else {
        const eventLastVisted = await AsyncStorage.getItem('EVENT_LAST_VISITED');
        if (!!eventLastVisted) {
          const { orgId, event_id } = JSON.parse(eventLastVisted);
          if (!!orgId && !!event_id) {
            navigation.navigate('Event Page', { orgId, event_id });
          } else {
            navigation.navigate('Wallet');
          }
        } else {
          navigation.navigate('Wallet');
        }
      }

      //existingAuthType, phoneNumber, email
    } catch (err) {
      console.log({ err });
      setError(err.message);
      setLoading(false);
    }
  };

  const handleLinkEmail = async () => {
    setError(null); // rest if tehre was an error on previous attempt
    setLoading(true);
    try {
      const credential = firebase.auth.EmailAuthProvider.credential(email, password);

      await auth.currentUser.linkWithCredential(credential);

      setLoading(false);
      navigation.navigate('EmailLinked');
    } catch (err) {
      console.log({ err });
      setError(err.message);
      setLoading(false);
    }
  };

  const submitDisabled =
    email.length < 3 ||
    !email.includes('@') ||
    !email.includes('.') ||
    password.length < 6 ||
    (params?.isSignUp && repassword !== password);

  // if "verify email" was pressed
  if (sendSuccess) return <SuccessMessage {...props} />;

  return (
    <View style={styles.container}>
      <ModalHeader1
        hideClose={true}
        heading="Email + Password"
        subHeading="Sign In"
        description="Sign into your existing account using Email and Password."
      />
      <View style={{ flex: 1, width: '100%' }}>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            params?.isSignUp ? handleLinkEmail() : handleSignInEmail();
            // sendEmail(phoneInput.current?.getNumberAfterPossiblyEliminatingZero().formattedNumber);
          }}
        >
          <InputMat
            required
            label="Email"
            placeholder="Enter email address"
            // onChangeText={(value) => dispatch(updateAuthForm({ key: 'email', value }))}
            onChangeText={(value) => setEmail(value)}
            value={email}
            autoComplete="email"
            autoCompleteType="email"
            keyboardType="email-address"
            checkValidation={(val) =>
              (val.length < 3 || !val.includes('@') || !val.includes('.')) &&
              'Enter a valid email address'
            }
          />

          <InputMat
            secureTextEntry
            required
            label="Password"
            placeholder="Enter password"
            // onChangeText={(value) => dispatch(updateAuthForm({ key: 'password', value }))}
            onChangeText={(value) => setPassword(value)}
            value={password}
            autoComplete="current-password"
            checkValidation={(val) => val.length < 6 && 'Enter a strong password'}
          />

          {params?.isSignUp && (
            <InputMat
              secureTextEntry
              required
              label="Re-enter Password"
              placeholder="Re-enter Enter password"
              onChangeText={(value) => setRepassword(value)}
              value={repassword}
              autoComplete="current-password"
              checkValidation={(val) => val !== password && 'Enter a matching password'}
            />
          )}

          <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginVertical: 15 }}>
            <TouchableOpacity
              style={{ padding: 10, backgroundColor: 'rgba(0,0,0,0.04)', borderRadius: 10 }}
              onPress={() =>
                navigation.navigate('Auth', {
                  screen: 'ForgotPassword',
                  params: { email },
                })
              }
            >
              <Text style={{ color: 'blue' }}>Forgot Password?</Text>
            </TouchableOpacity>
          </View>

          {error && <Text style={{ color: colors.negative }}>{error}</Text>}

          <button
            style={{
              border: 'none',
              background: 'none',
              width: '100%',
              padding: 0,
            }}
            type="submit"
            disabled={
              email.length < 3 ||
              !email.includes('@') ||
              !email.includes('.') ||
              password.length < 6 ||
              (params?.isSignUp && repassword !== password)
            }
          >
            <ButtonX
              type="solid"
              loading={loading}
              title="Continue"
              addStyle={{
                borderRadius: 10,
                backgroundColor: submitDisabled ? colors.shade2 : colors.balance,
                marginVertical: 20,
                marginTop: 10,
                width: '100%',
              }}
              titleStyle={{ fontWeight: 'bold', fontSize: 16 }}
              // onPress={sendVerification}
            />
          </button>
        </form>
      </View>
    </View>
  );
}

export default connect(null, {
  // sendEmailLink,
  getCreateUserObj,
})(SignInEmail);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    paddingTop: 80,
    alignItems: 'center',
    backgroundColor: 'white',
  },
  message: {
    fontSize: 14,
    color: colors.secondary,
  },
});
