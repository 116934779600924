import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import {
  View,
  Image,
  ImageBackground,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
} from 'react-native';
import { Button } from 'react-native-elements';
import * as RootNavigation from '../../RootNavigation.js';
import { Fontisto, Feather, SimpleLineIcons } from '@expo/vector-icons';
import { dimensions, colors, typography } from '../../utils/theme';
import TextBold from '../../components/Text/TextBold';
import TextBoldSmall from '../../components/Text/TextBoldSmall';
import Text from '../../components/Text/TextLight';

export default function (props) {
  const { type, event_title, event_description, titleImage, date, location, price } = props.item;

  // <View
  //   style={{
  //     flexDirection: "row",
  //     alignItems: "center",
  //     justifyContent: "space-between",
  //     marginBottom: 10
  //     // marginHorizontal: 15
  //   }}
  // >
  //   <TextBold>R {price}</TextBold>
  //   <Button
  //     title="Book now"
  //     buttonStyle={{
  //       height: 30,
  //       width: 100,
  //       // margin: 20,
  //       borderRadius: 30,
  //       backgroundColor: colors.pay,
  //       alignSelf: "flex-start"
  //     }}
  //     titleStyle={{ fontWeight: "bold", fontSize: 12 }}
  //     onPress={() => console.log("Book now!")}
  //   />
  // </View>

  const DateLocation = () => {
    return (
      <View style={{ marginVertical: 15 }}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginVertical: 5,
          }}
        >
          <Feather name="calendar" size={16} color="white" style={{ marginRight: 5 }} />
          <Text style={{ color: 'white', fontSize: 12 }}>
            {moment(date?.startTime?.seconds * 1000).format('dddd Do MMMM YYYY')}
          </Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginVertical: 5,
          }}
        >
          <SimpleLineIcons name="location-pin" size={16} color="white" style={{ marginRight: 5 }} />
          <Text style={{ color: 'white', fontSize: 12 }}>{location?.address}</Text>
        </View>
      </View>
    );
  };

  return (
    <TouchableOpacity
      onPress={() => RootNavigation.navigate('Event', { ...props })}
      style={styles.container}
    >
      <View style={{ width: '30%', alignItems: 'center', justifyContent: 'center' }}>
        <TextBold style={{ color: colors.shade3 }}>FRI</TextBold>
        <TextBold style={{ color: colors.primaryText }}>15th</TextBold>
      </View>
      <View style={{ width: '70%' }}>
        <Image source={{ uri: titleImage }} resizeMode="cover" style={styles.image} />
        <View style={styles.imageBackground}>
          <TextBold style={{ color: 'white', fontSize: 22 }}>{event_title}</TextBold>
          <DateLocation />
        </View>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    width: dimensions.width - 20,
    height: 200,
    paddingLeft: -20,
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
    flexDirection: 'row',
    //
    shadowColor: 'rgba(0,0,0,0.4)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 20,
  },
  image: {
    width: '100%',
    height: 200,
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
  },
  imageBackground: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
    padding: 15,
    //alignItems: "center",
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.7)',
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
  },
  // gradient: {
  //   flex: 1,
  //   position: "absolute",
  //   top: 0,
  //   right: 0,
  //   width: dimensions.width - 80,
  //   height: 200,
  //   justifyContent: "flex-end",
  //   padding: 20
  //   // borderBottomLeftRadius: 9,
  //   // borderBottomRightRadius: 9
  // }
});
