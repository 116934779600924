import { firestore } from "../../firebase/config";
import { LOGGING, INJECT_EVENT } from "./types";

const eventRef = (orgId, eventId) =>
  firestore.collection("entities").doc(orgId).collection("events").doc(eventId);

export const fetchEventById = (orgId, eventId) => (dispatch) => {
  eventRef(orgId, eventId)
    .get()
    .then((doc) => {
      if (doc.exists) {
        dispatch({ type: INJECT_EVENT, payload: doc.data() });
      } else {
        // console.log("No evnet doc found!");
      }
    });
};
