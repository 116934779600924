import { firebase, firestore, functions, auth } from '../../../firebase/config';
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
  SafeAreaView,
  ScrollView,
} from 'react-native';
import { Feather } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import TextBS from '../../components/Text/TextBoldSmall';
import TextBold from '../../components/Text/TextBold';
import InputMat from '../../components/common/MaterialInput';
import ButtonX from '../../components/buttons/ButtonX';
import ModalHeader1 from '../../components/headers/Header1';
import { dimensions, colors, typography } from '../../utils/theme';
import {
  foundUser,
  updateAuthForm,
  showAuthLoadingModal,
  setGlobalInitializing,
  showEmailAuthModal,
  setAuthError,
  getCreateUserObj,
  sendEmailLink,
  linkAuthProvider,
  showAuthModal,
} from '../../actions';

const Timer = ({ seconds, onResendPress, timeLeft, setTimeLeft }) => {
  useEffect(() => {
    // exit early when we reach 0
    if (timeLeft <= 0) return setTimeLeft(null);

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  return (
    <SafeAreaView>
      <View style={{ alignItems: 'center' }}>
        <Text style={{ color: colors.textSolid }}> Didn't receive an SMS?</Text>
        {!timeLeft ? null : (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text style={{ color: colors.textSolid }}>You may retry in </Text>
            <TextBold
              style={{
                width: 28,
                fontSize: 16,
                marginBottom: -2,
                color: colors.textSolid,
                textAlign: 'center',
              }}
            >
              {timeLeft}
            </TextBold>
            <Text style={{ color: colors.textSolid }}> seconds</Text>
          </View>
        )}
        <TouchableOpacity
          style={{ padding: 10 }}
          onPress={() => {
            onResendPress();
            setTimeLeft(60); // reset the timer to start again from 60s
            window?.recaptchaVerifier?.clear();
          }}
          disabled={timeLeft}
        >
          <Text style={{ color: timeLeft ? colors.shade2 : colors.balance }}>Start again</Text>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
};
const EnterOTPForm = ({ stuff }) => {
  const {
    loading,
    setStep,
    code,
    setCode,
    confirmCode,
    timeLeft,
    setTimeLeft,
    authError,
    startAgian,
  } = stuff;
  return (
    <View
      style={{
        flex: 1,
        width: '100%',
        alignItems: 'center',
        // justifyContent: "space-between",
      }}
    >
      <ModalHeader1 heading="Enter OTP" subHeading="SMS has been sent" hideClose={true} />

      <form
        onSubmit={(event) => {
          event.preventDefault();
          confirmCode();
        }}
        style={{ width: '100%' }}
      >
        <InputMat
          label="OTP"
          placeholder="enter One Time Pin"
          onChangeText={setCode}
          value={code}
          keyboardType="number-pad"
          textContentType="oneTimeCode"
          autoComplete="sms-otp"
          checkValidation={(val) => !val.length && 'Required'}
        />
        {authError && (
          <Text
            style={{
              textAlign: 'center',
              fontSize: 12,
              color: 'red',
            }}
          >
            {authError}
          </Text>
        )}

        <button
          style={{
            border: 'none',
            background: 'none',
            width: '100%',
            padding: 0,
          }}
          type="submit"
          disabled={code.length < 6}
        >
          <ButtonX
            type="solid"
            loading={loading}
            title="Sign In"
            addStyle={{
              borderRadius: 10,
              backgroundColor: code.length < 6 ? colors.shade2 : colors.balance,
              marginVertical: 20,
              marginTop: 10,
              width: '100%',
            }}
          />
        </button>
      </form>

      <Timer onResendPress={() => startAgian()} timeLeft={timeLeft} setTimeLeft={setTimeLeft} />
    </View>
  );
};

function ManageDualAccount(props) {
  const { navigation } = props;
  // console.log("ManageDualAccount PROPS: ", props.route.params);
  const { associated, existingAuthType, accounts, phoneNumber, email } = props.route.params;

  const [loading, setLoading] = useState(false);
  const [authError, setAuthError] = useState(null);
  // const [phoneNumber, setPhoneNumber] = useState();
  const [code, setCode] = useState('');
  const [step, setStep] = useState(1); //1
  // initialize timeLeft with the seconds prop
  const [timeLeft, setTimeLeft] = useState(60);
  const [captchaAttempt, setCaptchaAttempt] = useState(1);
  const [sendSuccess, setSendSucces] = useState(false);
  const captchaRef = useRef(null);

  useEffect(() => {
    props.setGlobalInitializing(false);
  }, []);
  useEffect(() => {
    !!auth.currentUser &&
      auth.currentUser.providerData.map((prov) => prov.providerId).includes('password') &&
      setStep(3);
  }, [auth.currentUser]);

  const sendEmail = async () => {
    setAuthError(null);
    setLoading(true);

    if (existingAuthType !== 'email') {
      return props
        .sendEmailLink({
          userObj: { email: accounts[0].email },
          params: '', // `account/${phoneNumber}`,
        })
        .then(() => {
          setSendSucces(true);
          setLoading(false);
          setStep(3);
        });
    } else {
      return props
        .sendEmailLink({
          userObj: { email: accounts[0].email },

          params: `auth/${phoneNumber}`,
        })
        .then(() => {
          setSendSucces(true);
          setLoading(false);
          setStep(3);
        });
    }
  };

  const sendVerification = async () => {
    setAuthError(null);
    setLoading(true);

    // console.log('ManageDulAccout Settings', { finalNumberMustBeCorrectlyFormatted: phoneNumber });

    try {
      const appVerifier = window.recaptchaVerifier;

      firebase
        .auth()
        .signInWithPhoneNumber(accounts[0].cellphoneNumber, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setStep(2);
          setLoading(false);
        })
        .catch((error) => {
          console.log('Sign in error', error);
          setAuthError(error.message);
          setLoading(false);
        });
    } catch (err) {
      //  console.log("THE ERROR", err);
      setLoading(false);
      setAuthError(err.message);
    }
  };

  // Function to be called when confirming the verification code that we received
  const confirmCode = async () => {
    setAuthError(null);
    setLoading(true);

    if (existingAuthType !== 'phone') {
      var credential = firebase.auth.PhoneAuthProvider.credential(
        window.confirmationResult.verificationId,
        code,
      );
      props
        .linkAuthProvider(credential)
        .then((usercred) => {
          var user = usercred?.user;
          setLoading(false);
          // props.showAuthLoadingModal(true);
          setStep(1);
          navigation.navigate('Passes');
        })
        .catch((error) => {
          setAuthError(error.message);
          setLoading(false);
          // setMergeModal(true);
          // setMergeCredential(credential);
        });
    } else {
      var newUser = {};
      await confirmationResult
        .confirm(code)
        .then(async (result) => {
          // User signed in successfully.
          newUser = result.user;

          await props
            .getCreateUserObj({
              newUser,
              blockUserAutoCreate: true,
              email,
            })
            .then((userObj) => {
              // setUser(userObj);
            });
          setLoading(false);
          // props.showAuthLoadingModal(true);
          setStep(3);
        })
        .catch((error) => {
          setAuthError(error.message);
          setLoading(false);
          // User couldn't sign in (bad verification code?)
        });
    }

    setCode('');
  };

  const startAgian = () => {
    setAuthError(null);
    setStep(1);
    window?.recaptchaVerifier?.render().then(function (widgetId) {
      grecaptcha.reset(widgetId);
    });
  };

  function hideEmail(addy) {
    var hiddenEmail = '';
    for (var i = 0; i < addy.length; i++) {
      if (i > 2 && i < addy.indexOf('@')) {
        hiddenEmail += '*';
      } else {
        hiddenEmail += addy[i];
      }
    }
    return hiddenEmail;
  }

  function hidePhone(addy) {
    var hiddenPhone = '';
    for (var i = 0; i < addy.length; i++) {
      if (i > 3 && i < addy.length - 4) {
        hiddenPhone += '*';
      } else {
        hiddenPhone += addy[i];
      }
    }
    return hiddenPhone;
  }

  const methodToLink = existingAuthType === 'email' ? 'phone' : 'email';
  const credToLink = existingAuthType === 'email' ? phoneNumber : email;
  const credToSignIn =
    existingAuthType === 'email'
      ? hideEmail(accounts[0].email)
      : hidePhone(accounts[0].cellphoneNumber);

  const Notice = () => {
    return (
      <View>
        <Text>
          It appears that <Text style={{ fontWeight: 'bold' }}>{credToLink}</Text> is associated
          with an existing account. We suggest signing in with{' '}
          <Text style={{ fontWeight: 'bold' }}>{credToSignIn}</Text>, and follow the prompts to
          enable password-less <Text style={{ fontWeight: 'bold' }}>{methodToLink}</Text> as a sign
          in method. In future you may use either email or phone to sign in.
          {/* <Text style={{ fontWeight: "bold" }}>
            Menu{"->"} Settings{"->"} Link Additional Sign In Methods.
          </Text>{" "} */}
        </Text>
      </View>
    );
  };

  return (
    <View style={styles.modalContainer}>
      <View
        key={captchaAttempt}
        ref={captchaRef}
        nativeID="reCAPTCHA-container"
        style={{ backgroundColor: 'white' }}
      ></View>

      {step === 1 && (
        <View>
          <ModalHeader1
            headerColor={colors.warning}
            hideClose={true}
            heading="Manage dual account"
            subHeading="Account Admin"
            // description="It appears that phone/email is associated with an existing account. We suggest signing in with phone/email, and only then add additional sign in methods sign as phone/email."
          />
          <Notice />
          <ButtonX
            type="solid"
            loading={loading}
            title={`Sign in with  ${credToSignIn}`}
            addStyle={{
              borderRadius: 10,
              marginVertical: 20,
              marginTop: 10,
              width: '100%',
            }}
            titleStyle={{ fontWeight: 'bold', fontSize: 16 }}
            onPress={() => {
              existingAuthType === 'phone' && sendVerification();
              existingAuthType === 'email' && sendEmail();
            }}
          />
        </View>
      )}
      {/* {step === 4 && (
        <View>
          <ModalHeader1
            headerColor={colors.warning}
            hideClose={true}
            heading="Manage dual account"
            subHeading="Password-less"
          />
          <Notice />
          <ButtonX
            type="solid"
            loading={loading}
            title={`Sign in with  ${phoneNumber}`}
            addStyle={{
              borderRadius: 10,
              marginVertical: 20,
              marginTop: 10,
              width: "100%",
            }}
            titleStyle={{ fontWeight: "bold", fontSize: 16 }}
            onPress={() => sendVerification()}
          />
        </View>
      )} */}
      {step === 2 && (
        <EnterOTPForm
          stuff={{
            loading,
            setStep,
            code,
            setCode,
            confirmCode,
            timeLeft,
            setTimeLeft,
            authError,
            startAgian,
          }}
        />
      )}

      {step === 3 && (
        <View>
          {sendSuccess ? (
            <SuccessMessage {...props} />
          ) : (
            <View style={{ alignItems: 'center', marginTop: 30 }}>
              <Feather name="check-circle" size={50} color={colors.highlight} />
              <TextBold style={{ fontSize: 24, margin: 20, color: colors.textSolid }}>
                Sign In Success!
              </TextBold>
              <Text>
                You have signed in succesfully using{' '}
                <Text style={{ fontWeight: 'bold' }}>{existingAuthType}</Text> method. You may now
                add <Text style={{ fontWeight: 'bold' }}>{credToLink}</Text> as{' '}
                <Text style={{ fontWeight: 'bold' }}>{methodToLink}</Text> sign in method. In
                future, you can use either sign in method to access your wallet account.
              </Text>
              <ButtonX
                type="solid"
                loading={loading}
                title={`Verify  ${credToLink}`}
                addStyle={{
                  borderRadius: 10,
                  marginVertical: 20,
                  marginTop: 10,
                  width: '100%',
                }}
                titleStyle={{ fontWeight: 'bold', fontSize: 16 }}
                onPress={() => {
                  methodToLink === 'phone' && sendVerification();
                  methodToLink === 'email' && sendEmail();
                }}
              />
              <ButtonX
                type="solid"
                loading={loading}
                title="Do this later"
                addStyle={{
                  borderRadius: 10,
                  // marginVertical: 20,
                  marginTop: 5,
                  width: '100%',
                  backgroundColor: colors.shade1,
                }}
                titleStyle={{
                  fontWeight: 'bold',
                  fontSize: 16,
                  color: 'black',
                }}
                onPress={() => navigation.navigate('Wallet')}
              />
            </View>
          )}
        </View>
      )}
    </View>
  );
}

const mapStateToProps = (state) => {
  const { user } = state.users;
  const { authError } = state.auth;
  const { eventFocus } = state.config;

  return {
    user,
    authError,
    eventFocus,
  };
};

export default connect(mapStateToProps, {
  foundUser,
  getCreateUserObj,
  sendEmailLink,
  linkAuthProvider,
  showAuthLoadingModal,
  showAuthModal,
  setAuthError,
  setGlobalInitializing,
})(ManageDualAccount);

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    padding: 20,
    alignItems: 'center',
    backgroundColor: 'white',
  },
  message: {
    fontSize: 14,
    color: colors.secondary,
  },
  footer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
  },
});
