import React, { useState, useRef } from 'react';
import { StyleSheet, View, Platform, Pressable } from 'react-native';
import TextBS from '../../components/Text/TextBoldSmall';
import { Picker } from '@react-native-picker/picker';
import { MaterialIcons } from '@expo/vector-icons';
import { colors, dimensions, typography } from '../../utils/theme';
import { browserName, isChrome, isFirefox, isSafari } from 'react-device-detect';

const NumberPicker = ({ items, label, addStyle, selectedItem, onSelect }) => {
  const [focus, setFocus] = useState(false);
  const pickerRef = useRef(null);

  return (
    <View
      style={[
        styles.inputContainer,
        {
          borderColor: focus ? colors.highlight : colors.shade3,
          borderWidth: focus ? 2 : 1,
        },
        addStyle,
      ]}
    >
      <View
        style={{
          position: 'absolute',
          top: -10,
          left: 20,
          backgroundColor: colors.bg, // "#312D2B", // "yellow",
          borderRadius: 5,
        }}
      >
        <View
          style={{
            flex: 1,
            borderRadius: 5,
            paddingHorizontal: 5,
            backgroundColor: colors.bg1,
          }}
        >
          <TextBS style={{ color: colors.balance, fontSize: 14 }}>{label}</TextBS>
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          marginVertical: 10,
          width: '100%',
          alignItems: 'center',
        }}
      >
        <Picker
          ref={pickerRef}
          selectedValue={selectedItem}
          dropdownIconColor="white"
          style={{
            height: 50,
            width: '100%',
            borderWidth: 0,
            backgroundColor: 'transparent',
            color: colors.textSolid,
          }}
          onValueChange={(itemValue, itemIndex) => {
            onSelect(parseFloat(itemValue));
          }}
        >
          {items.map((item, index) => (
            <Picker.Item label={item} value={item} key={index} />
          ))}
        </Picker>
      </View>
      {isSafari && (
        <Pressable
          onPress={() => {
            pickerRef.current.focus();
          }}
        >
          <MaterialIcons name="keyboard-arrow-down" size={18} color="black" style={{}} />
        </Pressable>
      )}
    </View>
  );
};

export default NumberPicker;

const styles = StyleSheet.create({
  inputContainer: {
    //width: "95%",
    height: 50,
    paddingHorizontal: 20,
    paddingLeft: 30,
    //marginHorizontal: 20,
    marginVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
  },
  inputStyle: {
    //backgroundColor: "yellow",
    // fontFamily: typography.primary,
    fontSize: 16,
    outlineStyle: 'none',
    outlineWidth: 0,
    outlineColor: 'transparent',
    color: colors.primaryText,
    borderBottomWidth: 1,
    borderBottomColor: colors.shade1,
    //paddingHorizontal: 10
  },
  inputTextFocussed: {
    fontSize: 16,
    outlineStyle: 'none',
    outlineWidth: 0,
    outlineColor: 'transparent',
    color: colors.primaryText,
    //paddingHorizontal: 10,
    borderBottomColor: colors.highlight,
    borderBottomWidth: 2,
  },
});
