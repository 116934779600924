import React, { PureComponent } from 'react';
import { View, TouchableOpacity, Image, TextInput } from 'react-native';
import CountryPicker, {
  getCallingCode,
  DARK_THEME,
  DEFAULT_THEME,
  CountryModalProvider,
  Flag,
} from 'react-native-country-picker-modal';
import { PhoneNumberUtil } from 'google-libphonenumber';
import styles from './styles';
import Text from '../../components/Text/TextLight';
import TextBS from '../../components/Text/TextBoldSmall';
import { colors, dimensions, typography } from '../../utils/theme';

const dropDown =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAi0lEQVRYR+3WuQ6AIBRE0eHL1T83FBqU5S1szdiY2NyTKcCAzU/Y3AcBXIALcIF0gRPAsehgugDEXnYQrUC88RIgfpuJ+MRrgFmILN4CjEYU4xJgFKIa1wB6Ec24FuBFiHELwIpQxa0ALUId9wAkhCnuBdQQ5ngP4I9wxXsBDyJ9m+8y/g9wAS7ABW4giBshQZji3AAAAABJRU5ErkJggg==';
const phoneUtil = PhoneNumberUtil.getInstance();

function MaterialContainer(props) {
  const { label, required } = props;
  return (
    <View
      style={[
        styles.inputContainer,
        {
          borderColor: focus ? colors.balance : !!validationError ? colors.alert : colors.shade3,
          borderWidth: focus ? 2 : !!validationError ? 2 : 1,
        },
        // addContainerStyle,
      ]}
    >
      <View
        style={{
          position: 'absolute',
          top: -10,
          left: 15,
          backgroundColor: colors.bg,
          borderRadius: 5,
        }}
      >
        <View
          style={{
            flex: 1,
            borderRadius: 5,
            paddingHorizontal: 5,
            backgroundColor: colors.bg1,
          }}
        >
          <TextBS style={{ color: colors.balance, fontSize: 14 }}>
            {label}
            {required && ' *'}
          </TextBS>
        </View>
      </View>
      {props.children}
    </View>
  );
}

export default class PhoneInput extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // code: props.defaultCode ? undefined : "91",
      // TODO: changed this..
      code: props.defaultCode ? props.defaultCode : '27',
      number: props.value ? props.value : props.defaultValue ? props.defaultValue : '',
      modalVisible: false,
      countryCode: props.defaultCode ? props.defaultCode : 'ZA',
      disabled: props.disabled || false,
      validationError: null,
      focus: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.disabled !== prevState.disabled) {
      if ((nextProps.value || nextProps.value === '') && nextProps.value !== prevState.number) {
        return { disabled: nextProps.disabled, number: nextProps.value };
      }
      return { disabled: nextProps.disabled };
    }
    return null;
  }

  async componentDidMount() {
    const { defaultCode } = this.props;
    if (defaultCode) {
      const code = await getCallingCode(defaultCode);
      this.setState({ code });
    }
  }

  getCountryCode = () => {
    return this.state.countryCode;
  };

  getCallingCode = () => {
    return this.state.code;
  };

  isValidNumber = (number) => {
    try {
      const { countryCode } = this.state;
      const parsedNumber = phoneUtil.parse(number, countryCode);
      return phoneUtil.isValidNumber(parsedNumber);
    } catch (err) {
      return false;
    }
  };

  onSelect = (country) => {
    const { onChangeCountry } = this.props;
    this.setState(
      {
        countryCode: country.cca2,
        code: country.callingCode[0],
      },
      () => {
        const { onChangeFormattedText } = this.props;
        if (onChangeFormattedText) {
          if (country.callingCode[0]) {
            onChangeFormattedText(`+${country.callingCode[0]}${this.state.number}`);
          } else {
            onChangeFormattedText(this.state.number);
          }
        }
      },
    );
    if (onChangeCountry) {
      onChangeCountry(country);
    }
  };

  formatPhoneNumber = (phoneNumber) => {
    const formatPhone = phoneNumber.replace(/[^a-zA-Z0-9]/g, ''); //.replace(/ /g, '_').replace(/[^a-zA-Z0-9+]/g, '');

    const removedZero = formatPhone.charAt(0) === '0' ? formatPhone.replace('0', '') : formatPhone;

    const { code } = this.state;

    if (removedZero.length > 4 && removedZero.slice(0, code.length) === code) {
      return removedZero.slice(code.length);
    }

    return removedZero;
  };

  onChangeText = (text) => {
    this.setState({ number: this.formatPhoneNumber(text) });
    const { onChangeText, onChangeFormattedText } = this.props;
    if (onChangeText) {
      // TODO: added the country code as a returning parameter
      const { code } = this.state;
      onChangeText(this.formatPhoneNumber(text), code);
    }
    if (onChangeFormattedText) {
      const { code } = this.state;
      if (code) {
        onChangeFormattedText(text.length > 0 ? `+${code}${text}` : text);
      } else {
        onChangeFormattedText(text);
      }
    }
  };

  getNumberAfterPossiblyEliminatingZero() {
    let { number, code } = this.state;
    if (number.length > 0 && number.startsWith('0')) {
      number = number.substr(1);
      return { number, formattedNumber: code ? `+${code}${number}` : number };
    } else {
      return { number, formattedNumber: code ? `+${code}${number}` : number };
    }
  }

  renderDropdownImage = () => {
    return <Image source={{ uri: dropDown }} resizeMode="contain" style={styles.dropDownImage} />;
  };

  renderFlagButton = (props) => {
    const { layout = 'first', flagSize } = this.props;
    const { countryCode } = this.state;
    if (layout === 'first') {
      return (
        <Flag countryCode={countryCode} flagSize={flagSize ? flagSize : DEFAULT_THEME.flagSize} />
      );
    }
    return <View />;
  };

  render() {
    const {
      withShadow,
      withDarkTheme,
      codeTextStyle,
      textInputProps,
      textInputStyle,
      autoFocus,
      placeholder,
      disableArrowIcon,
      flagButtonStyle,
      containerStyle,
      textContainerStyle,
      renderDropdownImage,
      countryPickerProps = {},
      filterProps = {},
      countryPickerButtonStyle,
      layout = 'first',
      validationError,
    } = this.props;
    const { modalVisible, code, countryCode, number, disabled, focus } = this.state;
    return (
      <CountryModalProvider>
        {/* <MaterialContainer {...this.props} label="Phone Number" required> */}
        <View>
          <View
            style={{
              zIndex: 10,
              position: 'absolute',
              top: -10,
              left: 15,
              backgroundColor: colors.bg,
              borderRadius: 5,
            }}
          >
            <View
              style={{
                flex: 1,
                borderRadius: 5,
                paddingHorizontal: 5,
                backgroundColor: colors.bg1,
              }}
            >
              <TextBS style={{ color: colors.balance, fontSize: 14 }}>
                {/* {label}
                {required && " *"} */}
                Phone Number *
              </TextBS>
            </View>
          </View>
          <View
            style={[
              styles.container,
              withShadow ? styles.shadow : {},
              styles.containerStyle ? styles.containerStyle : {},
              styles.inputContainer,
              {
                borderColor: focus
                  ? colors.balance
                  : !!validationError
                  ? colors.alert
                  : colors.shade3,
                borderWidth: focus ? 2 : !!validationError ? 2 : 1,
              },
            ]}
          >
            <TouchableOpacity
              style={[
                styles.flagButtonView,
                layout === 'second' ? styles.flagButtonExtraWidth : {},
                flagButtonStyle ? flagButtonStyle : {},
                countryPickerButtonStyle ? countryPickerButtonStyle : {},
              ]}
              disabled={disabled}
              onPress={() => this.setState({ modalVisible: true })}
            >
              <CountryPicker
                modalProps={{
                  ariaHideApp: false,
                }}
                onSelect={this.onSelect}
                preferredCountries={['ZA', 'US', 'GB', 'NL', 'DE', 'ES', 'PT', 'AO', 'SE']}
                withEmoji
                withFilter
                withFlag
                filterProps={filterProps}
                countryCode={countryCode}
                withCallingCode
                disableNativeModal={disabled}
                visible={modalVisible}
                renderFlagButton={this.renderFlagButton}
                onClose={() => this.setState({ modalVisible: false })}
                {...countryPickerProps}
              />
              {code && layout === 'second' && (
                <Text
                  style={[styles.codeText, codeTextStyle ? codeTextStyle : {}]}
                >{`+${code}`}</Text>
              )}
              {!disableArrowIcon && (
                <React.Fragment>
                  {renderDropdownImage ? renderDropdownImage : this.renderDropdownImage()}
                </React.Fragment>
              )}
            </TouchableOpacity>
            <View style={[styles.textContainer, textContainerStyle ? textContainerStyle : {}]}>
              {code && layout === 'first' && (
                <Text
                  style={[styles.codeText, codeTextStyle ? codeTextStyle : {}]}
                >{`+${code}`}</Text>
              )}

              <TextInput
                style={[styles.numberText, styles.textInputStyle ? styles.textInputStyle : {}]}
                placeholder={placeholder ? placeholder : 'Phone Number'}
                onChangeText={this.onChangeText}
                value={number}
                editable={disabled ? false : true}
                selectionColor="black"
                keyboardType="number-pad"
                // TODO: changed this to "tel"
                // autoCompleteType="off"
                autoCompleteType="tel"
                autoFocus={autoFocus}
                onFocus={() => this.setState({ focus: true })}
                onBlur={() => this.setState({ focus: false })}
                {...textInputProps}
              />
            </View>
          </View>
          <Text
            style={{
              color: colors.alert,
              fontSize: 14,
              padding: 5,
              paddingHorizontal: 10,
            }}
          >
            {!!validationError && validationError}
          </Text>
        </View>
      </CountryModalProvider>
    );
  }
}

export const isValidNumber = (number, countryCode) => {
  try {
    const parsedNumber = phoneUtil.parse(number, countryCode);
    return phoneUtil.isValidNumber(parsedNumber);
  } catch (err) {
    return false;
  }
};

// const styles = StyleSheet.create({
//   textInputStyle: {
//     outlineStyle: "none",
//     outlineWidth: 0,
//     outlineColor: "transparent",
//     fontSize: 18,
//   },
//   containerStyle: {
//     width: dimensions.width - 60,
//     alignItems: "center",
//     alignSelf: "center",
//     borderWidth: 1,
//     borderColor: colors.shade1,
//     borderRadius: 10,
//     overflow: "hidden",
//   },
// });
