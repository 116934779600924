import React, { useState } from 'react';
import {
  ScrollView,
  FlatList,
  TouchableHighlight,
  ActivityIndicator,
  StyleSheet,
  Text,
  View,
  Image,
  Button,
} from 'react-native';
import TextBS from '../components/Text/TextBoldSmall';
import TextBold from '../components/Text/TextBold';
import TextS from '../components/Text/TextS';
import TextLight from '../components/Text/TextLight';
import ButtonX from '../components/buttons/ButtonX';
import { connect } from 'react-redux';
import generateID from '../utils/firebaseDocIDutil';
import { dimensions, colors, typography } from '../utils/theme';
import * as RootNavigation from '../RootNavigation.js';
import { formatDate, timeAgo } from '../utils/timeAgo';
import { AntDesign, Fontisto, MaterialCommunityIcons, Entypo, Feather } from '@expo/vector-icons';

const { height, width } = dimensions;

const DotScroll = ({ count, index }) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <React.Fragment>
        {[...new Array(count)].map((c, i) => (
          <View
            key={i}
            style={{
              width: 7,
              height: 7,
              borderRadius: 7,
              backgroundColor: index === i ? colors.shade5 : colors.shade1,
              margin: 2,
            }}
          />
        ))}
      </React.Fragment>
    </View>
  );
};

const AuthButton = (props) => {
  return (
    <View
      style={{
        backgroundColor: colors.bg,
        borderRadius: 50,
      }}
    >
      <ButtonX
        {...props}
        title="Sign In"
        type="solid"
        addStyle={[
          styles.buttonAddStyle,
          {
            shadowColor: 'black',
            shadowOffset: { width: 0, height: 5 },
            shadowOpacity: 0.3,
            shadowRadius: 20,
          },
        ]}
        titleStyle={{ fontSize: 20, color: 'white' }}
      />
    </View>
  );
};

const CashlessBalance = (props) => {
  const { user, spinner } = props;
  const pendingCredit = user && user.pendingCredit ? user.pendingCredit : 0;
  const userCredit = user !== null ? user.credit + pendingCredit : 0;

  return (
    <View style={styles.circle}>
      <TextBS style={{ fontSize: 14, color: colors.textSolid }}>BALANCE</TextBS>
      <View style={styles.amountContainer}>
        {spinner ? (
          <View>
            <ActivityIndicator size="small" color={colors.black} />
            <TextLight style={{ fontSize: 10, marginTop: 5 }}>Processing your balance...</TextLight>
          </View>
        ) : (
          <TextBold
            style={{
              color: colors.textSolid,
              fontSize: 36,
              margin: 5,
              marginBottom: 0,
            }}
          >
            {parseInt(userCredit)?.toFixed(2)}
          </TextBold>
        )}
      </View>

      <TextBS style={{ fontSize: 20, color: colors.textSolid }}>ZAR</TextBS>
    </View>
  );
};

const EmptyCircle = () => {
  return (
    <View style={styles.circle}>
      <TextBS style={{ fontSize: 14, color: colors.textSolid }}>Rewards</TextBS>
      <View style={styles.amountContainer}>
        <TextBold
          style={{
            color: colors.textSolid,
            fontSize: 36,
            margin: 5,
            marginBottom: 0,
          }}
        >
          0
        </TextBold>
      </View>
      <View
        style={{
          backgroundColor: colors.balance,
          borderRadius: 20,
          paddingVertical: 5,
          paddingHorizontal: 15,
          marginBottom: 15,
        }}
      >
        <TextBS style={{ fontSize: 14, color: colors.bg }}>ready</TextBS>
      </View>
    </View>
  );
};

const RewardsBalance = (props) => {
  const { user, spinner } = props;
  const { rewards } = user;
  // const pendingCredit = user && user.pendingCredit ? user.pendingCredit : 0;
  // const userCredit = user !== null ? user.credit + pendingCredit : 0;

  return (
    <View
      style={{
        overflow: 'hidden',
        borderRadius: dimensions.width / 3.6,
        shadowColor: 'rgba(45,185,177,0.4)',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.5,
        shadowRadius: 20,
      }}
    >
      <ScrollView
        horizontal
        pagingEnabled
        centerContent
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{
          borderRadius: dimensions.width / 3.6,
          height: dimensions.width > 375 ? 210 : dimensions.width / 1.8,
          width: dimensions.width > 375 ? 210 : dimensions.width / 1.8,
        }}
      >
        {!user.rewards && <EmptyCircle />}
        {!!user?.rewards.length &&
          user.rewards.map((reward, i) => (
            <View key={i} style={styles.circle}>
              <TextBS style={{ fontSize: 14, color: colors.textSolid }}>Sales</TextBS>
              <View style={styles.amountContainer}>
                {spinner ? (
                  <View>
                    <ActivityIndicator size="small" color={colors.black} />
                    <TextLight style={{ fontSize: 10, marginTop: 5 }}>
                      Processing your balance...
                    </TextLight>
                  </View>
                ) : (
                  <TextBold
                    style={{
                      color: colors.textSolid,
                      fontSize: 36,
                      margin: 5,
                      marginBottom: 0,
                    }}
                  >
                    {reward.currency === 'cash' ? parseInt(user.credit)?.toFixed(2) : reward.qty}
                  </TextBold>
                )}
              </View>
              <View
                style={{
                  backgroundColor: colors.black,
                  borderRadius: 20,
                  paddingVertical: 5,
                  paddingHorizontal: 15,
                  marginBottom: 15,
                }}
              >
                <TextBS style={{ fontSize: 14, color: colors.bg }}>
                  {reward.reward_title_short || 'Sales'}
                </TextBS>
              </View>

              <DotScroll count={user.rewards.length} index={i} />
            </View>
          ))}
      </ScrollView>
    </View>
  );
};

const FlipButton = ({ onPress }) => {
  return (
    <TouchableHighlight
      activeOpacity={0.5}
      underlayColor="white"
      style={[
        {
          position: 'absolute',
          right: -70,
          // backgroundColor: "rgba(255,255,255, 0.4)",
          backgroundColor: 'white',
          borderRadius: 30,
          borderWidth: 1,
          borderColor: colors.shade1,
          padding: 15,
          shadowColor: 'rgba(45,185,177,0.4)',
          shadowOffset: { width: 0, height: 0 },
          shadowOpacity: 0.5,
          shadowRadius: 20,
        },
      ]}
      onPress={onPress}
    >
      <Feather name="refresh-ccw" size={25} color="black" />
    </TouchableHighlight>
  );
};
function BalanceCircle(props) {
  const [showRewards, setShowRewards] = useState(false);
  const { onPress, inviteVerified, isCashless, user, spinner, addStyle } = props;

  return (
    <View style={[styles.circleContainer, addStyle]}>
      {!!user?.isRewards && <FlipButton onPress={() => setShowRewards(!showRewards)} />}
      {!user && <AuthButton {...props} />}
      {!!user && showRewards ? (
        <RewardsBalance {...props} />
      ) : (
        !!user && !showRewards && <CashlessBalance {...props} />
      )}
    </View>
  );
}

export default BalanceCircle;

const styles = StyleSheet.create({
  circleContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor: "green", //"transparent",
    height: dimensions.width > 375 ? 210 : dimensions.width / 1.8,
    width: dimensions.width > 375 ? 210 : dimensions.width / 1.8,
    marginTop: -105, //this place the cirecle container half way over the line upwards
  },
  circle: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.bg,
    height: dimensions.width > 375 ? 210 : dimensions.width / 1.8,
    width: dimensions.width > 375 ? 210 : dimensions.width / 1.8,
    borderRadius: dimensions.width / 3.6,
    shadowColor: 'rgba(45,185,177,0.4)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 20,
  },
  amountContainer: {
    width: '85%',
    backgroundColor: colors.bg1,
    height: '35%',
    borderRadius: 40,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 10,
    shadowColor: 'rgba(0,0,0,0.2)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 13,
  },
  buttonAddStyle: {
    zIndex: 3,
    width: width / 1.8,
    height: 80,
    borderRadius: 40,
    borderColor: '#CC0860',
    margin: 10,
    //marginBottom: -40
  },
  topUp: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    height: 60,
    width: 60,
    borderRadius: 30,
    padding: 10,
    shadowColor: 'rgba(0,0,0,0.4)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 20,
  },
});

// !isCashless ? (
//   <View style={styles.amountContainer}>
//     <TextBS
//       style={{
//         fontSize: 14,
//         color: colors.secondary,
//         textAlign: "center",
//       }}
//     >
//       Event not cashless
//     </TextBS>
//   </View>
// ) : (
//   <View style={styles.amountContainer}>
//     {spinner ? (
//       <View>
//         <ActivityIndicator size="small" color="black" />
//         <TextLight style={{ fontSize: 10, marginTop: 5 }}>
//           Processing your balance...
//         </TextLight>
//       </View>
//     ) : (
//       <TextBold
//         style={{ fontSize: 36, margin: 5, marginBottom: 0 }}
//       >
//         {userCredit}
//       </TextBold>
//     )}
//     {
//       // !isCashless && (
//       //   <TextBS
//       //     style={{
//       //       fontSize: 14,
//       //       color: colors.secondary,
//       //       textAlign: "center",
//       //     }}
//       //   >
//       //     Event not cashless.
//       //   </TextBS>
//       // )
//     }
//   </View>
// )
