import React from "react";
import { View, TouchableOpacity, StyleSheet } from "react-native";
import Text from "../../components/Text/TextLight";
import TextBold from "../../components/Text/TextBold";
import TextBS from "../../components/Text/TextBoldSmall";
import { Button } from "react-native-elements";
import { Ionicons } from "@expo/vector-icons";
import { dimensions, colors, typography } from "../../utils/theme";

export default function (props) {
  const {
    heading,
    subHeading,
    headerColor,
    description = null,
    onClose,
    hideClose = false,
  } = props;
  return (
    <View
      style={{
        // height: 80,
        width: "100%",
        justifyContent: "space-between",
        alignItems: "flex-start",
        paddingBottom: 20,
        borderBottomColor: colors.bg2,
        borderBottomWidth: 1,
        marginBottom: 20,
      }}
    >
      <View>
        <TextBS
          style={[
            { fontSize: 14, color: colors.balance },
            headerColor && { color: headerColor },
          ]}
        >
          {subHeading}
        </TextBS>
        <TextBold style={{ fontSize: 26, color: colors.textSolid }}>
          {heading}
        </TextBold>
        {description !== null && (
          <TextBS style={{ fontSize: 14, color: colors.shade4, opacity: 0.7 }}>
            {description}
          </TextBS>
        )}
      </View>

      {!hideClose && (
        <TouchableOpacity
          onPress={onClose}
          style={{
            //backgroundColor: "yellow",
            position: "absolute",
            top: -10,
            right: -10,
            width: 70,
            height: 70,
            padding: 30,
            alignItems: "flex-start",
            justifyContent: "center",
          }}
        >
          <Ionicons name="ios-close" size={50} color={colors.textSolid} />
        </TouchableOpacity>
      )}
    </View>
  );
}
