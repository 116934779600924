import React, { useEffect, useState } from "react";
import {
  Linking,
  Image,
  TouchableOpacity,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { withTheme } from "react-native-elements";
// import {  } from "@expo/vector-icons";
// import HoverView from "../../components/common/HoverView"; // just pass hoverStyle={}
// import { H1 } from "../../components/text";
// import ButtonX from "../../components/buttons/ButtonX";

import instagram from "../../assets/svg/instagram.svg";
import facebook from "../../assets/svg/facebook.svg";
import whatsapp from "../../assets/svg/whatsapp.svg";
import email from "../../assets/svg/email.svg";
import web from "../../assets/svg/web.svg";

const CompanyLogo = require("../../assets/images/ADappicon.png");
// Image.prefetch(instagram);
// Image.prefetch(facebook);

const SocialIcon = (props) => {
  const { uri, link } = props;
  return (
    <TouchableOpacity
      style={{
        margin: 12,
        marginLeft: 0, // overides margin
      }}
      onPress={() => Linking.openURL(link)}
    >
      <Image source={{ uri: uri }} style={styles.icon} />
    </TouchableOpacity>
  );
};

//`https://www.facebook.com/dialog/share?app_id=145634995501895&display=popup&href=https://airdosh-wallet.web.app/${"jacocode"}redirect_uri=https://airdosh-wallet.web.app/`
function ShareIcons(props) {
  const { style, theme } = props;
  const { organiser, dynamicLink } = props; /// from {...props}
  const { user } = props;
  const {
    date,
    id, // event ID
    event_title,
    event_description,
    influencers,
    attendanceCount,
  } = props.route.params.item;
  const emailContact = "hello@airdosh.co.za";

  const { primary, secondary, secondary2, white, black } = theme.colors;
  return (
    <View style={[styles.container, style]}>
      <SocialIcon
        uri={email}
        link={`mailto:${emailContact}?subject=${`You are invited to ${event_title}`}. ${"Follow the link to the event wallet and to purchase your tickets"}&body=${dynamicLink}`}
      />
      <SocialIcon
        uri={whatsapp}
        link={`whatsapp://send?text=You are invited to ${event_title}. Follow the link below to the event wallet and to purchase your tickets! ${dynamicLink}`}
      />

      {
        // {organiser.org_urls?.instagram && (
        //   <SocialIcon uri={instagram} link={organiser.org_urls.instagram} />
        // )}
        // {organiser.org_urls?.web && (
        //   <SocialIcon uri={web} link={organiser.org_urls.web} />
        // )}
        // {organiser.org_contact?.email && (
        //   <SocialIcon
        //     uri={email}
        //     link={`mailto:${organiser.org_contact.email}?subject=I need help with my tickets!&body=Here is my problem...`}
        //   />
        // )}
        // {organiser.org_contact?.whatsapp && (
        //   <SocialIcon
        //     uri={whatsapp}
        //     link={`whatsapp://send?phone=${organiser.org_contact.whatsapp}text=Hi there!`}
        //   />
        // )}
      }
    </View>
  );
}

// organiser.org_urls?.facebook? &&{" "}
// <SocialIcon uri={facebook} link={organiser.org_urls.facebook} />

// <Image source={{ uri: facebook }} style={styles.icon} />
// <Image source={{ uri: instagram }} style={styles.icon} />
// <Image source={{ uri: whatsapp }} style={styles.icon} />
// <Image source={{ uri: email }} style={styles.icon} />
// <Image source={{ uri: web }} style={styles.icon} />

// { width: 45, height: 45, marginHorizontal: 10 }

export default withTheme(ShareIcons);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    //justifyContent: "space-around"
  },
  icon: {
    width: 45,
    height: 45,
  },
});
