import React, { useState, useEffect } from 'react';
import {
  ScrollView,
  FlatList,
  TouchableOpacity,
  StyleSheet,
  Text,
  View,
  Image,
} from 'react-native';
import { connect, useSelector } from 'react-redux';
import * as RootNavigation from '../RootNavigation.js';
import { Button } from 'react-native-elements';
import { Ionicons } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
//import Modal from "modal-enhanced-react-native-web";
//import Camera from "../components/BarCodeScanner";
import Camera from '../components/Camera';
import ScanFrame from '../views/Modals/linktag/extras/ScanFrame';
import TextBold from '../components/Text/TextBold';
import { dimensions, colors, typography } from '../utils/theme';
// import { isTagInUse } from "../actions/UsersActions";
import { showTagLinkFailModal, showTagLinkSuccesModal, insertTagId, isTagInUse } from '../actions';

const YellowFrame = () => {
  return (
    <View style={{ alignItems: 'center' }}>
      <TextBold style={{ color: colors.notice, margin: 15 }}>Scan QR code</TextBold>
      <ScanFrame color={colors.notice} />
      <Button
        title="Cancel"
        type="outline"
        buttonStyle={{
          height: 50,
          width: dimensions.width - 60,
          borderRadius: 25,
          borderWidth: 2,
          borderColor: colors.notice,
        }}
        titleStyle={{
          fontWeight: 'bold',
          fontSize: 14,
          color: colors.notice,
        }}
        onPress={() => RootNavigation.goBack()}
      />
    </View>
  );
};

function LinkTag(props) {
  const { tag_price } = useSelector((state) => state.config);
  const { _id } = useSelector((state) => state.users);

  const { route } = props;
  const [frozen, setFrozen] = useState(false);
  const userId = _id;

  const validateScanData = async (val) => {
    //
    const vaildUID = val.charAt(1) === 'x'; // the URL prefix is stripped in the camera component, leaving only the HEX UID i.e. 0x000
    const inUse = await props.isTagInUse(val);
    const allGood = vaildUID && !inUse && !frozen;
    const noGood = (!vaildUID && !frozen) || (inUse && !frozen);
    //
    if (allGood) {
      // setValidScan("success");
      props.insertTagId({ userId, val, tag_price });
      props.showTagLinkSuccesModal(true);
      RootNavigation.navigate('Wallet');
    }

    if (noGood) {
      // setValidScan("failed");
      props.showTagLinkFailModal(true);
      RootNavigation.navigate('Wallet');
    }
  };

  // MAIN return
  return (
    <View
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: -50,
      }}
    >
      <Camera {...props} onScanned={(val) => validateScanData(val)} />

      <View
        style={[
          styles.overlayStyle,
          // validScan && { backgroundColor: "rgba(0,0,0,0.7)" },
        ]}
      >
        <LinearGradient colors={['rgba(0,0,0,0.8)', 'transparent']} style={styles.gradient} />
        <YellowFrame />
      </View>
    </View>
  );
}
{
  /* {
          validScan === "success" ? (
            <ConfirmationFrame />
          ) : validScan === "failed" ? (
            <FailedScan />
          ) : (
            <YellowFrame />
          )
        } */
}

export default connect(null, {
  insertTagId,
  isTagInUse,
  showTagLinkFailModal,
  showTagLinkSuccesModal,
})(LinkTag);

const styles = StyleSheet.create({
  overlayStyle: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: "yellow"
  },
  // scanFrame: {
  //
  // },
  confirmFrame: {
    height: dimensions.width / 2,
    width: dimensions.width / 2,
    borderWidth: 4,
    borderRadius: 5,
    borderColor: colors.pay,
    alignItems: 'center',
    justifyContent: 'center',
  },
  gradient: {
    flex: 1,
    position: 'absolute',
    top: 0,
    width: dimensions.width,
    height: 250,
    justifyContent: 'flex-end',
    padding: 20,
    // borderBottomLeftRadius: 9,
    // borderBottomRightRadius: 9
  },
});
