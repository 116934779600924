import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  View,
  //Text,
  //TextInput,
  FlatList,
  ScrollView,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
} from 'react-native';
import { Button } from 'react-native-elements';
import { FontAwesome5 } from '@expo/vector-icons';
import * as RootNavigation from '../../RootNavigation.js';
import Modal from 'modal-enhanced-react-native-web';
import { dimensions, colors, typography } from '../../utils/theme';
import { Card } from '../../components/common';
import OrderConfirmModal from '../Modals/OrderConfirmationModal';
import Footer from './Footer';
import BackHeader from '../../components/common/NavButtonBack';
import BarListItem1 from './BarListItem1';
import LoadingScreen from './LoadingScreen';

import {
  fetchBarProducts,
  updateNameAndTable,
  //
  showCart,
  onYocoPay,
  onSnapScanPay,
} from '../../actions';

function OrderToSeatHome(props) {
  const { user, is_qless, barProducts, sortedBarProducts } = props;
  const header = useRef();
  const footer = useRef();

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [showConfirmationScreen, setShowConfirmationScreen] = useState(false);
  const [showLoadingScreen, setShowLoadingScreen] = useState(false);
  const [showEditDeliveryDetails, setShowEditDeliveryDetails] = useState(false);
  const [name, setName] = useState();
  const [tableNo, setTableNo] = useState();
  const [paymentMethod, setPaymentMethod] = useState('card');

  const orderedItemsTotal = selectedProducts.reduce((a, b) => a + b.price * b.qty, 0);

  useEffect(() => {
    !props.is_qless && props.navigation.goBack();
    !!props.is_qless && props.fetchBarProducts();
    // if (!props.is_qless || !props.orgId || !props.event_id) {
    //   props.navigation.navigate("Wallet");
    //   // console.log("Route params", props.route.params);
    // } else {

    // }
  }, []);

  // const OrderedItems = barProducts.filter(item =>
  //   selectedProducts.includes(item.id)
  // );
  // const OrderedItemsTotal = OrderedItems.reduce((a, b) => a + b.price, 0);
  // const renderItemType = ({ item }) => {
  //   const { type } = item;
  //   return type === "ticket" ? (
  //     <ProductCard item={item} />
  //   ) : type === "merchandise" ? (
  //     <MerchCard item={item} />
  //   ) : (
  //     <Text>no items in store. Check out other merch here...</Text>
  //   );
  // };

  // helpers for adding and removing items from q-less cart
  const addItem = (item) => {
    const prodIndex = selectedProducts.findIndex((prod) => prod.id === item.id);

    if (prodIndex < 0) {
      selectedProducts.push({
        ...item,
        qty: 1,
        orgId: props.orgId,
        event_id: props.event_id,
      });
      setSelectedProducts([...selectedProducts]);
    } else {
      selectedProducts.splice(prodIndex, 1, {
        ...selectedProducts[prodIndex],
        qty: selectedProducts[prodIndex].qty + 1,
      });
      setSelectedProducts([...selectedProducts]);
    }
  };

  const removeItem = (item) => {
    const prodIndex = selectedProducts.findIndex((prod) => prod.id === item.id);

    if (prodIndex < 0) {
      consol.log('No product to remove?');
    } else {
      selectedProducts.splice(prodIndex, 1);
      setSelectedProducts([...selectedProducts]);
    }
  };

  const onPressPay = () => {
    // props.updateNameAndTable(user, name, tableNo);

    setShowConfirmationScreen(false);
    setShowLoadingScreen(false);
    //

    paymentMethod === 'card' &&
      props.onYocoPay({
        paymentType: 'qlessOrder',
        amount: orderedItemsTotal,
        subCart: selectedProducts,
      });

    // paymentMethod === "snapscan" &&
    //   props.onSnapScanPay({
    //     ...props,
    //     user,
    //     paymentType: "qlessOrder",
    //     amount: OrderedItemsTotal,
    //     subCart,
    //   });
  };

  return (
    <View style={{ flex: 1, backgroundColor: 'white' }}>
      <BackHeader
        {...props}
        headerRef={header}
        title="Express order"
        onBackPress={() => RootNavigation.goBack()}
        onShowCartPress={() => props.showCart(true)}
      />
      <View style={{ height: 65 }} />
      <View
        style={{
          flex: 1,
          alignItems: 'center',
        }}
      >
        <FlatList
          style={{
            width: '100%',
            paddingBottom: 75,
            paddingTop: 15,
            paddingHorizontal: 10,
          }}
          data={sortedBarProducts}
          renderItem={({ item, index }) => (
            <BarListItem1
              {...props}
              item={item}
              qty={!!selectedProducts[index] ? selectedProducts[index].qty : 0}
              addItem={addItem}
              removeItem={removeItem}
            />
          )}
          keyExtractor={(item) => item.id}
          numColumns={numColumns}
        />
        <Footer
          {...props}
          selectedProducts={selectedProducts}
          footerRef={footer}
          onPress={() => setShowConfirmationScreen(true)}
          onClear={() => setSelectedProducts([])}
        />
      </View>
      <Modal
        isVisible={showConfirmationScreen}
        onBackdropPress={() => setShowConfirmationScreen(false)}
      >
        <OrderConfirmModal
          {...props}
          // products={barProducts}
          selectedProducts={selectedProducts}
          orderedItemsTotal={orderedItemsTotal}
          showEditDeliveryDetails={showEditDeliveryDetails}
          onEditPress={() => {
            setTableNo('');
            setShowEditDeliveryDetails(true);
          }}
          onNameChange={(name) => setName(name)}
          onTableChange={(tableNo) => setTableNo(tableNo)}
          setPaymentMethod={setPaymentMethod}
          paymentMethod={paymentMethod}
          onPressPay={() => onPressPay()}
          onCancel={() => setShowConfirmationScreen(false)}
        />
      </Modal>
      <Modal isVisible={showLoadingScreen} onBackdropPress={() => setShowLoadingScreen(false)}>
        <LoadingScreen {...props} onClear={() => setShowLoadingScreen(false)} />
      </Modal>
    </View>
  );
}

// helper function to sort products into 2 columns
const numColumns = 1;
const formatData = (data, numColumns) => {
  const numberOfFullRows = Math.floor(data.length / numColumns);

  let numberOfElementsLastRow = data.length - numberOfFullRows * numColumns;
  while (numberOfElementsLastRow !== numColumns && numberOfElementsLastRow !== 0) {
    data.push({ key: `blank-${numberOfElementsLastRow}`, empty: true });
    numberOfElementsLastRow++;
  }
  return data;
};
//
const mapStateToProps = (state) => {
  const { barProducts } = state.seatOrder;
  const { user } = state.users;

  // const { is_qless, orgId, event_id } = state.config.eventFocus;
  const { eventFocus } = state.config;

  return {
    user,
    is_qless: eventFocus?.is_qless,
    orgId: eventFocus?.orgId,
    event_id: eventFocus?.event_id,
    barProducts,
    sortedBarProducts: formatData(barProducts, numColumns),
  };
};

export default connect(mapStateToProps, {
  fetchBarProducts,
  updateNameAndTable,
  //
  showCart,
  onYocoPay,
  onSnapScanPay,
})(OrderToSeatHome);

const styles = StyleSheet.create({});
