import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { connect, useDispatch } from 'react-redux';
import {
  View,
  Image,
  ScrollView,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
} from 'react-native';
import ButtonX from '../../components/buttons/ButtonX';
import { Feather } from '@expo/vector-icons';
import BackArrow from '../../components/common/BackArrow';
import { dimensions, colors, typography } from '../../utils/theme';
import { Card } from '../../components/common';
import TextBold from '../../components/Text/TextBold';
import TextBS from '../../components/Text/TextBoldSmall';
import Text from '../../components/Text/TextLight';
import TextSemi from '../../components/Text/TextS';
import TextInput from '../../components/TextInput';
import generateOTP from '../../utils/generateOTP';
import { setAuthError, paymentFail, showProgressModal } from '../../actions';
import walletPlus from '../../assets/svg/walletPlus.svg';

const formatter = (number) =>
  number.toLocaleString(undefined, {
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  });

const LineItem = (props) => {
  return (
    <View
      style={{
        width: '100%',
        alignItems: 'center',
      }}
    >
      <View
        style={{
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <View
            style={{
              paddingVertical: 5,
              paddingHorizontal: 10,
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 5,
              backgroundColor: colors.shade1,
              marginRight: 10,
            }}
          >
            <TextSemi>1</TextSemi>
          </View>

          <TextSemi style={{ width: '60%' }}>{props.subTitle}</TextSemi>
        </View>
        <TextSemi style={{ width: '30%', textAlign: 'right' }}>
          R {props.progressTotalAmount}
        </TextSemi>
      </View>
    </View>
  );
};

const Divider = () => {
  return (
    <View
      style={{
        height: 1,
        width: '100%',
        marginVertical: 10,
        backgroundColor: colors.shade1,
      }}
    />
  );
};

function ProgressView(props) {
  const { progressMode, user, subCart, onCancel, onPressPay, progressTotalAmount, subTitle } =
    props;
  const dispatch = useDispatch();

  return (
    <View style={{ flex: 1 }}>
      <View style={{ width: '100%' }}>
        <View>
          <TextBS style={{ color: colors.balance }}>{subTitle}</TextBS>
          <TextBold>Payment processing..</TextBold>
        </View>
      </View>
      <ActivityIndicator size="large" color={colors.balance} style={{ margin: 30 }} />

      <LineItem {...props} />

      <Divider />

      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <TextBS style={{ fontSize: 18 }}>Total</TextBS>
        <TextBS style={{ fontSize: 18 }}>R {progressTotalAmount}</TextBS>
      </View>

      <ButtonX
        type="outline"
        title="Clear"
        addStyle={{
          width: '100%',
          borderColor: colors.shade3,
          marginVertical: 15,
          marginTop: 30,
        }}
        titleStyle={{ fontWeight: 'bold', fontSize: 14, color: colors.shade3 }}
        onPress={() => dispatch(showProgressModal({ bool: false }))}
      />

      <TextBS style={{ fontSize: 12, color: colors.alert, textAlign: 'center' }}>
        {`Charging your bank card with R ${progressTotalAmount}.`}
      </TextBS>
    </View>
  );
}

const TopUpSuccess = (props) => {
  const dispatch = useDispatch();
  return (
    <View style={{ flex: 1, alignItems: 'center' }}>
      <View style={{ width: '100%' }}>
        <TextBS style={{ color: colors.balance }}>Success</TextBS>
        <TextBold>Top Up Received</TextBold>
      </View>
      <View
        style={{
          width: dimensions.width - 60,
          //height: dimensions.height / 2,
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: 20,
          marginVertical: 20,
          borderRadius: 20,
          backgroundColor: colors.notice,
        }}
      >
        <View style={{ width: '100%' }}>
          <TextBS style={{ color: 'gray' }}>Woohoo</TextBS>
          <TextBold style={{ color: 'white' }}>Congrats!</TextBold>
          <TextBS style={{ color: 'gray', fontSize: 14 }}>
            Your Top Up is now available in your wallet.
          </TextBS>
        </View>
        <Image
          source={{ uri: walletPlus }}
          resizeMode="contain"
          style={{
            height: 150,
            width: dimensions.width - 120,
            marginVertical: 20,
          }}
        />
        <ButtonX
          type="outline"
          title="Take me there"
          addStyle={{
            width: '100%',
            margin: 15,
            borderColor: 'white',
          }}
          titleStyle={{ fontWeight: 'bold', fontSize: 14, color: 'white' }}
          onPress={() => dispatch(showProgressModal({ bool: false }))}
        />
        {/* <Button
          title="TAKE ME HERE"
          type="outline"
          buttonStyle={{
            height: 50,
            width: "100%",
            marginVertical: 10,
            borderWidth: 2,
            borderColor: "white",
            marginTop: 10,
          }}
          titleStyle={{
            fontWeight: "bold",
            fontSize: 14,
            color: "white",
          }}
          onPress={() => dispatch(showProgressModal({ bool: false }))}
        /> */}
      </View>
    </View>
  );
};

const QlessSuccess = (props) => {
  const dispatch = useDispatch();
  return (
    <View style={{ flex: 1, alignItems: 'center' }}>
      <View style={{ width: '100%' }}>
        <TextBS style={{ color: colors.balance }}>Success</TextBS>
        <TextBold>Queue-less order placed!</TextBold>
        <Text style={{ marginVertical: 5 }}>
          <TextBS style={{ color: colors.balance }}>Take note </TextBS>
          of your
          <TextBS style={{ color: colors.primaryText }}> order number </TextBS>
          below.
        </Text>
        <Text style={{ marginVertical: 5 }}>
          Show this number when collecting your order from the express pick-up counter.
        </Text>
      </View>
      <View
        style={{
          width: dimensions.width - 60,
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: 20,
          marginVertical: 20,
          borderRadius: 20,
          backgroundColor: colors.notice,
        }}
      >
        <View style={{ width: '100%', marginVertical: 5 }}>
          <TextBS style={{ color: 'white', fontSize: 14 }}>Express order number:</TextBS>
        </View>
        <View
          style={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'white',
            padding: 30,
            borderRadius: 15,
          }}
        >
          <TextBold>{props.qlessOrderNumber}</TextBold>
        </View>

        <ButtonX
          type="outline"
          title="Got it!"
          addStyle={{
            width: '100%',
            margin: 15,
            borderColor: 'white',
            backgroundColor: 'transparent',
          }}
          titleStyle={{ fontWeight: 'bold', fontSize: 14, color: 'white' }}
          onPress={() => dispatch(showProgressModal({ bool: false }))}
        />
      </View>
    </View>
  );
};

function SuccessView(props) {
  return (
    <View style={{ flex: 1, alignItems: 'center' }}>
      {!!(props.progressMode === 'top_up') && <TopUpSuccess {...props} />}
      {!!(props.progressMode === 'qless') && <QlessSuccess {...props} />}
    </View>
  );
}

function FailedView(props) {
  const { authError } = props;
  const dispatch = useDispatch();

  return (
    <View style={{ flex: 1, alignItems: 'center', backgroundColor: colors.bg1 }}>
      <View style={{ width: '100%' }}>
        <TextBS style={{ color: colors.balance }}>Unsuccessful</TextBS>
        <TextBold>Payment Failed</TextBold>
      </View>

      <Text style={styles.alertText}>{authError}</Text>

      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          width: '95%',
          //marginHorizontal: 20
        }}
      >
        <ButtonX
          title="Start Again"
          type="outline"
          addStyle={{
            height: 50,
            width: (dimensions.width - 80) / 2, //200,
            marginVertical: 10,
            borderRadius: 25,
            borderWidth: 1,
            borderColor: colors.shade2,
            //backgroundColor: colors.highlight
          }}
          titleStyle={{
            fontWeight: 'bold',
            fontSize: 14,
            color: colors.shade3,
          }}
          onPress={() => {
            dispatch(showProgressModal({ bool: false }));
            props.paymentFail(false);
            props.setAuthError(null);
          }}
        />
      </View>
    </View>
  );
}

function ProgressModal(props) {
  const { topUpReceived, paymentFailed } = props;
  return (
    <View style={styles.container}>
      {topUpReceived ? (
        <SuccessView {...props} />
      ) : paymentFailed ? (
        <FailedView {...props} />
      ) : (
        <ProgressView {...props} />
      )}
    </View>
  );
}

const mapStateToProps = (state) => {
  const { progressTotalAmount, progressMode, topUpReceived, qlessOrderNumber, paymentFailed } =
    state.store;
  const { authError } = state.auth;
  return {
    progressTotalAmount,
    progressMode,
    topUpReceived,
    qlessOrderNumber,
    paymentFailed,
    authError,
    subTitle: subTitle(progressMode), // progressMode === "top_up" ? "Top Up" : "Queue-less order", // subTitle(progressMode),
  };
};

export default connect(mapStateToProps, {
  setAuthError,
  paymentFail,
})(ProgressModal);

const subTitle = (progressMode) => {
  switch (progressMode) {
    case 'top_up':
      return 'Top Up';
    case 'qless':
      return 'Queue-less order';

    default:
      return 'payment';
  }
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 20,
    borderRadius: 20,
    backgroundColor: 'white',
  },
  alertText: {
    fontSize: 14,
    color: colors.alert,
    marginVertical: 20,
  },
});
