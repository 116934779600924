import { firebase, firestore, functions, auth } from '../../../firebase/config';
import React, { useState, useRef, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { View, StyleSheet } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Text from '../../components/Text/TextLight';
import { Feather } from '@expo/vector-icons';
import Lottie from 'react-lottie';
import animationData from '../../assets/lottie/paper-plane';
import TextBold from '../../components/Text/TextBold';
import TextBS from '../../components/Text/TextBoldSmall';
import ButtonX from '../../components/buttons/ButtonX';
import { dimensions, colors, typography } from '../../utils/theme';
import {
  setGlobalInitializing,
  sendEmailLink,
  linkAuthProvider,
  // signUpEmailLink,
  signInEmailLink,
  updateSubCart,
} from '../../actions';

function EmailLinked(props) {
  const { navigation } = props;
  // const {
  //   // associated, existingAuthType, accounts,
  //   phoneNumber,
  //   email,
  // } = props.route.params;

  const { initialUrl } = useSelector((state) => state.config);
  // const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [sendSuccess, setSendSucces] = useState(false);
  const [successTitle, setSuccessTitle] = useState('Sign In Success!');
  const [successBody, setSuccessBody] = useState(
    'You have successfully linked an additional sign in method!',
  );

  // const methodToLink = existingAuthType === 'email' ? 'phone' : 'email';
  // const credToLink = existingAuthType === 'email' ? phoneNumber : email;
  // const credToSignIn =
  //   existingAuthType === "email"
  //     ? hideEmail(accounts[0].email)
  //     : hidePhone(accounts[0].cellphoneNumber);

  // useEffect(() => {
  //   // const params = props.route.params;
  //   // const isUser =
  //   //   !!params && (!!params.cellNo?.length || !!params.email?.length);
  //   // const isLink = !!params && !!params.orgId && !!params.event_id;
  //   const isAuthLink = auth.isSignInWithEmailLink(initialUrl);

  //   // const navigateAirDosh_Wallet = () => {
  //   //   if (isMobile || width < 650) {
  //   //     props.fetchAllEvents();
  //   //     RootNavigation.navigate("Wallet");
  //   //   } else {
  //   //     navigation.dispatch(
  //   //       StackActions.replace("Desktop", {
  //   //         screen: "DesktopWallet",
  //   //         params: { wallet_url: "wallet.airdosh.co.za" },
  //   //       })
  //   //     );
  //   //   }
  //   // };

  //   async function processLink() {
  //     const isLoggedIn = !!auth?.currentUser?.uid;
  //     const { params } = props.route; //.params

  //     if (isLoggedIn && !params.isSignIn && !params.isSignUp) {
  //       // likely account linking
  //       const userObjForSignIn = await AsyncStorage.getItem('userObjForSignIn');
  //       // const { email } = JSON.parse(userObjForSignIn);
  //       var email = JSON.parse(userObjForSignIn).email;
  //       if (!email) {
  //         email = window.prompt('Please provide your email for confirmation');
  //       }
  //       var credential = firebase.auth.EmailAuthProvider.credentialWithLink(email, initialUrl);
  //       dispatch(linkAuthProvider(credential));
  //       setSuccessTitle('Account Link Success!');
  //       setSuccessBody(
  //         'You have succesfully linked your email as an additional sign in method. In future, you may sign into your account using either phone OTP, or email Verifictaion Link.',
  //       );
  //     } else if (!isLoggedIn && !!params.isSignIn) {
  //       const userObjForSignIn = await AsyncStorage.getItem('userObjForSignIn');
  //       const storage = JSON.parse(userObjForSignIn);
  //       const { subCart, userObj } = storage;
  //       const { phoneNumber } = userObj;

  //       var email = userObj?.email || '';
  //       if (!email) {
  //         // User opened the link on a different device. To prevent session fixation attacks,
  //         // ask the user to provide the associated email again. For example:
  //         email = window.prompt('Please provide your email for confirmation');
  //       }

  //       props.signInEmailLink(email, initialUrl).then(() => {
  //         // if there is a NAV instruction in the link, Nav to the destination with params to verify the Phone sign in method

  //         if (!!params.toNav) {
  //           navigation.navigate(params.toNav, {
  //             existingAuthType: 'email',
  //             phoneNumber,
  //             email,
  //           });
  //         }
  //         if (!params.toNav && !!subCart[0]) {
  //           dispatch(updateSubCart(subCart));

  //           const { orgId, event_id } = subCart[0];
  //           navigation.navigate('Event Page', { orgId, event_id });
  //           AsyncStorage.removeItem('userObjForSignIn');
  //           // clear async storage here !!
  //         }
  //       });
  //     } else if (!isLoggedIn && !!params.isSignIn && !!params.toNav) {
  //       // TRYING SIGN IN and NAV ->
  //       // const userObjForSignIn = await AsyncStorage.getItem("userObjForSignIn");
  //       // const { email } = JSON.parse(userObjForSignIn);
  //       // if (!email) {
  //       //   // User opened the link on a different device. To prevent session fixation attacks,
  //       //   // ask the user to provide the associated email again. For example:
  //       //   email = window.prompt("Please provide your email for confirmation");
  //       // }
  //       // props.signInEmailLink(email, initialUrl).then(() => {
  //       //   // navigation.navigate("Wallet");
  //       // });
  //     } else if (!isLoggedIn && !!params.isSignUp) {
  //       // TRYING SIGN UP ->
  //       setSuccessTitle('Sign Up Success!');
  //       setSuccessBody(
  //         'You have succesfully Signed Up using your email as the sign in method. In future, you may sign into your account using email Verifictaion Link.',
  //       );
  //       const userObjForSignIn = await AsyncStorage.getItem('userObjForSignIn');
  //       const storage = JSON.parse(userObjForSignIn);
  //       const { subCart, userObj } = storage;

  //       var email = userObj?.email || '';
  //       if (!email) {
  //         // User opened the link on a different device. To prevent session fixation attacks,
  //         // ask the user to provide the associated email again. For example:
  //         email = window.prompt('Please provide your email for confirmation');
  //       }

  //       props.signUpEmailLink(userObj, initialUrl).then(() => {
  //         // navigation.navigate("Wallet");
  //       });
  //     } else {
  //       // if (isLink) {
  //       //   const { orgId, event_id } = params;
  //       //   props.updateEventFocus({ orgId, event_id });
  //       // }
  //       // else if (isUser) {
  //       //   setCellphoneNumber(props.route.params?.cellNo || "0");
  //       //   setEmail(props.route.params?.email || "");
  //       //   props.parseCellNo(cellphoneNumber, email);
  //       // }
  //       // else {
  //       // if the link failed, reset the init nav state and start over
  //       // props.setGlobalInitializing(false);
  //       // navigateAirDosh_Wallet();
  //       // await AsyncStorage.removeItem(PERSISTENCE_KEY);
  //       // }
  //       // }
  //     }
  //   }
  //   isAuthLink && processLink();
  //   dispatch(setGlobalInitializing(false));
  // }, []);

  return (
    <View style={styles.container}>
      <View style={{ marginTop: 10 }}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginVertical: 20,
          }}
        >
          <Feather name="check-circle" size={50} color={colors.highlight} />
          <TextBold
            style={{
              fontSize: 24,
              margin: 20,
              color: colors.textSolid,
            }}
          >
            {successTitle}
          </TextBold>
        </View>

        <Text>{successBody}</Text>
        <ButtonX
          type="solid"
          loading={loading}
          title="Continue"
          addStyle={{
            borderRadius: 10,
            marginVertical: 20,
            width: '100%',
          }}
          titleStyle={{ fontWeight: 'bold', fontSize: 16 }}
          onPress={() => navigation.navigate('Wallet')}
        />
      </View>
    </View>
  );
}

export default connect(null, {
  sendEmailLink,
  // signUpEmailLink,
  signInEmailLink,
})(EmailLinked);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    alignItems: 'center',
    backgroundColor: 'white',
  },
  message: {
    fontSize: 14,
    color: colors.secondary,
  },
});
