import React from "react";
import { TouchableHighlight, Text, View } from "react-native";
import { colors, typography, dimensions, logo } from "../../utils/theme";
import * as RootNavigation from "../../RootNavigation.js";
import { Ionicons } from "@expo/vector-icons";
import TextBold from "../../components/Text/TextBold";
import "./sticky.css";

export default function ({ onPress, addCol, style }) {
  return (
    <TouchableHighlight
      activeOpacity={1}
      underlayColor="white"
      className="sticky"
      onPress={onPress}
      style={[
        {
          zIndex: 10,
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: "transparent",
          height: 90,
          width: 70,
          padding: 15,
          paddingBottom: 0,
          borderRadius: 15,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        },
        style,
      ]}
    >
      <View>
        <Ionicons
          name="ios-arrow-back"
          size={30}
          color={!addCol ? colors.highlight : addCol}
          style={{ alignSelf: "flex-start", marginLeft: 5 }}
        />

        <View style={{ width: 20, backgroundColor: "transparent" }} />
      </View>
    </TouchableHighlight>
  );
}
//  style={{ }}

//<Text style={{ fontSize: 16, fontWeight: "bold" }}></Text>
