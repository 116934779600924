import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { TouchableOpacity, StyleSheet, Text, View } from "react-native";
import { dimensions, colors, typography } from "../utils/theme";
import { withTheme } from "react-native-elements";
import TextSemi from "../components/Text/TextS";
import ButtonSignIn from "../components/buttons/ButtonSignIn";
import { showAuthModal, showCart } from "../actions";

function SignInWidget(props) {
  const { navigation } = props;
  const { altTitle } = props;
  //const { primary, secondary, secondary2, white, black } = theme.colors;
  return (
    <View style={styles.container}>
      <View style={{ flex: 1 }}>
        {altTitle ? (
          <TextSemi style={styles.alertText}>{altTitle}</TextSemi>
        ) : (
          <TextSemi style={styles.alertText}>
            Register and Sign In to complete payment. Password-less. Estimated
            time 20s.
          </TextSemi>
        )}

        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <ButtonSignIn
            title="Sign In / Register"
            type="solid"
            addStyle={{
              flex: 1,
              margin: 5,
              marginBottom: 0,
            }}
            onPress={() => {
              props.showAuthModal({
                showModal: true,
                isSignUp: false,
              });
              // close modal in case it was used
              props.showCart(false);
            }}
          />
          {/* <ButtonSignIn
            title="Register"
            type="solid"
            addStyle={{
              flex: 1,
              margin: 5,
              marginBottom: 0,
              backgroundColor: colors.black,
            }}
            onPress={() => {
              props.showAuthModal({ showModal: true, isSignUp: true });
            }}
          /> */}
        </View>
      </View>
    </View>
  );
}

export default withTheme(
  connect(null, { showAuthModal, showCart })(SignInWidget)
);

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
  },
  alertText: {
    textAlign: "center",
    fontSize: 14,
    color: colors.pay,
    marginTop: 10,
    marginBottom: 5,
    // marginBottom: 0, // cancels out
  },
});
