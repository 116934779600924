import React, { useEffect, useState } from "react";
import { View, Image, ImageBackground, StyleSheet } from "react-native";
import { connect } from "react-redux";
import { firebase, auth, firestore } from "../../firebase/config";
import * as RootNavigation from "../RootNavigation.js";
import { createStackNavigator } from "@react-navigation/stack";
import { colors, typography, dimensions, logo } from "../utils/theme";
import SplashScreen from "../components/SplashScreen";
import DesktopWallet from "../views/DesktopWallet";

import {} from "../actions";

const Stack = createStackNavigator();
const phoneFrame = require("../assets/svg/framer.svg");

function desktopNav(props) {
  const { navigation } = props;
  const { globalInitialising, orgId, event_id, isPrivate, backgroundImage } =
    props;

  const [initializing, setInitializing] = useState(true);
  // const [globalInitializing, setGlobalInitializing] = useState(true);
  const [preFetching, setPreFetching] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    cacheImages();
    // const subscriber = auth.onAuthStateChanged(onAuthStateChanged);
    const subscriber = setTimeout(() => {
      setInitializing(false);
    }, 1000);
    return () => subscriber; // unsubscribe on unmount
  }, []);

  async function cacheImages() {
    await Image.prefetch(backgroundImage);
    setPreFetching(false);
  }

  //while initialsing, show activity indicator
  if (initializing || preFetching) return <SplashScreen />;

  return (
    <Stack.Navigator
      screenOptions={{
        headerStyle: {
          backgroundColor: colors.primaryDark,
          shadowOffset: {
            height: 0,
          },
        },
        headerTitleAlign: "center",
        headerTitleStyle: {
          fontWeight: "bold",
          fontFamily: typography.primary,
          fontSize: 17,
          color: colors.primaryText,
        },
      }}
    >
      <Stack.Screen
        name="DesktopWallet"
        component={DesktopWallet}
        options={{
          headerShown: false,
        }}
      />
    </Stack.Navigator>
  );
}

const mapStateToProps = (state) => {
  const { user } = state.users;
  const { globalInitialising } = state.auth;

  const { theme, carouselData, isPrivate, orgId, event_id } = state.config;
  const { backgroundImage } = theme;

  return {
    globalInitialising,
    orgId,
    event_id,
    isPrivate,
    backgroundImage,
  };
};

export default connect(mapStateToProps, {})(desktopNav);

const styles = StyleSheet.create({});
