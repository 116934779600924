import React, { useState, useRef, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { View, StyleSheet } from 'react-native';
import InputMat from '../../components/common/MaterialInput';
import ButtonX from '../../components/buttons/ButtonX';

import ModalHeader1 from '../../components/headers/Header1';
import PhoneInput from '../../components/PhoneInput';
import { dimensions, colors, typography } from '../../utils/theme';
import { updateAuthForm } from '../../actions';

function SignUpPhone(props) {
  const { navigation } = props;
  const { params } = props.route;
  // TODO: change this to rather get the form AND phoneNumber + countryCode from props.route.params and make the email, name, surname a local state value rather

  const { countryCode, rawPhoneNumber } = params;

  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState(
    `+${countryCode}${rawPhoneNumber}`,
  );
  console.log(formattedPhoneNumber);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [sendSuccess, setSendSucces] = useState(false);

  const phoneInput = useRef(null);

  const sendOTP = async (formattedPhoneNumber) => {
    navigation.navigate('Auth', {
      screen: 'EnterOTPForm',
      params: {
        isSignUp: true,
        phoneNumber: formattedPhoneNumber.replace(/\s/g, ''),
        email,
        name,
        surname,
        ...params,
      },
    });
  };

  const submitDisabled =
    email.length < 3 ||
    !email.includes('@') ||
    !email.includes('.') ||
    !name.length ||
    !surname.length;

  return (
    <View style={styles.container}>
      <div style={{ padding: 30 }} />
      <ModalHeader1
        hideClose={true}
        heading="Sign up with OTP"
        subHeading="Password-less"
        description="Sign up with password-less Phone OTP for future sign-ins."
      />
      <View style={{ flex: 1, width: '100%' }}>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            sendOTP(formattedPhoneNumber);
          }}
        >
          <InputMat
            required
            label="First Name"
            placeholder="Enter first name"
            onChangeText={(value) => setName(value)}
            value={name}
            autoComplete="name"
            textContentType="name"
            checkValidation={(val) => !val.length && 'First name required'}
          />
          <InputMat
            required
            label="Last Name"
            placeholder="Enter last name"
            onChangeText={(value) => setSurname(value)}
            value={surname}
            autoComplete="name-family"
            textContentType="familyName"
            checkValidation={(val) => !val.length && 'Last name required'}
          />
          <PhoneInput
            ref={phoneInput}
            defaultValue={rawPhoneNumber}
            defaultCode="ZA"
            layout="first"
            onChangeText={(value, code) => {
              // console.log('Raw phone number and country code separate', { value, code });
              setFormattedPhoneNumber(`+${code}${value}`);
            }}
            autoFocus
          />
          <InputMat
            required
            label="Email"
            placeholder="Enter email address"
            onChangeText={(value) => setEmail(value)}
            value={email}
            autoComplete="email"
            autoCompleteType="email"
            keyboardType="email-address"
            checkValidation={(val) =>
              (val.length < 3 || !val.includes('@') || !val.includes('.')) &&
              'Enter a valid email address'
            }
          />
          <button
            style={{
              border: 'none',
              background: 'none',
              width: '100%',
              padding: 0,
            }}
            type="submit"
            disabled={submitDisabled}
          >
            <ButtonX
              type="solid"
              loading={loading}
              disabled={submitDisabled}
              title="Continue"
              addStyle={{
                borderRadius: 10,
                backgroundColor: submitDisabled ? colors.shade2 : colors.balance,
                marginVertical: 20,
                marginTop: 10,
                width: '100%',
              }}
              titleStyle={{ fontWeight: 'bold', fontSize: 16 }}
            />
          </button>
        </form>
        {/* <ButtonX
          type="solid"
          loading={loading}
          title="Sign UP with email"
          addStyle={{
            borderRadius: 10,
            marginTop: 5,
            width: "100%",
            backgroundColor: colors.shade1,
          }}
          titleStyle={{
            fontWeight: "bold",
            fontSize: 16,
            color: "black",
          }}
          onPress={() =>
            navigation.navigate("Auth", {
              screen: "SignUpEmail",
            })
          }
        /> */}
      </View>
    </View>
  );
}

export default connect(null, {})(SignUpPhone);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    alignItems: 'center',
    backgroundColor: 'white',
  },
  message: {
    fontSize: 14,
    color: colors.secondary,
  },
});
