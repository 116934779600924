import React, { useState, useEffect } from 'react';
import { auth } from '../../../firebase/config';
import moment from 'moment';
import {
  Linking,
  ScrollView,
  TouchableOpacity,
  TouchableHighlight,
  ActivityIndicator,
  StyleSheet,
  View,
  Image,
} from 'react-native';
import TextBS from '../../components/Text/TextBoldSmall';
import Text from '../../components/Text/TextLight';
import { Avatar, Button, Chip } from 'react-native-elements';
import { connect } from 'react-redux';
import BackHeader from '../../components/common/NavButtonBack';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { dimensions, colors, typography } from '../../utils/theme';
import { formatDate, timeAgo } from '../../utils/timeAgo';
import { Card, Typo2Way } from '../../components/common';
import {} from '../../actions';

const GutterMD = () => <View style={{ height: 20 }} />;

const ItemButton = ({ title, onPress, containerStyle, textStyle }) => {
  return (
    <TouchableOpacity onPress={onPress} style={[styles.itemButton, containerStyle]}>
      <TextBS style={[{ color: colors.textSolid }, textStyle]}>{title}</TextBS>
    </TouchableOpacity>
  );
};

function Settings(props) {
  const { navigation } = props;
  const { user } = props;

  // const loadJS = (src) => {
  //   const ref = window.document.getElementsByTagName("script")[0];
  //   const script = window.document.createElement("script");
  //   script.src = src;
  //   script.async = true;
  //   ref.parentNode.insertBefore(script, ref);
  // };

  // useEffect(() => {
  //   // this.loadJS("https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js", 1);
  //   loadJS("https://chat-assets.frontapp.com/v1/chat.bundle.js");
  // }, []);

  const AirDoshPhoneNumber = '+27600820302';
  const AirDoshPhoneNumberNoPlus = '17242133657';
  const emailContact = 'hello@airdosh.co.za';
  const emailLink = `mailto:${emailContact}?subject=${`Reaching out to AirDosh!`}&body=${'Here is what i need assistance with..'}`;
  const dialPhone = `tel:${AirDoshPhoneNumber}`;
  const sendWhatsApp = `https://wa.me/${AirDoshPhoneNumberNoPlus}?text=Hi there,${
    !!user?.name ? ' my name is ' + user.name + ',' : ''
  } here is what i need assistance with..`;

  return (
    <View style={{ flex: 1 }}>
      <BackHeader {...props} title="Contact AirDosh" onBackPress={() => navigation.goBack()} />
      <View style={{ height: 60 }} />

      <View
        style={{
          flex: 1,
          padding: 20,
          backgroundColor: colors.bg,
        }}
      >
        <Typo2Way style={{ marginBottom: 10 }} subTitle="Let's talk" title="Contact" />

        <Text style={{ color: colors.shade5, fontSize: 14 }}>
          Get in touch with the AirDosh team. We would be happy to assist you with a question or
          query you may have.
        </Text>
        <GutterMD />
        <ItemButton title="Email" onPress={() => Linking.openURL(emailLink)} />
        {/* <ItemButton title="Phone" onPress={() => Linking.openURL(dialPhone)} /> */}
        <ItemButton title="WhatsApp" onPress={() => Linking.openURL(sendWhatsApp)} />
      </View>
    </View>
  );
}
const mapStateToProps = (state) => {
  const { user } = state.users;

  return {
    user,
  };
};
export default connect(mapStateToProps, {})(Settings);

const styles = StyleSheet.create({
  itemButton: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 5,
    padding: 20,
    backgroundColor: colors.shade1,
    borderRadius: 5,
  },
});
