import React, { Component } from 'react';
import { TouchableOpacity, StyleSheet, View, Image } from 'react-native';
import TextBS from '../components/Text/TextBoldSmall';
import TextS from '../components/Text/TextS';
import TextLight from '../components/Text/TextLight';
import { connect } from 'react-redux';
import { dimensions, colors, typography } from '../utils/theme';
import * as RootNavigation from '../RootNavigation.js';
import { AntDesign, Fontisto, MaterialCommunityIcons, Entypo, Feather } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import drink from '../assets/svg/drink.svg';
import storefront from '../assets/svg/storefront.svg';

const { height, width } = dimensions;
const gradient = ['#E4E5E7', '#E4E5E7']; //overiding the props gradient in Action button

const ActionButton = ({ disabled, addStyle, onPress, children, title }) => {
  return (
    <TouchableOpacity
      disabled={disabled}
      onPress={onPress}
      style={[
        {
          position: 'absolute',
          backgroundColor: 'transparent', //bgcol,
          height: 80,
          width: 80,
          borderRadius: 40,
        },
        addStyle,
      ]}
    >
      <LinearGradient
        colors={gradient}
        start={{ x: 0, y: 1 }}
        end={{ x: 1, y: 0 }}
        style={{
          zIndex: 1,
          position: 'absolute',
          top: 0,
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 40,
        }}
      >
        {children}
        <TextS
          style={{
            position: 'absolute',
            bottom: -25,
            width: 100,
            textAlign: 'center',
            color: colors.textSolid,
            opacity: 0.7,
            fontSize: 15,
          }}
        >
          {title}
        </TextS>
      </LinearGradient>
    </TouchableOpacity>
  );
};

const ActionButtons = ({ user, item, bgcol, addStyle }) => {
  return (
    <>
      <View style={[styles.buttonContainer, addStyle]}>
        <ActionButton
          title="Passes"
          addStyle={{ bottom: -100, right: 120 }}
          // addStyle={{ bottom: -30, left: -75 }} // placemnet when four buttons
          // addStyle={{ bottom: -95, left: 10 }} // placemnet when two buttons only
          gradient={['#CC0860', '#A3095D']}
          onPress={() => {
            RootNavigation.navigate('Passes');
          }}
        >
          <MaterialCommunityIcons name="ticket-outline" size={35} color="#2E3E5C" />
        </ActionButton>

        {/* <ActionButton
        title="Order"
        addStyle={{ bottom: -95, left: 10 }}
        gradient={["#A3095D", "#770A5A"]}
        bgcol={bgcol}
        onPress={() => RootNavigation.navigate("Express Order")}
      >
        <Image
          source={{ uri: drink }}
          resizeMode="contain"
          style={styles.iconStyle}
        />
      </ActionButton> */}
        {/* 
        <ActionButton
          title="Top Up"
          addStyle={
            // { bottom: -95, right: 10 }
            // setting for middle of 3 buttons { bottom: -100, right: 65 }
            { bottom: -100, right: 65 }
          }
          gradient={['#770A5A', '#3B0A54']}
          bgcol={bgcol}
          onPress={() => {
            RootNavigation.navigate('Top_up', { user });
          }}
        >
          <Feather name="plus" size={40} color="#2E3E5C" />
        </ActionButton> */}

        <ActionButton
          id="addToCartButton"
          title="Market" //"Event"
          addStyle={{ bottom: -100, right: 10 }}
          // addStyle={{ bottom: -30, right: -75 }} // placemnet when four buttons
          // addStyle={{ bottom: -95, right: 10 }} // placemnet when two buttons only
          gradient={['#3B0A54', '#0D0A4F']}
          bgcol={bgcol}
          onPress={() => {
            RootNavigation.navigate('Marketplace');
            // TODO private event settings
            // RootNavigation.navigate("Event Page", { item });
          }}
        >
          <Image source={{ uri: storefront }} resizeMode="contain" style={styles.iconStyle} />
          {/* <MaterialCommunityIcons name="store" size={35} color="#2E3E5C" /> */}
        </ActionButton>
      </View>
    </>
  );
};
{
  /* {
    <ActionButton
        title="Order"
        addStyle={{ bottom: -95, left: 10 }}
        gradient={["#A3095D", "#770A5A"]}
        bgcol={bgcol}
        onPress={() => RootNavigation.navigate("Express Order")}>
        <MaterialCommunityIcons
          name="glass-cocktail"
          size={35}
          color="#2E3E5C"
        />
      </ActionButton>
    } */
}

//  <Entypo name="drink" size={34} color="white" />
//  <Entypo name="shop" size={40} color="white" />

export default function (props) {
  const { user } = props;
  const pendingCredit = user && user.pendingCredit ? user.pendingCredit : 0;
  const userCredit = user !== null ? user.credit + pendingCredit : 0;

  return (
    <View>
      <ActionButtons bgcol={colors.highlight} addStyle={{}} {...props} />
    </View>
  );
}

const styles = StyleSheet.create({
  buttonContainer: {
    //this is the duplicate size of the balance circle, used as reference for action buttons that are absolute
    zIndex: 1,
    marginTop: -215,
    marginBottom: 130,
    height: dimensions.width > 375 ? 210 : dimensions.width / 1.8,
    width: dimensions.width > 375 ? 210 : dimensions.width / 1.8,
  },
  iconStyle: {
    height: 33,
    width: 33,
  },
});
