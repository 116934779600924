import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import {
  View,
  Text,
  Image,
  ImageBackground,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
} from 'react-native';
import { Button } from 'react-native-elements';
import { Fontisto, Feather, SimpleLineIcons } from '@expo/vector-icons';
import QRCode from 'react-native-qrcode-svg';
import { LinearGradient } from 'expo-linear-gradient';
import { dimensions, colors, typography } from '../../utils/theme';
import TextBold from '../../components/Text/TextBold';

export default function (props) {
  const {
    id,
    type,
    createdAt,
    updatedAt,
    titleTeam,
    rivalTeam,
    description,
    titleImage,
    date,
    location,
    price,
    block,
    row,
    seat,
    admission,
    qr,
  } = props.item;

  const SignIn = () => {
    return (
      <View
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(255,255,255,0.9)',
          height: '100%',
          width: '90%',
        }}
      >
        <Button
          title="Sign In"
          type="outline"
          buttonStyle={{
            height: 50,
            width: 130,
            margin: 20,
            borderRadius: 30,
            borderWidth: 2,
            backgroundColor: 'white',
            borderColor: colors.black,
            alignSelf: 'flex-start',
            shadowColor: 'rgba(0,0,0,0.6)',
            shadowOffset: { width: 0, height: 0 },
            shadowOpacity: 0.5,
            shadowRadius: 20,
          }}
          titleStyle={{
            fontWeight: 'bold',
            fontSize: 18,
            color: colors.black,
          }}
          onPress={props.onPress}
        />
      </View>
    );
  };

  const BlockSeatRowContainer = ({ label, param }) => {
    return (
      <View style={{ alignItems: 'center', margin: 5, marginHorizontal: 10 }}>
        <Text style={{ fontSize: 20, fontWeight: 'bold', color: 'white' }}>{param}</Text>
        <Text style={{ color: 'white' }}>{label}</Text>
      </View>
    );
  };

  return (
    <View style={styles.cardContainer}>
      <LinearGradient
        colors={colors.gradient1}
        start={{ x: 0, y: 1 }}
        end={{ x: 1, y: 0 }}
        style={{
          flex: 1,
          width: '100%',
          borderRadius: 15,
          padding: 15,
          justifyContent: 'space-between',
          height: '100%',
          width: '90%',
        }}
      >
        <View style={styles.infoContainer}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              //justifyContent: "space-between",
              marginVertical: 5,
            }}
          >
            <BlockSeatRowContainer label="Block" param={block} />
            <BlockSeatRowContainer label="Row" param={row} />
            <BlockSeatRowContainer label="Seat" param={seat} />
            <BlockSeatRowContainer label="Admission" param={admission} />
          </View>

          {titleTeam.length !== 0 && rivalTeam.length !== 0 ? (
            <View style={{ alignItems: 'center' }}>
              <TextBold style={{ color: 'white', fontSize: 22 }}>{titleTeam}</TextBold>
              <Text style={{ color: 'white' }}>VS</Text>
              <TextBold style={{ color: 'white', fontSize: 22 }}>{rivalTeam}</TextBold>
            </View>
          ) : (
            <TextBold style={{ color: 'white', fontSize: 22 }}>{description}</TextBold>
          )}

          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginVertical: 5,
            }}
          >
            <Feather name="calendar" size={16} color="white" style={{ marginRight: 5 }} />
            <Text style={{ color: 'white' }}>
              {moment(date.seconds).format('dddd Do MMMM YYYY')}
            </Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginVertical: 5,
            }}
          >
            <SimpleLineIcons
              name="location-pin"
              size={16}
              color="white"
              style={{ marginRight: 5 }}
            />
            <Text style={{ color: 'white' }}>{location?.address}</Text>
          </View>
          <TextBold style={{ color: 'white', fontSize: 18 }}>ZAR {price}</TextBold>
        </View>

        <View
          style={{
            backgroundColor: 'transparent',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <View
            style={{
              backgroundColor: 'white',
              alignItems: 'center',
              //width: 190,
              padding: 15,
              margin: 20,
              marginBottom: 0,
              borderRadius: 10,
            }}
          >
            <QRCode value={qr} size={150} />
            <SignIn />
          </View>
        </View>
      </LinearGradient>
    </View>
  );
}
//<Fontisto name="qrcode" size={150} color="#5E5E5E" />

const styles = StyleSheet.create({
  infoContainer: { alignItems: 'center', justifyContent: 'center' },
  cardContainer: {
    flex: 1,
    width: '100%',
    height: dimensions.height - 120, // take the max height of screen
    borderRadius: 15,
    alignItems: 'center',
    backgroundColor: 'white', //colors.pay,
    margin: 5,
    shadowColor: 'rgba(0,0,0,0.4)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 20,
  },
  // image: {
  //   width: "100%",
  //   height: dimensions.width - 80,
  //   borderTopLeftRadius: 15,
  //   borderTopRightRadius: 15
  //   //flex: 1,
  //   //backgroundColor: "yellow",
  //   //height: dimensions.width - 40,
  //   //justifyContent: "flex-end",
  //   // borderRadius: 15
  // },
  // gradient: {
  //   flex: 1,
  //   position: "absolute",
  //   top: 0,
  //   width: dimensions.width - 40,
  //   height: dimensions.width - 80,
  //   justifyContent: "flex-end",
  //   padding: 20
  //   // borderBottomLeftRadius: 9,
  //   // borderBottomRightRadius: 9
  // }
});
