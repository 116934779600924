import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import {
  View,
  Image,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
} from "react-native";
import { dimensions, colors, typography } from "../../utils/theme";
import TextSemi from "../Text/TextS";

export default function IncrementButton(props) {
  const { qty, onIncPress, onDecPress } = props;
  return (
    <View style={[styles.incContainer, props.style]}>
      <TouchableOpacity onPress={onDecPress} style={styles.decreaseContainer}>
        <TextSemi style={{ color: colors.balance, fontSize: 22 }}>-</TextSemi>
      </TouchableOpacity>
      <View style={styles.qtyContainer}>
        <TextSemi style={{ color: colors.textSolid }}>{qty}</TextSemi>
      </View>
      <TouchableOpacity onPress={onIncPress} style={styles.increaseContainer}>
        <TextSemi style={{ color: colors.balance, fontSize: 22 }}>+</TextSemi>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  incContainer: {
    height: 50,
    width: dimensions.width / 2.8,
    borderRadius: 10,
    margin: 10,
    backgroundColor: colors.bg2,
    flexDirection: "row",
    justifyContent: "center",
    //
    borderWidth: 1,
    borderColor: colors.shade1,
    shadowColor: "rgba(0,0,0,0)", // cancel shadow
    // shadowOffset: { width: 0, height: 0 },
    // shadowOpacity: 0.5,
    // shadowRadius: 15,
  },
  decreaseContainer: {
    width: "35%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
    paddingLeft: 5,
  },
  increaseContainer: {
    width: "35%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
    paddingRight: 5,
  },
  qtyContainer: {
    width: "30%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    borderLeftWidth: 0.5,
    borderRightWidth: 0.5,
    borderColor: colors.bg2,
  },
});
