import { firebase, firestore, functions, auth } from '../../../firebase/config';
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { View, StyleSheet } from 'react-native';
import { Feather } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Text from '../../components/Text/TextLight';
import TextBold from '../../components/Text/TextBold';
import TextBS from '../../components/Text/TextBoldSmall';
import ButtonX from '../../components/buttons/ButtonX';
import { dimensions, colors, typography } from '../../utils/theme';
import { sendEmailLink } from '../../actions';

function LinkCredentials(props) {
  const { navigation } = props;
  const { existingAuthType, phoneNumber, email } = props.route.params;

  const [loading, setLoading] = useState(false);
  const [sendSuccess, setSendSucces] = useState(false);

  const methodToLink = existingAuthType === 'email' ? 'phone' : 'email';
  const credToLink = existingAuthType === 'email' ? phoneNumber : email;

  const handleVerifyEmail = async () => {
    navigation.navigate('Auth', {
      screen: 'SignInEmail',
      params: {
        isSignUp: true,
        existingAuthType: 'phone',
        associated: true,
        phoneNumber,
        email,
      },
    });
  };

  const sendOTP = () => {
    navigation.navigate('Auth', {
      screen: 'EnterOTPForm',
      params: {
        existingAuthType: 'email',
        associated: true,
        phoneNumber,
        email,
      },
    });
  };

  const handleDoThisLater = async () => {
    const eventLastVisted = await AsyncStorage.getItem('EVENT_LAST_VISITED');
    const { orgId, event_id } = JSON.parse(eventLastVisted);

    if (!!orgId && !!event_id) {
      navigation.navigate('Event Page', { orgId, event_id });
    } else {
      navigation.navigate('Wallet');
    }
  };

  // if "verify email" was pressed
  // if (sendSuccess) return <SuccessMessage {...props} />;

  return (
    <View style={styles.container}>
      <View style={{ alignItems: 'center', marginTop: 30 }}>
        <Feather name="check-circle" size={50} color={colors.highlight} />
        <TextBold style={{ fontSize: 24, margin: 20, color: colors.textSolid }}>
          Sign In Success!
        </TextBold>
        <Text>
          You have signed in succesfully using{' '}
          <Text style={{ fontWeight: 'bold' }}>{existingAuthType}</Text> method. You may now add{' '}
          <Text style={{ fontWeight: 'bold' }}>{credToLink}</Text> as{' '}
          <Text style={{ fontWeight: 'bold' }}>{methodToLink}</Text> sign in method. In future, you
          can use either sign in method to access your wallet account.
        </Text>
        <View style={{ padding: 10 }} />
        <ButtonX
          type="solid"
          loading={loading}
          title={`Verify  ${credToLink}`}
          addStyle={{
            borderRadius: 10,
            marginVertical: 20,
            marginTop: 10,
            width: '100%',
          }}
          titleStyle={{ fontWeight: 'bold', fontSize: 16 }}
          onPress={() => {
            methodToLink === 'phone' && sendOTP();
            methodToLink === 'email' && handleVerifyEmail();
          }}
        />
        <ButtonX
          type="solid"
          loading={loading}
          title="Do this later"
          addStyle={{
            borderRadius: 10,
            marginTop: 5,
            width: '100%',
            backgroundColor: colors.shade1,
          }}
          titleStyle={{
            fontWeight: 'bold',
            fontSize: 16,
            color: 'black',
          }}
          onPress={handleDoThisLater}
        />
      </View>
    </View>
  );
}

export default connect(null, { sendEmailLink })(LinkCredentials);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    alignItems: 'center',
    backgroundColor: 'white',
  },
  message: {
    fontSize: 14,
    color: colors.secondary,
  },
});
