import styles from '../assets/styles/stylesheet';
import { firebase, auth } from '../../firebase/config';
import React, { useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  useWindowDimensions,
  Linking,
  SafeAreaView,
  TouchableOpacity,
  StyleSheet,
  Image,
  View,
  ImageBackground,
} from 'react-native';
import { usePrevious } from '../utils/hooks';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import TextBold from '../components/Text/TextBold';
import Text from '../components/Text/TextLight';
import { Button } from 'react-native-elements';
import { Fontisto, Feather } from '@expo/vector-icons';
import * as RootNavigation from '../RootNavigation.js';
import { isReadyRef } from '../RootNavigation';
import { StackActions } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { LinearGradient } from 'expo-linear-gradient';
import Modal from 'modal-enhanced-react-native-web';
import Lottie from 'react-lottie';
import animationData from '../assets/lottie/gradient-background';
import { dimensions, colors, typography } from '../utils/theme';
import menuIcon from '../assets/svg/menu.svg';
import TextInput from '../components/TextInput';
import BalanceCircle from '../components/BalanceCircle';
import ActionButtons from '../components/ActionButtons';
import InProgressModal from './Modals/OrderInProgressModal';
import InviteNoticeModal from './Modals/InviteNoticeModal';
import {
  setGlobalInitializing,
  // setGlobalLoading,
  //
  fetchAllEvents,
  fetchDashboard,
  showAuthModal,
  showAuthLoadingModal,
  signInEmailLink,
  parseCellNo,
  updateThemeImage,
  updateEventFocus,
  linkAuthProvider,
} from '../actions';

const { width, height } = dimensions;

const hotline = '17242133657';
const helpLink = `https://wa.me/${hotline}/?text=${encodeURI('I need assistance with..')}`;

const TagLink = ({ navigation, user }) => {
  return !user ? (
    <TouchableOpacity
      onPress={() => Linking.openURL(helpLink)}
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 30,
        backgroundColor: 'rgba(255,255,255,0.2)',
        paddingVertical: 7,
        paddingHorizontal: 20,
      }}
    >
      <Fontisto name="whatsapp" size={24} color="white" style={{ marginRight: 10 }} />
      <Text style={{ color: 'white' }}>Help</Text>
    </TouchableOpacity>
  ) : (
    <View>
      {user?.activeTagId?.length === 0 ? (
        <Button
          title="LINK NEW TAG"
          type="outline"
          buttonStyle={{
            height: 40,
            width: 150,
            //margin: 10,
            borderRadius: 20,
            borderWidth: 2,
            borderColor: 'white', //colors.highlight
          }}
          titleStyle={{
            fontWeight: 'bold',
            fontSize: 14,
            color: 'white', //colors.highlight
          }}
          onPress={() => navigation.navigate('LinkTag')}
        />
      ) : (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Fontisto name="link" size={18} color={colors.highlight} style={{ marginRight: 10 }} />
          <Text style={{ color: colors.highlight, fontWeight: 'bold' }}>Tag linked</Text>
        </View>
      )}
    </View>
  );
};

function AirDosh_Wallet(props) {
  const dispatch = useDispatch();
  const { navigation } = props;
  const {
    abs,
    z1,
    fullWidth,
    fullHeight,
    halfHeight,
    itemsCenter,
    justifyBetween,
    pxsm,
    ptsm,
    rowCenterAround,
    rowCenterBetween,
    masm,
    width90,
  } = styles;
  const {
    user,
    product,
    spinnerBalance,
    //
    backgroundImage,
    carouselData,
    //
    showPurchaseInProgress,
    currentPurchaseObj,
    inviteVerified,
  } = props;

  const [cellphoneNumber, setCellphoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [showInviteVerificationModal, setShowInviteVerificationModal] = useState(false);
  const { width } = useWindowDimensions();
  var userFB = auth.currentUser;

  useEffect(() => {
    setTimeout(() => {
      dispatch(showAuthLoadingModal(false));
    }, 1500);
  }, []);

  // useEffect(() => {
  //   const { navigation, user } = props;
  //   const params = props.route.params;
  //   const isUser =
  //     !!params && (!!params.cellNo?.length || !!params.email?.length);
  //   const isLink = !!params && !!params.orgId && !!params.event_id;
  //   const isAuthLink = auth.isSignInWithEmailLink(props.initialUrl);

  //   const navigateAirDosh_Wallet = () => {
  //     if (isMobile || width < 650) {
  //       props.fetchAllEvents();
  //       RootNavigation.navigate("Wallet");
  //     } else {
  //       navigation.dispatch(
  //         StackActions.replace("Desktop", {
  //           screen: "DesktopWallet",
  //           params: { wallet_url: "wallet.airdosh.co.za" },
  //         })
  //       );
  //     }
  //   };

  //   async function checkIfLink() {
  //     const isLoggedIn = !!userFB?.uid;

  //     if (isLoggedIn && isAuthLink) {
  //       const userObjForSignIn = await AsyncStorage.getItem("userObjForSignIn");
  //       const { email } = JSON.parse(userObjForSignIn);
  //       if (!email) {
  //         email = window.prompt("Please provide your email for confirmation");
  //       }
  //       var credential = firebase.auth.EmailAuthProvider.credentialWithLink(
  //         email,
  //         props.initialUrl
  //       );
  //       // props.linkAuthProvider(credential);
  //     } else if (!isLoggedIn && isAuthLink) {
  //       const userObjForSignIn = await AsyncStorage.getItem("userObjForSignIn");
  //       const userObj = JSON.parse(userObjForSignIn);
  //       const { subCart } = userObj;
  //       var email = userObj?.email || ;
  //       if (!email) {
  //         // User opened the link on a different device. To prevent session fixation attacks,
  //         // ask the user to provide the associated email again. For example:
  //         email = window.prompt("Please provide your email for confirmation");
  //       }

  //       props.signInEmailLink(email, props.initialUrl).then(() => {
  //         // navigation.navigate("Wallet");
  //       });
  //     } else {
  //       // if (isLink) {
  //       //   const { orgId, event_id } = params;
  //       //   props.updateEventFocus({ orgId, event_id });
  //       // }
  //       // else if (isUser) {
  //       //   setCellphoneNumber(props.route.params?.cellNo || "0");
  //       //   setEmail(props.route.params?.email || "");
  //       //   props.parseCellNo(cellphoneNumber, email);
  //       // }
  //       // else {
  //       // console.log("NOT a Link");
  //       // if the link failed, reset the init nav state and start over
  //       // props.setGlobalInitializing(false);
  //       // navigateAirDosh_Wallet();
  //       // await AsyncStorage.removeItem(PERSISTENCE_KEY);
  //       // }
  //       // }
  //     }
  //   }
  //   // checkIfLink();
  //   props.setGlobalInitializing(false);
  // }, []);

  // useEffect(() => {
  //   if (!isMobile && isBrowser) {
  //     // navigation.navigate("Desktop", {
  //     //   screen: "DesktopWallet",
  //     //   params: { wallet_url: "wallet.airdosh.co.za" },
  //     // });

  //     // navigation.dispatch(
  //     //   StackActions.replace("Desktop", {
  //     //     screen: "DesktopWallet",
  //     //     params: { wallet_url: "wallet.airdosh.co.za" },
  //     //   })
  //     // );

  //     navigation.navigate("Desktop", {
  //       screen: "DesktopWallet",
  //       params: props.route.params,
  //     });
  //   }
  // }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMin slice',
    },
  };

  const CONTAINER_HEIGHT = dimensions.height / 1.8;

  return (
    <View
      style={{
        backgroundColor: 'black',
        flex: 1,
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      {/* Lottie here */}
      <Lottie options={defaultOptions} height={CONTAINER_HEIGHT} width={dimensions.width} />
      <View
        style={[
          abs,
          fullWidth,
          {
            top: 0,
            height: CONTAINER_HEIGHT,
          },
        ]}
      >
        <LinearGradient
          colors={[
            'rgba(0,0,0, 0.5)',
            'rgba(0,0,0,0.1)',
            'transparent',
            'transparent',
            'transparent',
            'transparent',
            // "rgba(0,0,0, 1)",
          ]}
          style={[
            abs,
            fullWidth,
            {
              top: 0,
              height: CONTAINER_HEIGHT,
            },
          ]}
        />
        <View style={[itemsCenter, fullWidth, fullHeight.height]}>
          <View style={[fullWidth, ptsm, rowCenterBetween, { paddingHorizontal: 20 }]}>
            <TouchableOpacity
              // disabled={user === null}
              style={[rowCenterBetween, masm, { width: 90 }]}
              onPress={() => {
                !user
                  ? props.showAuthModal({
                      showModal: true,
                      isSignUp: false,
                    })
                  : navigation.navigate('Settings');
              }}
            >
              <Image
                source={{ uri: menuIcon }}
                resizeMode="contain"
                style={{ height: 28, width: 28 }}
              />
              <Feather name="user" size={28} color={colors.shade3} />
              {user ? (
                <Feather name="shield" size={27} color={colors.pay} style={{ marginBottom: 2 }} />
              ) : (
                <Feather
                  name="shield-off"
                  size={27}
                  color={colors.shade3}
                  style={{ marginBottom: 2 }}
                />
              )}
            </TouchableOpacity>

            <TagLink {...props} />
          </View>

          <Image
            style={{
              height: CONTAINER_HEIGHT / 1.4,
              width: 150, // dimensions.width / 2,
              // marginVertical: 10,
              opacity: 0.9,
            }}
            source={{ uri: require('../assets/svg/ad_logo_white.svg') }}
            resizeMode="contain"
          />
        </View>
      </View>

      {/* <ImageBackground
        source={{ uri: backgroundImage }}
        resizeMode="cover"
        style={[
          z1,
          fullWidth,
          itemsCenter,
          { height: dimensions.height / 1.7 },
        ]}
      >
      </ImageBackground> */}

      <LinearGradient
        colors={[colors.bg, colors.bg]}
        style={{
          zIndex: 2,
          flex: 1,
          width: '100%',
          alignItems: 'center',
          backgroundColor: 'black',
        }}
      >
        <BalanceCircle
          {...props}
          parsedCellNo={cellphoneNumber}
          addStyle={{ zIndex: 2 }}
          user={user}
          onPress={() => {
            props.showAuthModal({
              showModal: true,
              isSignUp: false,
            });
            // inviteVerified
            //   ? this.props.showAuthModal(true)
            //   : this.setState({ showInviteVerificationModal: true });
          }}
          spinner={spinnerBalance}
        />

        <ActionButtons user={user} item={product} addStyle={{ zIndex: 3 }} />
      </LinearGradient>

      <Modal
        isVisible={showPurchaseInProgress}
        onBackdropPress={() => console.log('onBackdropPress, whatevs')}
      >
        <InProgressModal
          user={user}
          cellphoneNumber={cellphoneNumber}
          currentPurchaseObj={currentPurchaseObj}
        />
      </Modal>
      <Modal
        isVisible={showInviteVerificationModal}
        onBackdropPress={() => setShowInviteVerificationModal(false)}
      >
        <InviteNoticeModal onClearModal={() => setShowInviteVerificationModal(false)} />
      </Modal>
    </View>
  );
}

const mapStateToProps = (state) => {
  const { user, spinnerBalance } = state.users;
  const { initialUrl, theme, carouselData, inviteVerified, isCashless, eventFocus } = state.config;
  const { showPurchaseInProgress, currentPurchaseObj, product } = state.store;
  const { backgroundImage } = theme;

  return {
    // orgId, // temporary
    // event_id, // temporary
    initialUrl,
    user,
    product, // single product for Chris
    eventFocus,
    inviteVerified,
    isCashless,
    spinnerBalance,
    backgroundImage,
    carouselData,
    showPurchaseInProgress,
    currentPurchaseObj,
  };
};

export default connect(mapStateToProps, {
  setGlobalInitializing,
  // setGlobalLoading,
  fetchAllEvents,
  fetchDashboard,
  showAuthModal,
  signInEmailLink,
  parseCellNo,
  updateThemeImage,
  updateEventFocus,
  linkAuthProvider,
})(AirDosh_Wallet);

// const styles = StyleSheet.create({
//   iconStyle: {
//     height: 22,
//     width: 22,
//     color: colors.shade4,
//   },
// });
