import { firebase, firestore, functions, auth } from '../../../../firebase/config';
import React, { useState, useRef, useEffect, Suspense } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  View,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
  SafeAreaView,
  ScrollView,
  Pressable,
} from 'react-native';
import * as RootNavigation from '../../../RootNavigation';
import Lottie from 'react-lottie';
import animationData from '../../../assets/lottie/paper-plane';
import { ButtonGroup } from 'react-native-elements';
import * as Animatable from 'react-native-animatable';
import Text from '../../../components/Text/TextLight';
import TextBold from '../../../components/Text/TextBold';
import TextBS from '../../../components/Text/TextBoldSmall';
import InputMat from '../../../components/common/MaterialInput';
import { Button } from 'react-native-elements';
import ButtonX from '../../../components/buttons/ButtonX';
import BackArrow from '../../../components/common/BackArrow';
import { Card } from '../../../components/common';
import ModalHeader1 from '../../../components/headers/Header1';
import OAuthOptions from './OAuthOptions';
// import PhoneInput from "../../../components/PhoneInput";
import { dimensions, colors, typography } from '../../../utils/theme';
import {
  foundUser,
  updateAuthForm,
  showEmailAuthModal,
  setAuthError,
  sendEmailLink,
  showAuthModal,
} from '../../../actions';
// import PhoneInput from "react-native-phone-number-input";
const PhoneInput = React.lazy(() => import('../../../components/PhoneInput'));

var AuditSignIn = functions.httpsCallable('auditSignIn');

const userRef = firestore.collection('customers');
const minPhoneLength = 9;

const Timer = ({ seconds, onResendPress, timeLeft, setTimeLeft }) => {
  useEffect(() => {
    // exit early when we reach 0
    if (timeLeft <= 0) return setTimeLeft(null);

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  return (
    <SafeAreaView>
      <View style={{ alignItems: 'center' }}>
        <Text style={{ color: colors.textSolid }}> Didn't receive an SMS?</Text>
        {!timeLeft ? null : (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text style={{ color: colors.textSolid }}>You may retry in </Text>
            <TextBold
              style={{
                width: 28,
                fontSize: 16,
                marginBottom: -2,
                color: colors.textSolid,
                textAlign: 'center',
              }}
            >
              {timeLeft}
            </TextBold>
            <Text style={{ color: colors.textSolid }}> seconds</Text>
          </View>
        )}
        <TouchableOpacity
          style={{ padding: 10 }}
          onPress={() => {
            onResendPress();
            setTimeLeft(60); // reset the timer to start again from 60s
            window?.recaptchaVerifier?.clear();
          }}
          disabled={timeLeft}
        >
          <Text style={{ color: timeLeft ? colors.shade2 : colors.balance }}>Start again</Text>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
};

const SignInPhoneForm = ({ stuff }) => {
  const inputRef = useRef(null);
  const {
    loading,
    signInMethod,
    sendVerification,
    setRawPhoneNumber,
    setCountryCode,
    rawPhoneNumber,
  } = stuff;

  const phoneInput = useRef(null);

  return (
    <View>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          sendVerification(); // args are in parent component state {code, number}
        }}
      >
        <View>
          <PhoneInput
            ref={phoneInput}
            defaultValue=""
            defaultCode="ZA"
            layout="first"
            // TODO: changed this here to now return two values 1.countryCode 2.rawFormattedNumber
            onChangeText={(value, code) => {
              // console.log('Raw phone number and country code separate', { value, code });
              setRawPhoneNumber(value);
              setCountryCode(code);
            }}
            autoFocus
          />

          <Text
            style={{
              textAlign: 'center',
              fontSize: 12,
              color: colors.textSolid,
              marginVertical: 20,
            }}
          >
            You will receive a verification One Time Pin via SMS. Standard rates apply.
          </Text>
        </View>

        <button
          style={{
            border: 'none',
            background: 'none',
            width: '100%',
            padding: 0,
          }}
          type="submit"
          disabled={signInMethod === 0 && rawPhoneNumber.length < minPhoneLength}
        >
          <ButtonX
            type="solid"
            loading={loading}
            title="Sign in with OTP"
            addStyle={{
              borderRadius: 10,
              backgroundColor:
                signInMethod === 0 && rawPhoneNumber.length < minPhoneLength
                  ? colors.shade2
                  : colors.balance,
              marginVertical: 20,
              marginTop: 10,
              width: '100%',
            }}
            titleStyle={{ fontWeight: 'bold', fontSize: 16 }}
          />
        </button>
      </form>
      <TextBS style={{ color: colors.notice }}>
        If you are roaming and not able to receive sms messages, please use your WhatsApp number to
        receive OTP.
      </TextBS>
    </View>
  );
};

const SignInEmailForm = ({ stuff }) => {
  const inputRef = useRef(null);
  const { email, updateAuthForm, loading, signInMethod, handleContinueWithEmail } = stuff;

  return (
    <View>
      <form
        onSubmit={(event) => {
          event.preventDefault();
        }}
      >
        {/* <Text style={{ color: colors.shade5, fontSize: 12 }}>
          <TextBS>Start by entering your phone number</TextBS>
          <TextBS>Start by entering your phone number</TextBS>
        </Text> */}
        <View>
          <InputMat
            required
            label="Email"
            placeholder="Enter email address"
            onChangeText={(value) => updateAuthForm({ key: 'email', value })}
            value={email}
            autoComplete="email"
            autoCompleteType="email"
            keyboardType="email-address"
            checkValidation={(val) =>
              (val.length < 3 || !val.includes('@') || !val.includes('.')) &&
              'Enter a valid email address'
            }
          />
          <Text
            style={{
              textAlign: 'center',
              fontSize: 12,
              color: colors.textSolid,
              marginVertical: 20,
            }}
          >
            In the next step you may sign in or sign up. A new or existing password will be
            required.
          </Text>
        </View>

        <button
          style={{
            border: 'none',
            background: 'none',
            width: '100%',
            padding: 0,
          }}
          type="submit"
          disabled={
            (signInMethod === 1 && email.length < 3) || !email.includes('@') || !email.includes('.')
          }
        >
          <ButtonX
            type="solid"
            loading={loading}
            title="Continue with Email"
            addStyle={{
              borderRadius: 10,
              backgroundColor:
                (signInMethod === 1 && !email.includes('@')) || !email.includes('.')
                  ? colors.shade2
                  : colors.balance,
              marginVertical: 20,
              marginTop: 10,
              width: '100%',
            }}
            titleStyle={{ fontWeight: 'bold', fontSize: 16 }}
            onPress={handleContinueWithEmail}
          />
        </button>
      </form>
    </View>
  );
};

const EnterOTPForm = ({ stuff }) => {
  const {
    loading,
    setStep,
    onModalCancel,
    code,
    setCode,
    confirmCode,
    timeLeft,
    setTimeLeft,
    authError,
    startAgian,
  } = stuff;
  return (
    <View
      style={{
        flex: 1,
        width: '100%',
        alignItems: 'center',
        // justifyContent: "space-between",
      }}
    >
      <ModalHeader1
        heading="Enter OTP"
        subHeading="SMS has been sent"
        onClose={() => {
          setStep(1);
          onModalCancel(false);
        }}
      />

      <form
        onSubmit={(event) => {
          event.preventDefault();
          confirmCode();
        }}
        style={{ width: '100%' }}
      >
        <InputMat
          label="OTP"
          placeholder="enter One Time Pin"
          onChangeText={setCode}
          value={code}
          keyboardType="number-pad"
          textContentType="oneTimeCode"
          autoComplete="sms-otp"
          checkValidation={(val) => !val.length && 'Required'}
        />
        {authError && (
          <Text
            style={{
              textAlign: 'center',
              fontSize: 12,
              color: 'red',
            }}
          >
            {authError}
          </Text>
        )}

        <button
          style={{
            border: 'none',
            background: 'none',
            width: '100%',
            padding: 0,
          }}
          type="submit"
          disabled={code.length < 6}
        >
          <ButtonX
            type="solid"
            loading={loading}
            title="Sign In"
            addStyle={{
              borderRadius: 10,
              backgroundColor: code.length < 6 ? colors.shade2 : colors.balance,
              marginVertical: 20,
              marginTop: 10,
              width: '100%',
            }}
          />
        </button>
      </form>

      <Timer onResendPress={() => startAgian()} timeLeft={timeLeft} setTimeLeft={setTimeLeft} />
    </View>
  );
};

const SignUpInToggle = ({ props }) => {
  const { showAuthModal, isSignUp } = props;
  return (
    <TouchableOpacity
      onPress={() =>
        showAuthModal({
          showModal: true,
          isSignUp: !isSignUp,
        })
      }
      style={styles.footer}
    >
      <Text style={[styles.message, { marginRight: 0, textAlign: 'right' }]}>
        {!!isSignUp ? ' Previously signed up to AirDosh?' : 'First time using AirDosh?'}
      </Text>
      <View
        style={{
          backgroundColor: 'rgba(56,187,177, 0.1)',
          padding: 15,
          paddingHorizontal: 25,
          borderRadius: 30,
          marginLeft: 10,
        }}
      >
        <TextBS style={[styles.message, { color: colors.pay }]}>
          {!!isSignUp ? 'Sign In' : 'Sign Up'}
        </TextBS>
      </View>
    </TouchableOpacity>
  );
};

const SuccessMessage = ({ closeModalClear }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: colors.bg1,
        // paddingHorizontal: 20,
        width: '100%',
      }}
    >
      <View style={{ margin: -20 }}>
        <Lottie options={defaultOptions} height={220} width={dimensions.width} />
      </View>
      <div>
        <div style={{ paddingVertical: 20 }}>
          <span>
            {' '}
            <TextBS style={[styles.message, { color: colors.black, fontSize: 20 }]}>
              Verification
            </TextBS>
          </span>
          <span>
            {' '}
            <TextBS style={[styles.message, { color: colors.pay, fontSize: 20 }]}>
              email sent!
            </TextBS>
          </span>
        </div>
        <TextBS style={[styles.message, { color: colors.shade3 }]}>
          To complete sign in, please check your email inbox, and click the verification link -
          'sign in to AirDosh'
        </TextBS>
      </div>

      <ButtonX
        type="solid"
        title="OK"
        addStyle={{
          borderRadius: 10,
          marginVertical: 20,
          marginTop: 10,
          width: '100%',
        }}
        titleStyle={{ fontWeight: 'bold', fontSize: 14 }}
        onPress={closeModalClear}
      />
    </View>
  );
};

function AuthModal(props) {
  // const { navigation } = props;
  const { eventFocus } = useSelector((state) => state.config);
  const { onModalCancel, showAuthModal, updateAuthForm } = props;
  const {
    authError,
    isSignUp,
    isInstagram,
    parsedCelllNo,
    parsedEmail,
    phoneNumber,
    email,
    name,
    surname,
  } = props; // mapped from state

  const inputRef = useRef(null);
  const captchaRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [sendSuccess, setSendSucces] = useState(false);
  const [signInMethod, setSignInMethod] = useState(0);
  const [isAssociated, setAssociated] = useState(false);
  const [code, setCode] = useState('');
  const [verificationId, setVerificationId] = useState(null);
  const [step, setStep] = useState(1); //1
  // initialize timeLeft with the seconds prop
  const [timeLeft, setTimeLeft] = useState(60);
  const [captchaAttempt, setCaptchaAttempt] = useState(1);
  const [countryCode, setCountryCode] = useState('ZA');
  const [rawPhoneNumber, setRawPhoneNumber] = useState('');

  const handleContinueWithEmail = async () => {
    setAuthError(null);
    setLoading(true);
    const { data } = await AuditSignIn({ email });

    if (!data?.user && data?.associated && !isSignUp) {
      setLoading(false);
      setAssociated(true);
      props.showAuthModal({
        showModal: false,
      });
      RootNavigation.navigate('Auth', {
        screen: 'ManageDualAccount',
        params: { ...data, existingAuthType: 'phone', email },
      });
    } else if (!data?.user && !data?.associated && !isSignUp) {
      // toggle to show sign in
      setLoading(false);
      showAuthModal({ showModal: false });
      RootNavigation.navigate('Auth', {
        screen: 'SignUpEmail',
        params: { email },
      });
    } else {
      setLoading(false);
      showAuthModal({ showModal: false });
      RootNavigation.navigate('Auth', {
        screen: 'SignInEmail',
        params: { email },
      });
    }
  };

  // const sendEmail = async () => {
  //   // const { orgId, event_id } = props.eventFocus;
  //   setAuthError(null);
  //   setLoading(true);
  //   const { data } = await AuditSignIn({ email });

  //   if (!data?.user && data?.associated && !isSignUp) {
  //     setLoading(false);
  //     setAssociated(true);
  //     props.showAuthModal({
  //       showModal: false,
  //     });
  //     RootNavigation.navigate('Auth', {
  //       screen: 'ManageDualAccount',
  //       params: { ...data, existingAuthType: 'phone', email },
  //     });
  //   } else if (!data?.user && !data?.associated && !isSignUp) {
  //     // toggle to show sign in
  //     setLoading(false);
  //     showAuthModal({
  //       showModal: false,
  //       // isSignUp: !isSignUp,
  //     });
  //     RootNavigation.navigate('Auth', {
  //       screen: 'SignUpEmail',
  //       params: { email },
  //     });
  //   } else {
  //     return props
  //       .sendEmailLink({
  //         userObj: { email },
  //         params: 'auth/null/isSignIn', // event/orgId/event_id
  //       })
  //       .then(() => {
  //         setSendSucces(true);
  //         setLoading(false);
  //       });
  //   }
  // };

  const sendVerification = async () => {
    const formattedNumber = `+${countryCode}${rawPhoneNumber}`;

    setAuthError(null);
    setLoading(true);
    const { data } = await AuditSignIn({
      cellphoneNumber: formattedNumber,
    });
    const event_params =
      (!!eventFocus && {
        orgId: eventFocus.orgId,
        event_id: eventFocus.event_id,
      }) ||
      {};

    if (!data?.user && data?.associated && !isSignUp) {
      setLoading(false);
      setAssociated(true);
      props.showAuthModal({
        showModal: false,
      });
      RootNavigation.navigate('Auth', {
        screen: 'ManageDualAccount',
        params: {
          ...data,
          existingAuthType: 'email',
          // countryCode,
          // rawPhoneNumber,
          phoneNumber: formattedNumber,
        },
      });
    } else if (!data?.user && !data?.associated && !isSignUp) {
      setLoading(false);
      showAuthModal({
        showModal: false,
      });

      RootNavigation.navigate('Auth', {
        screen: 'SignUpPhone',
        // TODO: here the coutry code and phone number to be passed separately to hydrate phone input on Recaptcha page
        params: { countryCode, rawPhoneNumber, ...event_params },
      });
    } else {
      setLoading(false);
      props.showAuthModal({
        showModal: false,
      });
      RootNavigation.navigate('Auth', {
        screen: 'EnterOTPForm',
        params: {
          ...data,
          existingAuthType: 'email',
          // TODO: here we the finally formatted number is passed
          phoneNumber: formattedNumber,
          ...event_params,
        },
      });
    }
  };

  const closeModalClear = () => {
    onModalCancel(false);
    authError !== null && setAuthError(null) && setStep(1);
    setSendSucces(false);
  };

  return (
    <View style={styles.modalContainer}>
      <Suspense fallback={<div>Loading...</div>}>
        <View
          key={captchaAttempt}
          ref={captchaRef}
          nativeID="reCAPTCHA-container"
          style={{ backgroundColor: 'white' }}
        ></View>

        {step === 1 && (
          <View
            style={{
              flex: 1,
              backgroundColor: colors.bg1,
              width: '100%',
            }}
          >
            <ModalHeader1
              heading={!!isSignUp ? 'Sign Up' : 'Sign In / Register'}
              subHeading="Password-less"
              description={
                !!isSignUp
                  ? 'Sign up with email + mobile number. Use password-less OTP or Email and Password for future sign-ins.'
                  : 'Sign in with email + mobile number. Use password-less OTP or Email and Password to authenticate.'
              }
              onClose={() => {
                closeModalClear();
              }}
            />
            <ScrollView
              style={{
                margin: -20,
                padding: 20,
                paddingTop: 10,
                paddingBottom: 40,
              }}
            >
              {!isSignUp && !sendSuccess && (
                <View>
                  {/* {!isInstagram && ( */}
                  <ButtonGroup
                    buttons={['Phone', 'Email']}
                    selectedIndex={signInMethod}
                    onPress={(value) => {
                      setSignInMethod(value);
                    }}
                    containerStyle={{ marginBottom: 30, borderRadius: 10 }}
                    selectedButtonStyle={{ backgroundColor: colors.black }}
                  />

                  {signInMethod === 0 && (
                    <SignInPhoneForm
                      stuff={{
                        phoneNumber,
                        updateAuthForm,
                        // setPhoneNumber,
                        loading,
                        signInMethod,
                        sendVerification,
                        setCountryCode,
                        setRawPhoneNumber,
                        rawPhoneNumber,
                      }}
                    />
                  )}
                  {signInMethod === 1 && (
                    <SignInEmailForm
                      stuff={{
                        email,
                        updateAuthForm,
                        // setEmail,
                        loading,
                        signInMethod,
                        // sendEmail,
                        handleContinueWithEmail,
                      }}
                    />
                  )}
                </View>
              )}
            </ScrollView>
          </View>
        )}
      </Suspense>
    </View>
  );
}

const mapStateToProps = (state) => {
  const { product } = state.store;
  const { user } = state.users;
  const { authError, phoneNumber, email, name, surname } = state.auth;
  const { eventFocus, isInstagram } = state.config;

  return {
    user,
    product,
    authError,
    eventFocus,
    //
    phoneNumber,
    email,
    name,
    surname,
    isInstagram,
  };
};

export default connect(mapStateToProps, {
  foundUser,
  updateAuthForm,
  showEmailAuthModal,
  showAuthModal,
  setAuthError,
  sendEmailLink,
})(AuthModal);

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    backgroundColor: 'white',
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
    margin: -18,
    marginTop: 10,
    padding: 20,
    alignItems: 'center',
  },
  message: {
    fontSize: 14,
    // width: "100%",
    color: colors.secondary,
    // textAlign: "center",
  },

  footer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
  },
  // inputText: {
  //   marginHorizontal: 0, // cancels out whatever there is
  // },
});
