import { firebase, firestore, functions, auth } from '../../../firebase/config';
import React, { useState, useRef, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { View, TouchableOpacity, StyleSheet, Linking } from 'react-native';
import Lottie from 'react-lottie';
import animationData from '../../assets/lottie/paper-plane';
import TextBS from '../../components/Text/TextBoldSmall';
import Text from '../../components/Text/TextLight';
import InputMat from '../../components/common/MaterialInput';
import ButtonX from '../../components/buttons/ButtonX';
import ModalHeader1 from '../../components/headers/Header1';
import PhoneInput from '../../components/PhoneInput';
import { dimensions, colors, typography } from '../../utils/theme';
import { updateAuthForm, sendEmailLink } from '../../actions';

const SuccessMessage = (props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <View style={styles.container}>
      <View style={{ margin: -20 }}>
        <Lottie options={defaultOptions} height={220} width={dimensions.width} />
      </View>
      <div>
        <div style={{ paddingVertical: 20 }}>
          <span>
            {' '}
            <TextBS style={[styles.message, { color: colors.black, fontSize: 20 }]}>
              Verification
            </TextBS>
          </span>
          <span>
            {' '}
            <TextBS style={[styles.message, { color: colors.pay, fontSize: 20 }]}>
              email sent!
            </TextBS>
          </span>
        </div>
        <TextBS style={[styles.message, { color: colors.shade3 }]}>
          To complete the process, check your inbox for the verification email. Click the link, and
          reset your password.
        </TextBS>
        <br />
        <br />
        <Text style={{ color: colors.highlight }}>
          *Important! If this email is first delivered to your promotional or spam inbox, please
          take a second to move the message to your primary inbox. This will help ensure the
          successful delivery of important event updates in future.
        </Text>
      </div>

      {/* <ButtonX
        type="solid"
        title="OK"
        addStyle={{
          borderRadius: 10,
          marginVertical: 20,
          marginTop: 10,
          width: '100%',
        }}
        titleStyle={{ fontWeight: 'bold', fontSize: 14 }}
        onPress={() => props.navigation.navigate('Wallet')}
      /> */}
    </View>
  );
};

function SignUpEmail(props) {
  const { navigation } = props;
  const { params } = props.route;
  // TODO: change this to rather get the "email" from props.route.params
  const [email, setEmail] = useState('');

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [sendSuccess, setSendSucces] = useState(false);
  const [error, setError] = useState(null);
  const phoneInput = useRef(null);

  useEffect(() => {
    !!params?.email && setEmail(params.email);
  }, []);

  const handleResetPassword = async () => {
    setLoading(true);
    const contUrl = await Linking.getInitialURL();

    try {
      await auth.sendPasswordResetEmail(email, {
        url: contUrl,
      });
      setSendSucces(true);
      setLoading(false);
    } catch (err) {
      console.log({ err });
      setError(err.message);
      setLoading(false);
    }
  };

  const submitDisabled = email.length < 3 || !email.includes('@') || !email.includes('.');

  // if "verify email" was pressed
  if (sendSuccess) return <SuccessMessage {...props} />;

  return (
    <View style={styles.container}>
      <ModalHeader1
        hideClose={true}
        heading="Reset Password"
        subHeading="Security check"
        description="A verification email will be sent to your inbox."
      />
      <View style={{ flex: 1, width: '100%' }}>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            handleResetPassword();
          }}
        >
          <InputMat
            required
            label="Email"
            placeholder="Enter email address"
            // onChangeText={(value) => dispatch(updateAuthForm({ key: 'email', value }))}
            onChangeText={(value) => setEmail(value)}
            value={email}
            autoComplete="email"
            autoCompleteType="email"
            keyboardType="email-address"
            checkValidation={(val) =>
              (val.length < 3 || !val.includes('@') || !val.includes('.')) &&
              'Enter a valid email address'
            }
          />

          <button
            style={{
              border: 'none',
              background: 'none',
              width: '100%',
              padding: 0,
            }}
            type="submit"
            disabled={email.length < 3 || !email.includes('@') || !email.includes('.')}
          >
            <ButtonX
              type="solid"
              loading={loading}
              title="Continue"
              addStyle={{
                borderRadius: 10,
                backgroundColor: submitDisabled ? colors.shade2 : colors.balance,
                marginVertical: 20,
                marginTop: 10,
                width: '100%',
              }}
              titleStyle={{ fontWeight: 'bold', fontSize: 16 }}
            />
          </button>
        </form>
        <Text style={{ color: colors.highlight }}>
          *Important! If this email is first delivered to your promotional or spam inbox, please
          take a second to move the message to your primary inbox. This will help ensure the
          successful delivery of important event updates in future.
        </Text>
      </View>
    </View>
  );
}

export default connect(null, { sendEmailLink })(SignUpEmail);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    paddingTop: 80,
    alignItems: 'center',
    backgroundColor: 'white',
  },
  message: {
    fontSize: 14,
    color: colors.secondary,
  },
});
