import { LOGGING } from "../actions/types";

const INITIAL_STATE = {
  loading: false,
  passes: [],
};

const passReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGGING:
      return { ...state, loading: true };

    default:
      return state;
  }
};

export default passReducer;
