import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { View, FlatList, StyleSheet } from 'react-native';
import { CardGradient } from '../../components/common';
import { dimensions, colors, typography } from '../../utils/theme';
import BackHeader from '../../components/common/NavButtonBack';
import ProductCard from './ProductCard';
import EventCard1 from './EventCard1';
import MerchCard from './MerchCard';
import SplashLoading from '../../components/SplashLoading';
import TextSemi from '../../components/Text/TextS';
import {
  showCart,
  setGlobalLoading,
  fetchAllEvents,
  fetchSingleEvent,
  fetchOrganiserEvents,
  setEventFocus,
  fetchEventFocusOnce, // quick fetch for promo check
  fetchPromoLink,
  fetchPromoDetails,
} from '../../actions';

function PromoMarket(props) {
  const { navigation } = props;
  const { products, subCart } = props;
  const header = useRef();
  const statBarBG = useRef();

  useEffect(() => {
    // fetch the events at which the promo is valid

    const params = props?.route?.params;

    async function fetchPromoDetails() {
      const promoLink = await props.fetchPromoLink(params);

      if (!promoLink?.valid) {
        // navigate the user to the next evetn and let them know that the link is invalid
        console.log('Discount not valid, and could not be set.');
      } else {
        const { orgId, event_id, promo_id, link_id } = params;

        const promoDetails = await props.fetchPromoDetails(params);
        const { events_multi } = promoDetails;
        const thisEvent = await props.fetchEventFocusOnce(orgId, event_id);

        props.fetchOrganiserEvents(orgId, events_multi);
      }
    }
    fetchPromoDetails();
  }, []);

  const renderItemType = (props) => {
    const { type } = props.item;
    return type === 'event2' ? (
      <ProductCard {...props} />
    ) : type === 'event1' ? (
      <EventCard1 {...props} />
    ) : type === 'merchandise' ? (
      <MerchCard {...props} />
    ) : null;
  };

  const spinnerRef = useRef();

  if (!products.length)
    return (
      <View ref={spinnerRef} style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <SplashLoading />
      </View>
    );

  return (
    <div
      style={{
        backgroundColor: 'rgba(0,0,0,0.005)',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <BackHeader
        {...props}
        headerRef={header}
        subCart={subCart}
        title="Select event"
        onShowCartPress={() => props.showCart(true)}
        onBackPress={() => navigation.navigate('Wallet')}
      />
      <View style={{ height: 60 }} />
      <CardGradient eventFocus={{ color1: '#FBB034', color2: '#F5D020' }} style={{ margin: 15 }}>
        <TextSemi
          style={{
            color: 'rgba(255,255,255,1)', //colors.shade4,
            fontSize: 14,
            marginBottom: 10,
          }}
        >
          You are using a promotional link! Claim your promotional offer at any of the events below.
        </TextSemi>
      </CardGradient>

      <FlatList
        contentContainerStyle={{ alignItems: 'center' }}
        data={products}
        renderItem={({ item, i }) =>
          renderItemType({
            item,
            onPress: () => {
              // props.setGlobalLoading(true);
              const { orgId, event_id, id } = item; // has to he the orgId and event_id of the ITEM
              const { promo_id, link_id } = props.route.params;
              navigation.navigate('Event Page', {
                orgId,
                event_id: (!!event_id && event_id) || id,
                promo_id,
                link_id,
              });
            },
          })
        }
        keyExtractor={(item) => item.id}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  const { products } = state.store;
  const { subCart } = state.store;
  const { user } = state.users;

  return {
    user,
    products: products
      // .filter((prod) => prod.live_status) // until these evnets are LIVE this would have to remain commented
      // .filter((prod) => !prod.is_private)
      .sort(function (a, b) {
        return new Date(a?.date?.startTime?.seconds) - new Date(b?.date?.startTime?.seconds);
      }),
    subCart,
  };
};

export default connect(mapStateToProps, {
  showCart,
  setGlobalLoading,
  fetchAllEvents,
  fetchSingleEvent,
  fetchOrganiserEvents,
  setEventFocus,
  fetchEventFocusOnce, // quick fetch for promo check
  fetchPromoLink,
  fetchPromoDetails,
})(PromoMarket);

const styles = StyleSheet.create({
  backButton: {
    position: 'absolute',
    top: 20,
    right: 20,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.bg,
    height: 40,
    width: 40,
    borderRadius: 20,
    shadowColor: 'rgba(0,0,0,0.4)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 20,
  },
});
