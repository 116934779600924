import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import {
  View,
  Image,
  ScrollView,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
} from 'react-native';
// import { Button } from 'react-native-elements';
import ButtonX from '../../components/buttons/ButtonX';
import { Feather } from '@expo/vector-icons';
import BackArrow from '../../components/common/BackArrow';
import { dimensions, colors, typography } from '../../utils/theme';
import { Card } from '../../components/common';
import TextBold from '../../components/Text/TextBold';
import TextBS from '../../components/Text/TextBoldSmall';
import Text from '../../components/Text/TextLight';
import TextSemi from '../../components/Text/TextS';
import TextInput from '../../components/TextInput';
import generateOTP from '../../utils/generateOTP';
import { showInProgress, setAuthError, paymentFail } from '../../actions';
import ticketStack from '../../assets/svg/ticketStack.svg';

const formatter = (number) =>
  number.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

//const ticketStack = require("../../assets/images/ticketStack.png");

function ProgressView(props) {
  const { user, subCart, onCancel, onPressPay } = props;

  const amount = subCart.map((prod) => prod.qty * prod.price).reduce((acc, i) => acc + i, 0);
  //
  // const [name, setName] = useState(user?.displayName || "");
  // const [tableNo, setTableNo] = useState(user?.tableNo || "");
  //

  return (
    <View style={{ flex: 1 }}>
      <TextBS style={{ color: colors.balance }}>Order in progress</TextBS>
      <TextBold>Payment Pending</TextBold>

      <ActivityIndicator size="large" color={colors.balance} style={{ margin: 20 }} />

      <ScrollView style={{ width: '100%', marginTop: 10, paddingBottom: 10 }}>
        <React.Fragment>
          {subCart.map((prod, index) => (
            <View
              key={index}
              style={{
                paddingVertical: 20,
                width: '100%',
                alignItems: 'center',
              }}
            >
              <View
                style={{
                  width: '100%',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <View
                    style={{
                      paddingVertical: 5,
                      paddingHorizontal: 10,
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: 5,
                      backgroundColor: colors.shade1,
                      marginRight: 10,
                    }}
                  >
                    <TextSemi>{prod.qty}</TextSemi>
                  </View>

                  <Text style={{ width: '70%' }}>{prod.prod_title}</Text>
                </View>
                <Text style={{ width: '30%', textAlign: 'right' }}>
                  R {formatter(prod.qty * prod.price)}
                </Text>
              </View>
            </View>
          ))}
        </React.Fragment>
      </ScrollView>

      <View
        style={{
          width: '100%',
          paddingTop: 10,
          borderTopWidth: 1,
          borderTopColor: colors.shade1,
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <TextBS style={{ fontSize: 18 }}>Total</TextBS>
        <TextBS style={{ fontSize: 18 }}>R {formatter(amount)}</TextBS>
      </View>

      <ButtonX
        title="CLEAR"
        type="outline"
        addStyle={{
          width: dimensions.width - 80, //200,
          marginVertical: 40,
          borderColor: colors.shade2,
        }}
        titleStyle={{ color: colors.shade3 }}
        onPress={() => props.showInProgress(false)}
      />
      <TextBS
        style={{
          fontSize: 12,
          margin: 10,
          color: colors.alert,
          textAlign: 'center',
        }}
      >
        Placing order will charge your bank account <br /> with R {amount}
      </TextBS>
    </View>
  );
}

function SuccessView(props) {
  const { user, subCart, onCancel, onPressPay } = props;

  const amount = subCart.map((prod) => prod.qty * prod.price).reduce((acc, i) => acc + i, 0);
  //
  // const [name, setName] = useState(user?.displayName || "");
  // const [tableNo, setTableNo] = useState(user?.tableNo || "");
  //

  return (
    <View style={{ flex: 1, alignItems: 'center', backgroundColor: colors.bg1 }}>
      <View style={{ width: '100%' }}>
        <TextBS style={{ color: colors.balance }}>Success</TextBS>
        <TextBold>Payment Received</TextBold>
      </View>

      <View
        style={{
          width: dimensions.width - 80,
          //height: dimensions.height / 2,
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: 20,
          marginVertical: 20,
          borderRadius: 20,
          backgroundColor: colors.notice,
        }}
      >
        <View style={{ width: '100%' }}>
          <TextBold style={{ color: 'white' }}>Congrats!</TextBold>
          <TextBS style={{ color: 'white', fontSize: 14 }}>
            Give us a minute to deliver your purchased items to your wallet.
          </TextBS>
        </View>
        <View>
          <Image
            source={{ uri: ticketStack }}
            resizeMode="contain"
            style={{
              height: 150,
              width: 120,
              // shadowColor: 'rgba(0,0,0,0.6)',
              // shadowOffset: { width: 0, height: 0 },
              // shadowOpacity: 0.5,
              // shadowRadius: 5,
            }}
          />
        </View>
        <ButtonX
          title="View Tickets"
          type="outline"
          addStyle={{
            width: dimensions.width - 120,
            borderColor: 'white',
            backgroundColor: 'transparent',
            marginTop: 10,
          }}
          titleStyle={{
            fontWeight: 'bold',
            color: 'white',
          }}
          onPress={() => props.showInProgress(false)}
        />
      </View>
    </View>
  );
}

function FailedView(props) {
  const { authError } = props;
  return (
    <View style={{ backgroundColor: colors.bg1 }}>
      <TextBS style={{ color: colors.balance }}>Unsuccessful</TextBS>
      <TextBold>Payment Failed</TextBold>

      <Text style={styles.alertText}>{authError}</Text>

      <ButtonX
        title="Start Again"
        type="outline"
        addStyle={{ width: dimensions.width - 80 }}
        onPress={() => {
          props.showInProgress(false);
          props.paymentFail(false);
          props.setAuthError(null);
        }}
      />
    </View>
  );
}

function OrderInProgressModal(props) {
  const { paymentReceived, paymentFailed } = props;
  return (
    <View
      style={{
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 20,
        borderRadius: 20,
        backgroundColor: 'white',
      }}
    >
      {paymentReceived ? (
        <SuccessView {...props} />
      ) : paymentFailed ? (
        <FailedView {...props} />
      ) : (
        <ProgressView {...props} />
      )}
    </View>
  );
}

const mapStateToProps = (state) => {
  const { subCart, paymentReceived, paymentFailed } = state.store;
  const { authError } = state.auth;
  return { subCart, paymentReceived, paymentFailed, authError };
};

export default connect(mapStateToProps, {
  showInProgress,
  setAuthError,
  paymentFail,
})(OrderInProgressModal);

const styles = StyleSheet.create({
  alertText: {
    fontSize: 14,
    color: colors.alert,
    marginVertical: 20,
  },
});
