import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
//import { StatusBar } from "expo-status-bar";
import {
  SafeAreaView,
  ScrollView,
  View,
  Text,
  TextInput,
  FlatList,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
  Linking,
} from 'react-native';
import { Fontisto, AntDesign } from '@expo/vector-icons';
import * as RootNavigation from '../../RootNavigation.js';
import { BlurView } from 'expo-blur';
import { dimensions, colors, typography } from '../../utils/theme';
import TextBold from '../../components/Text/TextBold';
import BackHeader from '../../components/common/NavButtonBack';
import ProductCard from './ProductCard';
import EventCard1 from './EventCard1';
import MerchCard from './MerchCard';
import SplashLoading from '../../components/SplashLoading';
import {
  showCart,
  setGlobalLoading,
  fetchAllEvents,
  fetchSingleEvent,
  setEventFocus,
} from '../../actions';
//
import '../../components/common/sticky.css';

function Marketplace(props) {
  const { navigation } = props;
  const { products, subCart } = props;
  const header = useRef();
  const statBarBG = useRef();

  useEffect(() => {
    // !products.length && props.fetchAllEvents();
    props.fetchAllEvents();
  }, []);

  const renderItemType = (props) => {
    const { type } = props.item;
    return type === 'event2' ? (
      <ProductCard {...props} />
    ) : type === 'event1' ? (
      <EventCard1 {...props} />
    ) : type === 'merchandise' ? (
      <MerchCard {...props} />
    ) : null;
  };

  const spinnerRef = useRef();

  if (!products.length)
    return (
      <View ref={spinnerRef} style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <SplashLoading />
      </View>
    );

  return (
    <div style={{ backgroundColor: 'rgba(0,0,0,0.005)' }}>
      <BackHeader
        {...props}
        headerRef={header}
        subCart={subCart}
        title="Marketplace"
        onShowCartPress={() => props.showCart(true)}
        onBackPress={() => navigation.navigate('Wallet')}
      />
      <View style={{ height: 60 }} />

      <FlatList
        contentContainerStyle={{ alignItems: 'center' }}
        data={products}
        renderItem={({ item, i }) =>
          renderItemType({
            item,
            onPress: () => {
              props.setGlobalLoading(true);
              const { orgId, event_id } = item;
              navigation.navigate('Event Page', { orgId, event_id });
            },
          })
        }
        keyExtractor={(item) => item.id}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  const { products } = state.store;
  const { subCart } = state.store;
  const { user } = state.users;

  return {
    user,
    products: products
      .filter((prod) => prod.live_status)
      .filter((prod) => !prod.is_private)
      .sort(function (a, b) {
        return new Date(a?.date?.startTime?.seconds) - new Date(b?.date?.startTime?.seconds);
      }),
    subCart,
  };
};

export default connect(mapStateToProps, {
  showCart,
  setGlobalLoading,
  fetchAllEvents,
  fetchSingleEvent,
  setEventFocus,
})(Marketplace);

const styles = StyleSheet.create({
  backButton: {
    position: 'absolute',
    top: 20,
    right: 20,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.bg,
    height: 40,
    width: 40,
    borderRadius: 20,
    shadowColor: 'rgba(0,0,0,0.4)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 20,
  },
});
