import {
  LOGGING,
  TAG_LINK_SUCCESS_MODAL,
  TAG_LINK_FAIL_MODAL,
  SET_SHOW_TERMS_MODAL,
} from "../actions/types";

const INITIAL_STATE = {
  loading: false,
  tagLinkSuccessModal: false,
  tagLinkFailModal: false,
  showTermsModal: false,
};

const modalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGGING:
      return { ...state, loading: action.bool };
    case TAG_LINK_SUCCESS_MODAL:
      return { ...state, tagLinkSuccessModal: action.bool };
    case TAG_LINK_FAIL_MODAL:
      return { ...state, tagLinkFailModal: action.bool };
    case SET_SHOW_TERMS_MODAL:
      return { ...state, showTermsModal: action.bool };
    default:
      return state;
  }
};

export default modalReducer;
