import { firebase, firestore, auth } from '../../../../firebase/config';
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { View, TouchableOpacity, StyleSheet, ActivityIndicator, SafeAreaView } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { Button } from 'react-native-elements';
import { Card } from '../../../components/common';
import Text from '../../../components/Text/TextLight';
import TextBold from '../../../components/Text/TextBold';
import { dimensions, colors, typography } from '../../../utils/theme';
import { setAuthError, showAuthLoadingModal } from '../../../actions';

const SuccessMessage = (props) => {
  setTimeout(() => {
    props.onModalCancel(false);
  }, 2000);
  return (
    <View style={{ alignItems: 'center', marginTop: 30 }}>
      <Feather name="check-circle" size={50} color={colors.highlight} />
      <TextBold style={{ fontSize: 24, margin: 20, color: colors.textSolid }}>
        Sign In Success!
      </TextBold>
    </View>
  );
};

function AuthLoading(props) {
  const { user, authError } = props;
  const [somethingWrong, setSomethingWrong] = useState(false);

  useEffect(() => {
    user && setSomethingWrong(false);
    var timeout = setTimeout(() => {
      !user && setSomethingWrong(true) && props.setAuthError('Sign in timed out ;(');
    }, 10000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <Card>
      {user ? (
        <SuccessMessage {...props} />
      ) : (
        <View style={{ alignItems: 'center', marginTop: 30 }}>
          <ActivityIndicator size="large" color={colors.pay} />
          <TextBold
            style={{
              fontSize: 24,
              textAlign: 'center',
              margin: 20,
              color: colors.textSolid,
            }}
          >
            Opening your wallet...
          </TextBold>
        </View>
      )}
    </Card>
  );
}

const mapStateToProps = (state) => {
  const { user } = state.users;
  const { authError } = state.auth;
  return {
    user,
    authError,
  };
};

export default connect(mapStateToProps, { setAuthError, showAuthLoadingModal })(AuthLoading);
