import React, { useEffect, useState } from "react";
import {
  Linking,
  Image,
  TouchableOpacity,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { withTheme } from "react-native-elements";
// import {  } from "@expo/vector-icons";
// import HoverView from "../../components/common/HoverView"; // just pass hoverStyle={}
// import { H1 } from "../../components/text";
// import ButtonX from "../../components/buttons/ButtonX";

import instagram from "../../assets/svg/instagram.svg";
import facebook from "../../assets/svg/facebook.svg";
import whatsapp from "../../assets/svg/whatsapp.svg";
import email from "../../assets/svg/email.svg";
import web from "../../assets/svg/web.svg";

const CompanyLogo = require("../../assets/images/ADappicon.png");
// Image.prefetch(instagram);
// Image.prefetch(facebook);

// const socialIconArray = [
//   {
//     uri: facebook,
//     link: () => Linking.openURL("https://www.facebook.com/TheEventorsSA/")
//   },
//   {
//     uri: instagram,
//     link: () =>
//       Linking.openURL("https://instagram.com/itisartime?igshid=i6oq0csbndll")
//   },
//   {
//     uri: whatsapp,
//     link: () => Linking.openURL("whatsapp://send?text=hello")
//   },
//   {
//     uri: email,
//     link: () =>
//       Linking.openURL(
//         "mailto:support@example.com?subject=SendMail&body=Description"
//       )
//   },
//   {
//     uri: web,
//     link: () => Linking.openURL("http://www.eventors.co.za/")
//   }
// ];

const SocialIcon = (props) => {
  const { uri, link } = props;
  return (
    <TouchableOpacity
      style={{
        margin: 12,
        marginLeft: 0, // overides margin
      }}
      onPress={() => Linking.openURL(link)}
    >
      <Image source={{ uri: uri }} style={styles.icon} />
    </TouchableOpacity>
  );
};

function SocialIcons(props) {
  const { style, theme } = props;
  const { organiser } = props; /// from {...props}

  const { primary, secondary, secondary2, white, black } = theme.colors;
  return (
    <View style={[styles.container, style]}>
      {!!organiser.org_urls?.facebook && (
        <SocialIcon uri={facebook} link={organiser.org_urls.facebook} />
      )}
      {!!organiser.org_urls?.instagram && (
        <SocialIcon uri={instagram} link={organiser.org_urls.instagram} />
      )}
      {!!organiser.org_urls?.web && (
        <SocialIcon uri={web} link={organiser.org_urls.web} />
      )}
      {!!organiser.org_contact?.email && (
        <SocialIcon
          uri={email}
          link={`mailto:${organiser.org_contact.email}?subject=I need help with my tickets!&body=Here is my problem...`}
        />
      )}
      {!!organiser.org_contact?.whatsapp && (
        <SocialIcon
          uri={whatsapp}
          link={`whatsapp://send?phone=${organiser.org_contact.whatsapp}text=Hi there!`}
        />
      )}
    </View>
  );
}

// organiser.org_urls?.facebook? &&{" "}
// <SocialIcon uri={facebook} link={organiser.org_urls.facebook} />

// <Image source={{ uri: facebook }} style={styles.icon} />
// <Image source={{ uri: instagram }} style={styles.icon} />
// <Image source={{ uri: whatsapp }} style={styles.icon} />
// <Image source={{ uri: email }} style={styles.icon} />
// <Image source={{ uri: web }} style={styles.icon} />

// { width: 45, height: 45, marginHorizontal: 10 }

export default withTheme(SocialIcons);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    //justifyContent: "space-around"
  },
  icon: {
    width: 45,
    height: 45,
  },
});
