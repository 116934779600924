import React, { useState } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { dimensions, colors } from '../../utils/theme';
import { Card, Typo2Way } from '../../components/common';
import TextBoldSmall from '../../components/Text/TextBoldSmall';
import SocialIcons from '../../components/social/SocialIcons';
import ButtonX from '../../components/buttons/ButtonX';
import { setShowTermsModal } from '../../actions';

export default function (props) {
  const { org_about, org_name } = props.organiser;
  const [more, setMore] = useState(false);

  return (
    <Card
      style={{
        marginTop: 0,
        // marginBottom: -15,
      }}
    >
      <Typo2Way style={{ marginBottom: 10 }} subTitle="About" title="The Organiser" />
      <TouchableOpacity onPress={() => setMore(!more)}>
        {more ? (
          <TextBoldSmall style={{ color: colors.shade4, fontSize: 14, marginBottom: 10 }}>
            {org_name} - {org_about}
          </TextBoldSmall>
        ) : (
          <TextBoldSmall
            numberOfLines={3}
            ellipsizeMode="tail"
            style={{ color: colors.shade4, fontSize: 14, marginBottom: 10 }}
          >
            {org_name} - {org_about}
          </TextBoldSmall>
        )}
      </TouchableOpacity>
      <SocialIcons {...props} />
      <ButtonX
        type="outline"
        title="Refund Policy"
        addStyle={{
          borderRadius: 10,
          borderColor: 'rgba(160, 164, 168, 0.8)',
          marginVertical: 10,
          marginTop: 10,
          width: '100%',
        }}
        titleStyle={{
          color: 'grey',
        }}
        onPress={() => props.setShowTermsModal(true)}
      />
    </Card>
  );
}

const imageHieght = dimensions.width - 200;

const styles = StyleSheet.create({
  container: {
    width: dimensions.width - 30,
    borderRadius: 15,
    padding: 15,
    margin: 20,
    marginVertical: 10,
    backgroundColor: 'white',
    //
    shadowColor: 'rgba(0,0,0,0.4)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 20,
  },
  image: {
    width: '100%',
    height: imageHieght,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    //flex: 1,
    //backgroundColor: "yellow",
    //height: dimensions.width - 40,
    //justifyContent: "flex-end",
    // borderRadius: 15
  },
  gradient: {
    flex: 1,
    position: 'absolute',
    top: 0,
    width: dimensions.width - 30,
    height: imageHieght,
    justifyContent: 'flex-end',
    padding: 20,
    // borderBottomLeftRadius: 9,
    // borderBottomRightRadius: 9
  },
});
