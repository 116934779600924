import { firestore, auth } from '../../../firebase/config';
import React, { useState, useRef, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { StyleSheet, View, Image, ActivityIndicator, Pressable, Linking } from 'react-native';
import TextBold from '../../components/Text/TextBold';
import TextBoldSmall from '../../components/Text/TextBoldSmall';
import Text from '../../components/Text/TextLight';
import ButtonX from '../../components/buttons/ButtonX';
import { dimensions, colors, typography } from '../../utils/theme';

const happy = require('../../assets/images/happyPay.png');
const imageDim = dimensions.width / 7;

const hotline = '17242133657';
const helpLink = `https://wa.me/${hotline}/?text=${encodeURI(
  'I need assistance with a Happy Pay purchase..',
)}`;
const happySupportLink = 'https://happypay.co.za/contact';

export default function (props) {
  const { navigation } = props;
  const orderId = props.route?.params?.order_id || null;
  const [isSuccess, setSuccess] = useState(false);
  const [isUnsure, setUnsure] = useState(false);
  const [isReady, setReady] = useState(false);
  const [navParams, setNavParams] = useState(null);

  useEffect(() => {
    if (!!orderId && orderId === 'failed') {
      setSuccess(false);
      setReady(true);
      setUnsure(false);
    } else if (!!orderId && orderId === 'success') {
      setSuccess(true);
      setReady(true);
      setUnsure(false);
    } else {
      setUnsure(true);
      setReady(true);
    }
  }, [orderId]);

  if (!isReady)
    return (
      <View style={[styles.container, { gap: 40 }]}>
        <Image
          source={{ uri: happy }}
          style={{
            width: imageDim,
            height: imageDim,
            opacity: 0.8,
            borderRadius: 10,
          }}
        />
        <ActivityIndicator size="large" color={colors.black} />
      </View>
    );

  return (
    <View style={styles.container}>
      <Image
        source={{ uri: happy }}
        style={{
          width: imageDim,
          height: imageDim,
          opacity: 0.8,
          borderRadius: 10,
        }}
      />

      <View style={{ alignItems: 'center', gap: 10, marginVertical: 50, textAlign: 'center' }}>
        <TextBoldSmall
          style={[{ fontSize: 14, color: isSuccess ? colors.balance : colors.negative }]}
        >
          Happy Pay
        </TextBoldSmall>
        <TextBold style={{ fontSize: 26, color: colors.textSolid }}>
          {!isUnsure ? (isSuccess ? 'Payment Success' : 'Payment Failed') : null}
          {isUnsure && 'Payment Notification'}
        </TextBold>
        <br />
        <br />

        {!isUnsure && isSuccess && (
          <TextBoldSmall style={{ fontSize: 14, color: colors.shade4, opacity: 0.7 }}>
            You have made a successful payment on <br />
            Happy Pay.
            <br />
            <br />
            Your AirDosh passes will be delivered to your wallet, as well as to your registered
            email or phone number.
          </TextBoldSmall>
        )}

        {!isUnsure && !isSuccess && (
          <TextBoldSmall style={{ fontSize: 14, color: colors.shade4, opacity: 0.7 }}>
            Your recent payment on Happy Pay failed.
            <br />
            <br />
            You may return to AirDosh and retry your purchase using another payment method. Or you
            can get in contact with the Happy Pay support team{' '}
            <Pressable onPress={() => Linking.openURL(happySupportLink)}>
              <Text style={{ color: colors.black, fontWeight: 'bold' }}> here.</Text>
            </Pressable>
          </TextBoldSmall>
        )}
        {isUnsure && (
          <TextBoldSmall style={{ fontSize: 14, color: colors.shade4, opacity: 0.7 }}>
            You have attempted to make payment on Happy Pay.
            <br />
            <br />
            If successful, your AirDosh passes will be delivered to your wallet, as well as to your
            registered email or phone number.
            <br />
            <br />
            If you are unsure, you may reach out to the support team{' '}
            <Pressable onPress={() => Linking.openURL(helpLink)}>
              <Text style={{ color: colors.black, fontWeight: 'bold' }}> here.</Text>
            </Pressable>
          </TextBoldSmall>
        )}
      </View>

      {auth.currentUser && isSuccess ? (
        <ButtonX
          type="solid"
          title="View my passes"
          addStyle={{
            borderRadius: 10,
            marginVertical: 10,
            marginTop: 10,
            width: '100%',
          }}
          onPress={() => navigation.navigate('Passes')}
        />
      ) : (
        <ButtonX
          type="solid"
          title="Back to AirDosh"
          addStyle={{
            borderRadius: 10,
            marginVertical: 10,
            marginTop: 10,
            width: '100%',
          }}
          onPress={() => navigation.navigate('Wallet')}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
  },
});
