import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  View,
  Text,
  Image,
  ImageBackground,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
} from "react-native";
import * as RootNavigation from "../../RootNavigation.js";
import { Button, Avatar } from "react-native-elements";
import {
  Fontisto,
  Feather,
  SimpleLineIcons,
  MaterialCommunityIcons,
  Ionicons,
} from "@expo/vector-icons";
import { LinearGradient } from "expo-linear-gradient";
import { dimensions, colors, typography } from "../../utils/theme";
import TextBold from "../../components/Text/TextBold";
import BackArrow from "../../components/common/BackArrow";

export default function (props) {
  const { eventFocus } = useSelector((state) => state.config);
  const { type, title, description, titleImage, price, options } = eventFocus;
  return (
    <View style={styles.container}>
      <Image
        source={{ uri: titleImage }}
        resizeMode="cover"
        style={styles.image}
      />
      <BackArrow addCol={"white"} onPress={() => RootNavigation.goBack()} />
      <View style={{ backgroundColor: "transparent", padding: 15 }}>
        <TextBold style={{ color: colors.primaryText }}>{title}</TextBold>
        <Text style={{ color: colors.primaryText }}>{description}</Text>
      </View>
      <View
        style={{ backgroundColor: "transparent", height: 155, padding: 15 }}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 10,
            // marginHorizontal: 15
          }}
        >
          <TextBold>R {price}</TextBold>
          <Button
            title="Buy now"
            buttonStyle={{
              height: 50,
              width: 150,
              // margin: 20,
              borderRadius: 30,
              backgroundColor: colors.pay,
              alignSelf: "flex-start",
            }}
            titleStyle={{ fontWeight: "bold", fontSize: 16 }}
            onPress={() => console.log("Book now!")}
          />
        </View>
      </View>

      <View
        style={{
          backgroundColor: "transparent",
          padding: 15,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Text>LOCATION</Text>
        <View style={{ flexDirection: "row" }}>
          <TouchableOpacity>
            <Text style={{ color: colors.highlight, marginRight: 10 }}>
              How do i get there?
            </Text>
          </TouchableOpacity>
          <Ionicons
            name="ios-arrow-forward"
            size={16}
            color={colors.highlight}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // width: dimensions.width - 30,
    // height: dimensions.width - 80,
    //alignItems: "center",
    //borderRadius: 15
  },
  image: {
    width: "100%",
    height: dimensions.width - 140,
    //height: dimensions.width - 80,
    // borderTopLeftRadius: 15,
    // borderTopRightRadius: 15
    //flex: 1,
    //backgroundColor: "yellow",
    //height: dimensions.width - 40,
    //justifyContent: "flex-end",
    // borderRadius: 15
  },
  gradient: {
    flex: 1,
    position: "absolute",
    top: 0,
    width: "100%", //dimensions.width - 40,
    height: dimensions.width - 140,
    justifyContent: "flex-end",
    padding: 20,
    // borderBottomLeftRadius: 9,
    // borderBottomRightRadius: 9
  },
});
