import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { firebase, functions } from '../../../firebase/config';
import { StyleSheet, View, TouchableHighlight } from 'react-native';
import * as RootNavigation from '../../RootNavigation.js';
import { Button } from 'react-native-elements';
import generateID from '../../utils/firebaseDocIDutil';
import { Entypo, MaterialIcons, Feather } from '@expo/vector-icons';
import { dimensions, colors, typography } from '../../utils/theme';
import { formatDate, timeAgo } from '../../utils/timeAgo';
import ButtonSignIn from '../../components/buttons/ButtonSignIn';
import TextBS from '../../components/Text/TextBoldSmall';
import Text from '../../components/Text/TextLight';
import TextBold from '../../components/Text/TextBold';
import TextInputRounded from '../../components/TextInputRounded';
import { Card, Typo2Way } from '../../components/common';
import PayOption from '../../components/PaymentOptions';
import {
  showAuthModal,
  onYocoPay,
  onZapperPay,
  onSnapScanPay,
  // spinnerBalance,
} from '../../actions';
import './apple.css';

const imageDim = dimensions.width / 5;

var AppleSession = firebase.functions().httpsCallable('appleSession');

class Top_up extends Component {
  state = {
    paymentMethod: 'snapscan',
    topUpAmount: 0,
    minAmountNotice: false,
    signInNotice: false,
    //cellphoneNumber: ""
  };

  componentDidMount() {
    // this.loadJS("https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js", 1);
    // this.loadJS('https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js', 0);
    this.loadJS('https://js.yoco.com/sdk/v1/yoco-sdk-web.js', 1);
  }

  componentDidUpdate(prevProps, prevState) {
    // const { orgId, event_id } = this.props.eventFocus;
    const { user } = this.props;
    const { signInNotice } = this.state;
    const { topUpAmount, minAmountNotice } = this.state;
    if (topUpAmount > 2 && prevState.minAmountNotice === true) {
      this.setState({ minAmountNotice: false });
    }

    // if there was previously no user and the signInNotice was shown, when a user signs in and the userObj updates in this component, then take the notice away
    user &&
      signInNotice &&
      !prevProps.user &&
      !prevState.signInNotice &&
      this.setState({ signInNotice: false });
  }

  loadJS(src, i) {
    //  console.log("Loading JS - ", src);
    const ref = window.document.getElementsByTagName('script')[i];
    const script = window.document.createElement('script');
    script.src = src;
    script.async = true;
    ref.parentNode.insertBefore(script, ref);
  }
  onApplePay = () => {
    var request = {
      countryCode: 'ZA',
      currencyCode: 'ZAR',
      supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
      merchantCapabilities: ['supports3DS'],
      total: { label: 'AirDosh top up', amount: '10.00' },
    };
    var session = new ApplePaySession(3, request);
    session.begin(); // this invokes the below "onvalidatemerchant" function

    session.onvalidatemerchant = async function (event) {
      //  console.log("Event passed in <onvalidatemerchant> ", event);
      const { validationURL } = event;
      // Call your own server to request a new merchant session.
      await AppleSession({ validationURL })
        .then((res) => res.json()) // Parse response as JSON.
        .then((merchantSession) => {
          //  console.log("merchantSession ", merchantSession);
          session.completeMerchantValidation(merchantSession);
        })
        .catch((err) => {
          console.error('Error fetching merchant session', err);
        });
    };
  };

  render() {
    const { paymentMethod, topUpAmount, minAmountNotice, signInNotice } = this.state;
    const { user } = this.props;
    const { orgId, event_id, isCashless, hasCashlessEvents, cashlessEventsTitlesString } =
      this.props;

    return (
      <View
        style={{
          flex: 1,
          backgroundColor: colors.bg,
          alignItems: 'center',
        }}
      >
        <View style={{ height: 50 }} />
        <Card
          style={{
            alignSelf: 'center',
            marginTop: 20,
            // backgroundColor: colors.bg2,
          }}
        >
          <View
            style={{
              flex: 1,
              //padding: 20
            }}
          >
            <Typo2Way
              style={{ marginBottom: 10 }}
              subTitle="Top up wallet funds"
              title="Payment Options"
            />

            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                marginTop: 15,
                //backgroundColor: "yellow"
              }}
            >
              <PayOption
                name="snapscan"
                size={5}
                selected={paymentMethod === 'snapscan'}
                onPress={() => this.setState({ paymentMethod: 'snapscan' })}
              />
              {/* <PayOption
                name="zapper"
                size={5}
                selected={paymentMethod === "zapper"}
                onPress={() => this.setState({ paymentMethod: "zapper" })}
              /> */}
              <PayOption
                name="card"
                size={5}
                selected={paymentMethod === 'card'}
                onPress={() => this.setState({ paymentMethod: 'card' })}
              />
            </View>

            {/* {<apple-pay-button
              className="apple-pay-button"
              buttonstyle=colors.black
              type="plain"
              locale="en"
            >
              Apple
            </apple-pay-button>} */}

            {/* <TouchableHighlight
              style={{
                padding: 10,
                backgroundColor: colors.black,
                borderRadius: 5,
              }}
              onPress={() => this.onApplePay()}
            >
              <Text style={{ color: "white" }}>Apple Pay</Text>
            </TouchableHighlight> */}

            {signInNotice ? (
              <View>
                <Text style={styles.alertText}>Sign in to upload funds.</Text>
                <ButtonSignIn
                  title="SIGN IN"
                  addStyle={{ marginBottom: 0 }}
                  onPress={() =>
                    this.props.showAuthModal({
                      showModal: true,
                      isSignUp: false,
                    })
                  }
                />
              </View>
            ) : (
              <TextInputRounded
                addStyle={{ height: 60 }}
                value={topUpAmount === 0 ? '' : topUpAmount}
                onChangeText={(text) => this.setState({ topUpAmount: text })}
                style={{
                  fontSize: 16,
                  width: dimensions.width / 2,
                  height: 60,
                }}
                autoCapitalize="none"
                placeholder="Amount"
                autoCorrect={false}
                keyboardType="numeric"
              />
            )}

            {minAmountNotice && (
              <Text style={styles.alertText}>A minimum amount of R10 must be uploaded</Text>
            )}

            <Button
              title="Pay"
              disabled={!hasCashlessEvents}
              buttonStyle={{
                height: 60,
                width: '100%',
                marginVertical: 20,
                borderRadius: 10,
                backgroundColor: colors.highlight,
                alignSelf: 'flex-start',
              }}
              titleStyle={{ fontWeight: 'bold', fontSize: 18 }}
              onPress={() => {
                !user && this.setState({ signInNotice: true });
                user && topUpAmount < 10 && this.setState({ minAmountNotice: true });
                user &&
                  topUpAmount >= 10 &&
                  paymentMethod === 'card' &&
                  this.props.onYocoPay({
                    // ...props.event, // single event for chris
                    ...this.props.eventFocus,
                    user,
                    paymentType: 'topUp',
                    amount: topUpAmount,
                    //subcart
                  });
                user &&
                  paymentMethod === 'zapper' &&
                  this.props.onZapperPay({
                    user,
                    ...this.props.eventFocus,
                    paymentType: 'topUp',
                    amount: topUpAmount,
                    //subcart
                  });
                user &&
                  paymentMethod === 'snapscan' &&
                  this.props.onSnapScanPay({
                    user,
                    // orgId,
                    // event_id,
                    ...this.props.eventFocus,
                    paymentType: 'topUp',
                    amount: topUpAmount,
                    //subcart
                  });

                // if (user === "noUser") {
                //   this.setState({ signInNotice: true });
                // } else {
                //   topUpAmount < 2
                //     ? this.setState({ minAmountNotice: true })
                //     : this.onUploadPress();
                //navigation.goBack();
                //}
              }}
            />
            {!hasCashlessEvents && (
              <TextBS style={styles.alertText}>Cashless top up not available currently.</TextBS>
            )}
            {hasCashlessEvents ? (
              <View>
                <View style={{ justifyContent: 'center' }}>
                  <Text style={styles.noticeText}>Your cashless events: </Text>
                  <TextBS style={styles.noticeText}>{cashlessEventsTitlesString}</TextBS>
                </View>

                <TextBS
                  style={{
                    textAlign: 'center',
                    margin: 20,
                    color: colors.shade3,
                  }}
                >
                  The uploaded funds will reflect in your wallet, as well as your linked RFID card.
                </TextBS>
              </View>
            ) : (
              <View>
                <Text style={styles.alertText}>
                  Currently you do not own tickets to any cashless events. We suggest not topping up
                  until you do.
                </Text>
                <Button
                  title="Vist Marketplace"
                  onPress={() => RootNavigation.navigate('Marketplace')}
                  buttonStyle={{
                    height: 50,
                    width: '100%',
                    marginVertical: 20,
                    borderRadius: 10,
                    backgroundColor: 'rgba(83,155,248,0.1)', // colors.highlight,
                    alignSelf: 'flex-start',
                  }}
                  titleStyle={{
                    fontWeight: 'bold',
                    fontSize: 14,
                    color: colors.highlight,
                  }}
                />
              </View>
            )}
          </View>
        </Card>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  const { eventFocus } = state.config;
  const { isCashless } = state.config;
  const { products } = state.store;
  const { userPasses, user } = state.users;

  const nowSeconds = new Date().getTime() / 1000;
  const userFuturePassesIds = [
    // remove duplicates
    ...new Set(
      userPasses
        .filter(
          (pass) => pass.date?.startTime?.seconds > nowSeconds && pass.valid,
          //  && pass.price !== 0
        )
        .map((pass) => pass.event_id),
    ),
  ];
  const cashlessEvents = products.filter((event) => userFuturePassesIds.includes(event.id));

  return {
    user,
    eventFocus,
    hasCashlessEvents: false, //cashlessEvents.length !== 0, // TODO off until we decide to do cashless again
    cashlessEventsTitlesString: cashlessEvents.map((event) => event.event_title).toString(),
    isCashless,
  };
};

export default connect(mapStateToProps, {
  showAuthModal,
  onYocoPay,
  onZapperPay,
  onSnapScanPay,
  // spinnerBalance,
})(Top_up);

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  paymentOption: {
    height: imageDim + 40,
    width: imageDim + 10,
    alignItems: 'center',
    padding: 5,
    borderRadius: 20,
    justifyContent: 'space-between',
  },
  selectedPaymentOption: {
    shadowColor: 'rgba(0,0,0,0.2)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 20,
  },
  alertText: {
    textAlign: 'center',
    fontSize: 14,
    color: colors.alert,
    margin: 10,
    marginBottom: 0, // cancels out
  },
  noticeText: {
    textAlign: 'center',
    fontSize: 14,
    color: colors.balance,
    // margin: 10,
    marginBottom: 0, // cancels out
  },
  title: {
    fontWeight: 'bold',
    color: colors.primaryText,
    fontSize: 30,
  },
});
