import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
} from "react-native";
import { Button } from "react-native-elements";
import { Feather } from "@expo/vector-icons";
import BackArrow from "../../components/common/BackArrow";
import { Card } from "../../components/common";
import ModalHeader1 from "../../components/headers/Header1";
import { dimensions, colors, typography } from "../../utils/theme";
import TextBold from "../../components/Text/TextBold";
import TextBoldSmall from "../../components/Text/TextBoldSmall";
import generateOTP from "../../utils/generateOTP";

export default function ({ onUnlink, onClearModal, isAtagLinked }) {
  return (
    <Card style={styles.modalContainer}>
      <ModalHeader1
        heading="Unlink RFID Card"
        subHeading="Contactless card"
        onClose={onClearModal}
      />
      <View style={{ alignItems: "center" }}>
        {isAtagLinked ? (
          <TextBoldSmall>Are you sure you want to unlink?</TextBoldSmall>
        ) : (
          <TextBoldSmall>No tag currently linked</TextBoldSmall>
        )}

        <Button
          title="UNLINK TAG"
          type="solid"
          disabled={!isAtagLinked}
          buttonStyle={{
            height: 40,
            width: 150,
            marginVertical: 20,
            borderRadius: 20,
            //borderWidth: 2,
            //borderColor: colors.highlight
            backgroundColor: colors.highlight,
            //alignSelf: "flex-start"
          }}
          titleStyle={{
            fontWeight: "bold",
            fontSize: 14,
            color: "white", //colors.highlight
          }}
          onPress={onUnlink}
        />
        <TextBoldSmall
          style={{ textAlign: "center", width: "80%", color: "gray" }}
        >
          Note: When unlinking your contactless card, you will no longer be able
          to make payments with the card. If the card was faulty, replace and
          link a new card. If unlinking was in error, you may relink a card by
          rescanning the QR code.
        </TextBoldSmall>

        {
          //<BackArrow onPress={onClearModal} />
        }
      </View>
    </Card>
  );
}

const styles = StyleSheet.create({
  modalContainer: {
    //flex: 1,
    // height: "95%",
    // width: "100%",
    // backgroundColor: "white",
    // borderTopRightRadius: 15,
    // borderTopLeftRadius: 15,
    // margin: -20,
    // marginTop: 10,
    //marginHorizontal: -20,
    // paddingTop: 30,
    alignItems: "center",
    alignSelf: "center",
    // justifyContent: "space-between"
  },
});
