import { LOGGING, FETCH_BAR_ITEMS_SUCCESS } from "../actions/types";

const INITIAL_STATE = {
  loading: false,
  barProducts: [],
  showInProgresssQless: false,
};

const storeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGGING:
      return { ...state, loading: true };
    case FETCH_BAR_ITEMS_SUCCESS:
      return { ...state, barProducts: action.payload };

    default:
      return state;
  }
};

export default storeReducer;
