import React, { useRef } from 'react';
import {
  View,
  StyleSheet,
  ActivityIndicator,
  TouchableOpacity,
  TouchableHighlight,
} from 'react-native';
import TextS from '../../components/Text/TextS';
import { colors, dimensions } from '../../utils/theme';

export default function ButtonX({
  // elementType,
  type,
  style,
  addStyle,
  title,
  titleStyle,
  icon,
  iconName,
  iconSize,
  iconColor,
  iconStyle,
  onPress,
  disabled,
  loading,
}) {
  const isHovered = false; //useHover(ref);
  return (
    <TouchableHighlight
      activeOpacity={0.5}
      underlayColor="rgba(0,0,0,0.1)"
      onPress={onPress}
      disabled={disabled}
      style={[
        styles.buttonStyle,
        type === 'solid' ? styles.buttonSolidStyle : styles.buttonOutlineStyle,
        type === 'solid' && disabled && styles.buttonSolidStyleDisabled,
        type === 'outline' && disabled && styles.buttonOutlineStyleDisabled,
        isHovered && { opacity: 0.7 },
        addStyle,
      ]}
    >
      <View
        style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 10 }}
      >
        {!loading && icon}
        {!loading && (
          <TextS
            style={[
              type && type === 'solid' ? styles.titleSolidStyle : styles.titleOutlineStyle,
              titleStyle,
            ]}
          >
            {title}
          </TextS>
        )}
        {loading && (
          <ActivityIndicator
            size="small"
            color="white"
            style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
          />
        )}
      </View>
    </TouchableHighlight>
  );
}

// <Button
//   style={[styles.iconButton, style]}
//   type="clear"
//   icon={icon}
//   title={title}
//   titleStyle={titleStyle}
// />

const styles = StyleSheet.create({
  buttonStyle: {
    flexHorizontal: 1,
    height: 60,
    width: dimensions.width - 60,
    borderRadius: 10,
    // marginTop: 20,
    paddingHorizontal: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonOutlineStyle: {
    borderWidth: 2,
    borderColor: colors.balance,
    backgroundColor: 'white',
  },
  buttonOutlineStyleDisabled: {
    borderWidth: 2,
    borderColor: colors.primary,
    backgroundColor: 'white',
  },
  buttonSolidStyle: {
    backgroundColor: colors.black,
  },
  buttonSolidStyleDisabled: {
    backgroundColor: 'rgba(0,0,0,0.3)',
    // color: "gray",
  },
  titleOutlineStyle: {
    fontSize: 16,
    color: colors.balance,
  },
  titleSolidStyle: {
    fontSize: 16,
    color: 'white',
  },
  iconButton: {
    justifyContent: 'space-around',
    margin: 10,
  },
});
