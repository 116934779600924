import {
  LOGGING,
  PASS_LANDED,
  IMAGES_LOADED,
  ADD_TO_SUBCART_SUCCESS,
  UPDATE_SUBCART_SUCCESS,
  SHOW_CART,
  SET_SHOW_PRODUCT_VARIABLES_MODAL,
  SHOW_ALT_PAY_OPTIONS,
  //
  SHOW_IN_PROGRESS,
  PAYMENT_RECEIVED_TICKET,
  PAYMENT_RECEIVED_TOPUP,
  PAYMENT_FAILED,
  SHOW_IN_PROGRESS_MODAL,
  SHOW_PAYMENT_PENDING_WIDGET,
  SHOW_HAPPY_PAY_MODAL,
  //
  FETCH_EVENTS_SUCCESS,
  FETCH_SINGLE_EVENT_SUCCESS,
  FETCH_EVENT_ORGANISER_SUCCESS,
  FETCH_EVENTS_PRODUCTS_SUCCESS,
  FETCH_EVENTS_PRODUCTS_GLOBAL_SUCCESS,
  FETCH_EVENTS_ALL_PRODUCTS_SUCCESS,
  FETCH_EVENT_IMAGES_SUCCESS,
  INJECT_EVENT,
} from '../actions/types';

const INITIAL_STATE = {
  loading: false,
  imagesLoading: true,
  showCartModal: false,
  showProductVariableModal: false,
  subCartProdToEdit: null,
  productVariableModalEditMode: false,
  showPaymentProcessing: false,
  //
  showPurchaseInProgress: false, // false,
  showAltPayOptionsModal: false,
  paymentReceived: false,
  paymentFailed: false,
  // happy pay
  showHappyPayModal: false,
  happyRedirectUrl: '',
  //
  progressMode: '',
  showProgressModal: false,
  progressTotalAmount: 0,
  qlessOrderNumber: 0,
  topUpReceived: false,

  //
  products: [], // this is actually events on the marketplace
  product: {}, // single event for Chris
  subCart: [],
  productList: [],
  productListLocal: [],
  productListGlobal: [],
  imageCollection: [],
  organiser: {},
  showPendingPaymentMessage: false,
  pendingPaymentMessage:
    'Update message to show when a payment is pending, and purchased items are being processed',
  //
};

const storeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGGING:
      return { ...state, loading: true };
    case PASS_LANDED:
      return { ...state, showPaymentProcessing: action.bool };
    case IMAGES_LOADED:
      return { ...state, imagesLoading: action.payload };
    case ADD_TO_SUBCART_SUCCESS:
      state.subCart.push(action.payload);
      return { ...state, subCart: state.subCart.slice() };
    case UPDATE_SUBCART_SUCCESS:
      return { ...state, subCart: action.payload.slice() };
    case SHOW_IN_PROGRESS:
      //reset the payment received bool if modal is cleared
      return {
        ...state,
        showPurchaseInProgress: action.bool,
      };
    case SHOW_CART:
      return {
        ...state,
        showCartModal: action.bool,
      };
    case SET_SHOW_PRODUCT_VARIABLES_MODAL:
      const { bool, subCartProdToEdit } = action;
      return {
        ...state,
        showProductVariableModal: bool,
        subCartProdToEdit,
        productVariableModalEditMode:
          (!!action.productVariableModalEditMode && action.productVariableModalEditMode) || false,
      };
    case SHOW_ALT_PAY_OPTIONS:
      return { ...state, showAltPayOptionsModal: action.bool };
    case FETCH_EVENTS_SUCCESS:
      return { ...state, products: action.fetchedEvents };
    // case FETCH_SINGLE_EVENT_SUCCESS:
    //   return { ...state, product: action.fetchedEvent };
    case FETCH_EVENT_ORGANISER_SUCCESS:
      return { ...state, organiser: action.organiserObj };
    // the below calls listen to the products fetched locally and globally, the lst one combines them
    case FETCH_EVENTS_PRODUCTS_SUCCESS:
      // console.log('productListLocal ', action.eventProducts);
      return { ...state, productListLocal: action.eventProducts };
    case FETCH_EVENTS_PRODUCTS_GLOBAL_SUCCESS:
      // console.log('productListGlobal ', action.globalProducts);
      return { ...state, productListGlobal: action.globalProducts };
    case FETCH_EVENTS_ALL_PRODUCTS_SUCCESS:
      // console.log('ALL productList ', [...state.productListLocal, ...state.productListGlobal]);
      return { ...state, productList: [...state.productListLocal, ...state.productListGlobal] };
    //
    case FETCH_EVENT_IMAGES_SUCCESS:
      return { ...state, imageCollection: action.eventImages };
    case INJECT_EVENT:
      state.products.push(action.payload);
      return { ...state };
    case PAYMENT_RECEIVED_TICKET: // also triggers in USER reducer
      return {
        ...state,
        paymentReceived: true,
        subCart: [],
        showPaymentProcessing: true,
      }; // done, reset the cart items
    case SHOW_IN_PROGRESS_MODAL:
      return {
        ...state,
        showProgressModal: action.bool,
        progressMode: action.progressMode,
        progressTotalAmount: action.progressTotalAmount,
      };
    case PAYMENT_RECEIVED_TOPUP:
      return {
        ...state,
        topUpReceived: true,
        progressTotalAmount: 0,
        qlessOrderNumber: action.qlessOrderNumber,
      };
    case PAYMENT_FAILED:
      return { ...state, paymentFailed: action.bool };
    case SHOW_PAYMENT_PENDING_WIDGET:
      return {
        ...state,
        showPendingPaymentMessage: action.bool,
        pendingPaymentMessage: action.message,
        subCart: [],
      };
    case SHOW_HAPPY_PAY_MODAL:
      return {
        ...state,
        showHappyPayModal: action.bool,
        happyRedirectUrl: action.redirectUrl,
      };

    default:
      return state;
  }
};

export default storeReducer;
