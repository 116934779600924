import { firebase, firestore, auth } from '../../../../firebase/config';
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { View, TouchableOpacity, StyleSheet, ActivityIndicator } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { Button } from 'react-native-elements';
import ButtonX from '../../../components/buttons/ButtonX';
import { Card } from '../../../components/common';
import Text from '../../../components/Text/TextLight';
// import TextB from "../../../components/Text/TextBold";
import TextBS from '../../../components/Text/TextBoldSmall';
import InputMat from '../../../components/common/MaterialInput';
import ModalHeader1 from '../../../components/headers/Header1';
import { dimensions, colors, typography } from '../../../utils/theme';
import { setAuthError, showAuthModal, sendEmailLink } from '../../../actions';

const EmailForm = ({ loading, email, setEmail, sendMail, authError, setAuthError }) => {
  return (
    <View
      style={{
        flex: 1,
        backgroundColor: colors.bg1,
        // paddingHorizontal: 20,
        width: '100%',
      }}
    >
      <form
        onSubmit={(event) => {
          event.preventDefault();
          authError !== null && setAuthError(null);
          sendMail();
        }}
      >
        <InputMat
          required
          label="Email"
          placeholder="Enter email address"
          onChangeText={setEmail}
          value={email}
          autoComplete="email"
          autoCompleteType="email"
          addContainerStyle={styles.inputText}
          keyboardType="email-address"
          checkValidation={(val) =>
            (val.length < 3 || !val.includes('@') || !val.includes('.')) &&
            'Enter a valid email address'
          }
        />

        {authError && <Text style={styles.errorText}>{authError}</Text>}
        <button
          style={{
            border: 'none',
            background: 'none',
            width: '100%',
            padding: 0,
          }}
          type="submit"
          disabled={!email.length}
        >
          <ButtonX
            type="solid"
            title="Sign in"
            loading={loading}
            buttonStyle={{
              height: 60,
              borderRadius: 10,
              marginVertical: 10,
              backgroundColor:
                email.length < 3 || !email.includes('@') || !email.includes('.')
                  ? colors.shade2
                  : colors.balance,
            }}
            titleStyle={{ fontWeight: 'bold', fontSize: 14 }}
          />
        </button>
        <View>
          <Text
            style={{
              textAlign: 'center',
              fontSize: 12,
              color: colors.textSolid,
              marginVertical: 20,
            }}
          >
            A verification email will be sent to your inbox. To complete sign in, click the
            verification link.
          </Text>
        </View>
      </form>
      {/* <ButtonX
        type="solid"
        title="Sign in with mobile number"
        onPress={() => {
          onModalCancel(false);
          authError !== null && setAuthError(null);
          props.showAuthModal({
            showModal: true,
            isSignUp: false,
          });
        }}
        addStyle={{
          height: 50,
          width: "100%",
          marginTop: 20,
          paddingHorizontal: 20,
          borderRadius: 10,
          backgroundColor: "rgba(83,155,248,0.1)", // colors.highlight,
          alignSelf: "flex-start",
        }}
        titleStyle={{
          fontWeight: "bold",
          fontSize: 14,
          color: colors.highlight,
        }}
      /> */}

      {/* <View style={styles.footer}>
        <Text style={[styles.message, { marginRight: 5 }]}>Sign up with</Text>
        <TouchableOpacity onPress={() => console.log("Sign up with Meta")}>
          <TextBS style={[styles.message, { color: colors.pay }]}>
            Meta
          </TextBS>
        </TouchableOpacity>
        <Text style={[styles.message, { marginHorizontal: 5 }]}>or</Text>
        <TouchableOpacity onPress={() => console.log("Sign up with Google")}>
          <TextBS style={[styles.message, { color: colors.pay }]}>
            Google
          </TextBS>
        </TouchableOpacity>
      </View> */}
    </View>
  );
};

function EmailAuthModal(props) {
  const { authError, parsedEmail } = props;
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [sendSuccess, setSendSucces] = useState(false);
  const isValid = true;
  var timeout;

  useEffect(() => {
    // this is to force update the parsed Email addy to the input as i couldn't get it to work normally, bad day.
    !!parsedEmail && setEmail(parsedEmail);
  }, [parsedEmail]);

  const sendMail = () => {
    const { orgId, event_id } = props.eventFocus;
    setLoading(true);
    return props
      .sendEmailLink({
        userObj: { email },
      })
      .then(() => {
        setSendSucces(true);
        setLoading(false);
        props.onModalCancel(false);
      })
      .catch(() => {
        setLoading(fasle);
        console.log('Could not send email');
      });
  };

  const SuccessMessage = () => {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: colors.bg1,
          // paddingHorizontal: 20,
          width: '100%',
        }}
      >
        <TextBS style={[styles.message, { color: colors.pay }]}>
          Verification email sent! Please check your email inbox to complete sign in.
        </TextBS>
      </View>
    );
  };

  return (
    <Card>
      <View
        style={{
          backgroundColor: colors.bg,
          borderRadius: 10,
        }}
      >
        <ModalHeader1
          heading="Email link"
          subHeading="Passwordless"
          onClose={() => {
            props.onModalCancel();
            authError !== null && setAuthError(null);
            setSendSucces(false);
            // clearTimeout(timeout);
          }}
        />
        {!!sendSuccess ? (
          <SuccessMessage />
        ) : (
          <EmailForm {...{ loading, email, setEmail, sendMail, authError, setAuthError }} />
        )}
      </View>
    </Card>
  );
}

const mapStateToProps = (state) => {
  // const { user } = state.users;
  const { authError } = state.auth;
  const { eventFocus } = state.config;

  return {
    authError,
    eventFocus,
  };
};

export default connect(mapStateToProps, {
  sendEmailLink,
  showAuthModal,
  setAuthError,
})(EmailAuthModal);

const styles = StyleSheet.create({
  backButton: {
    position: 'absolute',
    top: 20,
    right: 20,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    height: 40,
    width: 40,
    borderRadius: 20,
    shadowColor: 'rgba(0,0,0,0.4)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 20,
  },
  inputText: {
    // marginVertical: 10,
    width: '100%',
    textAlign: 'center',
    fontSize: 18,
    outlineStyle: 'none',
    outlineWidth: 0,
    outlineColor: 'transparent',
  },
  message: {
    fontSize: 14,
    width: '100%',
    color: colors.secondary,
    // textAlign: "center",
  },
  errorText: {
    marginTop: 15,
    textAlign: 'center',
    fontSize: 12,
    color: 'red',
  },
  footer: {
    paddingVertical: 15,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
