import { firestore, auth } from '../../../firebase/config';
import React, { useState, useRef, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { StyleSheet, View, Image, ActivityIndicator, Pressable, Linking } from 'react-native';
import TextBold from '../../components/Text/TextBold';
import TextBoldSmall from '../../components/Text/TextBoldSmall';
import Text from '../../components/Text/TextLight';
import ButtonX from '../../components/buttons/ButtonX';
import { setGlobalInitializing } from '../../actions/AuthActions';

import { dimensions, colors, typography } from '../../utils/theme';

const ad_logo = require('../../assets/images/splash.png');
const imageDim = dimensions.width / 7;

const hotline = '17242133657';
const helpLink = `https://wa.me/${hotline}/?text=${encodeURI(
  'I need assistance with an AirDosh purchase using Yoco checkout..',
)}`;
const happySupportLink = 'https://happypay.co.za/contact';

export default function (props) {
  const { navigation } = props;
  const dispatch = useDispatch();
  const orderId = props.route?.params?.order_id || null;
  const [isSuccess, setSuccess] = useState(false);
  const [isUnsure, setUnsure] = useState(false);
  const [isReady, setReady] = useState(false);
  const [navParams, setNavParams] = useState(null);

  useEffect(() => {
    if (!!orderId && orderId === 'failed') {
      setSuccess(false);
      setReady(true);
      setUnsure(false);
    } else if (!!orderId && orderId === 'success') {
      setSuccess(true);
      setReady(true);
      setUnsure(false);
    } else {
      setUnsure(true);
      setReady(true);
    }
  }, [orderId]);

  useEffect(() => {
    dispatch(setGlobalInitializing(false));
    // console.log('Disbale global initialliziation');
  }, []);

  // if (!isReady)
  //   return (
  //     <View style={[styles.container, { gap: 40 }]}>
  //       <Image
  //         style={{ width: dimensions.width / 3, height: 35, marginTop: 20 }}
  //         source={require('../../assets/images/splash.png')}
  //         resizeMode="contain"
  //       />
  //       <ActivityIndicator size="large" color={colors.black} />
  //     </View>
  //   );

  return (
    <View style={styles.container}>
      <Image
        style={{ width: dimensions.width / 3, height: 35, marginTop: 20 }}
        source={require('../../assets/images/splash.png')}
        resizeMode="contain"
      />

      <View style={{ alignItems: 'center', gap: 10, marginVertical: 50, textAlign: 'center' }}>
        {/* <TextBoldSmall
          style={[{ fontSize: 14, color: isSuccess ? colors.balance : colors.negative }]}
        >
          Done!
        </TextBoldSmall> */}
        <TextBold style={{ fontSize: 26, color: colors.textSolid }}>
          {!isUnsure ? (isSuccess ? 'Payment Success' : 'Payment Failed') : null}
          {isUnsure && 'Payment Notification'}
        </TextBold>
        <br />
        <br />

        {!isUnsure && isSuccess && (
          <TextBoldSmall style={{ fontSize: 14, color: colors.shade4, opacity: 0.7 }}>
            You have made a successful payment on <br />
            AirDosh
            <br />
            <br />
            Your passes will be delivered to your wallet, as well as to your registered email
            address or phone number.
          </TextBoldSmall>
        )}

        {!isUnsure && !isSuccess && (
          <TextBoldSmall style={{ fontSize: 14, color: colors.shade4, opacity: 0.7 }}>
            Your recent payment on AirDosh failed.
            <br />
            <br />
            You may return to AirDosh and retry your purchase using another payment method.
            Alternatively reach out to AirDosh support.
            <Pressable onPress={() => Linking.openURL(helpLink)}>
              <Text style={{ color: colors.black, fontWeight: 'bold' }}> here.</Text>
            </Pressable>
          </TextBoldSmall>
        )}
        {isUnsure && (
          <TextBoldSmall style={{ fontSize: 14, color: colors.shade4, opacity: 0.7 }}>
            You have attempted to make payment on AirDosh.
            <br />
            <br />
            If successful, your passes will be delivered to your wallet, as well as to your
            registered email address or phone number.
            <br />
            <br />
            If you are unsure, you may reach out to the support team{' '}
            <Pressable onPress={() => Linking.openURL(helpLink)}>
              <Text style={{ color: colors.black, fontWeight: 'bold' }}> here.</Text>
            </Pressable>
          </TextBoldSmall>
        )}
      </View>

      {auth.currentUser && isSuccess ? (
        <View>
          <ButtonX
            type="solid"
            title="View my passes"
            addStyle={{
              borderRadius: 10,
              marginVertical: 10,
              marginTop: 10,
              width: '100%',
            }}
            onPress={() => navigation.navigate('Passes')}
          />
          <ButtonX
            type="outline"
            title="Back to AirDosh"
            addStyle={{
              borderRadius: 10,
              marginVertical: 10,
              marginTop: 10,
              width: '100%',
            }}
            onPress={() => navigation.navigate('Wallet')}
          />
        </View>
      ) : (
        <ButtonX
          type="solid"
          title="Back to AirDosh"
          addStyle={{
            borderRadius: 10,
            marginVertical: 10,
            marginTop: 10,
            width: '100%',
          }}
          onPress={() => navigation.navigate('Wallet')}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
  },
});
