import React, { useRef } from 'react';
import {
  View,
  StyleSheet,
  ActivityIndicator,
  TouchableOpacity,
  TouchableHighlight,
} from 'react-native';
import TextS from '../../components/Text/TextS';
import { useHover } from 'react-native-web-hooks';
import { colors } from '../../utils/theme';

const ButtonSignIn = ({
  type,
  style,
  addStyle,
  title,
  titleStyle,
  icon,
  iconName,
  iconSize,
  iconColor,
  iconStyle,
  onPress,
  disabled,
  loading,
}) => {
  const ref = useRef(null);
  const isHovered = useHover(ref);
  return (
    <TouchableHighlight
      activeOpacity={0.8}
      underlayColor="transparent"
      ref={ref}
      onPress={onPress}
      disabled={disabled}
      style={[
        type === 'solid' ? styles.buttonSolidStyle : styles.buttonOutlineStyle,
        isHovered && { opacity: 0.7 },
        addStyle,
      ]}
    >
      <View>
        {!loading && icon}
        {!loading && (
          <TextS
            style={[
              {
                fontSize: 16,
                color: 'white',
              },
              titleStyle,
            ]}
          >
            {title}
          </TextS>
        )}
        {!!loading && (
          <ActivityIndicator
            size="small"
            color="white"
            style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
          />
        )}
      </View>
    </TouchableHighlight>
  );
};

export default ButtonSignIn;

const styles = StyleSheet.create({
  buttonOutlineStyle: {
    marginVertical: 20,
    height: 60,
    //width: 120,
    borderRadius: 10,
    borderWidth: 2,
    borderColor: colors.balance,
    backgroundColor: colors.bg1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonSolidStyle: {
    marginVertical: 20,
    height: 60,
    //width: 120,
    borderRadius: 10,
    backgroundColor: colors.balance,
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconButton: {
    justifyContent: 'space-around',
    margin: 10,
  },
});
