import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { View, Text, Image, TouchableOpacity, StyleSheet, ActivityIndicator } from 'react-native';
import { Button } from 'react-native-elements';
import * as RootNavigation from '../../RootNavigation.js';
import { LinearGradient } from 'expo-linear-gradient';
import { dimensions, colors } from '../../utils/theme';
import { Card, Typo2Way } from '../../components/common';
import TextBold from '../../components/Text/TextBold';
import TextBoldSmall from '../../components/Text/TextBoldSmall';
import { Ionicons, FontAwesome5, MaterialCommunityIcons } from '@expo/vector-icons';
import yoga_svg from '../../assets/svg/yoga.svg';
import graphic_eq from '../../assets/svg/graphic_eq.svg';

const Amenity = ({ title, children }) => {
  return (
    <View
      style={{
        width: '50%',
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: 5,
      }}
    >
      {children}
      <TextBoldSmall style={{ fontSize: 14, color: colors.shade4, marginLeft: 10 }}>
        {title}
      </TextBoldSmall>
    </View>
  );
};
const Amenities = (props) => {
  const { amenities } = useSelector((state) => state.config.eventFocus);

  return (
    <View
      style={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginRight: 10,
      }}
    >
      {amenities.includes('parking') && (
        <Amenity title="Parking">
          <FontAwesome5 name="car-alt" size={18} color={colors.shade4} />
        </Amenity>
      )}
      {amenities.includes('swim') && (
        <Amenity title="Swimming">
          <FontAwesome5 name="swimmer" size={18} color={colors.shade4} />
        </Amenity>
      )}

      {amenities.includes('food') && (
        <Amenity title="Food Market">
          <MaterialCommunityIcons name="food-fork-drink" size={20} color={colors.shade4} />
        </Amenity>
      )}
      {amenities.includes('lgbtq-safe') && (
        <Amenity title="LGBTQ Safe">
          <MaterialCommunityIcons name="cards-heart" size={20} color={colors.shade4} />
        </Amenity>
      )}
      {amenities.includes('taxi-only') && (
        <Amenity title="UBER only">
          <MaterialCommunityIcons name="taxi" size={20} color={colors.shade4} />
        </Amenity>
      )}
      {amenities.includes('yoga') && (
        <Amenity title="Yoga">
          <Image source={{ uri: yoga_svg }} resizeMode="contain" style={styles.iconStyle} />
        </Amenity>
      )}
      {amenities.includes('sound-journey') && (
        <Amenity title="Sound journey">
          <Image source={{ uri: graphic_eq }} resizeMode="contain" style={styles.iconStyle} />
        </Amenity>
      )}

      {amenities.includes('cashless') && (
        <Amenity title="Cashless">
          <MaterialCommunityIcons name="contactless-payment" size={18} color={colors.shade4} />
        </Amenity>
      )}
    </View>
  );
};

export default function (props) {
  return (
    <Card>
      <Typo2Way style={{ marginBottom: 10 }} subTitle="Included" title="Amenities" />
      <Amenities {...props} />
    </Card>
  );
}
{
  /* {     
      
      <Amenity title="Cashless Payments">
        <MaterialCommunityIcons
          name="contactless-payment"
          size={20}
          color={colors.shade4}
        />
      </Amenity>
      <Amenity title="Free Wifi">
        <MaterialCommunityIcons name="wifi" size={20} color={colors.shade4} />
      </Amenity>

      } */
}

const imageHieght = dimensions.width - 200;

const styles = StyleSheet.create({
  container: {
    width: dimensions.width - 30,
    borderRadius: 15,
    padding: 15,
    margin: 20,
    marginVertical: 10,
    backgroundColor: 'white',
    //
    shadowColor: 'rgba(0,0,0,0.4)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 20,
  },
  image: {
    width: '100%',
    height: imageHieght,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    //flex: 1,
    //backgroundColor: "yellow",
    //height: dimensions.width - 40,
    //justifyContent: "flex-end",
    // borderRadius: 15
  },
  gradient: {
    flex: 1,
    position: 'absolute',
    top: 0,
    width: dimensions.width - 30,
    height: imageHieght,
    justifyContent: 'flex-end',
    padding: 20,
    // borderBottomLeftRadius: 9,
    // borderBottomRightRadius: 9
  },
  iconStyle: {
    height: 22,
    width: 22,
    color: colors.shade4,
  },
});
