import React, { Component } from 'react';
import { TouchableOpacity, StyleSheet, View, Image } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { dimensions, colors, typography } from '../utils/theme';

const snapscan = require('../assets/images/snapscan.png');
const zapper = require('../assets/images/zapper.png');
const bankcard = require('../assets/images/bank-card.png');
const airdosh = require('../assets/images/airdoshpay.png');
const happy = require('../assets/images/happyPay.png');

export default function PayOption({ name, selected, onPress, size }) {
  const imageDim = dimensions.width / size;
  const uri =
    name === 'snapscan'
      ? snapscan
      : name === 'zapper'
      ? zapper
      : name === 'card'
      ? bankcard
      : name === 'airdosh'
      ? airdosh
      : name === 'happy'
      ? happy
      : null;

  return (
    <View style={[styles.paymentOption, selected && styles.selectedPaymentOption]}>
      <View style={{ flex: 1 }}>
        {selected && (
          <Feather name="check" size={24} color={colors.highlight} style={{ marginBottom: 5 }} />
        )}
      </View>
      <TouchableOpacity onPress={onPress}>
        <Image
          source={{ uri }}
          style={{
            width: imageDim,
            height: imageDim,
            opacity: 0.8,
            borderRadius: 10,
          }}
        />
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  paymentOption: {
    alignItems: 'center',
    padding: 10,
    borderRadius: 20,
    justifyContent: 'space-between',
  },
  selectedPaymentOption: {
    shadowColor: 'rgba(0,0,0,0.2)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 20,
    backgroundColor: colors.bg1,
  },
});
