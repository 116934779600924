import { firebase, firestore, auth, functions } from '../../firebase/config';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  GLOBAL_INITIIALISING,
  LOGGING,
  SHOW_AUTH_MODAL,
  UPDATE_AUTH_FORM,
  SHOW_ACCOUNT_FORM,
  SHOW_EMAIL_AUTH_MODAL,
  SHOW_AUTH_LOADING_MODAL,
  SET_AUTH_ERROR,
  NEW_USER_CREATED_SUCCESS,
  FOUND_USER,
  UPDATE_USER_OBJ,
  SET_REWARDS_AGENT,
} from './types';
import { userLogAction, createCustomer, fetchFullUser } from './UsersActions';
import generateID from '../utils/firebaseDocIDutil';

var CreateDynamicLink = functions.httpsCallable('createDynamicLink');
var GetFrontChatHash = functions.httpsCallable('getFrontChatHash');
var FetchAgent = functions.httpsCallable('fetchAgent');

const userRef = firestore.collection('customers');
const userRewardsref = (uid) => firestore.collection('customers').doc(uid).collection('rewards');
const userLogRef = (userId) => firestore.collection('customers').doc(userId).collection('log');

export const setGlobalInitializing = (bool) => ({
  type: GLOBAL_INITIIALISING,
  bool,
});

export const updateAuthForm = ({ key, value }) => ({
  type: UPDATE_AUTH_FORM,
  key,
  value,
});

export const showAuthModal =
  ({ showModal, isSignUp }) =>
  (dispatch) => {
    showModal === true && dispatch(setAuthError(null)); // reset any errors that previously existed
    dispatch({ type: SHOW_AUTH_MODAL, showModal, isSignUp });
  };
export const showEmailAuthModal = (bool) => (dispatch) => {
  bool === true && dispatch(setAuthError(null)); // reset any errors that previously existed
  dispatch({
    type: SHOW_EMAIL_AUTH_MODAL,
    bool,
  });
};
export const showAuthLoadingModal = (bool) => ({
  type: SHOW_AUTH_LOADING_MODAL,
  bool,
});
export const setAuthError = (err) => ({
  type: SET_AUTH_ERROR,
  err,
});

export const showAccountFormModal = (bool, accountFormFields) => ({
  type: SHOW_ACCOUNT_FORM,
  bool,
  accountFormFields,
});

export const signOut = (user) => async (dispatch) => {
  // user &&
  //   userLogRef(user._id).add({
  //     user,
  //     action: {
  //       label: 'User logged out of wallet app.',
  //       notes: ['no special notes'],
  //     },
  //   });

  try {
    auth.signOut();

    dispatch({ type: FOUND_USER, payload: null });
  } catch (error) {
    console.log('Signout ERROR', error);
  }
};

export function sendCustomerMessage(cellphoneNumber, message) {
  var data = {
    message: message,
    // Brinf this back when international phone recognition is implemented
    // cellphoneNumber: cellphoneNumber.replace("0", "+27"),
  };

  var sendSMS = functions.httpsCallable('sendSMS');
}

export const getCreateUserObj =
  ({ newUser, blockUserAutoCreate, name, surname, email, phoneNumber }) =>
  async (dispatch, getState) => {
    // const { auth } = getState();
    // const { email, phoneNumber, name, surname } = auth;
    // console.log('getCreateUserObj ', { email, phoneNumber, name, surname });

    // console.log('getCreateUserObj HIT', {
    //   newUser,
    //   blockUserAutoCreate,
    //   name,
    //   surname,
    //   email,
    //   phoneNumber,
    // });
    // console.log('getState', getState());

    // check for the user by 3 params - _id, email, cellphoneNumber

    let customer = null;

    const custDoc = await userRef.doc(newUser.uid).get();
    custDoc.exists && (customer = custDoc.data());

    if (customer) {
      if (customer.isRewards) {
        const rewardSnap = await userRewardsref(customer._id).get();
        let rewards = [];
        rewardSnap.forEach((doc) => {
          rewards.push(doc.data());
        });
        dispatch({
          type: FOUND_USER,
          payload: { ...customer, rewards },
        });
      } else {
        dispatch({
          type: FOUND_USER,
          payload: customer,
        });
      }
    } else {
      const { uid } = newUser;
      // console.log('getCreateUserObj CREATE customer', uid, blockUserAutoCreate);

      const isEmailSignUp = !!(
        newUser.providerData.length && newUser.providerData[0]?.providerId === 'password'
      );
      if (uid) {
        !blockUserAutoCreate &&
          dispatch(
            createCustomer({
              cellphoneNumber: phoneNumber, // !isEmailSignUp ? newUser.phoneNumber : phoneNumber,
              email: isEmailSignUp ? newUser.email : email,
              uid,
              name,
              surname,
            }),
          );
      }
    }
  };

export const updateUserObj =
  ({ uid, userObj }) =>
  (dispatch) => {
    userRef
      .doc(uid)
      .update(userObj)
      .then(() => {
        dispatch({
          type: UPDATE_USER_OBJ,
          payload: userObj,
        });
      });
  };

// export const signInOAuth = (provider) => (dispatch) => {
//   firebase.auth().useDeviceLanguage();

//   // console.log("signInOAuth HIT", provider);
//   // var google = new firebase.auth.GoogleAuthProvider();
//   // var facebook = new firebase.auth.FacebookAuthProvider();

//   // firebase.auth().signInWithRedirect(provider);
//   const getProvider = (p) => {
//     return p === 'facebook'
//       ? new firebase.auth.FacebookAuthProvider()
//       : p === 'twitter'
//       ? new firebase.auth.TwitterAuthProvider()
//       : new firebase.auth.GoogleAuthProvider();
//   };

//   //firebase.auth().signInWithRedirect(getProvider(provider));

//   firebase
//     .auth()
//     .getRedirectResult()
//     .then((result) => {
//       if (result.credential) {
//         var credential = result.credential;

//         // This gives you a Google Access Token. You can use it to access the Google API.
//         var token = credential.accessToken;
//         // ...
//       }
//       // The signed-in user info.
//       var user = result.user;
//       if (!user) {
//         firebase.auth().signInWithRedirect(getProvider(provider));
//       } else {
//         dispatch(getCreateUserObj({ newUser: user, blockUserAutoCreate: false }));
//       }
//     })
//     .catch((error) => {
//       // Handle Errors here.
//       var errorCode = error.code;
//       // var errorMessage = error.message;
//       // The email of the user's account used.
//       var email = error.email;
//       // The firebase.auth.AuthCredential type that was used.
//       var credential = error.credential;
//       dispatch(setAuthError(error.message));
//       // ...
//     });
// };

export const signInEmailPassword =
  ({ email, password }) =>
  (dispatch) => {
    //  console.log("signInEmailPassword HIT", { email, password });

    auth
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        //      console.log("User Signed IN", userCredential);
        // Signed in
        var newUser = userCredential.user;
        dispatch(getCreateUserObj({ newUser, blockUserAutoCreate: true }));
        dispatch(setAuthError(null));
        dispatch(showEmailAuthModal(false));
      })
      .catch((error) => {
        //  console.log("sign IN error", error);
        var errorCode = error.code;
        dispatch(setAuthError(error.message));
      });
  };

// export const signUpEmailPassword =
//   ({ email, password }) =>
//   (dispatch) => {
//     //  console.log("sign UP EmailPassword HIT", { email, password });
//     auth
//       .createUserWithEmailAndPassword(email, password)
//       .then((userCredential) => {
//         //  console.log("User Signed UP", userCredential);
//         // Signed in
//         var newUser = userCredential.user;
//         dispatch(getCreateUserObj({ newUser, blockUserAutoCreate: false }));
//         dispatch(setAuthError(null));
//         dispatch(showEmailAuthModal(false));
//       })
//       .catch((error) => {
//         //  console.log("sign UP error", error);
//         var errorCode = error.code;
//         dispatch(setAuthError(error.message));
//       });
//   };

export const sendEmailLink =
  ({ userObj, params }) =>
  (dispatch, getState) => {
    const prefixUrl =
      process.env.NODE_ENV === 'development'
        ? `http://localhost:19006/`
        : `https://wallet.airdosh.co.za/`;
    const returnUrl = `${prefixUrl}${params}`;

    var actionCodeSettings = {
      url: returnUrl, // "https://wallet.airdosh.co.za/auth/",
      handleCodeInApp: true,
      dynamicLinkDomain: 'airdoshsignup.page.link',
    };

    const { subCart } = getState().store;
    const { email } = userObj;
    return auth
      .sendSignInLinkToEmail(email, actionCodeSettings)
      .then(async () => {
        await AsyncStorage.setItem('userObjForSignIn', JSON.stringify({ userObj, subCart }));
        // The link was successfully sent. Inform the user.
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log('Error sending email link', error);
        // ...
      });
  };

export const signInEmailLink = (email, href) => async (dispatch) => {
  dispatch(showAuthLoadingModal(true));

  return auth
    .signInWithEmailLink(email, href)
    .then(({ user }) => {
      // Clear email from storage.
      // await AsyncStorage.removeItem("userObjForSignIn");
      // You can access the new user via result.user
      // Additional user info profile not available via:
      // result.additionalUserInfo.profile == null
      // You can check if the user is new or existing:
      // result.additionalUserInfo.isNewUser
      var newUser = user;
      dispatch(getCreateUserObj({ newUser, blockUserAutoCreate: true }));
      dispatch(setAuthError(null));
      dispatch(showAuthLoadingModal(false));
      // dispatch(showEmailAuthModal(false));
      // dispatch(setGlobalInitializing(false));
    })
    .catch((error) => {
      console.log('Email Sign in ERROR', error);
      dispatch(setAuthError(error.message));
      dispatch(setGlobalInitializing(false));
      dispatch(showAuthLoadingModal(false));
    });
};

// export const signUpEmailLink = (userObj, href) => async (dispatch) => {
//   dispatch(showAuthLoadingModal(true));
//   return auth
//     .signInWithEmailLink(userObj.email, href)
//     .then(({ user }) => {
//       var newUser = user;
//       dispatch(getCreateUserObj({ newUser, blockUserAutoCreate: false, ...userObj }));
//       dispatch(setAuthError(null));
//       dispatch(showAuthLoadingModal(false));
//     })
//     .catch((error) => {
//       console.log('Email SIGN_UP ERROR', error);
//       dispatch(setAuthError(error.message));
//       dispatch(setGlobalInitializing(false));
//       dispatch(showAuthLoadingModal(false));
//     });
// };

export const linkAuthProvider = (credential) => async (dispatch) => {
  // console.log("linkAuthProvider HIT: ", credential);

  return auth.currentUser.linkWithCredential(credential).then((usercred) => {
    // console.log("usercred linking provider", usercred.user);
    const { uid, email, phoneNumber } = usercred.user;
    dispatch(updateUserObj({ uid, userObj: { email, cellphoneNumber: phoneNumber } }));
  });
};

export const linkProviderMergeAccount = (newCredential) => async (dispatch) => {
  var sourceUser = auth.currentUser;
  // The implementation of how you store your user data depends on your application
  var fullUserObj = await dispatch(fetchFullUser(sourceUser.uid)); //new MyUserDataRepo();
  sourceUser.delete(); // not yet deleting the DB userOBJ

  // Get reference to the currently signed-in user

  // Get the data which you will want to merge. This should be done now
  // while the app is still signed in as this user.
  // var prevUserData = repo.get(prevUser);

  // Delete the user's data now, we will restore it if the merge fails
  // repo.delete(prevUser);

  // // Sign in user with the account you want to link to
  return auth
    .signInWithCredential(newCredential)
    .then(async (result) => {
      // console.log("Sign In Success", result);
      var currentUser = result.user;
      var currentUserData = await dispatch(fetchFullUser(currentUser.uid));
      // var currentUserData = repo.get(currentUser);

      // Merge prevUser and currentUser data stored in Firebase.
      // Note: How you handle this is specific to your application
      var mergedData = { ...currentUserData, ...fullUserObj }; //repo.merge(prevUserData, currentUserData);

      await sourceUser
        .linkWithCredential(result.credential) // email credential
        .then(async (linkResult) => {
          try {
            return await auth.signInWithCredential(linkResult.credential);
          } catch (error) {
            return console.log('signInWithCredential ERROR', error);
          }
        })
        .then((signInResult) => {
          // Save the merged data to the new user
          // repo.set(signInResult.user, mergedData);
        })
        .catch((error) => console.log('linkWithCredential ERROR', error));

      // Save the merged data to the new user
      // console.log(
      //   "signInResult.user || mergedData",
      //   signInResult.user,
      //   mergedData
      // );
      // repo.set(signInResult.user, mergedData);
    })
    .catch((error) => {
      // If there are errors we want to undo the data merge/deletion
      console.log('Sign In Error', error);
      // repo.set(prevUser, prevUserData); // reset the deleted data
    });
};

export const createDynamicLink = (params) => (dispatch) => {
  // cloud function
  return CreateDynamicLink(params);
  // .then(
  //   (res) => res.data.join("")
  //   // console.log(
  //   //   "Dynamic Link created",
  //   //   res.data.join("") //JSON.parse(res.data)
  //   // )
  // );
};

export const getFrontChatHash = (user) => (dispatch) => {
  // cloud function
  return GetFrontChatHash(user);
};

export const getAgentName = (uid) => async (dispatch) => {
  // cloud function

  try {
    const { data } = await FetchAgent({ uid });

    if (data?.agent) {
      const { name, surname, cellphoneNumber } = data.agent;
      if (!!name && !!surname) {
        dispatch({ type: SET_REWARDS_AGENT, payload: name + ' ' + surname });
      } else {
        dispatch({ type: SET_REWARDS_AGENT, payload: cellphoneNumber });
      }
    } else {
    }
  } catch (err) {
    console.log('Error ading sales agent', err);
  }
};
