import React, { useEffect, useState, Suspense } from 'react';
import { View, Image, useWindowDimensions, ScrollView } from 'react-native';
import { connect, useSelector, useDispatch } from 'react-redux';
import { firebase, auth, firestore } from '../../firebase/config';
import Modal from 'modal-enhanced-react-native-web';
import * as RootNavigation from '../RootNavigation.js';
import { createStackNavigator } from '@react-navigation/stack';
import { Button } from 'react-native-elements';
import { withServiceWorkerUpdater, ServiceWorkerUpdaterProps } from '@3m1/service-worker-updater';
import { isBrowser, isMobile } from 'react-device-detect';
import { colors, typography, dimensions, logo } from '../utils/theme';
import { Card, Typo3Way } from '../components/common';

import TextBoldSmall from '../components/Text/TextBoldSmall';
import BackHeader from '../components/common/NavButtonBack';
import AlertWidget from '../components/common/AlertWidget';
import PhoneAuthModal from '../views/Modals/auth/RecapchaModal';
import EmailAuthModal from '../views/Modals/auth/EmailAuthModal';
import EmailLinkModal from '../views/Modals/auth/EmailLinkModal';
import AccountFormModal from '../views/Modals/AccountFormModal';
import AuthLoading from '../views/Modals/auth/AuthLoading';
import PaymentAlt from '../views/Modals/PaymentOptionsAlt';
import InProgressModal from '../views/Modals/OrderInProgressModal';
import ProgressModal from '../views/Modals/ProgressModal';
import TagLinkSuccessModal from '../views/Modals/linktag/TagLinkSuccess';
import TagLinkFailModal from '../views/Modals/linktag/TagLinkFail';
import TermsModal from '../views/Modals/TermsModal';
import HappyPayModal from '../views/Modals/HappyPayModal';
import AirDosh_Wallet from '../views/AirDosh_Wallet';
import AuthNav from './navigation.auth';
import Top_up from '../views/Payment/TopUp';
import SettingsHome from './navigation.settings';
import LinkTag from '../views/LinkTag';
import Marketplace from './navigation.store';
import PromoMarket from '../views/Store/PromoMarket';
import Passes from './navigation.passes';
import Desktop from './navigation.desktop';
import OrderToSeatHome from '../views/OrderToSeat/OrderToSeatHome';
import SplashScreen from '../components/SplashScreen';
import SplashLoading from '../components/SplashLoading';
import SplashLottie from '../components/SplashLottie';
import EventDetail from '../views/Store/EventDetail';
import HappyPayResult from '../views/Payment/HappySuccess';
import YocoResult from '../views/Payment/YocoSuccess';
import Cart from '../views/Store/Cart';

import {
  verifyGuestByContact,
  //
  getCreateUserObj,
  foundUser,
  showAuthModal,
  showAccountFormModal,
  showEmailAuthModal,
  showAuthLoadingModal,
  showCart,
  showAltPaymentOptions,
  toggleHappyModal,
  //
  showTagLinkSuccesModal,
  showTagLinkFailModal,
  setShowTermsModal,
  setShowNewContent,
} from '../actions';

const Stack = createStackNavigator();

function walletNav(props) {
  const { navigation } = props;
  const {
    globalInitialising,
    gLoading,
    orgId,
    event_id,
    isPrivate,
    //
    authModal,
    isSignUp,
    emailAuthModal,
    authLoadingModal,
    showAccountModal,
    // userObj,
    parsedCelllNo,
    parsedEmail,
    showCartModal,
    showAltPayOptionsModal,
    showProgressModal,
    showPendingPaymentMessage,
    showInProgresssQless, // qless
    showPurchaseInProgress,
    pendingPaymentMessage,
    //
    showHappyPayModal,
    //
    tagLinkSuccessModal,
    tagLinkFailModal,
    showTermsModal,
    showLoadNewContent,
    //
    backgroundImage,
  } = props;

  // const [cellphoneNumber, setCellphoneNumber] = useState("");

  const userReducer = useSelector((state) => state.users);
  const { inviteVerified } = useSelector((state) => state.config);

  const [initializing, setInitializing] = useState(true);
  const [preFetching, setPreFetching] = useState(true);
  const [user, setUser] = useState(null);
  const { newServiceWorkerDetected, onLoadNewServiceWorkerAccept } = props;

  useEffect(() => {
    cacheImages();
    const subscriber = auth.onAuthStateChanged(onAuthStateChanged);

    return () => subscriber; // unsubscribe on unmount
  }, []);

  useEffect(() => {
    const checkVerifiedUser = async () => {
      const { orgId, event_id } = props.eventFocus;

      try {
        await props.verifyGuestByContact({
          orgId,
          event_id,
          user: userReducer.user,
        });
      } catch (err) {
        console.error({
          user,
          currentUser: userReducer.user,
        });
      }
    };
    if (props.eventFocus) {
      isPrivate && user && checkVerifiedUser();
    }
  }, [isPrivate, userReducer]);

  async function onAuthStateChanged(newUser) {
    setUser(newUser);

    // isPrivate &&
    //   userFB &&
    //   props.verifyGuestByContact({ orgId, event_id, user: userFB });

    // TODO
    // var cell = userFB && userFB.phoneNumber; //?.replace("+27", "0");

    if (!newUser) {
      setInitializing(false);
    } else {
      props
        .getCreateUserObj({ newUser, blockUserAutoCreate: true })
        .then(() => setInitializing(false));
    }
  }

  async function cacheImages() {
    await Image.prefetch(backgroundImage);
    setPreFetching(false);
  }

  //while initialsing, show activity indicator
  if (initializing || preFetching) return <SplashScreen />;

  if (dimensions.width > 650 && isBrowser)
    return (
      <Stack.Navigator>
        <Stack.Screen
          name="Desktop"
          component={Desktop}
          options={{
            headerShown: false,
          }}
        />
      </Stack.Navigator>
    );
  return (
    <View
      style={{
        height: dimensions.height,
      }}
    >
      <Stack.Navigator>
        {/* {dimensions.width > 650 && isBrowser && (
          <Stack.Screen
            name="Desktop"
            component={Desktop}
            options={{
              headerShown: false,
            }}
          />
        )} */}
        <Stack.Screen
          name="Wallet"
          component={AirDosh_Wallet}
          options={{
            headerShown: false,
            name: 'Wallet',
          }}
        />
        <Stack.Screen name="Auth" component={AuthNav} options={{ headerShown: false }} />
        <Stack.Screen
          name="Top_up"
          component={Top_up}
          options={{
            header: () => <BackHeader title="Top Up" onBackPress={() => RootNavigation.goBack()} />,
            headerStyle: {
              backgroundColor: 'transparent',
              borderBottomColor: 'transparent',
            },
          }}
        />
        <Stack.Screen name="Marketplace" component={Marketplace} options={{ headerShown: false }} />
        <Stack.Screen name="PromoMarket" component={PromoMarket} options={{ headerShown: false }} />
        <Stack.Screen name="Passes" component={Passes} options={{ headerShown: false }} />
        <Stack.Screen
          name="Express Order"
          component={OrderToSeatHome}
          options={{ headerShown: false }}
        />
        <Stack.Screen name="LinkTag" component={LinkTag} options={{ headerShown: false }} />
        <Stack.Screen name="Settings" component={SettingsHome} options={{ headerShown: false }} />

        <Stack.Screen
          name="Event Page"
          component={EventDetail}
          options={{
            headerShown: false,
          }}
        />

        <Stack.Screen
          name="Cart"
          component={Cart}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="HappyPayResult"
          component={HappyPayResult}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="YocoResult"
          component={YocoResult}
          options={{
            headerShown: false,
          }}
        />
      </Stack.Navigator>

      <Modal isVisible={showHappyPayModal} onBackdropPress={() => props.toggleHappyModal(false)}>
        <HappyPayModal onClearModal={() => props.toggleHappyModal(false)} />
      </Modal>

      {showPendingPaymentMessage !== false && <AlertWidget {...props} />}

      <Suspense fallback={<div>Loading... </div>}>
        <Modal isVisible={showCartModal} onBackdropPress={() => props.showCart(false)}>
          <Card style={{ height: dimensions.height - 30 }}>
            <ScrollView style={{ marginHorizontal: -15, paddingHorizontal: 15 }}>
              <Cart
                {...props}
                modalStyle={{
                  height: dimensions.height - 20,
                  // overides child padding
                }}
                onModalCancel={() => props.showCart(false)}
              />
            </ScrollView>
          </Card>
        </Modal>

        <Modal
          isVisible={authModal && !isSignUp}
          onBackdropPress={() =>
            props.showAuthModal({
              showModal: false,
            })
          }
        >
          <PhoneAuthModal
            {...props}
            isSignUp={isSignUp}
            onModalCancel={() =>
              props.showAuthModal({
                showModal: false,
              })
            }
          />
        </Modal>

        <Modal isVisible={emailAuthModal} onBackdropPress={() => props.showEmailAuthModal(false)}>
          <EmailLinkModal {...props} onModalCancel={() => props.showEmailAuthModal(false)} />
        </Modal>
        <Modal
          isVisible={authLoadingModal}
          onBackdropPress={() => props.showAuthLoadingModal(false)}
        >
          <AuthLoading onModalCancel={() => props.showAuthLoadingModal(false)} />
        </Modal>

        <Modal
          isVisible={showAltPayOptionsModal}
          onBackdropPress={() => props.showAltPaymentOptions(false)}
        >
          <PaymentAlt {...props} />
        </Modal>
        <Modal isVisible={showPurchaseInProgress} onBackdropPress={() => {}}>
          <InProgressModal />
        </Modal>
        <Modal isVisible={showProgressModal} onBackdropPress={() => {}}>
          <ProgressModal progressMode="top_up" />
        </Modal>
        <Modal isVisible={showProgressModal} onBackdropPress={() => {}}>
          <ProgressModal progressMode="qless" />
        </Modal>

        <Modal
          isVisible={tagLinkSuccessModal}
          onBackdropPress={() => props.showTagLinkSuccesModal(false)}
        >
          <TagLinkSuccessModal onOk={() => props.showTagLinkSuccesModal(false)} />
        </Modal>
        <Modal
          isVisible={tagLinkFailModal}
          onBackdropPress={() => props.showTagLinkFailModal(false)}
        >
          <TagLinkFailModal onOk={() => props.showTagLinkFailModal(false)} />
        </Modal>

        <Modal
          isVisible={showLoadNewContent} // {newServiceWorkerDetected} // {showLoadNewContent} //
          onBackdropPress={() => props.setShowNewContent(false)}
        >
          <View style={{ flex: 1, justifyContent: 'flex-end' }}>
            <View
              style={{
                height: 70,
                borderRadius: 15,
                padding: 10,
                paddingLeft: 20,
                backgroundColor: 'white',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                // marginBottom: 20,
              }}
            >
              <TextBoldSmall>New content available!</TextBoldSmall>
              <Button
                title="Refresh"
                buttonStyle={{
                  borderRadius: 10,
                  color: colors.pay,
                  padding: 15,
                }}
                titleStyle={{
                  fontWeight: 'bold',
                  fontSize: 14,
                }}
                onPress={
                  () => {
                    props.setShowNewContent(false);
                    onLoadNewServiceWorkerAccept();
                    // window.location.reload()
                  }
                  //  props.setShowNewContent(false)
                }
              >
                <TextBoldSmall>Refresh</TextBoldSmall>
              </Button>
            </View>
          </View>
        </Modal>

        <Modal isVisible={showTermsModal} onBackdropPress={() => props.setShowTermsModal(false)}>
          <TermsModal {...props} onClearModal={() => props.setShowTermsModal(false)} />
        </Modal>

        <Modal
          isVisible={showAccountModal}
          onBackdropPress={() => props.showAccountFormModal(false, [])}
        >
          <AccountFormModal {...props} onModalClose={() => props.showAccountFormModal(false, [])} />
        </Modal>

        {/* <Modal
        isVisible={emailAuthModal}
        onBackdropPress={() => props.showEmailAuthModal(false)}
      >
        <EmailAuthModal onModalCancel={() => props.showEmailAuthModal(false)} />
      </Modal> */}
      </Suspense>

      {globalInitialising && <SplashLottie />}
      {gLoading && <SplashLoading />}
    </View>
  );
}

const mapStateToProps = (state) => {
  const { user, parsedCelllNo, parsedEmail } = state.users;
  const {
    globalInitialising,
    authModal,
    isSignUp,
    showAccountModal,
    emailAuthModal,
    authLoadingModal,
  } = state.auth;
  const { tagLinkSuccessModal, tagLinkFailModal, showTermsModal } = state.modal;
  const { showInProgresssQless } = state.seatOrder;
  const {
    showCartModal,
    showAltPayOptionsModal,
    showPurchaseInProgress,
    showProgressModal,
    //topUpReceived
    showPendingPaymentMessage,
    pendingPaymentMessage,
    //
    showHappyPayModal,
  } = state.store;
  const {
    eventFocus,
    gLoading,
    theme,
    carouselData,
    isPrivate,
    orgId,
    event_id,
    showLoadNewContent,
  } = state.config;

  const { backgroundImage } = theme;

  return {
    globalInitialising,
    gLoading,
    orgId,
    event_id,
    isPrivate,
    eventFocus,
    //
    // userObj: user,
    parsedCelllNo,
    parsedEmail,
    authModal,
    isSignUp,
    emailAuthModal,
    showAccountModal,
    authLoadingModal,
    showCartModal,
    showAltPayOptionsModal,
    showProgressModal,
    showPurchaseInProgress,
    showInProgresssQless, // qless
    showPendingPaymentMessage,
    pendingPaymentMessage,
    //
    tagLinkSuccessModal,
    tagLinkFailModal,
    showTermsModal,
    //
    backgroundImage,
    showLoadNewContent,
    //
    showHappyPayModal,
  };
};

export default connect(mapStateToProps, {
  verifyGuestByContact,
  //
  getCreateUserObj,
  foundUser,
  showAuthModal,
  showAccountFormModal,
  showEmailAuthModal,
  showAuthLoadingModal,
  showCart,
  showAltPaymentOptions,
  toggleHappyModal,
  //
  showTagLinkSuccesModal,
  showTagLinkFailModal,
  setShowTermsModal,
  setShowNewContent,
})(walletNav);
// withServiceWorkerUpdater(
