import {
  GLOBAL_INITIIALISING,
  LOGGING,
  UPDATE_AUTH_FORM,
  SHOW_AUTH_MODAL,
  SHOW_ACCOUNT_FORM,
  SHOW_EMAIL_AUTH_MODAL,
  SHOW_AUTH_LOADING_MODAL,
  SET_AUTH_ERROR,
  SET_REWARDS_AGENT,
} from '../actions/types';

const INITIAL_STATE = {
  globalInitialising: false,
  loading: false,
  authModal: false,
  showAccountModal: false,
  accountFormFields: [], // ["name", "surname", "email", "cellphoneNumber"],
  isSignUp: false,
  emailAuthModal: false,
  authLoadingModal: false,
  authError: null,
  //
  phoneNumber: '', // "+27",
  email: '',
  name: '',
  surname: '',
  password: '',
  repassword: '',
  //
  rewardsAgent: null,
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GLOBAL_INITIIALISING:
      return { ...state, globalInitialising: action.bool };
    case LOGGING:
      return { ...state, loading: action.bool };
    case UPDATE_AUTH_FORM:
      return { ...state, [action.key]: action.value };
    case SHOW_AUTH_MODAL:
      const { showModal, isSignUp } = action;
      return {
        ...state,
        authModal: showModal,
        isSignUp,
      };
    case SHOW_EMAIL_AUTH_MODAL:
      return { ...state, emailAuthModal: action.bool };
    case SHOW_AUTH_LOADING_MODAL:
      return { ...state, authLoadingModal: action.bool };
    case SHOW_ACCOUNT_FORM:
      return {
        ...state,
        showAccountModal: action.bool,
        accountFormFields: action.accountFormFields,
      };
    case SET_AUTH_ERROR:
      return { ...state, authError: action.err };
    case SET_REWARDS_AGENT:
      return { ...state, rewardsAgent: action.payload };
    default:
      return state;
  }
};

export default authReducer;
