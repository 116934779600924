import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import {
  View,
  Image,
  ImageBackground,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
} from 'react-native';
import { Fontisto, Feather, SimpleLineIcons } from '@expo/vector-icons';
import { dimensions, colors, typography } from '../../utils/theme';
import Text from '../../components/Text/TextLight';
import TextBold from '../../components/Text/TextBold';

const Chip = (props) => {
  return (
    <Text>
      <View
        style={[
          styles.container,
          props.style,
          props.solid && styles.solidStyle,
          props.outline && styles.outlineStyle,
          props.rounded && styles.rounded,
          props.white && { borderColor: 'white' },
        ]}
      >
        <Text
          style={[
            { fontSize: 10 },
            props.solid && { color: colors.bg },
            props.outline && { color: colors.highlight },
            props.white && { color: 'white' },
          ]}
        >
          {props.title}
        </Text>
      </View>
    </Text>
  );
};

export { Chip };

const styles = StyleSheet.create({
  container: {
    padding: 5,
    paddingVertical: 3,
    borderRadius: 3,
    alignItems: 'center',
  },
  solidStyle: { backgroundColor: colors.highlight },
  outlineStyle: {
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: colors.highlight,
  },
  rounded: {
    borderRadius: 15,
  },
});
