import { auth, firestore, functions } from '../../firebase/config';
import * as RootNavigation from '../RootNavigation.js';
import * as MailComposer from 'expo-mail-composer';
import moment from 'moment';
import {
  LOGGING,
  SET_EVENT_FOCUS,
  PASS_LANDED,
  IMAGES_LOADED,
  FETCH_EVENTS_SUCCESS,
  FETCH_SINGLE_EVENT_SUCCESS,
  FETCH_EVENT_ORGANISER_SUCCESS,
  FETCH_EVENTS_PRODUCTS_SUCCESS,
  FETCH_EVENTS_PRODUCTS_GLOBAL_SUCCESS,
  FETCH_EVENTS_ALL_PRODUCTS_SUCCESS,
  FETCH_EVENT_IMAGES_SUCCESS,
  TOGGLE_INVITE_STATUS_SUCCESS,
  DASHBOARD_FETCH_SUCCESS,
} from './types';
import { userLogAction } from './UsersActions';
import { setGlobalInitializing } from './AuthActions';
import { setGlobalLoading } from './ConfigActions';

// const productsRef = (orgId, eventId) =>
//   firestore
//     .collection('entities')
//     .doc(orgId)
//     .collection('events')
//     .doc(eventId)
//     .collection('products');

const productsRef = ({ isGlobalProduct, orgId, event_id }) => {
  return isGlobalProduct
    ? firestore.collection('entities').doc(orgId).collection('global_products')
    : firestore
        .collection('entities')
        .doc(orgId)
        .collection('events')
        .doc(event_id)
        .collection('products');
};

const timeSlotsRef = ({ isGlobalProduct, orgId, event_id, prod_id }) =>
  productsRef({ isGlobalProduct, orgId, event_id }).doc(prod_id).collection('time_slots');

const slotSeatRef = ({ isGlobalProduct, orgId, event_id, prod_id, slot_id }) =>
  productsRef({ isGlobalProduct, orgId, event_id })
    .doc(prod_id)
    .collection('time_slots')
    .doc(slot_id)
    .collection('slot_seats');

// const productsGlobalRef = (orgId) =>
//   firestore.collection('entities').doc(orgId).collection('global_products');

const eventImagesRef = (orgId, eventId) =>
  firestore
    .collection('entities')
    .doc(orgId)
    .collection('events')
    .doc(eventId)
    .collection('imageCollection');

const orgRef = (orgId, eventId) => firestore.collection('entities').doc(orgId);

var SendEmail = functions.httpsCallable('sendEmail');

//Logging and loading
export const toggleImagesLoading = (bool) => (dispatch) => {
  dispatch({
    type: IMAGES_LOADED,
    payload: bool,
  });
};

export const togglePaymentLoading = (bool) => (dispatch) => {
  dispatch({
    type: PASS_LANDED,
    bool,
  });
};

export const fetchSingleEvent = (orgId, event_id, prod_id) => (dispatch) => {
  return (
    firestore
      .collection('entities')
      .doc(orgId)
      .collection('events')
      .doc(event_id)
      // .get();
      .onSnapshot((doc) => {
        if (doc.exists) {
          const data = doc.data();
          dispatch({
            type: SET_EVENT_FOCUS,
            payload: { ...data, event_id }, // fixing the "event_id" naming issue before redux
          });

          dispatch(fetchEventProducts(orgId, event_id));
          dispatch(fetchEventImageCollection(orgId, event_id));
          dispatch(fetchOrganiser(orgId, event_id));

          setTimeout(() => {
            dispatch(setGlobalInitializing(false));
            dispatch(setGlobalLoading(false));
          }, 2000);
          return doc.data();
        } else {
          RootNavigation.navigate('Marketplace');
          // TODO nav to market screen
        }
      })
  );
};

export const fetchSingleProduct = async (orgId, event_id, prod_id) => {
  try {
    const doc = await productsRef({ orgId, event_id }).doc(prod_id).get();
    return { response: doc.data() };
  } catch (error) {
    console.error('Could not fetch single product -> ', error);
    return { error };
  }
};

export const fetchEventFocusOnce = (orgId, event_id) => (dispatch) => {
  return firestore
    .collection('entities')
    .doc(orgId)
    .collection('events')
    .doc(event_id)
    .get()
    .then((doc) => {
      if (doc.exists) {
        const data = doc.data();
        dispatch({
          type: SET_EVENT_FOCUS,
          payload: { ...data, event_id }, // fixing the "event_id" naming issue before redux
        });

        return doc.data() || null;
      } else {
        return null;
      }
    });
};

export const fetchPromoLink =
  ({ orgId, event_id, promo_id, link_id }) =>
  (dispatch) => {
    var promoLink = null;
    var promises = [];

    const localLinks = firestore
      .collection('entities')
      .doc(orgId)
      .collection('events')
      .doc(event_id)
      .collection('promo_codes')
      .doc(promo_id)
      .collection('code_links')
      .doc(link_id)
      .get()
      .then((doc) => {
        doc.exists && (promoLink = doc.data());
      });
    promises.push(localLinks);

    const globalLinks = firestore
      .collection('entities')
      .doc(orgId)
      .collection('global_promo_codes')
      .doc(promo_id)
      .collection('code_links')
      .doc(link_id)
      .get()
      .then((doc) => {
        doc.exists && (promoLink = doc.data());
      });
    promises.push(globalLinks);

    return Promise.all(promises).then(() => {
      return promoLink;
    });
  };

export const fetchPromoDetails =
  ({ orgId, event_id, promo_id }) =>
  (dispatch) => {
    var promoObj = null;
    const promises = [];
    const localPromo = firestore
      .collection('entities')
      .doc(orgId)
      .collection('events')
      .doc(event_id)
      .collection('promo_codes')
      .doc(promo_id)
      .get()
      .then((doc) => {
        doc.exists && (promoObj = doc.data());
      });
    promises.push(localPromo);

    const globalPromo = firestore
      .collection('entities')
      .doc(orgId)
      .collection('global_promo_codes')
      .doc(promo_id)
      .get()
      .then((doc) => {
        doc.exists && (promoObj = doc.data());
      });
    promises.push(globalPromo);

    return Promise.all(promises).then(() => {
      return promoObj;
    });
  };

export const fetchDashboard = (orgId, eventId) => (dispatch) => {
  // firestore
  //   .collection("entities")
  //   .doc(orgId)
  //   .collection("events")
  //   .doc(eventId)
  //   .collection("dashboard")
  //   .get()
  //   .then((querySnapshot) => {
  //     var dashboard = [];
  //     querySnapshot.forEach((doc) => {
  //       dashboard.push(doc.data());
  //     });
  //     dispatch({ type: DASHBOARD_FETCH_SUCCESS, payload: dashboard });
  //   });
};

export const verifyGuestByContact =
  ({ orgId, event_id, user }) =>
  async (dispatch) => {
    const { cellphoneNumber, email } = user;
    // const contactType = !!phoneNumber ? 'phone' : 'email';
    // const contact = contactType === 'phone' ? phoneNumber : email;

    let userInvited = [];

    for await (const [key, value] of Object.entries({ phone: cellphoneNumber, email })) {
      // !!value && checkEligibility(key, value);
      try {
        const querySnapshot = await firestore
          .collection('entities')
          .doc(orgId)
          .collection('events')
          .doc(event_id)
          .collection('campaigns')
          .doc('secret_campaign')
          .collection('recipients')
          .where(key, '==', value)
          .get();

        querySnapshot.forEach((doc) => {
          if (doc.exists) {
            userInvited.push(doc.data()); // "push"ing in case there is more than one user obj found
          }
        });
      } catch (err) {
        console.log('Error check eligibility', err);
      }
    }

    const userVerified = userInvited.length !== 0;
    userVerified && dispatch({ type: TOGGLE_INVITE_STATUS_SUCCESS, payload: true });
    !userVerified && dispatch({ type: TOGGLE_INVITE_STATUS_SUCCESS, payload: false });
  };

export const fetchAllEvents = () => (dispatch) => {
  firestore
    .collectionGroup('events')
    .get()
    .then((querySnapshot) => {
      const fetchedEvents = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        fetchedEvents.push({ ...data, event_id: data.id });
      });
      dispatch({ type: FETCH_EVENTS_SUCCESS, fetchedEvents });
    });
};

export const fetchOrganiserEvents = (orgId, events_multi) => async (dispatch) => {
  var promises = [];
  var orgEvents = [];
  const eventIdArray = events_multi.map((e) => e.value);
  for await (const event_id of eventIdArray) {
    promises.push(
      firestore
        .collection('entities')
        .doc(orgId)
        .collection('events')
        .doc(event_id)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const event = doc.data();
            orgEvents.push(event);
          }
        }),
    );
  }

  return Promise.all(promises).then(() => {
    dispatch({ type: FETCH_EVENTS_SUCCESS, fetchedEvents: orgEvents });
    return orgEvents;
  });
};

// IN EVENT DETAIL    // IN EVENT DETAIL    // IN EVENT DETAIL    // IN EVENT DETAIL    // IN EVENT DETAIL

export const fetchOrganiser = (orgId, id) => (dispatch) => {
  orgRef(orgId)
    .get()
    .then((doc) => {
      if (doc.exists) {
        var organiserObj = doc.data();

        dispatch({ type: FETCH_EVENT_ORGANISER_SUCCESS, organiserObj });
      }
    });
};

export const fetchEventProducts = (orgId, event_id) => async (dispatch, getState) => {
  const { config } = getState();
  const { eventFocus } = config;

  // fetch Local (event specific) products
  productsRef({ orgId, event_id }).onSnapshot(async (querySnapshot) => {
    var eventProducts = [];
    querySnapshot.forEach((doc) => {
      eventProducts.push(doc.data());
    });
    const params = {
      orgId,
      event_id,
      eventProducts,
    };
    const updatedProds = await checkForProductVariantOptions({ params, eventFocus });
    updatedProds?.length &&
      dispatch({
        type: FETCH_EVENTS_PRODUCTS_SUCCESS,
        eventProducts: updatedProds,
      });
    dispatch({
      type: FETCH_EVENTS_ALL_PRODUCTS_SUCCESS,
    });
  });

  // fetch Global (organiser specific) products
  productsRef({ isGlobalProduct: true, orgId }).onSnapshot(async (querySnapshot) => {
    var globalProducts = [];
    querySnapshot.forEach((doc) => {
      globalProducts.push(doc.data());
    });
    // check that the global product is to show within THIS event context
    globalProducts = globalProducts.filter((prod) =>
      prod.events_multi.map((e) => e.value).includes(event_id),
    );

    const params = {
      orgId,
      event_id,
      eventProducts: globalProducts,
    };
    const updatedProds = await checkForProductVariantOptions({
      params,
      eventFocus,
      isGlobalProduct: true,
    });
    updatedProds?.length &&
      dispatch({
        type: FETCH_EVENTS_PRODUCTS_GLOBAL_SUCCESS,
        globalProducts: updatedProds,
      });
    dispatch({
      type: FETCH_EVENTS_ALL_PRODUCTS_SUCCESS,
    });
  });
};

// export const fetchEventProductsOnce = (orgId, eventId) => async (dispatch) => {
//   var eventProducts = [];
//   await productsRef(orgId, eventId)
//     .get()
//     .then((querySnapshot) => {
//       querySnapshot.forEach((doc) => {
//         eventProducts.push(doc.data());
//       });
//     });
//   const updatedProds = await checkForProductVariantOptions({
//     orgId,
//     eventId,
//     eventProducts,
//   });
//   return updatedProds;
// };

export const checkForProductVariantOptions = async ({ isGlobalProduct, params, eventFocus }) => {
  const { orgId, event_id, eventProducts } = params;
  !!eventProducts.length &&
    eventProducts.forEach(async (prod, prodIndex) => {
      const { prod_id } = prod;
      if (!!prod.time_slots) {
        var fetched_time_slots = [];
        await timeSlotsRef({ isGlobalProduct, orgId, event_id, prod_id })
          .get()
          .then((timeSlotSnap) => {
            timeSlotSnap.forEach((doc) => doc.exists && fetched_time_slots.push(doc.data()));
            eventProducts[prodIndex].time_slots = fetched_time_slots.sort(
              (a, b) => a.startTime.seconds - b.startTime.seconds,
            );
          });

        eventProducts[prodIndex].time_slots.forEach(async (slot, slotIndex) => {
          const { slot_id } = slot;
          var fetched_slot_seat = [];
          await slotSeatRef({ isGlobalProduct, orgId, event_id, prod_id, slot_id })
            .get()
            .then((slotSeatSnap) => {
              slotSeatSnap.forEach((doc) => {
                if (doc.exists) {
                  const seatSlot = doc.data();
                  seatSlot.is_available && fetched_slot_seat.push(seatSlot);
                }
              });
              eventProducts[prodIndex].time_slots[slotIndex].slot_seats = fetched_slot_seat;
            });
        });
      }

      if (!!prod.date_slots) {
        var fetched_date_slots = [];
        await productsRef({ isGlobalProduct, orgId, event_id })
          .doc(prod.prod_id)
          .collection('date_slots')
          .get()
          .then((dateSlotSnap) => {
            dateSlotSnap.forEach((doc) => doc.exists && fetched_date_slots.push(doc.data()));
            eventProducts[prodIndex].date_slots = fetched_date_slots;
          });
      } else {
        const eventDate = eventFocus.startTime ? eventFocus.startTime : eventFocus.date.startTime;
        eventProducts[prodIndex].date_slots = [
          {
            is_available: true,
            slot_id: 'day_1',
            startTime: eventDate,
          },
        ];
      }
    });
  return eventProducts;
};

export const fetchEventImageCollection = (orgId, id) => (dispatch) => {
  eventImagesRef(orgId, id)
    .get()
    .then(function (querySnapshot) {
      var eventImages = [];
      querySnapshot.forEach(function (doc) {
        eventImages.push(doc.data());
      });
      dispatch({
        type: FETCH_EVENT_IMAGES_SUCCESS,
        eventImages: eventImages.sort((a, b) => a.sort_order - b.sort_order),
      });
    });
};

export const sendMailToSelf = ({ to, subject, body, attachments }) => {
  var emailTrigger = {};

  const data = {
    to,
    message: {
      subject,
      text: 'You have emailed your event pass to yourself. Please remain vigilant and do not share this pass, or allow it to be copied.',
      body,
      attachments,
    },
  };
  emailTrigger = JSON.parse(JSON.stringify(data));

  // CLOUD FUNCTION --->
  return SendEmail({ emailTrigger }); // this returns a bool
};
