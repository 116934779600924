import { firebase, firestore } from '../../firebase/config';
import { Linking } from 'react-native';
import * as RootNavigation from '../RootNavigation.js';
import generateId from '../utils/lengthID';
import {
  LOGGING,
  ADD_TO_SUBCART_SUCCESS,
  REMOVE_FROM_SUBCART_SUCCESS,
  UPDATE_SUBCART_SUCCESS,
  //
  PAYMENT_RECEIVED_TICKET,
  SHOW_IN_PROGRESS,
  SHOW_IN_PROGRESS_MODAL,
  SHOW_IN_PROGRESS_QLESS,
  SHOW_BALANCE_SPINNER,
  SHOW_CART,
  SHOW_ALT_PAY_OPTIONS,
  //
  SHOW_PAYMENT_PENDING_WIDGET,
} from '../actions/types';
import { CountryCodeList } from 'react-native-country-picker-modal';

const userRef = firestore.collection('customers');
const userWalletRef = (uid) => firestore.collection('customers').doc(uid).collection('wallet');

export const addToCart = (props) => (dispatch, getState) => {
  const {
    prod_id,
    qty,
    prod_type,
    voucher,
    discount,
    selected_time_slot,
    selected_slot_seat,
    // selected_date_slot,
    seat_number,
  } = props;

  if (!!prod_type && prod_type === 'voucher') {
    dispatch({
      type: ADD_TO_SUBCART_SUCCESS,
      payload: voucher,
    });
  } else {
    const { config, store } = getState();
    const { eventFocus, subCart } = config;
    const { current_ticket_phase, ticket_phases } = eventFocus;
    const { productList } = store;
    const prodIndex = productList.findIndex((prod) => prod.id === prod_id);
    const item = (prodIndex > -1 && productList[prodIndex]) || null;
    if (item) {
      const phaseTicketPrice = () => {
        if (item.enable_phases) {
          return item.price_phases[current_ticket_phase]?.price || item.price;
        } else {
          return item.price;
        }
      };
      const isDiscountValidForEvent = eventFocus?.event_id === discount?.event_id;

      var product = {
        prod_id,
        ...item,
        price: phaseTicketPrice(),
        prod_title: item.prod_title,
        qty, // could be default "1" or set by a picker
        ...eventFocus, // event item
        event_id: eventFocus.event_id, // this is important, each subcart item needs to have it event_id in otder to allocate TX
        selected_time_slot,
        selected_slot_seat,
        // selected_date_slot,
        seat_number,
      };

      const discountedProduct = {
        prod_id: !!discount ? `${item.id}_${discount.title}` : item.id, //do this unless there is a duplicate of "id"!!! gotcha!
        ...item,
        discount: (isDiscountValidForEvent && discount) || null, // this might be better for a future solution - process on backend
        price: !!discount
          ? phaseTicketPrice() -
            phaseTicketPrice() * (isDiscountValidForEvent ? discount.percentage : 0)
          : phaseTicketPrice(),
        strikePrice: phaseTicketPrice(),
      };

      if (!!discount && !discount.product.value) {
        product = { ...product, ...discountedProduct };
        dispatch({
          type: ADD_TO_SUBCART_SUCCESS,
          payload: product,
        });
      } else if (
        !!discount &&
        !!discount.product.value &&
        discount.product.value === prod_id
        // && also check the QTY for the specific discount
      ) {
        product = { ...product, ...discountedProduct };
        dispatch({
          type: ADD_TO_SUBCART_SUCCESS,
          payload: product,
        });
      } else {
        dispatch({
          type: ADD_TO_SUBCART_SUCCESS,
          payload: product,
        });
      }
    } else {
      console.log('No promo item to add to cart');
    }
  }
};

export const updateSubCart = (subCart) => (dispatch) => {
  dispatch({
    type: UPDATE_SUBCART_SUCCESS,
    payload: subCart,
  });
};

export const showInProgress = (bool) => ({ type: SHOW_IN_PROGRESS, bool }); //
export const showProgressModal = (actionProps) => (dispatch) =>
  dispatch({ type: SHOW_IN_PROGRESS_MODAL, ...actionProps });
export const setShowSpinner = (bool) => ({ type: SHOW_BALANCE_SPINNER, bool });
export const showCart = (bool) => ({ type: SHOW_CART, bool });
export const showAltPaymentOptions = (bool) => ({
  type: SHOW_ALT_PAY_OPTIONS,
  bool,
});
export const showPendingPaymentWidget = (bool, message) => ({
  type: SHOW_PAYMENT_PENDING_WIDGET,
  bool,
  message,
});
