import React, { useState } from 'react';
import { StyleSheet, View, Text, TouchableOpacity, TextInput } from 'react-native';
import { colors, dimensions, typography } from '../utils/theme';

const TextInputRounded = ({
  placeholder,
  onChangeText,
  addStyle,
  value,
  keyboardType,
  secureTextEntry,
}) => {
  const [focus, setFocus] = useState(false);
  return (
    <View style={focus ? styles.focussedInputContainer : styles.inputContainer}>
      <Text
        style={[
          { marginLeft: 20, color: colors.textSolid },
          focus && { color: colors.pay, fontWeight: 'bold' },
        ]}
      >
        ZAR
      </Text>
      <TextInput
        onChangeText={onChangeText}
        value={value}
        style={[styles.inputStyle, { fontSize: 16 }, addStyle]}
        autoCapitalize="none"
        placeholder={placeholder}
        autoCorrect={false}
        secureTextEntry={secureTextEntry}
        keyboardType={keyboardType}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
    </View>
  );
};

export default TextInputRounded;

const styles = StyleSheet.create({
  focussedInputContainer: {
    height: 60,
    width: '100%',
    borderWidth: 2,
    borderRadius: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: colors.pay,
    //backgroundColor: colors.bg1,
    paddingHorizontal: 20,
    paddingVertical: 5,
    marginTop: 15,
  },
  inputContainer: {
    height: 60,
    width: '100%',
    borderWidth: 1,
    borderRadius: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: colors.shade2,
    //backgroundColor: colors.bg1,
    paddingHorizontal: 20,
    paddingVertical: 5,
    marginTop: 15,
  },
  inputStyle: {
    //backgroundColor: "yellow",
    // fontFamily: typography.primary,
    // fontSize: 16,
    outlineStyle: 'none',
    outlineWidth: 0,
    outlineColor: 'transparent',
    color: colors.textSolid,
    paddingHorizontal: 10,
  },
  inputTextFocussed: {
    // outlineStyle: "none",
    // outlineWidth: 0,
    // outlineColor: "transparent",
    color: colors.primaryText,
    paddingHorizontal: 10,
    borderBottomColor: colors.highlight,
    borderBottomWidth: 2,
  },
});
