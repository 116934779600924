import React, { useState, useRef, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import moment from "moment";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  TouchableHighlight,
  StyleSheet,
  ActivityIndicator,
} from "react-native";
import { Button } from "react-native-elements";
import Expand from "react-expand-animated";
import * as RootNavigation from "../../RootNavigation.js";
import { Fontisto, Feather, SimpleLineIcons } from "@expo/vector-icons";
import { LinearGradient } from "expo-linear-gradient";
import { dimensions, colors } from "../../utils/theme";
import ButtonX from "../../components/buttons/ButtonX";
import TextSemi from "../../components/Text/TextS";

import {
  Card,
  CardGradient,
  Typo3Way,
  Typo2Way,
} from "../../components/common";
import { AvatarStack, DateTimeLocation } from "../../components/social";
import TextBold from "../../components/Text/TextBold";
import TextBoldSmall from "../../components/Text/TextBoldSmall";
import {} from "../../actions";

const day = (seconds) => moment(seconds * 1000).format("Do");

const dateFormatted = (date) => {
  if (!!date) {
    const { startTime, endTime } = date;

    if (day(startTime) === day(endTime)) {
      return `${moment(startTime.seconds * 1000).format("dddd Do MMM `YY")}`;
    } else {
      return `${moment(startTime.seconds * 1000).format(
        "Do MMM YYYY"
      )} - ${moment(endTime.seconds * 1000).format("Do MMM YYYY")}`;
    }
  } else {
    return "Date TBC";
  }
};

// moment(date?.startTime?.seconds * 1000).format(
//   "dddd Do MMMM YYYY"
// )

// const itemDate = (date) => {
//   if (startTime && !endTime) {
//     return dateFormatted({ startTime });
//   }
//   if (startTime && endTime) {
//     return dateFormatted({ startTime, endTime });
//   } else {
//     return dateFormatted(date);
//   }
// };

function EventSummary(props) {
  const { eventFocus } = useSelector((state) => state.config);
  const { user } = useSelector((state) => state.users);

  const {
    date,
    event_title,
    event_description,
    color1,
    color2,
    //influencers,
    //attendanceCount
  } = eventFocus; // from {...props} - Parent component - EventDetail.js
  const [more, setMore] = useState(false);
  //
  return (
    <CardGradient {...props}>
      <Typo2Way
        subTitle={dateFormatted(date)}
        title={event_title}
        subTitleStyle={{ color: "rgba(255,255,255,0.7)" }}
        titleStyle={{ color: "white" }}
      />
      <TouchableHighlight
        activeOpacity={0.9}
        underlayColor="transparent"
        onPress={() => setMore(!more)}
      >
        <View>
          {!more && (
            <TextSemi
              numberOfLines={3}
              ellipsizeMode="tail"
              style={{
                color: "rgba(255,255,255,0.7)", //colors.shade4,
                fontSize: 14,
                marginBottom: 10,
              }}
            >
              {event_description}
            </TextSemi>
          )}

          <Expand open={more}>
            <TextSemi
              style={{
                color: "rgba(255,255,255,0.7)", //colors.shade4,
                fontSize: 14,
                marginBottom: 10,
              }}
            >
              {event_description}
            </TextSemi>
          </Expand>

          <View style={{ alignItems: "flex-end" }}>
            {more ? (
              <View style={styles.expandButton}>
                <TextBoldSmall style={{ color: "white", opacity: 0.7 }}>
                  {" "}
                  Less{" "}
                </TextBoldSmall>
              </View>
            ) : (
              <View style={styles.expandButton}>
                <TextBoldSmall style={{ color: "white", opacity: 0.7 }}>
                  {" "}
                  More{" "}
                </TextBoldSmall>
              </View>
            )}
          </View>
        </View>
      </TouchableHighlight>
      <DateTimeLocation
        {...props}
        item={eventFocus}
        oneColor={{ color: "white" }}
        showTimes={
          !!date && day(date.startTime.seconds) === day(date.endTime.seconds)
        }
      />
    </CardGradient>
  );
}

export default connect(null, {})(EventSummary);

const imageHieght = dimensions.width - 200;

const styles = StyleSheet.create({
  container: {
    width: dimensions.width - 30,
    borderRadius: 15,
    // margin: 20,
    backgroundColor: "white",
    shadowColor: "rgba(0,0,0,0.4)",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 20,
  },
  image: {
    width: "100%",
    height: imageHieght,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    //flex: 1,
    //backgroundColor: "yellow",
    //height: dimensions.width - 40,
    //justifyContent: "flex-end",
    // borderRadius: 15
  },
  gradient: {
    flex: 1,
    position: "absolute",
    top: 0,
    width: dimensions.width - 30,
    height: imageHieght,
    justifyContent: "flex-end",
    padding: 20,
    // borderBottomLeftRadius: 9,
    // borderBottomRightRadius: 9
  },
  expandButton: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    backgroundColor: "rgba(0,0,0,0.05)",
    borderRadius: 8,
  },
});

//   <AvatarStack
//   influencers={influencers}
//   attendanceCount={attendanceCount}
// />
// <Card
//   style={{
//     flexDirection: "row",
//     alignItems: "center",
//     justifyContent: "space-between",
//     width: dimensions.width - 80,
//     marginTop: 20,
//     marginBottom: 0
//   }}
// >
//   {
//     //  <Typo2Way subTitle="From" title="R800" />
//   }
//   <Button
//     title="Book now"
//     buttonStyle={{
//       height: 50,
//       width: 150,
//       // margin: 20,
//       borderRadius: 30,
//       backgroundColor: colors.pay,
//       alignSelf: "flex-start"
//     }}
//     titleStyle={{ fontWeight: "bold", fontSize: 16 }}
//     onPress={() => console.log("Book now!")}
//   />
// </Card>
