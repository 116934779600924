export default function generateID(l) {
  //sSourceCharacters, iCodeLength, sSeperator, aSeparatorPositions
  var sNewCode = "";

  var charGroup =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  var length = l; //  "l" is passed in

  for (var i = 0; i < length; i++) {
    sNewCode =
      sNewCode + charGroup.charAt(parseInt(Math.random() * charGroup.length));

    if (charGroup.indexOf(i + 1) !== -1) {
      sNewCode = sNewCode;
    }
  }

  return sNewCode;
}

// var divider = '-';
// var dividerLocations = [3, 7, 10]; // the numbers indicate which character to place the divider after
//
// var generatedCode = generateCode(charGroup, length, divider, dividerLocations);
