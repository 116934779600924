import React, { useState } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import {
  SafeAreaView,
  View,
  Image,
  ScrollView,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
} from "react-native";
import ButtonX from "../../components/buttons/ButtonX";
import { Feather } from "@expo/vector-icons";
import BackArrow from "../../components/common/BackArrow";
import { dimensions, colors, typography } from "../../utils/theme";
import { Card, Typo2Way } from "../../components/common";
import TextBold from "../../components/Text/TextBold";
import TextBS from "../../components/Text/TextBoldSmall";
import TextSemi from "../../components/Text/TextS";
import Text from "../../components/Text/TextLight";
import TextInput from "../../components/TextInput";
import PayOption from "../../components/PaymentOptions";
import generateOTP from "../../utils/generateOTP";

const payIconSize = 7;

const Divider = () => {
  return (
    <View
      style={{
        height: 0.5,
        width: "95%",
        alignSelf: "center",
        backgroundColor: colors.shade1,
        marginVertical: 15,
      }}
    />
  );
};

const LocationDeliveryDetails = (props) => {
  const {
    showEditDeliveryDetails,
    name,
    setName,
    tableNo,
    setTableNo,
    onNameChange,
    onTableChange,
  } = props;
  if (showEditDeliveryDetails)
    return (
      <View
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <Card
          style={{
            width: dimensions.width - 70,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <View>
            <Text>Delivering items to</Text>
            <TextBS style={{ fontSize: 16 }}>{user?.displayName}</TextBS>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Text>at table no.</Text>
              <TextBS style={{ fontSize: 16, marginBottom: -2 }}>
                {" "}
                {user?.tableNo}
              </TextBS>
            </View>
          </View>
          <Button
            title="Edit"
            type="outline"
            buttonStyle={{
              height: 50,
              width: 100,
              margin: 5,
              borderRadius: 25,
              borderWidth: 1,
              borderColor: colors.highlight,
              backgroundColor: "white", // colors.highlight
            }}
            titleStyle={{
              fontWeight: "bold",
              fontSize: 14,
              color: colors.highlight,
            }}
            onPress={onEditPress}
          />
        </Card>
      </View>
    );

  return (
    <View style={{ width: "100%", alignItems: "center" }}>
      <TextBold style={{ marginBottom: 10 }}>Who? Where?</TextBold>
      <View
        style={{
          width: "100%",
          flexDirection: "row",
          borderRadius: 15,
          shadowColor: "rgba(45,185,177,0.4)",
          shadowOffset: { width: 0, height: 0 },
          shadowOpacity: 0.5,
          shadowRadius: 20,
        }}
      >
        <View
          style={{
            width: "50%",
            //backgroundColor: "yellow",
            borderTopLeftRadius: 15,
            borderBottomLeftRadius: 15,
            borderRightWidth: 1,
            borderRightColor: colors.shade1,
          }}
        >
          <TextInput
            label="Name"
            placeholder="Enter name here."
            onChangeText={(text) => {
              setName(text);
              onNameChange(text);
            }}
            value={name}
            addStyle={{
              fontSize: 12,
              textAlign: "center",
              width: "50%",
            }}
            addContainerStyle={{ width: "100%" }}
            autoFocus={true}
          />
        </View>
        <View
          style={{
            width: "50%",
            borderBottomRightRadius: 15,
            borderTopRightRadius: 15,
          }}
        >
          <TextInput
            label="Table no."
            placeholder="Enter table no."
            onChangeText={(text) => {
              setTableNo(text);
              onTableChange(text);
            }}
            value={tableNo}
            addStyle={{
              fontSize: 12,
              textAlign: "center",
              width: "50%",
            }}
            keyboardType="number-pad"
            autoFocus={true}
          />
        </View>
      </View>
    </View>
  );
};

export default function OrderConfirmModal(props) {
  const {
    user,
    products,
    selectedProducts,
    is_qless,
    onEditPress,
    showEditDeliveryDetails,
    onNameChange,
    onTableChange,
    onCancel,
    onPressPay,
    orderedItemsTotal,
    //
    paymentMethod,
    setPaymentMethod,
  } = props;

  const [name, setName] = useState(user?.displayName || "");
  const [tableNo, setTableNo] = useState(user?.tableNo || "");

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View
        style={{
          width: "100%",
          height: dimensions.height - 30,
          alignItems: "center",
          padding: 20,
          //  height: dimensions.height / 2,
          borderRadius: 20,
          backgroundColor: "white",
        }}
      >
        <Typo2Way
          subTitle="Confirm"
          title="Order Items"
          style={{ alignSelf: "flex-start" }}
        />
        <Divider />
        <ScrollView style={{ width: "100%", marginTop: 10, paddingBottom: 10 }}>
          {selectedProducts.map((item, i) => (
            <View
              key={i}
              style={{
                width: "95%",
                marginVertical: 5,
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <TextSemi>{item.qty} x </TextSemi>
              <TextSemi
                numberOfLines={1}
                style={{ color: "gray", maxWidth: "70%" }}
              >
                {item.name}
              </TextSemi>
              <TextSemi style={{ width: "30%", textAlign: "right" }}>
                R {item.price.toFixed(2)}
              </TextSemi>
            </View>
          ))}
        </ScrollView>

        <View
          style={{
            width: "95%",
            paddingTop: 10,
            borderTopWidth: 1,
            borderTopColor: colors.shade1,
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <TextBS style={{ fontSize: 18 }}>Total</TextBS>
          <TextBS style={{ fontSize: 18 }}>
            R {orderedItemsTotal.toFixed(2)}
          </TextBS>
        </View>

        {/* <LocationDeliveryDetails
          {...props}
          name={name}
          setName={setName}
          tableNo={tableNo}
          setTableNo={setTableNo}
        /> */}

        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            marginTop: 15,
            padding: 15,
            backgroundColor: colors.bg2,
            borderRadius: 15,
          }}
        >
          <PayOption
            name="snapscan"
            size={payIconSize}
            selected={paymentMethod === "snapscan"}
            onPress={() => {
              setPaymentMethod("snapscan");
            }}
          />

          <PayOption
            name="card"
            size={payIconSize}
            selected={paymentMethod === "card"}
            onPress={() => {
              setPaymentMethod("card");
            }}
          />
          {/* {isCashless && (
            <PayOption
              name="airdosh"
              size={payIconSize}
              selected={paymentMethod === "airdosh"}
              onPress={() => setPaymentMethod("airdosh")}
            />
          )} */}
        </View>

        <View
          style={{
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginVertical: 15,
          }}
        >
          <ButtonX
            type="outline"
            title="Back"
            addStyle={{
              flex: 1,
              borderRadius: 10,
              // margin: 10,
              marginRight: 10,
              backgroundColor: colors.shade1,
            }}
            titleStyle={{
              fontWeight: "bold",
              fontSize: 16,
              color: colors.primaryText,
            }}
            onPress={onCancel}
          />
          <ButtonX
            type="solid"
            // loading={loading}
            disabled={selectedProducts?.length === 0 || !is_qless}
            title="Pay"
            addStyle={{
              flex: 1,
              borderRadius: 10,
              // margin: 10,
              backgroundColor:
                !selectedProducts?.length || !is_qless
                  ? colors.shade1
                  : colors.highlight,
            }}
            titleStyle={{
              fontWeight: "bold",
              fontSize: 16,
              color:
                !selectedProducts?.length || !is_qless
                  ? colors.shade2
                  : "white",
            }}
            onPress={onPressPay}
          />
        </View>
        <Text
          style={{ fontSize: 12, color: colors.alert, textAlign: "center" }}
        >
          {`Placing order will charge your bank account with R ${orderedItemsTotal.toFixed(
            2
          )}`}
        </Text>
      </View>
    </SafeAreaView>
  );
}
