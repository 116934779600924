import React, { useState, useRef, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { View, ScrollView, TouchableOpacity, StyleSheet } from 'react-native';
import * as Animatable from 'react-native-animatable';
import {
  Feather,
  Ionicons,
  MaterialCommunityIcons,
  SimpleLineIcons,
  AntDesign,
} from '@expo/vector-icons';
import Checkbox from 'expo-checkbox';
import { dimensions, colors, typography } from '../../utils/theme';
import ButtonX from '../../components/buttons/ButtonX';
import TextBold from '../../components/Text/TextBold';
import TextBoldSmall from '../../components/Text/TextBoldSmall';
import TextSemi from '../../components/Text/TextS';
import TextLight from '../../components/Text/TextLight';
import { Card, Typo2Way, Typo3Way, Chip } from '../../components/common';
import { DateTimeIcon } from '../../components/social';
import PayOption from '../../components/PaymentOptions';
import IncrementButton from '../../components/buttons/ButtonInc';
import RemoveButton from '../../components/buttons/ButtonRemove';
import SignInWidget from '../../components/SignInWidget';
import { SET_SHOW_PRODUCT_VARIABLES_MODAL } from '../../actions/types';

import {
  updateSubCart,
  setShowSpinner,
  showAuthModal,
  showCart,
  showAltPaymentOptions,
  onYocoPay,
  onYocoCheckout,
  onPayZero,
  onZapperPay,
  onSnapScanPay,
  onAirDoshPay,
  onHappyPay,
  setShowTermsModal,
} from '../../actions';

import { animate } from '../../helpers';

const formatter = (number) =>
  number.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

const Divider = (props) => {
  return (
    <View
      style={[
        {
          height: 0.5,
          width: '95%',
          alignSelf: 'center',
          backgroundColor: colors.shade1,
          marginVertical: 15,
        },
        props.style,
      ]}
    />
  );
};

const CartLineItem = (props) => {
  const { prod, discount } = props;

  return (
    <View
      style={{
        width: '100%',
        flexDirection: 'row',
        alignItems: 'start',
        justifyContent: 'space-between',
        paddingHorizontal: 5,
      }}
    >
      <View style={{ flex: 1 }}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'start',
            maxWidth: '90%',
          }}
        >
          <View
            style={{
              paddingVertical: 5,
              paddingHorizontal: 10,
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 5,
              backgroundColor: colors.shade1,
              marginRight: 10,
              marginTop: 8,
            }}
          >
            <TextSemi style={{ color: colors.textSolid }}>{prod.qty}</TextSemi>
          </View>
          <View style={{ maxWidth: '90%' }}>
            <TextSemi
              numberOfLines={1}
              ellipsizeMode="tail"
              style={{ fontSize: 10, color: colors.shade4 }}
            >
              {prod.event_title}
            </TextSemi>
            <TextSemi numberOfLines={2} ellipsizeMode="tail" style={{ color: colors.textSolid }}>
              {prod.prod_title}
            </TextSemi>
            {!!prod.enable_phases && (
              <Chip
                style={{ marginTop: 5 }}
                outline
                title={prod.price_phases[prod.current_ticket_phase]?.title || ''}
              />
            )}
            {(!!prod.selected_date_slot || !!prod.selected_time_slot) && (
              <View style={{ marginVertical: 3 }}>
                {/* {!!prod.selected_date_slot && (
                  <DateTimeIcon
                    showDate
                    oneColor={{ color: colors.highlight }}
                    item={{ date: prod.selected_date_slot }}
                  />
                )} */}
                {!!prod.selected_time_slot && (
                  <DateTimeIcon
                    showTimes
                    oneColor={{ color: colors.highlight }}
                    item={{ date: prod.selected_time_slot }}
                  />
                )}
                {!!prod.selected_slot_seat && (
                  <DateTimeIcon
                    showSeat
                    oneColor={{ color: colors.highlight }}
                    item={{
                      seat: prod.selected_slot_seat.seat_id.charAt(
                        prod.selected_slot_seat.seat_id.length - 1,
                      ),
                    }}
                  />
                )}
              </View>
            )}
          </View>
        </View>
      </View>

      <View
        style={{
          width: '25%',
          marginTop: 15,
        }}
      >
        {!!discount && !!prod.strikePrice && (
          <TextSemi
            style={{
              textAlign: 'right',
              color: colors.shade4,
              textDecorationLine: 'line-through',
              textDecorationStyle: 'solid',
              textDecorationColor: colors.alert,
            }}
          >
            R {formatter(prod.qty * prod.strikePrice)}
          </TextSemi>
        )}
        <TextSemi style={{ textAlign: 'right', color: colors.textSolid }}>
          R {formatter(prod.qty * prod.price)}
        </TextSemi>
      </View>
    </View>
  );
};

const CheckoutFees = (props) => {
  const { fees, discount, voucher, cardProcessFee, doshBookingFee } = props;
  const agentUid = props?.route?.params?.uid || 'no agent';

  return (
    <View style={{ width: '45%' }}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingVertical: 5,
        }}
      >
        <TextBoldSmall style={[{ color: colors.balance, fontSize: 12 }]}>Bank fee</TextBoldSmall>
        <TextBold style={{ color: colors.textSolid, fontSize: 12 }}>
          R{' '}
          {cardProcessFee.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
        </TextBold>
      </View>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingVertical: 5,
        }}
      >
        <TextBoldSmall style={[{ color: colors.balance, fontSize: 12 }]}>Booking fee</TextBoldSmall>
        <TextBold style={{ color: colors.textSolid, fontSize: 12 }}>
          R{' '}
          {doshBookingFee.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
        </TextBold>
      </View>

      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingVertical: 5,
        }}
      >
        <TextBoldSmall style={[{ color: colors.balance, fontSize: 12 }]}>VAT incl.</TextBoldSmall>
        <TextBold style={{ color: colors.textSolid, fontSize: 12 }}>15 %</TextBold>
      </View>
      {!!discount && !!props.discountIsApplied && (
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingVertical: 5,
          }}
        >
          <TextBoldSmall style={[{ color: colors.alert, fontSize: 12 }]}>
            Discounted @
          </TextBoldSmall>
          <TextBold style={{ color: colors.alert, fontSize: 12 }}>
            - {discount.percentage * 100} %
          </TextBold>
        </View>
      )}
      {!!voucher && (
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingVertical: 5,
          }}
        >
          <TextBoldSmall style={[{ color: colors.alert, fontSize: 12 }]}>
            Discounted @
          </TextBoldSmall>
          <TextBold style={{ color: colors.alert, fontSize: 12 }}>- R {voucher.fixed}</TextBold>
        </View>
      )}
    </View>
  );
};

function Cart(props) {
  const dispatch = useDispatch();
  const productList = useSelector((state) => state.store.productList);

  //
  const { modalStyle, childStyle, onModalCancel, setShowTermsModal } = props;
  const {
    user,
    spinnerBalance,
    inviteVerified,
    isCashless,
    subCart,
    subCartTotal,
    subCartFees,
    discount,
    rewardsAgent,
    eventFocus,
  } = props;

  const [alertMessage, setAlertMesage] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('card');
  const [signInNotice, setSignInNotice] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const isDiscountedToZero =
    (!!subCart.filter((prod) => !!prod.discount).length && subCartTotal + subCartFees === 0) ||
    false;

  useEffect(() => {
    // this removes a product from the subcart is the product becomes SOLD OUT
    const subCartIds = subCart.map(({ prod_id }) => prod_id);
    const soldOutProds = productList
      .filter(({ pax, paxSold }) => paxSold >= pax)
      .map(({ prod_id }) => prod_id);

    if (!!soldOutProds?.length && !!subCartIds?.length) {
      subCartIds.forEach((prodId) => {
        if (soldOutProds.includes(prodId)) {
          const prodIndex = subCart.findIndex(({ prod_id }) => prod_id === prodId);
          subCart.splice(prodIndex, 1);
          props.updateSubCart(subCart);
        }
      });
    }
  }, [productList]);

  useEffect(() => {
    // loadJS('https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js');
    loadJS('https://js.yoco.com/sdk/v1/yoco-sdk-web.js');
  }, []);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }

  useEffect(() => {
    user && setSignInNotice(false);
  }, [user]);

  const inc = (prod, index) => {
    const { pax, paxSold, max_per_cart } = subCart[index];
    const { qty } = prod;

    const isWithinMax = max_per_cart ? qty <= max_per_cart - 1 : true;
    const existInWallet = props.userPassesIds.filter(
      (prod_id) => prod_id === subCart[index].prod_id,
    ).length;
    const isMaxInWallet = max_per_cart && qty + existInWallet >= max_per_cart;
    const isMax = max_per_cart && qty === max_per_cart;

    const countInStock = pax - paxSold;

    const inc = parseInt(subCart[index].qty) + 1;

    if (qty < countInStock && isWithinMax) {
      subCart[index].qty = inc;
      props.updateSubCart(subCart);
      setAlertMesage(null);
    }
    existInWallet !== 0 && isMaxInWallet
      ? setAlertMesage({
          index,
          message: `You have already purchased the maximum quantity of  ${
            max_per_cart && max_per_cart
          }.`,
        })
      : isMax
      ? setAlertMesage({
          index,
          message: `Max quantity of  ${max_per_cart && max_per_cart}  selected.`,
        })
      : setAlertMesage(null);
  };
  const dec = (prod, index) => {
    setAlertMesage(null);
    prod.qty >= 2
      ? ((subCart[index].qty = subCart[index].qty - 1), props.updateSubCart(subCart))
      : setAlertMesage({
          index,
          message: 'Press the Remove button to discard item',
        });
  };

  function loadJS(src) {
    const ref = window.document.getElementsByTagName('script')[0];
    const script = window.document.createElement('script');
    script.src = src;
    script.async = true;
    ref.parentNode.insertBefore(script, ref);
  }

  const payIconSize = 7;
  return (
    <Animatable.View animation="zoomInUp" duration={200} style={{ alignItems: 'center' }}>
      {!!modalStyle && (
        <Ionicons
          name="ios-close"
          size={50}
          color={colors.highlight}
          style={{
            zIndex: 10,
            position: 'absolute',
            right: 20,
            paddingHorizontal: 15,
            backgroundColor: colors.bg1,
            borderRadius: 15,
          }}
          onPress={() => onModalCancel()}
        />
      )}
      <ScrollView style={{ flex: 1 }}>
        <Typo3Way
          subTitle="Checkout"
          title="My Cart"
          body="Review the cart. Add or Remove items before Checkout."
          style={{ width: '70%' }}
        />

        <View style={{ alignItems: 'center' }}>
          {subCart.map((prod, index) => (
            <Animatable.View key={index} animation="zoomIn" duration={300}>
              <View
                style={{
                  width: dimensions.width - 60,
                  alignItems: 'center',
                }}
              >
                <Divider />
                <CartLineItem {...props} prod={prod} discount={discount} />

                {prod.prod_type === 'time_slot_pass' && (
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <TouchableOpacity
                      onPress={() =>
                        dispatch({
                          type: SET_SHOW_PRODUCT_VARIABLES_MODAL,
                          bool: true,
                          subCartProdToEdit: {
                            ...prod,
                            subCartItemIndex: index,
                          },
                          productVariableModalEditMode: true,
                        })
                      }
                      style={[styles.editButton, { alignItems: 'center' }]}
                    >
                      <Feather
                        name="edit"
                        size={18}
                        color={colors.highlight}
                        style={{ marginRight: 10 }}
                      />
                      <TextSemi style={{ color: colors.highlight }}>Edit</TextSemi>
                    </TouchableOpacity>
                    <RemoveButton
                      onRemove={() => {
                        // splice this item here, this is an exception as there will be duplicate ids, and no other way to find the correct index of item
                        subCart.splice(index, 1);
                        props.updateSubCart(subCart);
                        setAlertMesage(null);
                      }}
                    />
                  </View>
                )}

                {prod.prod_type !== 'time_slot_pass' && (
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      // marginHorizontal: 15,
                    }}
                  >
                    <IncrementButton
                      qty={prod.qty}
                      onIncPress={() => inc(prod, index)}
                      onDecPress={() => dec(prod, index)}
                    />
                    <RemoveButton
                      onRemove={() => {
                        // splice this item here, this is an exception as there will be duplicate ids, and no other way to find the correct index of item
                        subCart.splice(index, 1);
                        props.updateSubCart(subCart);
                        setAlertMesage(null);
                      }}
                    />
                  </View>
                )}

                {!!alertMessage && alertMessage.index === index && (
                  <Animatable.View animation="bounceIn">
                    <TextSemi
                      style={{
                        fontSize: 12,
                        color: colors.alert,
                        marginBottom: 10,
                      }}
                    >
                      {alertMessage.message}
                    </TextSemi>
                  </Animatable.View>
                )}
              </View>
            </Animatable.View>
          ))}
        </View>
        <Card
          style={{
            flex: 1,
            alignItems: 'flex-start',
            alignSelf: 'center',
            backgroundColor: colors.bg2,
            borderWidth: 1,
            borderColor: colors.shade1,
            width: dimensions.width - 60,
            shadowColor: 'rgba(0,0,0,0.0)', //cancel shadow
          }}
          childStyle={{
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: dimensions.width - 60,
          }}
        >
          <CheckoutFees fees={subCartFees} {...props} />
          <View>
            <View style={{ alignItems: 'flex-end' }}>
              <TextBoldSmall style={{ color: colors.balance, marginLeft: 1 }}>
                Your Total
              </TextBoldSmall>

              <TextBold
                style={[
                  {
                    color: colors.textSolid,
                    fontSize: 24,
                  },
                ]}
              >
                {/* <span style={{ color: colors.shade4, fontSize: 20 }}>R </span> */}
                {`R ${subCartTotal.toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}`}
              </TextBold>
            </View>
            {!!rewardsAgent && (
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <AntDesign
                  name="adduser"
                  size={20}
                  style={{ margin: 7, marginTop: 8 }}
                  color={colors.highlight}
                />

                <Chip
                  solid
                  title={rewardsAgent}
                  containerStyle={{
                    margin: 5,
                    backgroundColor: colors.notice,
                  }}
                />
              </View>
            )}
          </View>
        </Card>

        <Divider />
        <Typo3Way
          subTitle="Single Click Checkout"
          title="Payment Options"
          body="Checkout and pay using Credit Card or the balance
            in your wallet."
        />

        <View style={{ marginVertical: 15 }}>
          {!!user &&
            paymentMethod === 'airdosh' &&
            user.credit + user.pendingCredit >= subCartTotal && (
              <TextBoldSmall style={{ color: colors.notice }}>
                You have R {formatter(user.credit + user.pendingCredit)} in your wallet. Once you
                PAY NOW, your balance will be R{' '}
                {formatter(user.credit + user.pendingCredit - subCartTotal)}{' '}
              </TextBoldSmall>
            )}
          {!!user &&
            paymentMethod === 'airdosh' &&
            user.credit + user.pendingCredit !== 0 &&
            user.credit + user.pendingCredit < subCartTotal && (
              <TextBoldSmall style={{ color: colors.notice }}>
                You have R {formatter(user.credit + user.pendingCredit)} in your wallet. You may use
                your wallet credit towards the purchase, then pay the balance of R{' '}
                {formatter(subCartTotal - (user.credit + user.pendingCredit))} using one of the
                alternative payment options
              </TextBoldSmall>
            )}
          {!!user && paymentMethod === 'airdosh' && user.credit + user.pendingCredit === 0 && (
            <TextBoldSmall style={{ color: colors.notice }}>
              You currently have R {formatter(user.credit + user.pendingCredit)} in your wallet.
              When you have left over funds from a previous cashless event, you may use it towards
              your next ticket purchase.
            </TextBoldSmall>
          )}
          {paymentMethod === 'snapscan' ? (
            <TextBoldSmall style={{ color: colors.notice }}>
              You will be redirected to the Snap Scan app, your account details and the amount of R{' '}
              {formatter(subCartTotal)} will be pre-filled. Simply press PAY and return to this page
              to find your purchased items.
            </TextBoldSmall>
          ) : paymentMethod === 'zapper' ? (
            <TextBoldSmall style={{ color: colors.notice }}>
              You will be redirected to the Zapper app, your account details and the amount of R{' '}
              {formatter(subCartTotal)} will be pre-filled. Simply press PAY and return to this page
              to find your purchased items.
            </TextBoldSmall>
          ) : paymentMethod === 'card' && !isDiscountedToZero ? (
            <TextBoldSmall style={{ color: colors.notice }}>
              Use your bank card for the once-off payment of R {formatter(subCartTotal)}. Upon
              successful payment, return to this page to find your purchased items.
            </TextBoldSmall>
          ) : paymentMethod === 'happy' && !isDiscountedToZero ? (
            <TextBoldSmall style={{ color: colors.notice }}>
              Buy Now, Pay Later from Happy Pay.
            </TextBoldSmall>
          ) : paymentMethod === 'card' && isDiscountedToZero ? (
            <TextBoldSmall style={{ color: colors.notice }}>
              The amount due is R 0.00 and you are not required to make a payment. You may simply
              check out to receive the tickets in your cart.
            </TextBoldSmall>
          ) : null}
        </View>

        {!isDiscountedToZero && (
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              marginTop: 15,
              padding: 15,
              backgroundColor: colors.bg2,
              borderRadius: 15,
            }}
          >
            {/* <PayOption
              name="snapscan"
              size={payIconSize}
              selected={paymentMethod === 'snapscan'}
              onPress={() => {
                animate();
                setPaymentMethod('snapscan');
              }}
            /> */}

            <PayOption
              name="card"
              size={payIconSize}
              selected={paymentMethod === 'card'}
              onPress={() => {
                animate();
                setPaymentMethod('card');
              }}
            />
            {eventFocus?.isHappyPayEnabled && (
              <PayOption
                name="happy"
                size={payIconSize}
                selected={paymentMethod === 'happy'}
                onPress={() => setPaymentMethod('happy')}
              />
            )}
            {isCashless && (
              <PayOption
                name="airdosh"
                size={payIconSize}
                selected={paymentMethod === 'airdosh'}
                onPress={() => setPaymentMethod('airdosh')}
              />
            )}
          </View>
        )}

        {!inviteVerified && (
          <TextBoldSmall style={styles.alertText}>
            Checkout available to invited guests only.
          </TextBoldSmall>
        )}

        <View>
          {!termsAccepted && (
            <TextBoldSmall style={styles.alertText}>Accept the terms of purchase.</TextBoldSmall>
          )}
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <TouchableOpacity
              onPress={() => {
                animate();
                setTermsAccepted(!termsAccepted);
                !user && setSignInNotice(true);
              }}
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Checkbox
                style={{ margin: 10 }}
                value={termsAccepted}
                color={termsAccepted ? colors.highlight : undefined}
              />
              {!!termsAccepted ? (
                <TextLight style={{ color: colors.textSolid }}>
                  Accepted terms of purchase
                </TextLight>
              ) : (
                <TextLight style={{ color: colors.textSolid }}>
                  I accept terms of purchase
                </TextLight>
              )}
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                animate();
                setTermsAccepted(!termsAccepted);
                !user && setSignInNotice(true);
              }}
              style={{ padding: 10 }}
            >
              <MaterialCommunityIcons
                onPress={() => props.setShowTermsModal(true)}
                name="information-outline"
                size={25}
                color={colors.highlight}
              />
            </TouchableOpacity>
          </View>
        </View>

        {!!signInNotice && <SignInWidget {...props} />}
        {!!user &&
          paymentMethod === 'airdosh' &&
          user.credit + user.pendingCredit < subCartTotal && (
            <ButtonX
              type="solid"
              title="Split Payment"
              addStyle={{ marginTop: 20 }}
              disabled={
                !inviteVerified || !termsAccepted || !user || user.credit + user.pendingCredit === 0
              }
              onPress={() => {
                user && props.showCart(false);
                props.showAltPaymentOptions(true);
              }}
            />
          )}
        {!!user && paymentMethod !== 'airdosh' && !isDiscountedToZero && (
          <ButtonX
            type="solid"
            title="Pay Now"
            loading={spinnerBalance}
            addStyle={{ marginTop: 20 }}
            disabled={!inviteVerified || !termsAccepted || !user}
            onPress={() => {
              !user && setSignInNotice(true);
              user && props.showCart(false);
              user &&
                paymentMethod === 'card' &&
                props.onYocoCheckout({
                  ...props, // rewardUid is on props
                  paymentType: 'buyTicket',
                  amount: subCartTotal,
                  subCart,
                });
              // props.onYocoPay({
              //   ...props.event,
              //   ...props.route?.params?.item,
              //   ...props,
              //   user,
              //   paymentType: 'buyTicket',
              //   amount: subCartTotal,
              //   subCart,
              // });
              user &&
                paymentMethod === 'zapper' &&
                props.onZapperPay({
                  ...props,
                  user,
                  orgId,
                  event_id,
                  paymentType: 'buyTicket',
                  amount: subCartTotal,
                  subCart,
                });
              user &&
                paymentMethod === 'snapscan' &&
                props.onSnapScanPay({
                  ...props,
                  user,
                  // orgId,
                  // event_id,
                  paymentType: 'buyTicket',
                  amount: subCartTotal,
                  subCart,
                });
              user &&
                paymentMethod === 'happy' &&
                props.onHappyPay({
                  ...props.event,
                  ...props.route?.params?.item,
                  ...props,
                  user,
                  paymentType: 'buyTicket',
                  amount: subCartTotal,
                  subCart,
                });
              user &&
                paymentMethod === 'airdosh' &&
                user.credit + user.pendingCredit - subCartTotal >= 0 &&
                props.onAirDoshPay({
                  ...props,
                  user,
                  paymentType: 'buyTicket',
                  amount: subCartTotal,
                  subCart,
                });
            }}
          />
        )}
        {!!isDiscountedToZero && (
          <ButtonX
            type="solid"
            title="Check out @ R0.00"
            loading={spinnerBalance}
            addStyle={{ marginTop: 20 }}
            disabled={!inviteVerified || !termsAccepted || !user}
            onPress={() =>
              props.onPayZero({
                paymentType: 'buyTicket',
                user,
                amount: 0,
                subCart,
              })
            }
          />
        )}
      </ScrollView>
      {/* </Card> */}
    </Animatable.View>
  );
}
{
  /* {
    <PayOption
    name="zapper"
    size={payIconSize}
    selected={paymentMethod === "zapper"}
    onPress={() => {
      animate();
      setPaymentMethod("zapper");
    }}
  />} */
}

const calcBookingFee = (prod) => {
  const { bank_comms, engage_credit, ticket_comms } = prod.booking_fees;

  const isProductFixedFee = prod.booking_fee > 0;

  const customerBankFee =
    (bank_comms.paid_by === 'customer' &&
      (bank_comms.calc_type === 'percentage' ? bank_comms.rate * prod.price : bank_comms.rate)) ||
    0;
  const customerTicketFee =
    (ticket_comms.paid_by === 'customer' &&
      (ticket_comms.calc_type === 'percentage'
        ? ticket_comms.rate * prod.price
        : ticket_comms.rate)) ||
    0;
  const customerEngageFee =
    (engage_credit.paid_by === 'customer' &&
      (engage_credit.calc_type === 'percentage'
        ? engage_credit.rate * prod.price
        : engage_credit.rate)) ||
    0;

  return isProductFixedFee
    ? prod.booking_fee + customerBankFee
    : customerBankFee + customerTicketFee + customerEngageFee || 0;
};

const calcCardFee = (prod) => {
  const { bank_comms } = prod.booking_fees;

  const customerBankFee =
    (bank_comms.paid_by === 'customer' &&
      (bank_comms.calc_type === 'percentage' ? bank_comms.rate * prod.price : bank_comms.rate)) ||
    0;

  return customerBankFee || 0;
};

const calcDoshFee = (prod) => {
  const { engage_credit, ticket_comms } = prod.booking_fees;

  const customerTicketFee =
    (ticket_comms.paid_by === 'customer' &&
      (ticket_comms.calc_type === 'percentage'
        ? ticket_comms.rate * prod.price
        : ticket_comms.rate)) ||
    0;

  const customerEngageFee =
    (engage_credit.paid_by === 'customer' &&
      (engage_credit.calc_type === 'percentage'
        ? engage_credit.rate * prod.price
        : engage_credit.rate)) ||
    0;

  return (
    (prod.booking_fee > 0
      ? prod.booking_fee + customerEngageFee
      : customerTicketFee + customerEngageFee) || 0
  );
};

const mapStateToProps = (state) => {
  const { rewardsAgent } = state.auth;
  const { user, userPasses, spinnerBalance } = state.users;

  const { inviteVerified, isCashless, eventFocus } = state.config;
  const { subCart, product } = state.store;

  const subCartFeeSum =
    subCart.map((prod) => prod.qty * calcBookingFee(prod)).reduce((acc, i) => acc + i, 0) || 0;

  const subCartSum = subCart.map((prod) => prod.qty * prod.price).reduce((acc, i) => acc + i, 0);

  const cardProcessFeeSum =
    subCart.map((prod) => prod.qty * calcCardFee(prod)).reduce((acc, i) => acc + i, 0) || 0;

  const doshBookingFeeSum =
    subCart.map((prod) => prod.qty * calcDoshFee(prod)).reduce((acc, i) => acc + i, 0) || 0;

  const discountIsApplied = !!subCart.filter((prod) => !!prod.discount).length;

  return {
    user,
    rewardsAgent,
    spinnerBalance,
    inviteVerified,
    isCashless,
    eventFocus,
    event: product,
    subCart,
    subCartTotal: subCartSum + subCartFeeSum < 0 ? 0 : subCartSum + subCartFeeSum,
    subCartFees: subCartFeeSum < 0 ? 0 : subCartFeeSum,
    cardProcessFee: cardProcessFeeSum < 0 ? 0 : cardProcessFeeSum,
    doshBookingFee: doshBookingFeeSum < 0 ? 0 : doshBookingFeeSum,
    discountIsApplied,
    userPassesIds: userPasses.map((pass) => pass.prod_id),
  };
};

export default connect(mapStateToProps, {
  updateSubCart,
  setShowSpinner,
  showAuthModal,
  showCart,
  showAltPaymentOptions,
  onYocoPay,
  onYocoCheckout,
  onPayZero,
  onZapperPay,
  onSnapScanPay,
  onAirDoshPay,
  onHappyPay,
  setShowTermsModal,
})(Cart);

const imageHieght = dimensions.width - 200;

const styles = StyleSheet.create({
  incConcontainer: {
    height: 50,
    width: dimensions.width / 2.8,
    borderRadius: 25,
    margin: 10,
    backgroundColor: 'white',
    flexDirection: 'row',
    justifyContent: 'center',
    //
    shadowColor: 'rgba(0,0,0,0.2)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 15,
  },
  editButton: {
    height: 50,
    width: dimensions.width / 2.8,
    borderRadius: 10,
    margin: 10,
    backgroundColor: colors.bg2,
    flexDirection: 'row',
    justifyContent: 'center',
    //
    borderWidth: 1,
    borderColor: colors.shade1,
    shadowColor: 'rgba(0,0,0,0)', // cancel shadow
    // shadowColor: "rgba(0,0,0,0.2)",
    // shadowOffset: { width: 0, height: 0 },
    // shadowOpacity: 0.5,
    // shadowRadius: 15,
  },
  container: {
    //height: 550,
    width: dimensions.width - 30,
    borderRadius: 15,
    // margin: 20,
    marginVertical: 5,
    backgroundColor: 'white',
    //
    shadowColor: 'rgba(0,0,0,0.4)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 20,
  },
  image: {
    width: '100%',
    height: imageHieght,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    //flex: 1,
    //backgroundColor: "yellow",
    //height: dimensions.width - 40,
    //justifyContent: "flex-end",
    // borderRadius: 15
  },
  gradient: {
    flex: 1,
    position: 'absolute',
    top: 0,
    width: dimensions.width - 30,
    height: imageHieght,
    justifyContent: 'flex-end',
    padding: 20,
    // borderBottomLeftRadius: 9,
    // borderBottomRightRadius: 9
  },
  alertText: {
    textAlign: 'center',
    fontSize: 14,
    color: colors.alert,
    margin: 10,
    marginBottom: 0, // cancels out
  },
});

/* <TouchableOpacity
onPress={() => {
  // splice this item here, this is an exception as there will be duplicate ids, and no other way to find the correct index of item
  subCart.splice(index, 1);
  props.updateSubCart(subCart);
  setAlertMesage(null);
}}
style={{
  alignItems: "center",
  justifyContent: "center",
  padding: 5,
  borderWidth: 1,
  borderRadius: 5,
  borderColor: colors.alert,
}}
>
<Feather
  name="trash-2"
  size={18}
  color={colors.alert}
  style={{}}
/>
<TextSemi style={{ fontSize: 8, color: colors.alert }}>
  Remove
</TextSemi>
</TouchableOpacity>
<TouchableOpacity
onPress={() => {
  // splice this item here, this is an exception as there will be duplicate ids, and no other way to find the correct index of item
  subCart.splice(index, 1);
  props.updateSubCart(subCart);
  setAlertMesage(null);
}}
style={{
  alignItems: "center",
  justifyContent: "center",
  padding: 5,
  borderWidth: 1,
  borderRadius: 5,
  borderColor: colors.alert,
}}
>
<Feather
  name="trash-2"
  size={18}
  color={colors.alert}
  style={{}}
/>
<TextSemi style={{ fontSize: 8, color: colors.alert }}>
  Remove
</TextSemi>
</TouchableOpacity> */
