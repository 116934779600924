import * as React from 'react';

export const navigationRef = React.createRef();

export const isReadyRef = React.createRef();

export function navigate(name, params) {
  // navigationRef.current?.navigate(name, params);
  if (isReadyRef?.current && navigationRef?.current) {
    // Perform navigation if the app has mounted
    !!navigationRef.current.getRootState() && navigationRef?.current?.navigate(name, params);
  } else {
    // You can decide what to do if the app hasn't mounted
    // You can ignore this, or add these actions to a queue you can call later
  }
}

// RESET not yet used anywhere
export function reset(name, params) {
  navigationRef.current?.reset(name, params);
}

export function goBack() {
  navigationRef.current?.goBack();
}

// add other navigation functions that you need and export them
