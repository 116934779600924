import { firestore } from '../../firebase/config';
import * as RootNavigation from '../RootNavigation.js';

import { LOGGING, FETCH_BAR_ITEMS_SUCCESS, SET_GLOBAL_LOADING } from './types';
import generateID from '../utils/firebaseDocIDutil';

const productsRef = (orgId, event_id) =>
  firestore
    .collection('entities')
    .doc(orgId)
    .collection('events')
    .doc(event_id)
    .collection('cashless')
    .doc('cashless')
    .collection('products');

export const fetchBarProducts = () => (dispatch, getState) => {
  const { orgId, event_id } = getState().config.eventFocus;
  productsRef(orgId, event_id)
    .get()
    .then(function (querySnapshot) {
      var barItems = [];
      querySnapshot.forEach(function (doc) {
        barItems.push(doc.data());
      });
      dispatch({
        type: FETCH_BAR_ITEMS_SUCCESS,
        payload: barItems,
      });
      dispatch({
        type: SET_GLOBAL_LOADING,
        bool: false,
      });
    })
    .catch(function (error) {
      console.error(error);
    });
};
