import React from 'react';
// import { PageContext } from "../../AppContextProvider";
import { Text, View } from 'react-native';
import { AppLoading } from 'expo';
import { useFonts } from 'expo-font';

export default (props) => {
  let [fontsLoaded] = useFonts({
    'inter-semiB': require('../../assets/fonts/Montserrat-SemiBold.ttf'),
  });

  const { style, ellipsizeMode, numberOfLines } = props;

  if (!fontsLoaded) {
    return <AppLoading />;
  } else {
    return (
      <Text
        numberOfLines={numberOfLines}
        ellipsizeMode="tail"
        style={[{ fontFamily: 'inter-semiB', fontSize: 16, letterSpacing: -0.3 }, style]}
      >
        {props.children}
      </Text>
    );
  }
};
