import React, { useState, useRef } from 'react';
import { StyleSheet, View, Pressable, TextInput } from 'react-native';
import TextBS from '../../components/Text/TextBoldSmall';
import { Picker } from '@react-native-picker/picker';
import { AntDesign, MaterialIcons } from '@expo/vector-icons';
import { colors, dimensions, typography } from '../../utils/theme';
import { color } from 'react-native-reanimated';
import { browserName, isChrome, isFirefox, isSafari } from 'react-device-detect';

const MatPicker = (props) => {
  const { items, label, addStyle, onSelect, selectedItem } = props;
  const [focus, setFocus] = useState(false);
  const pickerRef = useRef(null);
  return (
    <View
      style={[
        styles.inputContainer,
        {
          borderColor: focus ? colors.highlight : colors.shade3,
          borderWidth: focus ? 2 : 1,
          width: '100%',
        },
        addStyle,
      ]}
    >
      <View
        style={{
          position: 'absolute',
          top: -10,
          left: 15,
          backgroundColor: colors.bg,
          borderRadius: 5,
        }}
      >
        <View
          style={{
            flex: 1,
            borderRadius: 5,
            backgroundColor: colors.bg1,
          }}
        >
          <TextBS
            style={{
              color: colors.balance,
              fontSize: 14,
              paddingHorizontal: 5,
            }}
          >
            {label}
          </TextBS>
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          marginVertical: 10,
          width: '110%',
          alignItems: 'center',
          // backgroundColor: "yellow",
        }}
      >
        <Picker
          ref={pickerRef}
          selectedValue={selectedItem}
          style={{
            height: 50,
            width: '100%',
            borderWidth: 0,
            backgroundColor: 'transparent',
            color: colors.textSolid,
          }}
          onValueChange={(itemValue, itemIndex) => onSelect(itemValue)}
        >
          {!!items.length &&
            items.map((item, index) => (
              <Picker.Item
                enabled={item.is_available}
                // itemStyle={{ textDecorationLine: "line-through" }}
                label={item.prod_title}
                value={item.id || item.slot_id || item.seat_id}
                key={index}
              />
            ))}
        </Picker>
        {isSafari && (
          <Pressable onPress={() => pickerRef.current.focus()}>
            <MaterialIcons name="keyboard-arrow-down" size={18} color="black" style={{}} />
          </Pressable>
        )}
      </View>
    </View>
  );
};

export default MatPicker;

const styles = StyleSheet.create({
  inputContainer: {
    //width: "95%",
    height: 50,
    paddingHorizontal: 30,
    //  marginHorizontal: 20,
    marginVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
  },
  inputStyle: {
    //backgroundColor: "yellow",
    // fontFamily: typography.primary,
    fontSize: 16,
    outlineStyle: 'none',
    outlineWidth: 0,
    outlineColor: 'transparent',
    color: colors.primaryText,
    borderBottomWidth: 1,
    borderBottomColor: colors.shade1,
    //paddingHorizontal: 10
  },
  inputTextFocussed: {
    fontSize: 16,
    outlineStyle: 'none',
    outlineWidth: 0,
    outlineColor: 'transparent',
    color: colors.primaryText,
    //paddingHorizontal: 10,
    borderBottomColor: colors.highlight,
    borderBottomWidth: 2,
  },
});
