import { LOGGING, DASHBOARD_FETCH_SUCCESS } from "../actions/types";

const INITIAL_STATE = {
  loading: false,
  dashboard: {},
};

const dashReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGGING:
      return { ...state, loading: true };
    case DASHBOARD_FETCH_SUCCESS:
      return { ...state, dashboard: action.payload };
    default:
      return state;
  }
};

export default dashReducer;
