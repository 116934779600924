import { firebase, firestore, functions, auth } from '../../../firebase/config';
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { View, StyleSheet, Linking } from 'react-native';
import { Fontisto } from '@expo/vector-icons';
import Text from '../../components/Text/TextLight';
import TextBS from '../../components/Text/TextBoldSmall';
import ButtonX from '../../components/buttons/ButtonX';
import ModalHeader1 from '../../components/headers/Header1';
import { dimensions, colors, typography } from '../../utils/theme';
import { sendEmailLink } from '../../actions';

function hideEmail(addy) {
  var hiddenEmail = '';
  for (var i = 0; i < addy.length; i++) {
    if (i > 2 && i < addy.indexOf('@')) {
      hiddenEmail += '*';
    } else {
      hiddenEmail += addy[i];
    }
  }
  return hiddenEmail;
}
function hidePhone(addy) {
  var hiddenPhone = '';
  for (var i = 0; i < addy.length; i++) {
    if (i > 3 && i < addy.length - 4) {
      hiddenPhone += '*';
    } else {
      hiddenPhone += addy[i];
    }
  }
  return hiddenPhone;
}

function ManageDualAccount(props) {
  const { navigation } = props;
  const { user, associated, existingAuthType, accounts, phoneNumber, email } = props.route.params;

  const [loading, setLoading] = useState(false);
  const [sendSuccess, setSendSucces] = useState(false);

  const methodToLink = existingAuthType === 'email' ? 'phone' : 'email';
  const credToLink = existingAuthType === 'email' ? phoneNumber : email;
  const credToSignIn =
    existingAuthType === 'email'
      ? hideEmail(accounts[0].email)
      : hidePhone(accounts[0].cellphoneNumber);

  const hotline = '17242133657';
  const helpLink = `https://wa.me/${hotline}/?text=${encodeURI('I need assistance with..')}`;

  const handleManageDualAccount = () => {
    existingAuthType === 'phone' &&
      navigation.navigate('Auth', {
        screen: 'EnterOTPForm',
        params: {
          isSignUp: false,
          existingAuthType: 'phone',
          phoneNumber: accounts[0].cellphoneNumber,
          email: accounts[0].email,
        },
      });

    existingAuthType === 'email' &&
      navigation.navigate('Auth', {
        screen: 'SignInEmail',
        params: {
          isSignUp: false,
          existingAuthType: 'email',
          phoneNumber: accounts[0].cellphoneNumber,
          email: accounts[0].email,
        },
      });
  };

  const Notice = () => {
    return (
      <View>
        <Text>
          It appears that <Text style={{ fontWeight: 'bold' }}>{credToLink}</Text> is associated
          with an existing account. We suggest signing in with{' '}
          <Text style={{ fontWeight: 'bold' }}>{credToSignIn}</Text>, and follow the prompts to
          enable <Text style={{ fontWeight: 'bold' }}>{methodToLink}</Text> as a sign in method. In
          future you may use either email or phone to sign in.
          {/* <Text style={{ fontWeight: "bold" }}>
            Menu{"->"} Settings{"->"} Link Additional Sign In Methods.
          </Text>{" "} */}
        </Text>
      </View>
    );
  };

  if (sendSuccess) return <SuccessMessage {...props} />;

  return (
    <View style={styles.container}>
      <ModalHeader1
        headerColor={colors.warning}
        hideClose={true}
        heading="Manage dual account"
        subHeading="Account Admin"
        // description="It appears that phone/email is associated with an existing account. We suggest signing in with phone/email, and only then add additional sign in methods sign as phone/email."
      />
      <Notice />
      <View style={{ padding: 10 }} />
      <ButtonX
        type="solid"
        loading={loading}
        title={`Sign in with  ${credToSignIn}`}
        addStyle={{
          borderRadius: 10,
          marginVertical: 20,
          marginTop: 10,
          width: '100%',
        }}
        titleStyle={{ fontWeight: 'bold', fontSize: 16 }}
        onPress={handleManageDualAccount}
      />

      <View style={{ padding: 20 }} />

      <Text style={{ color: colors.shade4 }}>
        If you no longer have access to the contact details above, reach out to an AirDosh agent
        below.
      </Text>
      <View style={{ padding: 10 }} />
      <ButtonX
        type="outline"
        loading={loading}
        title="Chat to an Agent"
        addStyle={{
          borderRadius: 10,
          marginVertical: 20,
          marginTop: 10,
          width: '100%',
        }}
        titleStyle={{ fontSize: 16 }}
        icon={<Fontisto name="whatsapp" size={22} color={colors.balance} />}
        onPress={() => Linking.openURL(helpLink)}
      />
    </View>
  );
}

export default connect(null, { sendEmailLink })(ManageDualAccount);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    alignItems: 'center',
    backgroundColor: 'white',
  },
});
