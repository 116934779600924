import { firebase } from '../../../firebase/config';
import React, { useState, useRef, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';
import {
  View,
  StyleSheet,
  ScrollView,
  Platform,
  LayoutAnimation,
  UIManager,
  // Image,
  // TouchableOpacity,
  // ActivityIndicator,
} from 'react-native';
import * as Animatable from 'react-native-animatable';
import * as RootNavigation from '../../RootNavigation.js';
import { Ionicons, Fontisto, Entypo } from '@expo/vector-icons';
import { dimensions, colors } from '../../utils/theme';
import { Card, Typo3Way } from '../../components/common';
import { AvatarStack } from '../../components/social';
import QRCode from 'react-native-qrcode-svg';
import ButtonX from '../../components/buttons/ButtonX';
import TextBold from '../../components/Text/TextBold';
import TextBoldSmall from '../../components/Text/TextBoldSmall';
import ShareIcons from '../../components/social/ShareIcons';
import SignInWidget from '../../components/SignInWidget';
import { createDynamicLink, updateCustomer } from '../../actions';
import { Easing } from 'react-native-reanimated';

function Campaign(props) {
  const { eventFocus } = useSelector((state) => state.config);
  const { user } = useSelector((state) => state.users);

  const { location } = eventFocus;

  const [loading, setLoading] = useState(false);
  const [showQR, setShowQR] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [signInNotice, setSignInNotice] = useState(false);
  // const [isAgent, setAgent] = useState(false);
  // const [dynamicLink, setDynamicLink] = useState(null);

  const { _id } = user;
  const { event_description, titleImage, influencers, attendanceCount } = eventFocus; // from {...props} - Parent component - EventDetail.js
  const { orgId, event_id } = eventFocus;

  const qrRef = useRef();
  const viewRef = useRef();

  useEffect(() => {
    loadJS();
  }, []);

  function loadJS() {
    window.fbAsyncInit = function () {
      FB.init({
        appId: '3674052485983914',
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v10.0',
      });

      FB.AppEvents.logPageView();
    };
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');

    // window.fbAsyncInit = function() {
    //   FB.init({
    //     appId: "3674052485983914",
    //     autoLogAppEvents: true,
    //     xfbml: true,
    //     version: "v10.0"
    //   });
    // };
  }

  const isAgent = (reward) => {
    return (
      user?.rewardLinks &&
      Array.isArray(user?.rewardLinks) &&
      user?.rewardLinks.map((rew) => rew.reward_id).includes(reward.reward_id)
    );
  };

  const ComposeReward = ({ reward }) => {
    const { reward_currency, reward_value, reward_ceiling, reward_goal, product } = reward;
    const isCash = reward_currency === 'cash';
    const currency = (reward_value) =>
      reward_currency === 'cash' ? 'cash' : reward_value > 1 ? 'points' : 'x point';

    const isProduct = (prod) =>
      prod?.prod_title == 'All' ? 'Any product' : 'every ' + prod?.prod_title;
    return (
      <View>
        <TextBoldSmall style={styles.textBlack}>
          {`Earn ${isCash ? 'R' : ''}${reward_value} ${currency(reward_value)} for ${isProduct(
            product,
          )} purchased using your unique sharable link. ${
            reward_ceiling > 0
              ? 'When you reach ' + reward_goal + ' points, you receive your reward.'
              : ''
          }
          `}
        </TextBoldSmall>
      </View>
    );
  };

  const dynamicLink = (reward) => {
    const validLink = user.rewardLinks.filter((rew) => rew.reward_id === reward.reward_id)[0];
    return validLink?.shareLink || null;
  };

  const onShowQr = () => {
    setShowShare(false);
    // user && setShowQR(!showQR);
    // setShowShare(false);
    !user && setSignInNotice(true);
    // !showQR &&
    qrRef.current
      .animate(
        {
          0: { maxHeight: '0%' },
          1: { maxHeight: '100%' },
        },
        1000,
      )
      .then(setShowQR(false));
  };

  const onShowShareButtons = () => {
    // setShowShare(false);
    // user && setShowQR(!showQR);
    // setShowShare(false);
    // !user && setSignInNotice(true);
    // user && setShowShare(!showShare);
  };

  const startSharing = async ({ reward_id, reward_title }) => {
    setLoading(true);

    const link = await props.createDynamicLink({
      orgId,
      event_id,
      uid: user._id,
      event_title,
      event_description,
      titleImage,
    });
    const { shortLink } = link; // JSON.parse(link.data.join(""));

    props
      .updateCustomer({
        ...user,
        rewardLinks: firebase.firestore.FieldValue.arrayUnion({
          shareLink: shortLink,
          orgId,
          event_id,
          reward_id,
          reward_title,
        }),
      })
      .then(() => setLoading(false));
  };

  const scrollToExposeBottom = () => {
    //const blue = document.getElementById('blue');
    // let position = viewRef?.current?.getInnerViewNode();
    let position = viewRef?.current?.getBoundingClientRect();
    // scrolls to 20px above element
    window.scrollTo(position?.left, position?.top + window.scrollY - 80);
  };

  const fadeIn = {
    from: {
      height: '0%',
      scale: 0,
    },
    to: {
      height: '100%',
      scale: 1,
    },
  };

  const fadeOut = {
    from: {
      height: '100%',
      scale: 1,
    },
    to: {
      height: '0%',
      scale: 0,
    },
  };

  const ShareQrCode = ({ reward, showQR }) => {
    return (
      showQR &&
      !!dynamicLink(reward) && (
        <Animatable.View
          animation={showQR ? fadeIn : fadeOut}
          duration={500}
          style={styles.qrContainer}
        >
          <QRCode value={dynamicLink(reward)} size={200} />
        </Animatable.View>
      )
    );
  };

  //
  return (
    <View ref={viewRef}>
      <ScrollView
        horizontal
        pagingEnabled
        centerContent
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{
          marginVertical: -10,
          paddingVertical: 20,
          // paddingHorizontal: 20,
          // borderRadius: dimensions.width / 3.6,
          // height: dimensions.width > 375 ? 210 : dimensions.width / 1.8,
          width: dimensions.width, // > 375 ? 210 : dimensions.width / 1.8,
        }}
      >
        <React.Fragment>
          {eventFocus?.rewards?.map((reward, i) => (
            <Card key={i} style={{ flex: 1, marginHorizontal: 20 }}>
              <Typo3Way
                subTitle="Rewards"
                title="Campaign"
                body={`${reward.reward_description}`}
                //"Earn cash in your wallet, promote this event. Campaigns offer a reward for every referral, that results in the purchase of a ticket."
              />

              <View style={{ marginVertical: 10 }} />

              <ComposeReward reward={reward} />

              {/* {!isAgent(reward) && (
            <ButtonX
              type="solid"
              title="Start sharing"
              loading={loading}
              addStyle={{ marginTop: 20 }}
              onPress={() => startSharing(reward)}
            />
          )} */}
              <ShareQrCode showQR={showQR} reward={reward} />

              {/* {showQR && !!dynamicLink(reward) && (
            <View style={styles.qrContainer}>
              <QRCode value={dynamicLink(reward)} size={200} />
            </View>
          )} */}
              {!!signInNotice && (
                <SignInWidget
                  {...props}
                  altTitle="In order to start sharing and earning rewards, sign in using One Time Pin. NO full signup required."
                />
              )}
              {/* {isAgent(reward) && ( */}
              <View>
                <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                  <ButtonX
                    type="solid"
                    icon={<Entypo name="facebook" size={24} color="white" />}
                    addStyle={{
                      marginTop: 20,
                      width: '50%',
                      marginHorizontal: 5,
                    }}
                    onPress={() => {
                      !user && setSignInNotice(true);
                      user &&
                        FB.ui(
                          {
                            display: 'popup',
                            method: 'share',
                            href: dynamicLink(reward),
                            redirect_uri: dynamicLink(reward),
                            // href: `https://wallet.airdosh.co.za/${id}/${user._id}`,
                            // redirect_uri: `https://wallet.airdosh.co.za/${id}/${user._id}`,
                            hashtag: `#${'influencer'}`,
                            quote: `You are invited to ${event_title}. Click the link below to purchase tickets! ${dynamicLink(
                              reward,
                            )}`,
                            // quote: `You are invited to ${event_title}. Click the link below to purchase tickets! ${`https://wallet.airdosh.co.za/${id}/${user._id}`}`,
                          },
                          function (response) {},
                        );
                    }}
                  />

                  <ButtonX
                    type="solid"
                    addStyle={{
                      marginTop: 20,
                      width: '20%',
                      marginHorizontal: 5,
                    }}
                    onPress={() => {
                      setShowQR(!showQR);
                      setShowShare(false);
                      scrollToExposeBottom();
                      //onShowQr}
                    }}
                    icon={<Fontisto name="qrcode" size={18} color="white" />}
                  />

                  <ButtonX
                    type="solid"
                    addStyle={{
                      marginTop: 20,
                      width: '20%',
                      marginHorizontal: 5,
                    }}
                    onPress={() => {
                      setShowShare(!showShare);
                      setShowQR(false);
                      // user && setShowQR(!showQR);
                      !user && setSignInNotice(true);
                      // user && setShowShare(!showShare);
                    }}
                    icon={<Fontisto name="share" size={18} color="white" />}
                  />
                </View>
                {!!showShare && <ShareIcons {...props} dynamicLink={dynamicLink(reward)} />}
              </View>
              {/* )} */}
            </Card>
          ))}
        </React.Fragment>
      </ScrollView>
    </View>
  );
}
{
  /* {  <View
  style={{
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 10
  }}
>
  <Ionicons
    name="ios-heart"
    size={16}
    color={colors.pay}
    style={{ marginRight: 5 }}
  />
  <TextBoldSmall>Influencers</TextBoldSmall>
</View>
<AvatarStack
  influencers={influencers}
  attendanceCount={attendanceCount}
/>} */
}

export default connect(null, { createDynamicLink, updateCustomer })(Campaign);

const imageHieght = dimensions.width - 200;

const styles = StyleSheet.create({
  container: {
    //height: 550,
    width: dimensions.width - 30,
    borderRadius: 15,
    margin: 20,
    marginVertical: 10,
    backgroundColor: 'white',
    //
    shadowColor: 'rgba(0,0,0,0.4)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 20,
    maxHeight: '0%',
  },
  image: {
    width: '100%',
    height: imageHieght,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    //flex: 1,
    //backgroundColor: "yellow",
    //height: dimensions.width - 40,
    //justifyContent: "flex-end",
    // borderRadius: 15
  },
  gradient: {
    flex: 1,
    position: 'absolute',
    top: 0,
    width: dimensions.width - 30,
    height: imageHieght,
    justifyContent: 'flex-end',
    padding: 20,
    // borderBottomLeftRadius: 9,
    // borderBottomRightRadius: 9
  },
  qrContainer: {
    flex: 1,
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 15,
    margin: 20,
    borderRadius: 10,
  },
  textBlack: {
    fontSize: 12,
    color: colors.black,
  },
  textLight: {
    fontSize: 12,
    color: colors.shade3,
  },
});
