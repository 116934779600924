import React from "react";
import { Text, View } from "react-native";
import { AppLoading } from "expo";
import { useFonts } from "expo-font";

export default (props) => {
  let [fontsLoaded] = useFonts({
    //"roboto-black": require("../../assets/fonts/Roboto-Black.ttf")
    "inter-black": require("../../assets/fonts/Montserrat-Black.ttf"),
  });
  const { numberOfLines, ellipsizeMode } = props;

  if (!fontsLoaded) {
    return <AppLoading />;
  } else {
    return (
      <Text
        style={[
          { fontFamily: "inter-black", fontSize: 30, letterSpacing: -0.5 },
          props.style,
        ]}
        numberOfLines={numberOfLines}
        ellipsizeMode="tail"
      >
        {props.children}
      </Text>
    );
  }
};

// const uText = props => {
//   let [fontsLoaded] = useFonts({
//     "uber-bold": require("../../assets/fonts/UberMoveBold.otf"),
//     "uber-medium": require("../../assets/fonts/UberMoveMedium.otf"),
//     "kumbhsans-bold": require("../../assets/fonts/KumbhSans-Bold.ttf"),
//     "kumbhsans-light": require("../../assets/fonts/KumbhSans-Light.ttf"),
//     "kumbhsans-regular": require("../../assets/fonts/KumbhSans-Regular.ttf")
//   });
//   //
//   // if (!fontsLoaded) {
//   //   return <AppLoading />;
//   // } else {
//   return (
//     <View>
//       <Text style={[{ fontFamily: "kumbhsans-bold" }, props.style]}>
//         {props.children}
//       </Text>
//     </View>
//   );
//   //  }
// };
//
// export { uText };
