import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import {
  View,
  Image,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
} from "react-native";
import { dimensions, colors, typography } from "../../utils/theme";
import { MaterialIcons } from "@expo/vector-icons";
import TextSemi from "../Text/TextS";

export default function RemoveButton({ onAdd, disabled }) {
  return (
    <TouchableOpacity
      disabled={disabled}
      onPress={onAdd}
      style={[
        styles.incContainer,
        { alignItems: "center" },
        disabled && { backgroundColor: "gray" },
      ]}
    >
      <MaterialIcons
        name="add-shopping-cart"
        size={18}
        color="white"
        style={{ marginRight: 10 }}
      />
      <TextSemi style={{ color: "white" }}>Add</TextSemi>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  incContainer: {
    height: 50,
    width: dimensions.width / 2.8,
    borderRadius: 10,
    margin: 10,
    backgroundColor: colors.balance,
    flexDirection: "row",
    justifyContent: "center",
    //
    shadowColor: "rgba(0,0,0,0.2)",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 15,
  },
});
