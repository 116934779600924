import React, { useState, useRef, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { View, SectionList, FlatList, TouchableOpacity, StyleSheet } from 'react-native';
import moment from 'moment';
import Modal from 'modal-enhanced-react-native-web';
import ButtonX from '../../components/buttons/ButtonX';
import Text from '../../components/Text/TextLight';
import TextBold from '../../components/Text/TextBold';
import TextBoldSmall from '../../components/Text/TextBoldSmall';
import Expand from 'react-expand-animated';
import { MaterialIcons } from '@expo/vector-icons';
import { Card, Typo2Way, Chip } from '../../components/common';
import Picker from '../../components/common/Picker';
import NumberPicker from '../../components/common/PickerNumber';
import ProductItem from './ProductItem';
import { dimensions, colors } from '../../utils/theme';

import {} from '../../actions';

const Section = (props) => {
  const { eventFocus } = useSelector((state) => state.config);
  const { current_ticket_phase, ticket_phases } = eventFocus;
  const {
    selected,
    index,
    onSelect,
    setShowProductVariableModal,
    subCartProdToEdit,
    setSubCartProdToEdit,
  } = props;
  const { title, data } = props.sectionData;

  const listRef = useRef(null);

  const scrollTopOfList = () => {
    setTimeout(() => {
      let position = listRef?.current?.getBoundingClientRect();
      window.scrollTo(position?.left, position?.top + (scrollY - 150));
    }, 400);
  };

  // const renderTicketPrice = (item) => {
  //   if (item.enable_phases) {
  //     return item.price_phases[current_ticket_phase]?.price || 0;
  //   } else {
  //     return item.price.toLocaleString(undefined, {
  //       minimumFractionDigits: 0,
  //       maximumFractionDigits: 0,
  //     });
  //   }
  // };

  const phaseTicketPrice = ({ enable_phases, price_phases, price }) => {
    if (enable_phases) {
      return price_phases[current_ticket_phase]?.price || 0;
    } else {
      return price;
    }
  };

  return (
    <View ref={listRef}>
      <TouchableOpacity
        onPress={() => {
          onSelect();
          scrollTopOfList();
        }}
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: 10,
          marginVertical: 5,
          marginHorizontal: -10,
          backgroundColor: colors.shade1,
          borderRadius: 5,
        }}
      >
        <TextBoldSmall style={{ color: colors.shade5 }}>{title}</TextBoldSmall>
        <Text style={{ color: colors.highlight }}>{selected === index ? '-' : 'Show'}</Text>
      </TouchableOpacity>

      <Expand duration={400} open={selected === index}>
        <FlatList
          data={data}
          renderItem={({ item }) => <ProductItem {...props} item={item} />}
          keyExtractor={(item) => item.id}
        />
      </Expand>
    </View>
  );
};

function productCarousel(props) {
  const { productSectionList } = props;

  const [selected, setSelected] = useState(0);
  const [showProductVariableModal, setShowProductVariableModal] = useState(false);
  const [subCartProdToEdit, setSubCartProdToEdit] = useState(null);

  return (
    <Card style={{ marginTop: 0 }}>
      <Typo2Way style={{ marginBottom: 10 }} subTitle="Get Passes!" title="Ticket Options" />
      {!!props.voucher && (
        <Chip
          style={{ marginBottom: 5, backgroundColor: colors.pay }}
          solid
          title={'R ' + props.voucher?.fixed + ' discount applied to purchase total'}
        />
      )}
      {!!props.discount && (
        <Chip
          style={{ marginBottom: 5, backgroundColor: colors.pay }}
          outline
          title={props.discount?.percentage * 100 + ' % discount applied!'}
        />
      )}

      {productSectionList.map((section, i) => {
        return (
          <Section
            {...props}
            {...{
              setShowProductVariableModal,
              subCartProdToEdit,
              setSubCartProdToEdit,
            }}
            key={i}
            sectionData={section}
            index={i}
            selected={selected}
            onSelect={() => setSelected(i)}
          />
        );
      })}
    </Card>
  );
}

export default connect(null, {})(productCarousel);

const styles = StyleSheet.create({
  incContainer: {
    // height: 50,
    width: 40,
    borderRadius: 10,
    marginTop: 10,
    padding: 10,
    backgroundColor: colors.balance,
    flexDirection: 'row',
    justifyContent: 'center',
    //
    shadowColor: 'rgba(0,0,0,0.2)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 15,
  },
  chipStyle: {
    fontSize: 12,
    paddingVertical: 2,
    paddingHorizontal: 10,
    borderRadius: 20,
    color: 'white',
  },
});
