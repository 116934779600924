import React from "react";
// import { PageContext } from "../../AppContextProvider";
import { Text, View } from "react-native";
import { AppLoading } from "expo";
import { useFonts } from "expo-font";

export default (props) => {
  let [fontsLoaded] = useFonts({
    "open-sans-reg": require("../../assets/fonts/Montserrat-Medium.ttf"),
  });

  if (!fontsLoaded) {
    return <AppLoading />;
  } else {
    return (
      <Text
        numberOfLines={props.numberOfLines}
        ellipsizeMode={props.ellipsizeMode}
        style={[{ fontFamily: "open-sans-reg", fontSize: 14 }, props.style]}
      >
        {props.children}
      </Text>
    );
  }
};

// const uText = props => {
//   let [fontsLoaded] = useFonts({
//     "uber-bold": require("../../assets/fonts/UberMoveBold.otf"),
//     "uber-medium": require("../../assets/fonts/UberMoveMedium.otf"),
//     "kumbhsans-bold": require("../../assets/fonts/KumbhSans-Bold.ttf"),
//     "kumbhsans-light": require("../../assets/fonts/KumbhSans-Light.ttf"),
//     "kumbhsans-regular": require("../../assets/fonts/KumbhSans-Regular.ttf")
//   });
//   //
//   // if (!fontsLoaded) {
//   //   return <AppLoading />;
//   // } else {
//   return (
//     <View>
//       <Text style={[{ fontFamily: "kumbhsans-bold" }, props.style]}>
//         {props.children}
//       </Text>
//     </View>
//   );
//   //  }
// };
//
// export { uText };
