import { firebase, firestore } from '../../firebase/config';
import * as RootNavigation from '../RootNavigation.js';
import {
  LOGGING,
  FOUND_USER,
  FETCH_USERS_SUCCESS,
  UPDATE_PRODUCTS_BY_USER,
  USER_PASSES_UPDATED,
  PARSED_CELLNO_SUCCESS,
} from './types';
import generateID from '../utils/firebaseDocIDutil';

const now = new Date();

const userRef = firestore.collection('customers');
const cashoutRef = firestore.collection('cashless_refunds');

const compsRef = (orgId, event_id, cellphoneNumber) =>
  firestore
    .collection('entities')
    .doc(orgId)
    .collection('events')
    .doc(event_id)
    .collection('comps')
    .where('phone', '==', cellphoneNumber)
    .get();

const userWalletRef = (userId) =>
  firestore.collection('customers').doc(userId).collection('wallet');
const userLogRef = (userId) => firestore.collection('customers').doc(userId).collection('log');

export const insertTagId =
  ({ userId, val, tag_price }) =>
  (dispatch) => {
    // deduct an Amount for the linking of tags
    const updatedCredit = firebase.firestore.FieldValue.increment(-tag_price);
    userRef
      .doc(userId)
      .update({
        activeTagId: val,
        credit: updatedCredit,
      })
      .then(() => {
        dispatch(
          userLogAction({
            user: { _id: userId },
            action: {
              label: 'User linked an RFID tag to their wallet',
              notes: [
                `Amount paid for tag - R ${tag_price}`,
                `Tag ID - ${val}`,
                `User ID - ${userId}`,
              ],
            },
          }),
        );
      });
  };

export const isTagInUse = (tagId) => async (dispatch) => {
  const querySnapshot = await userRef
    .where('activeTagId', '==', tagId) //`${tagId}`
    .get();
  var customersWithThisTagId = [];
  querySnapshot.forEach(function (doc) {
    customersWithThisTagId.push(doc.data());
  });
  return customersWithThisTagId.length === 0 ? false : true;
};

// This function takes thse user credit and moves it to "pendingCredit", as ONLY pendingCredit gets applied on the next transaction, which is likely to be done on a new card
export function unlinkTag(user) {
  return userRef
    .doc(user._id)
    .update({
      activeTagId: '',
      pendingCredit: firebase.firestore.FieldValue.increment(user.credit),
      credit: 0,
    })
    .then(RootNavigation.navigate('Wallet'));
}

export const cashOut = (user) => async (dispatch) => {
  await cashoutRef.doc(user._id).set({
    ...user,
    requestAt: new Date(),
  });

  return userRef.doc(user._id).update({ credit: 0, pendingCredit: 0 });
};

export const updateNameAndTable = (user, name, tableNo) => (dispatch) => {
  return userRef.doc(user._id).update({ displayName: name, tableNo: tableNo });
};

export const updateCustomer = (user) => (dispatch) => {
  return userRef.doc(user._id).update(user);
};

export const fetchFullUser = (_id) => async (dispatch) => {
  const getCollection = async (collection) => {
    const colSnap = await userRef.doc(_id).collection(collection).get();
    var col = [];
    colSnap.forEach((doc) => {
      col.push(doc.data());
    });
    return col;
  };

  var userObj = await userRef
    .doc(_id)
    .get()
    .then((doc) => {
      return doc.exists && doc.data();
    });
  var wallet = await getCollection('wallet');
  var engage = await getCollection('log');
  var log = await getCollection('engage');

  return { userObj, wallet, engage, log };
};
// direct action call when user found
export const foundUser = (user) => ({
  type: FOUND_USER,
  payload: user,
});

export const parseCellNo = (cellNo, email) => ({
  type: PARSED_CELLNO_SUCCESS,
  cellNo,
  email,
});

export const createCustomer =
  ({ cellphoneNumber = null, email = null, uid, name, surname }) =>
  (dispatch) => {
    var customer = {
      _id: uid,
      cellphoneNumber,
      email,
      name,
      surname,
      activeTagId: '',
      credit: 0,
      createdAt: new Date(),
    };

    userRef
      .doc(customer._id)
      .set(customer)
      .then(() => {
        dispatch({
          type: FOUND_USER,
          payload: customer,
        });
      })
      .catch(() => console.log('This user already exisits', customer));

    // try {
    //   const compsSnap = await compsRef(orgId, event_id, cellphoneNumber);

    //   var compsSent = [];

    //   compsSnap.forEach((doc) => {
    //     const comp = doc.data();
    //     compsSent.push(comp);
    //   });

    //   compsSent.length &&
    //     (customer = {
    //       ...customer,
    //       name: compsSent[0]?.name || "",
    //       surname: compsSent[0]?.surname || "",
    //     });

    //   await userRef.doc(customer._id).set(customer);
    // } catch {
    //   await userRef.doc(customer._id).set(customer);
    // }

    // return customer;
  };

const fetchUserWallet = (dispatch, _id) => {
  var purchasedItems = [];
  userRef
    .doc(_id)
    .collection('wallet')
    .get()
    .then(function (querySnapshot) {
      querySnapshot.docChanges().forEach((change) => {
        purchasedItems.push(change.doc.data());
      });

      dispatch({ type: USER_PASSES_UPDATED, purchasedItems });
    });
};

export const listenUserWallet = (_id) => (dispatch) => {
  var purchasedItems = [];
  return (
    userRef
      .doc(_id)
      .collection('wallet')
      //.get()
      .onSnapshot(function (querySnapshot) {
        querySnapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            purchasedItems.push(change.doc.data());
          }
          if (change.type === 'modified') {
            const tIndex = purchasedItems.findIndex((i) => i.qr_id === change.doc.data().qr_id);
            purchasedItems.splice(tIndex, 1, change.doc.data());
          }
        });

        dispatch({ type: USER_PASSES_UPDATED, purchasedItems });
      })
  );
};

// replace the user "perchasedItems" with the updated "percahsedItems" that has the newly purchased ticket in it
//user.purchasedItems = purchasedItems;

// CUSTOMER PASSES   // CUSTOMER PASSES   // CUSTOMER PASSES   // CUSTOMER PASSES   // CUSTOMER PASSES   // CUSTOMER PASSES

export const updateUserPass =
  ({ user, qr_id, key, value }) =>
  (dispatch) => {
    return userWalletRef(user._id)
      .doc(qr_id)
      .update({ [key]: value });
  };

// CUSTOMER METRICS  // CUSTOMER METRICS  // CUSTOMER METRICS  // CUSTOMER METRICS  // CUSTOMER METRICS  // CUSTOMER METRICS

export const userLogAction =
  ({ user, action }) =>
  (dispatch) => {
    return userLogRef(user._id).add({ action, createdAt: now });
  };
