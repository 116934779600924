import { combineReducers, createStore, applyMiddleware } from "redux";
import ReduxThunk from "redux-thunk";
//import ProductsReducer from "./ProductsReducer";
import AuthReducer from "./AuthReducer";
import UsersReducer from "./UsersReducer";
import StoreReducer from "./StoreReducer";
import PassReducer from "./PassReducer";
import ConfigReducer from "./ConfigReducer";
import OrderToSeatReducer from "./OrderToSeatReducer";
import ModalReducer from "./ModalReducer";
import DashReducer from "./DashReducer";

const combinedReducers = combineReducers({
  auth: AuthReducer,
  users: UsersReducer,
  store: StoreReducer,
  pass: PassReducer,
  config: ConfigReducer,
  seatOrder: OrderToSeatReducer,
  modal: ModalReducer,
  dashboard: DashReducer,
  // social: SocialReducer,
  // user: UserReducer,
  // settings: SettingsReducer
});

const configureStore = () => {
  return createStore(combinedReducers, {}, applyMiddleware(ReduxThunk));
};

export default configureStore;
