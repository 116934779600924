import React, { useEffect, useRef } from 'react';
import {
  ActivityIndicator,
  //StyleSheet,
  View,
  Text,
  Image,
} from 'react-native';
import { colors, typography, dimensions, logo } from '../utils/theme';
import { SimpleLineIcons } from '@expo/vector-icons';
import Lottie from 'react-lottie';
// import animationData from "../assets/lottie/spin-split";
import animationData from '../assets/lottie/spin-colors-longer';

export default function () {
  const spinnerRef = useRef();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid',
    },
  };

  return (
    <View
      ref={spinnerRef}
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <Image
        style={{ width: dimensions.width / 2, height: 35, marginTop: 20 }}
        source={require('../assets/images/splash.png')}
        resizeMode="contain"
      />

      <Text style={{ color: colors.pay, fontWeight: 'bold', margin: 20 }}>Loading...</Text>
      <Lottie options={defaultOptions} height={60} width={100} />
      {/* <ActivityIndicator size="large" color={colors.pay} /> */}
    </View>
  );
}
//    <SimpleLineIcons name="wallet" size={38} color={colors.pay} />
//    <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
