import React, { useState, useRef, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import moment from 'moment';
import ButtonX from '../../components/buttons/ButtonX';
import Text from '../../components/Text/TextLight';
import TextBoldSmall from '../../components/Text/TextBoldSmall';
import { Card, Typo2Way, Chip } from '../../components/common';
import Picker from '../../components/common/Picker';
import NumberPicker from '../../components/common/PickerNumber';
import { dimensions, colors } from '../../utils/theme';
import { Feather } from '@expo/vector-icons';
//
import { addToCart, updateSubCart } from '../../actions';
import { SET_SHOW_PRODUCT_VARIABLES_MODAL } from '../../actions/types';

const timeSlots = (time_slots, price) => {
  const slotArray = [];

  if (!!time_slots) {
    time_slots
      .sort((a, b) => a.startTime.seconds - b.startTime.seconds)
      .forEach((slot, i) => {
        const { slot_id, startTime, endTime } = slot;
        !!slot?.slot_seats?.length &&
          slotArray.push({
            slot_id,
            is_available: !!slot.slot_seats.length, // check if there are seats available to the time slot  //slot.is_available,
            qty_available: slot.slot_seats.length,
            prod_title: formatStartEnd(startTime, endTime),
            // + (!!is_available ? ' @ R ' + price : ' - unavailable'),
            price,
          });
      });
  }
  return slotArray;
};

const dateSlots = (date_slots, price) => {
  const slotArray = [];

  !!date_slots &&
    date_slots.forEach((slot, i) => {
      slotArray.push({
        id: slot.slot_id,
        is_available: slot.is_available,
        prod_title: formatStartTime(slot.startTime), //+ (!slot.is_available ? ' - unavailable' : ''),
      });
    });
  return slotArray;
};

const TimeSlotOptions = (props) => {
  const {
    item,
    timeSlotTime,
    timeSlotsData,
    dateSlotsData,
    qty,
    setQty,
    setTimeSlotDay,
    setTimeSlotTime,
    productVariableModalEditMode,
  } = props;
  const { time_slots, date_slots } = item;

  const [selectedDateId, setSelectedDateId] = useState(0);
  const [selectedTimeId, setSelectedTimeId] = useState(0);

  useEffect(() => {
    !!date_slots && setTimeSlotDay(date_slots[0]);
    setTimeSlotTime(time_slots[0]);
  }, []);

  useEffect(() => {
    if (!!productVariableModalEditMode) {
      //  hydrate in edit mode
      setQty(item.qty);
      // setTimeSlotDay(item.selected_date_slot);
      setTimeSlotTime(item.selected_time_slot);

      // hydrate the "slot_id" used for the pickers
      // const hydrateDateIndex = date_slots.findIndex(
      //   (slot) => slot.slot_id === item.selected_date_slot.slot_id,
      // );
      // setSelectedDateId(date_slots[hydrateDateIndex].slot_id);
      //
      const hydrateTimeIndex = time_slots.findIndex(
        (slot) => slot.slot_id === item.selected_time_slot.slot_id,
      );
      setSelectedTimeId(time_slots[hydrateTimeIndex].slot_id);
    }
  }, [productVariableModalEditMode]);

  return (
    <View>
      {/* <Picker
        label="Select a Day"
        items={dateSlotsData}
        selectedItem={selectedDateId}
        onSelect={(id) => {
          setSelectedDateId(id);
          const slotIndex = date_slots.findIndex((slot) => slot.slot_id === id);
          setTimeSlotDay(date_slots[slotIndex]);
        }}
        addStyle={{ width: dimensions.width - 60 }}
      /> */}
      <View style={{ flexDirection: 'row', marginTop: 10 }}>
        {!productVariableModalEditMode && (
          <NumberPicker
            label="Seats"
            items={qtyAvails(timeSlotTime?.slot_seats?.length || 0)} //qty_available
            selectedItem={qty}
            onSelect={(val) => setQty(val)}
            addStyle={{
              // marginRight: productVariableModalEditMode ? 0 : 5,
              width: (dimensions.width - 60) * 0.3,
            }}
          />
        )}
        <Picker
          label="Select Time Slot"
          items={timeSlotsData}
          selectedItem={selectedTimeId}
          onSelect={(id) => {
            setSelectedTimeId(id);
            const slotIndex = time_slots.findIndex((slot) => slot.slot_id === id);
            setTimeSlotTime(time_slots[slotIndex]);
          }}
          addStyle={{
            marginLeft: productVariableModalEditMode ? 0 : 5,
            width: dimensions.width * (productVariableModalEditMode ? 0.9 : 0.65),
          }}
        />
      </View>
    </View>
  );
};

const Divider = () => {
  return (
    <View
      style={{
        height: 0.5,
        width: '95%',
        alignSelf: 'center',
        backgroundColor: colors.shade1,
        marginVertical: 10,
      }}
    />
  );
};

const formatStartEnd = (startTime, endTime) => {
  return `${moment(startTime.seconds * 1000).format('HH:mm')} - ${moment(
    endTime.seconds * 1000,
  ).format('HH:mm')}`;
};

const formatStartTime = (startTime) => {
  return moment(startTime?.seconds * 1000).format('dddd Do MMM YYYY');
};

const qtyAvails = (qty = 10) => {
  const qtyArray = [1];

  for (let i = 0; i < qty + 1; i++) {
    i !== 0 && !qtyArray.includes(i) && qtyArray.push(i);
  }

  return qtyArray;
};

export default function ProductItem(props) {
  const { item, discount, showProductOptions = false } = props;

  const {
    subCartItemIndex, // used to ensure the correct subCart item is updated in case of aditing
    //
    available,
    pax,
    paxSold,
    enable_phases,
    price_phases,
    price,
    time_slots,
    date_slots,
  } = item;
  const { eventFocus } = useSelector((state) => state.config);
  const { productVariableModalEditMode } = useSelector((state) => state.store);

  const { current_ticket_phase, ticket_phases } = eventFocus;
  //
  const dispatch = useDispatch();
  const { subCart } = useSelector((state) => state.store);
  //
  const [qty, setQty] = useState(1);
  const [timeSlotDay, setTimeSlotDay] = useState(null);
  const [timeSlotTime, setTimeSlotTime] = useState(null);

  const threshold = 20;
  const isSoldOut = available && paxSold >= pax; //pax !== 0 &&
  const isComingSoon = available && pax === 0;
  const isBelowThreshold = pax - paxSold < threshold;
  const countInStock = pax - paxSold < 0 ? 0 : pax - paxSold;
  const note =
    !isComingSoon && !isSoldOut && isBelowThreshold
      ? `${countInStock} left`
      : isSoldOut
      ? 'Sold out'
      : null;

  const phaseTicketPrice = () => {
    if (enable_phases) {
      return price_phases[current_ticket_phase]?.price || item.price;
    } else {
      return item.price;
    }
  };

  // const isDiscountValidForEvent = eventFocus?.event_id === discount?.event_id;

  // When the "+" button is pressed
  const onAddToSubCartPress = () => {
    if (
      item.prod_type === 'time_slot_pass' &&
      !!timeSlotTime &&
      !!timeSlotTime?.slot_seats?.length
    ) {
      for (let i = 0; i < qty; i++) {
        dispatch(
          addToCart({
            prod_id: item.id,
            qty: 1,
            discount,
            selected_time_slot: timeSlotTime,
            selected_slot_seat: timeSlotTime?.slot_seats[i],
            // selected_date_slot: timeSlotDay,
            seat_number: timeSlotTime?.slot_seats[i].seat_id.charAt(
              timeSlotTime?.slot_seats[i].seat_id.length - 1,
            ),
          }),
        );
        dispatch({
          type: SET_SHOW_PRODUCT_VARIABLES_MODAL,
          bool: false,
          subCartProdToEdit: null,
        });
        props.onProductAdded();
      }
    }
    // else if (
    //   !item.prod_type === 'time_slot_pass' &&
    //   !timeSlotTime &&
    //   !timeSlotTime?.slot_seats?.length
    // ) {
    //   console.log('Could not add tiem slot pass - TYPE: ', item.prod_type);
    // }
    if (item.prod_type !== 'time_slot_pass') {
      dispatch(
        addToCart({
          prod_id: item.id,
          qty: 1,
          discount,
        }),
      );
      dispatch({
        type: SET_SHOW_PRODUCT_VARIABLES_MODAL,
        bool: false,
        subCartProdToEdit: null,
      });
      props.onProductAdded();
      // !!process.env.APP_MANIFEST?.extra?.dosh_pixel_id &&

      try {
        if (eventFocus?.pixelsFacebook?.length) {
          eventFocus?.pixelsFacebook.forEach((pixel_id) =>
            window?.fbq('trackSingleCustom', pixel_id, 'AddToCart', {
              content_name: item.prod_title,
              content_category: eventFocus.event_title,
              currency: 'ZAR',
              value: item.price,
            }),
          );

          // window.fbq('trackCustom', 'AddToCart', { product: item.prod_title, price: item.price });
        }
        const dosh_pixel_id = process.env.APP_MANIFEST?.extra?.dosh_pixel_id;

        !!dosh_pixel_id &&
          window?.fbq('trackSingleCustom', dosh_pixel_id, 'AddToCart', {
            content_name: item.prod_title,
            content_category: eventFocus.event_title,
            currency: 'ZAR',
            value: item.price,
          });
      } catch (error) {
        console.error(error);
      }
    }

    // TODO fix the auto scroll
  };
  const onEditPress = () => {
    dispatch({
      type: SET_SHOW_PRODUCT_VARIABLES_MODAL,
      bool: true,
      subCartProdToEdit: item,
      productVariableModalEditMode: false,
    });
  };

  const onUpdateSubCart = () => {
    subCart[subCartItemIndex].qty = qty;
    subCart[subCartItemIndex].selected_time_slot = timeSlotTime;
    subCart[subCartItemIndex].selected_date_slot = timeSlotDay;

    dispatch(updateSubCart(subCart));
    dispatch({
      type: SET_SHOW_PRODUCT_VARIABLES_MODAL,
      bool: false,
      subCartProdToEdit: null,
    });
    props.onProductAdded();
  };

  return (
    <View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          paddingVertical: 10,
        }}
      >
        <View
          style={{
            paddingRight: 20,
            maxWidth: '72%',
          }}
        >
          <TextBoldSmall>{item.prod_title}</TextBoldSmall>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            {!!note && (
              <Text style={{ marginVertical: 5, marginRight: 5 }}>
                <Text
                  style={[
                    styles.chipStyle,
                    {
                      backgroundColor:
                        isSoldOut || (!isSoldOut && countInStock < 5)
                          ? colors.negative
                          : !isSoldOut && countInStock < 11
                          ? colors.warning
                          : colors.highlight,
                    },
                  ]}
                >
                  {item?.note}
                </Text>
              </Text>
            )}
            {!!enable_phases && (
              <Chip
                style={{ marginVertical: 5 }}
                // solid
                outline
                title={ticket_phases[current_ticket_phase]?.title || ''}
              />
            )}
          </View>

          <Text style={{ fontSize: 12, color: colors.shade5 }}>{item.prod_description}</Text>
        </View>
        <View style={{ width: '28%', alignItems: 'flex-end' }}>
          <Text>
            R{' '}
            {phaseTicketPrice().toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </Text>
          {/* <ButtonAdd {...props} {...{ isSoldOut }} /> */}
          {!showProductOptions && (
            <TouchableOpacity
              onPress={item.prod_type === 'time_slot_pass' ? onEditPress : onAddToSubCartPress}
              style={[styles.incContainer, isSoldOut && { backgroundColor: colors.shade3 }]}
              disabled={isSoldOut} //  || isComingSoon
            >
              <Feather name="plus" size={18} color="white" style={{}} />
            </TouchableOpacity>
          )}
        </View>
      </View>
      <Divider />
      {!!showProductOptions && (
        <View>
          <TimeSlotOptions
            {...props}
            {...{
              qty,
              setQty,
              timeSlotDay,
              setTimeSlotDay,
              timeSlotTime,
              setTimeSlotTime,
              productVariableModalEditMode,
            }}
            timeSlotsData={timeSlots(time_slots, price)}
            dateSlotsData={dateSlots(date_slots, price)}
          />
          {!!productVariableModalEditMode ? (
            <ButtonX
              type="solid"
              title="Update Cart"
              addStyle={{ marginTop: 20, backgroundColor: colors.pay, width: '100%' }}
              onPress={onUpdateSubCart}
            />
          ) : (
            <ButtonX
              type="solid"
              title="Add to Cart"
              addStyle={{ marginTop: 20, backgroundColor: colors.pay, width: '100%' }}
              onPress={onAddToSubCartPress}
            />
          )}
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  incContainer: {
    // height: 50,
    width: 40,
    borderRadius: 10,
    marginTop: 10,
    padding: 10,
    backgroundColor: colors.balance,
    flexDirection: 'row',
    justifyContent: 'center',
    //
    shadowColor: 'rgba(0,0,0,0.2)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 15,
  },
  chipStyle: {
    fontSize: 12,
    paddingVertical: 2,
    paddingHorizontal: 10,
    borderRadius: 20,
    color: 'white',
  },
});
