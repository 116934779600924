import { LayoutAnimation } from "react-native";
import { UIManager } from "react-native";

const CONFIG = {
  duration: 500,
  create: {
    type: LayoutAnimation.Types.linear,
    property: LayoutAnimation.Properties.scaleXY,
  },
  update: {
    type: LayoutAnimation.Types.easeInEaseOut,
  },
};

export function animate() {
  LayoutAnimation.configureNext(CONFIG);
}
