import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import {
  View,
  Text,
  Image,
  ImageBackground,
  TouchableOpacity,
  TouchableHighlight,
  StyleSheet,
  ActivityIndicator,
  Button,
} from 'react-native';
// import { Button } from "react-native-elements";
import ButtonX from '../../components/buttons/ButtonX';
import * as RootNavigation from '../../RootNavigation.js';
import { Fontisto, Feather, SimpleLineIcons } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { dimensions, colors, typography } from '../../utils/theme';
import { AvatarStack, DateTimeLocation } from '../../components/social';
import TextBold from '../../components/Text/TextBold';
import TextBoldSmall from '../../components/Text/TextBoldSmall';
import TextSemi from '../../components/Text/TextS';
import Lottie from 'react-lottie';
import animationData from '../../assets/lottie/skeleton-image3';

const day = (seconds) => moment(seconds * 1000).format('Do');

export default function (props) {
  const { onPress } = props;
  const {
    orgId,
    id, // event_id
    type,
    event_title,
    event_description,
    titleImage,
    date,
    location,
    price,
    influencers,
    attendanceCount,
  } = props.item;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <TouchableHighlight
      activeOpacity={0.8}
      underlayColor="transparent"
      onPress={onPress}
      style={styles.container}
    >
      <View>
        <View style={{ justifyContent: 'flex-end' }}>
          <View style={[styles.skeleton, { zIndex: 0 }]}>
            {/* <Lottie
              options={defaultOptions}
              height={"100%"}
              width={"100%"}
              speed={3}
            /> */}
            <ActivityIndicator size="large" color={colors.shade3} style={{ padding: 30 }} />
          </View>

          <Image
            source={{ uri: titleImage }}
            resizeMode="cover"
            style={[styles.image, { zIndex: 1 }]}
          />
          <LinearGradient
            colors={['transparent', 'rgba(0,0,0,0.3)', 'rgba(0,0,0,0.5)']}
            style={[styles.gradient, { zIndex: 2 }]}
          >
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <ButtonX
                title="Book Now"
                type="solid"
                onPress={onPress}
                addStyle={{
                  borderColor: colors.bg,
                  borderWidth: 2,
                  height: 45,
                  width: 130,
                  alignSelf: 'flex-end',
                  borderRadius: 30,
                  backgroundColor: 'rgba(0,0,0,0.3)', // "transparent", //colors.pay,
                  shadowColor: 'rgba(0,0,0,0.4)',
                  shadowOffset: { width: 0, height: 0 },
                  shadowOpacity: 0.5,
                  shadowRadius: 20,
                }}
                titleStyle={{ fontWeight: 'bold', fontSize: 14 }}
              />
            </View>
          </LinearGradient>
        </View>

        <View style={{ backgroundColor: 'transparent', padding: 15, zIndex: 0 }}>
          <TextBold style={{ color: colors.primaryText }}>{event_title}</TextBold>
          <TextSemi
            numberOfLines={3}
            ellipsizeMode="tail"
            style={{
              color: colors.shade4,
              fontSize: 14,
              marginTop: 10,
              marginBottom: 5,
            }}
          >
            {event_description}
          </TextSemi>
          <DateTimeLocation
            {...props}
            // showTimes={true}
            showTimes={day(date?.startTime?.seconds) === day(date?.endTime?.seconds)}
          />
        </View>
      </View>
    </TouchableHighlight>
  );
}

const imageHieght = dimensions.width - 100;

const styles = StyleSheet.create({
  container: {
    //height: 550,
    width: dimensions.width - 30,
    borderRadius: 15,
    margin: 20,
    marginVertical: 10,
    backgroundColor: 'white',
    //
    shadowColor: 'rgba(0,0,0,0.4)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 20,
  },
  image: {
    width: '100%',
    height: imageHieght,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    //flex: 1,
    //backgroundColor: "yellow",
    //height: dimensions.width - 40,
    // borderRadius: 15
  },
  skeleton: {
    width: '100%',
    height: imageHieght,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    position: 'absolute',
    bottom: 0,
    overflow: 'hidden',
  },
  gradient: {
    flex: 1,
    position: 'absolute',
    bottom: 0,
    width: dimensions.width - 30,
    // height: imageHieght,
    justifyContent: 'flex-end',
    padding: 20,
    // borderBottomLeftRadius: 9,
    // borderBottomRightRadius: 9
  },
});
