import {
  SET_GLOBAL_LOADING,
  UPDATE_THEME_IMAGE,
  UPDATE_REFUND_POLICY,
  SET_EVENT_FOCUS,
  UPDATE_EVENT_FOCUS,
} from "../actions/types";

export const setGlobalLoading = (bool) => ({
  type: SET_GLOBAL_LOADING,
  bool,
});

export const updateThemeImage = (url) => ({
  type: UPDATE_THEME_IMAGE,
  payload: url,
});

export const setRefundPolicy = (policy) => ({
  type: UPDATE_REFUND_POLICY,
  payload: policy,
});

export const setEventFocus = (payload) => ({
  type: SET_EVENT_FOCUS,
  payload,
});
export const updateEventFocus = (payload) => ({
  type: UPDATE_EVENT_FOCUS,
  payload,
});
