import React, { useEffect, useState } from 'react';
// import { connect } from "react-redux";
import { TouchableOpacity, StyleSheet, View } from 'react-native';
import { dimensions, colors, typography } from '../../utils/theme';
import { withTheme } from 'react-native-elements';
import { Card } from '../../components/common';
import TextBold from '../../components/Text/TextBold';
import TextBoldSmall from '../../components/Text/TextBoldSmall';
import ButtonX from '../../components/buttons/ButtonX';
import Text from '../../components/Text/TextLight';
import IncrementButton from '../../components/buttons/ButtonInc';
import { FontAwesome5 } from '@expo/vector-icons';

function BarListItem1(props) {
  const { theme } = props;
  //const { primary, secondary, secondary2, white, black } = theme.colors;
  const { item, selectedProducts, qty, addItem, removeItem } = props;
  //
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    selectedProducts?.length === 0 && setSelected(false);
    // console.log("BarListItem1 qty ", qty);
  }, [selectedProducts]);

  return (
    <TouchableOpacity
      style={[styles.productContainer, qty > 0 && { borderWidth: 3, borderColor: colors.pay }]}
    >
      <View>
        <TextBoldSmall style={{ marginBottom: 5, marginLeft: 5, color: 'gray' }}>
          {item.name}
        </TextBoldSmall>
        <TextBold style={{ fontSize: 22 }}> R {item.price}</TextBold>
      </View>

      {qty !== 0 ? (
        <IncrementButton
          style={{ margin: 0 }}
          qty={qty}
          onIncPress={() => addItem(item)}
          onDecPress={() => qty > 0 && removeItem(item)}
        />
      ) : (
        <ButtonX
          addStyle={{
            width: '25%',
            height: 50,
            backgroundColor: colors.pay,
          }}
          type="solid"
          title="Add"
          onPress={() => addItem(item)}
        />
      )}

      {selected && (
        <View style={styles.selectedProductCheck}>
          <FontAwesome5 name="check" size={16} color="white" />
        </View>
      )}
    </TouchableOpacity>
  );
}

export default BarListItem1;

const styles = StyleSheet.create({
  productContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: dimensions.width - 30,
    margin: 5,
    padding: 15,
    borderRadius: 15,
    borderWidth: 3,
    borderColor: 'transparent',
    shadowColor: 'rgba(0,0,0,0.2)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 20,
  },
  selectedProductCheck: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    height: 30,
    width: 30,
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: 10,
    borderBottomRightRadius: 10,
    backgroundColor: colors.pay,
  },
});
