import React from 'react';
import { connect } from 'react-redux';
import { View, useWindowDimensions } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import { FontAwesome } from '@expo/vector-icons';
import BackHeader from '../components/common/NavButtonBack';
import Settings from '../views/Settings/Settings';
import ProfileSettings from '../views/Settings/ProfileSettings';
import RecaptchaSettings from '../views/RecaptchaPage';
import ContactPage from '../views/Settings/ContactPage';
import TransferPage from '../views/Settings/TransferPage';
import ManageDualAccount from '../views/Settings/ManageDualAccount';
// /Users/jacodewet/Dev/cashless-wallet-mvp/src/views/Settings/ManageDualAccount.js

import { colors, typography, dimensions, logo } from '../utils/theme';

const Stack = createStackNavigator();

//https://reactnavigation.org/docs/stack-navigator/#options
//Products NAVIGATION
function settingsNav(props) {
  const { navigation } = props;

  return (
    <Stack.Navigator
      screenOptions={{
        headerStyle: {
          backgroundColor: colors.primaryDark,
          shadowOffset: {
            height: 0,
          },
        },
        headerTitleAlign: 'center',
        headerTitleStyle: {
          fontWeight: 'bold',
          fontSize: 17,
          color: colors.primaryText,
        },
      }}
    >
      <Stack.Screen
        name="SettingsHome"
        component={Settings}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="ProfileSettings"
        component={ProfileSettings}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="Transfer"
        component={TransferPage}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="Contact"
        component={ContactPage}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="RecaptchaSettings"
        component={RecaptchaSettings}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="ManageDualAccount"
        component={ManageDualAccount}
        options={{
          headerShown: false,
        }}
      />
    </Stack.Navigator>
  );
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {})(settingsNav);
