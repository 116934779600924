import React, { useState, useRef, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { Card, Typo2Way, Chip } from '../../components/common';
import ProductItem from '../../components/productCarousel/ProductItem';
import { dimensions, colors } from '../../utils/theme';
import { SET_SHOW_PRODUCT_VARIABLES_MODAL } from '../../actions/types';

const Divider = () => {
  return (
    <View
      style={{
        height: 0.5,
        width: '95%',
        alignSelf: 'center',
        backgroundColor: colors.shade1,
        marginVertical: 10,
      }}
    />
  );
};

export default function (props) {
  const dispatch = useDispatch();
  const { subCartProdToEdit, productVariableModalEditMode } = useSelector((state) => state.store);

  return (
    <Text>
      <View style={styles.modalContainer}>
        <View
          style={{
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typo2Way
            style={{}}
            subTitle=""
            title={(!!productVariableModalEditMode && 'Edit Options') || 'Select Options'}
          />
          <Text>
            <TouchableOpacity
              style={{ backgroundColor: 'transparent', padding: 5 }}
              onPress={() => {
                dispatch({
                  type: SET_SHOW_PRODUCT_VARIABLES_MODAL,
                  bool: false,
                  subCartProdToEdit: null,
                });
              }}
            >
              <AntDesign name="closecircle" size={24} color={colors.shade4} />
            </TouchableOpacity>
          </Text>
        </View>

        <View style={{ paddingHorizontal: 20 }}>
          <Divider />
          <ProductItem {...props} showProductOptions item={subCartProdToEdit} />
        </View>

        <View style={{ height: 80 }} />
      </View>
    </Text>
  );
}
const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    alignItems: 'center',
    alignSelf: 'center',
    width: dimensions.width,
    backgroundColor: colors.bg,
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
    margin: -18,
    padding: 15,
  },
});
