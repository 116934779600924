import { firebase, firestore, functions, auth } from '../../../firebase/config';
import React, { useState, useRef, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { View, StyleSheet } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

import Lottie from 'react-lottie';
import animationData from '../../assets/lottie/paper-plane';
import TextBS from '../../components/Text/TextBoldSmall';
import InputMat from '../../components/common/MaterialInput';
import ButtonX from '../../components/buttons/ButtonX';
import ModalHeader1 from '../../components/headers/Header1';
import PhoneInput from '../../components/PhoneInput';
import { dimensions, colors, typography } from '../../utils/theme';
import {
  updateAuthForm,
  sendEmailLink,
  getCreateUserObj,
  showAuthLoadingModal,
} from '../../actions';

const minPhoneLength = 9;

function SignUpEmail(props) {
  const dispatch = useDispatch();
  const { navigation } = props;
  const { params } = props.route;

  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRepassword] = useState('');

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sendSuccess, setSendSucces] = useState(false);

  useEffect(() => {
    setEmail(params.email);
  }, []);

  const phoneInput = useRef(null);

  const handleNewUserEmailPassword = async () => {
    setLoading(true);
    dispatch(showAuthLoadingModal(true));

    try {
      const userRecord = await auth.createUserWithEmailAndPassword(email, password);
      // TODO: these values for name, surname, email, formattedPhoneNumber to be used for the userObj account creation
      // TODO: new createCustomer implemented here, this should be a hook in V2

      var customer = {
        _id: userRecord.user.uid,
        cellphoneNumber: formattedPhoneNumber.replace(/\s/g, ''),
        email,
        name,
        surname,
        activeTagId: '',
        credit: 0,
        createdAt: new Date(),
      };

      await firestore.collection('customers').doc(customer._id).set(customer);

      const navToEventPage = async () => {
        const eventLastVisted = await AsyncStorage.getItem('EVENT_LAST_VISITED');
        const { orgId, event_id } = JSON.parse(eventLastVisted);

        if (!!orgId && !!event_id) {
          navigation.navigate('Event Page', { orgId, event_id });
        } else {
          navigation.navigate('Wallet');
        }
      };

      setTimeout(() => {
        setLoading(false);
        navToEventPage();
      }, 1500);
    } catch (err) {
      console.log({ err });
      setError(err.message);
      setLoading(false);
    }
  };

  const submitDisabled =
    // phoneNumber.length < minPhoneLength ||
    email.length < 3 ||
    !email.includes('@') ||
    !email.includes('.') ||
    !name.length ||
    !surname.length ||
    password.length < 6 ||
    repassword.length < 6 ||
    repassword !== password;

  // if "verify email" was pressed
  if (sendSuccess) return <SuccessMessage {...props} />;

  return (
    <View style={styles.container}>
      <ModalHeader1
        hideClose={true}
        heading="Sign up with Email"
        subHeading="Email Sign Up"
        description="Sign up using email and password"
      />
      <View style={{ flex: 1, width: '100%' }}>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            handleNewUserEmailPassword();
          }}
        >
          <InputMat
            required
            label="First Name"
            placeholder="Enter first name"
            onChangeText={(value) => setName(value)}
            value={name}
            autoComplete="name"
            textContentType="name"
            checkValidation={(val) => !val.length && 'First name required'}
          />
          <InputMat
            required
            label="Last Name"
            placeholder="Enter last name"
            onChangeText={(value) => setSurname(value)}
            value={surname}
            autoComplete="name-family"
            textContentType="familyName"
            checkValidation={(val) => !val.length && 'Last name required'}
          />
          <PhoneInput
            ref={phoneInput}
            defaultValue=""
            defaultCode="ZA"
            layout="first"
            onChangeText={(value, code) => {
              // console.log('Raw phone number and country code separate', { value, code });
              setFormattedPhoneNumber(`+${code}${value}`);
            }}
            autoFocus
          />

          <InputMat
            required
            label="Email"
            placeholder="Enter email address"
            onChangeText={(value) => setEmail(value)}
            value={email}
            // editable={!params.email}
            autoComplete="email"
            autoCompleteType="email"
            keyboardType="email-address"
            checkValidation={(val) =>
              (val.length < 3 || !val.includes('@') || !val.includes('.')) &&
              'Enter a valid email address'
            }
          />
          <InputMat
            secureTextEntry
            required
            label="Password"
            placeholder="Enter password"
            // onChangeText={(value) => dispatch(updateAuthForm({ key: 'password', value }))}
            onChangeText={(value) => setPassword(value)}
            value={password}
            autoComplete="current-password"
            checkValidation={(val) => val.length < 6 && 'Enter a strong password'}
          />
          <InputMat
            secureTextEntry
            required
            label="Re-enter Password"
            placeholder="Re-enter Enter password"
            onChangeText={(value) => setRepassword(value)}
            value={repassword}
            autoComplete="current-password"
            checkValidation={(val) => val !== password && 'Enter a matching password'}
          />
          <button
            style={{
              border: 'none',
              background: 'none',
              width: '100%',
              padding: 0,
            }}
            type="submit"
            disabled={
              email.length < 3 ||
              !email.includes('@') ||
              !email.includes('.') ||
              !name.length ||
              !surname.length ||
              password.length < 6 ||
              repassword.length < 6 ||
              repassword !== password
            }
          >
            <ButtonX
              type="solid"
              loading={loading}
              title="Continue"
              addStyle={{
                borderRadius: 10,
                backgroundColor: submitDisabled ? colors.shade2 : colors.balance,
                marginVertical: 20,
                marginTop: 10,
                width: '100%',
              }}
              titleStyle={{ fontWeight: 'bold', fontSize: 16 }}
            />
          </button>
        </form>
      </View>
    </View>
  );
}

export default connect(null, {
  getCreateUserObj,
})(SignUpEmail);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    paddingTop: 80,
    alignItems: 'center',
    backgroundColor: 'white',
  },
  message: {
    fontSize: 14,
    color: colors.secondary,
  },
});
