import React, { useState } from 'react';
import { StyleSheet, View, TouchableOpacity, TextInput } from 'react-native';
import { Input } from 'react-native-elements';
import { AntDesign, MaterialIcons } from '@expo/vector-icons';
import { colors, dimensions, typography } from '../utils/theme';

const myTextInput = ({
  placeholder,
  onMinusPress,
  showMinus,
  onChangeText,
  onFocus,
  focusStyle,
  onBlur,
  editable,
  onSubmitEditing,
  onKeyPress,
  multiline,
  addStyle,
  addContainerStyle,
  highlightStyle,
  answerSelected,
  value,
  input,
  label,
  type,
  keyboardType,
  thisRef,
  // meta: { touched, error }
}) => {
  const [focus, setFocus] = useState(true);
  return (
    <View style={[{ width: '95%', alignSelf: 'center', paddingHorizontal: 0 }, addContainerStyle]}>
      <View
        style={{
          flexDirection: 'row',
          marginVertical: 10,
          width: '100%',
          alignItems: 'center',
          //backgroundColor: "yellow"
        }}
      >
        <Input
          ref={thisRef}
          style={{ outline: 'none' }}
          multiline={multiline}
          inputContainerStyle={{
            borderBottomColor: focus ? 'transparent' : colors.primaryLight,
          }}
          labelStyle={{
            color: focus ? colors.highlight : colors.primaryText,
            alignSelf: 'center',
          }}
          inputStyle={focus ? [styles.inputTextFocussed, addStyle] : [styles.inputStyle, addStyle]}
          {...input}
          label={label}
          placeholder={placeholder}
          onChangeText={onChangeText}
          value={value}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          editable={editable}
          onSubmitEditing={onSubmitEditing}
          onKeyPress={onKeyPress}
          keyboardType={keyboardType}
        />

        {showMinus ? (
          <TouchableOpacity onPress={onMinusPress} style={{ marginLeft: 10 }}>
            <AntDesign name="minuscircle" size={24} color={colors.highlight} />
          </TouchableOpacity>
        ) : null}
      </View>
    </View>
  );
};

//        {touched && error && <View>{error}</View>}

//<input type={type} placeholder={label} />

//inputStyle={styles.inputStyle}

//  <View
//   style={{
//     flexDirection: "row",
//     alignSelf: "flex-end",
//     marginRight: 50,
//     //backgroundColor: "yellow"
//   }}
// >
//   <MaterialIcons name="cancel" size={24} color={colors.highlight} />
//   <MaterialIcons name="check-circle" size={24} color={colors.highlight} />
// </View>

export default myTextInput;

const styles = StyleSheet.create({
  inputStyle: {
    //backgroundColor: "yellow",
    // fontFamily: typography.primary,
    fontSize: 18,
    outlineStyle: 'none',
    outlineWidth: 0,
    outlineColor: 'transparent',
    color: colors.primaryText,
    borderBottomWidth: 1,
    borderBottomColor: colors.shade1,
    //paddingHorizontal: 10
  },
  inputTextFocussed: {
    fontSize: 18,
    outlineStyle: 'none',
    outlineWidth: 0,
    outlineColor: 'transparent',
    color: colors.primaryText,
    //paddingHorizontal: 10,
    borderBottomColor: colors.highlight,
    borderBottomWidth: 2,
  },
});
