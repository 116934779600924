import React, { Fragment, useEffect, useState, useRef, Suspense } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Linking, View, Image, StyleSheet } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import '../../components/common/sticky.css';
import Modal from 'modal-enhanced-react-native-web';
import { useFocusEffect } from '@react-navigation/native';
import * as RootNavigation from '../../RootNavigation.js';
import { Button, Avatar } from 'react-native-elements';
import { Ionicons, AntDesign } from '@expo/vector-icons';
import { Card, Typo3Way, Chip } from '../../components/common';
import { dimensions, colors, typography } from '../../utils/theme';
import BackHeader from '../../components/common/NavButtonBack';
// import ImageCarousel from "../../components/carousel/ImageCarousel";
const ImageCarousel = React.lazy(() => import('../../components/carousel/ImageCarousel'));
import ImageDescriptionCarousel from '../../components/carousel/ImageDescriptionCarousel';
import PosterCarousel from '../../components/carousel/PosterCarousel';
import ProductCarousel from '../../components/productCarousel/ProductCarousel';
import SelectProductVariablesModal from '../../views/Modals/SelectProductVariablesModal';
import EventSummary from './EventSummary';
import DirectionsCard from './DirectionsCard';
import Cart from './Cart';
import AmenityCard from './AmenityCard';
import ProductSelector from './ProductSelector';
import Campaign from './Campaign';
import AboutOrganiser from './AboutOrganiser';
import PassesWidget from './PassesWidget';
import QlessWidget from './QlessWidget';
import EventInfoCard from './EventInfoCard';
import EventLineupCard from './EventLineupCard';
import { fetchSingleProduct } from '../../actions';
// import PhotoRoll from "./PhotoRoll";
const PhotoRoll = React.lazy(() => import('./PhotoRoll'));

import backHomeIcon from '../../assets/svg/back_home.svg';
import SplashLoading from '../../components/SplashLoading';
import SplashLottie from '../../components/SplashLottie';
//
import {
  setGlobalInitializing,
  setGlobalLoading,
  setEventFocus,
  fetchSingleEvent,
  fetchEventFocusOnce, // quick fetch for promo check
  fetchPromoLink,
  fetchPromoDetails,
  listenUserWallet,
  toggleImagesLoading,
  fetchOrganiser,
  fetchEventImageCollection,
  updateThemeImage,
  setShowTermsModal,
  setRefundPolicy,
  addToCart,
  showAccountFormModal,
  getFrontChatHash,
  getAgentName,
} from '../../actions';
import { SET_SHOW_PRODUCT_VARIABLES_MODAL } from '../../actions/types';

const { NODE_ENV, APP_MANIFEST } = process.env;
const { extra } = APP_MANIFEST;
const { dosh_pixel_id } = extra;

const BackToWallet = (prps) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: colors.black,
        paddingHorizontal: 12,
        paddingVertical: 5,
        borderRadius: 20,
      }}
    >
      <Ionicons name="ios-arrow-back" size={20} color="white" style={{ marginRight: 10 }} />
      {/* <Text style={{ color: "white" }}>Home</Text> */}
      <AntDesign name="home" size={20} color="white" style={{ marginRight: 10 }} />
    </View>
  );
};

const Divider = () => {
  return (
    <View
      style={{
        height: 15,
        width: '100%',
        alignSelf: 'center',
        backgroundColor: 'transparent',
        // marginVertical: 10,
      }}
    />
  );
};

function EventDetail(props) {
  const { eventFocus, navigation } = props;
  const {
    user,
    userPasses,
    subCart,
    productList,
    imageCollection, // using merged array that includes product info as overlay - this is for prefetch
    textAndImages,
    imageDescriptionCarouselData,
    organiser,
    //
    imagesLoading,
    isInstagram,
  } = props;

  const header = useRef();
  const footer = useRef();
  const cartRef = useRef();

  const [showTermsModal, setShowTermsModal] = useState(false);
  const [itemDetail, setItemDetail] = useState(null);
  const [showProductDetailModal, setShowProductDetailModal] = useState(false);
  const [carouselMounted, setCarouselMounted] = useState(false);
  const [discount, setDiscount] = useState(null);
  const [voucher, setVoucher] = useState(null);
  const [isPromo, setPromo] = useState(false);
  const [carouselPosition, setCourouselPosition] = useState(0);
  const [hasPixelId, setPixelId] = useState(true);
  //
  const dispatch = useDispatch();
  const { showProductVariableModal, subCartProdToEdit } = useSelector((state) => state.store);

  const loadJS = (src) => {
    const ref = window.document.getElementsByTagName('script')[0];
    const script = window.document.createElement('script');
    script.src = src;
    script.async = true;
    ref.parentNode.insertBefore(script, ref);
  };

  const setLastVistedEvent = async ({ orgId, event_id }) => {
    await AsyncStorage.setItem('EVENT_LAST_VISITED', JSON.stringify({ orgId, event_id }));
  };

  useFocusEffect(
    React.useCallback(() => {
      // Do something when the screen is focused
      const { orgId, event_id } = props.route.params;
      orgId && event_id && setLastVistedEvent({ orgId, event_id });

      var unsubscribeEventFocus = orgId && !!event_id && props.fetchSingleEvent(orgId, event_id);

      !![...subCart].length &&
        setTimeout(() => {
          scrollToCart();
        }, 500);

      return () => {
        // Do something when the screen is unfocused
        !!unsubscribeEventFocus && unsubscribeEventFocus();
      };
    }, [props.route]),
    // keep this "props.route" dependency in there
    // https://stackoverflow.com/questions/64186894/react-native-navigation-navigate-params-not-updating
  );

  useEffect(() => {
    // this.loadJS("https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js", 1);
    loadJS('https://chat-assets.frontapp.com/v1/chat.bundle.js');

    // let position = prodCarouselRef?.current?.getBoundingClientRect();
    // setCourouselPosition(position);
  }, []);

  useEffect(() => {
    async function checkPromo() {
      const params = props?.route?.params;

      // !!params && !!params.prod_id && setPromo(true);
      // const isPromo = !!params && !!params.prod_id; // && setPromo(true);
      const isPromoLink = !!params && !!params.promo_id && !!params.link_id;
      const isOldDiscount = !!params && !!params.promo_id && !params.link_id;
      const isEventWithProduct = !!params && !!params.prod_id; // && !params.promo_id && !params.link_id;
      const isReward = !!params && !!params.uid; // && initialUrl.includes("/reward/"); // strictly check for rewards and not just any "user"

      // can check if the url "includes" what the link is used for
      const initialUrl = await Linking.getInitialURL();

      if (isEventWithProduct) {
        const { orgId, event_id, prod_id } = params;
        const { response, error } = await fetchSingleProduct(orgId, event_id, prod_id);
        const thisEvent = await props.fetchEventFocusOnce(orgId, event_id);
        const product = response;

        props.addToCart({
          ...product,
          qty: 1,
          ...thisEvent,
          event_id, // this is important, each subcart item needs to have it event_id in otder to allocate TX
        });
        setTimeout(() => {
          scrollToCart();
        }, 500);
      }

      if (isReward) {
        props.getAgentName(params.uid);
      }

      // old discount to still work, remove after Lost City
      if (isOldDiscount) {
        const { event_id, promo_id } = params;
        if (promo_id.includes('discount')) {
          const percentage = parseInt(promo_id.replace('discount', '')) / 100;
          setDiscount({
            title: `Discounted @ ${percentage * 100}%`,
            percentage,
            event_id,
            product: { title: '', value: null },
          });
        }
      }

      if (isPromoLink) {
        const promoLink = await props.fetchPromoLink(params);
        if (!promoLink?.valid) {
          console.log('Discount not valid, and could not be set.');
        } else {
          const { orgId, event_id, promo_id, link_id } = params;

          const promoDetails = await props.fetchPromoDetails(params);
          const thisEvent = await props.fetchEventFocusOnce(orgId, event_id);

          // apply promo code
          const {
            promo_unit,
            promo_value,
            multi_use,
            multi_use_limit,
            product,
            is_global_promo_code,
          } = promoDetails;

          if (promo_unit === 'percentage') {
            const discountNew = {
              title: `Discounted @ ${promo_value}%`,
              promo_id,
              link_id,
              percentage: promo_value / 100,
              event_id,
              multi_use,
              multi_use_limit,
              is_global_promo_code: (!!is_global_promo_code && is_global_promo_code) || false,
              product,
            };

            setDiscount(discountNew);
            // auto add the product to the cart
            setTimeout(() => {
              if (!!product) {
                props.addToCart({
                  prod_id: product.value, // product.value is the prod_id used when setting up a promo code
                  discount: discountNew,
                  qty: 1,
                });
              }
              scrollToCart();
            }, 500);
          }
          if (promo_unit === 'fixed') {
            // "setVoucher" is mostly for cart display, voucher is applied on backend
            setVoucher({
              title: `Discounted @ R ${promo_value}`,
              fixed: promo_value,
              event_id,
            });

            props.addToCart({
              prod_type: 'voucher',
              voucher: {
                prod_id: promo_id,
                prod_title: `Discount @ R ${promo_value}`,
                prod_type: 'voucher',
                promo_id,
                link_id,
                multi_use,
                multi_use_limit,
                price: -promo_value,
                qty: 1,
                provider: 'discount',
                userId: null, // not yet available, but will written to the promo_link object
                ...thisEvent,
                event_id, // this is important, each subcart item needs to have it event_id in otder to allocate TX
              },
            });
            // auto add product to cart
            if (!!product) {
              props.addToCart({
                ...product,
                prod_id: product.id,
                qty: 1,
                ...thisEvent,
                event_id, // this is important, each subcart item needs to have it event_id in otder to allocate TX
              });
            }

            setTimeout(() => {
              scrollToCart();
            }, 500);
          }
        }
      }

      // if (isPromo) {
      //   const { orgId, event_id, prod_id } = params;
      //   const products = await props.fetchEventProductsOnce(orgId, event_id);
      //   const thisEvent = await props.fetchEventFocusOnce(orgId, event_id);
      //   const prodIndex = products.findIndex((prod) => prod.id === prod_id);
      //   if (prodIndex >= 0 && products[prodIndex]?.pax > products[prodIndex]?.paxSold) {
      //     props.addToCart({
      //       prod_id,
      //       ...products[prodIndex],
      //       qty: 1,
      //       ...thisEvent,
      //       event_id, // this is important, each subcart item needs to have it event_id in otder to allocate TX
      //     });
      //     setTimeout(() => {
      //       scrollToCart();
      //     }, 500);
      //   }
      // }
    }
    async function checkPromoMultiEvent() {}

    //
    checkPromo();
    checkPromoMultiEvent();
  }, []);

  useEffect(() => {
    try {
      let windowPixels = window?.fbq?.getState()?.pixels?.map(({ id }) => id) || [];

      // check if dosh pixel is initialsed
      const doshPixelInitialised = windowPixels.includes(dosh_pixel_id);
      // if not, initialise dosh pixel
      if (!doshPixelInitialised) {
        window?.fbq('init', dosh_pixel_id);
      }
      window?.fbq('trackSingle', dosh_pixel_id, 'PageView');

      if (eventFocus?.pixelsFacebook?.length) {
        eventFocus?.pixelsFacebook.forEach((pixel_id) => {
          if (!windowPixels.includes(pixel_id)) {
            window?.fbq('init', pixel_id);
          }
          window?.fbq('trackSingle', pixel_id, 'PageView');
        });
      }
      // console.log({
      //   pixelsFacebook: eventFocus?.pixelsFacebook,
      //   windowPixels: window?.fbq?.getState()?.pixels,
      // });
    } catch (error) {
      console.error(error);
    }
  }, [eventFocus]);

  useEffect(() => {
    imageCollection.length &&
      textAndImages.length &&
      imageCollection.forEach((img) => Image.prefetch(img.url));
    return () => {
      setTimeout(() => {
        props.toggleImagesLoading(false);
      }, 2000);
    };
  }, [imageCollection]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (carouselMounted) {
  //       props.setGlobalInitializing(false);
  //       props.setGlobalLoading(false);
  //     } else {
  //       setTimeout(() => {
  //         props.setGlobalInitializing(false);
  //         props.setGlobalLoading(false);
  //       }, 5000);
  //     }
  //   }, 1500);
  // }, [carouselMounted]);

  useEffect(() => {
    const { user } = props;
    var unsubscribe = () => null;

    if (user) {
      try {
        unsubscribe = props.listenUserWallet(user?._id);
      } catch (error) {
        console.log('Could not listen for user Wallet');
      }
    }

    !!user && !!user.email && fetchFrontHash(user);
    return () => unsubscribe();
  }, [user]);

  async function fetchFrontHash(user) {
    var hash = null;
    hash = await props.getFrontChatHash({ user });

    const isDev = process.env.NODE_ENV === 'development' || false;

    if (!isDev && hash && user) {
      loadJS(
        window.FrontChat('init', {
          chatId: 'b6212538ad32e2d36f80ad220bd76f8e',
          useDefaultLauncher: true,
          email: user.email,
          userHash: hash.data,
          name: user.name + ' ' + user.surname,
          customFields: { 'Mobile Number': user.cellphoneNumber },
        }),
      );
    }
  }

  useEffect(() => {
    const checkInfo = ['name', 'surname', 'email', 'cellphoneNumber'];
    const missingFields = [];
    !!user &&
      checkInfo.forEach((field) => {
        !user[field] && missingFields.push(field);
      });
    missingFields.length &&
      setTimeout(() => {
        props.showAccountFormModal(true, missingFields);
      }, 1000);
  }, [user]);

  const scrollToCart = () => {
    setTimeout(() => {
      let position = cartRef?.current?.getBoundingClientRect();
      window.scrollTo(position?.left, position?.top + window.scrollY - 80);
    }, 300);
  };

  const spinnerRef = useRef();

  if (!eventFocus)
    return (
      <View ref={spinnerRef} style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <SplashLoading />
      </View>
    );
  return (
    <>
      <Suspense fallback={<div>Loading... </div>}>
        <div
          style={{
            backgroundColor: colors.bg,
            width: dimensions.width,
          }}
        >
          <BackHeader
            {...props}
            headerRef={header}
            subCart={subCart}
            title={eventFocus.event_title || ''}
            onBackPress={() => {
              RootNavigation.navigate('Marketplace');
              props.setEventFocus(null);
            }}
            onShowCartPress={() => scrollToCart()}
            replaceIcon={() => <BackToWallet />}
          />
          <View style={{ height: 65 }} />

          <View style={styles.container}>
            {!!userPasses.length && (
              <View>
                <PassesWidget {...props} onPress={() => props.setGlobalLoading(true)} />
                <Divider />
              </View>
            )}
            {/* <View
          style={{
            padding: 4,
            borderRadius: 2,
            backgroundColor: !!isInstagram ? "green" : "red",
          }}
        ></View> */}

            {!!eventFocus.is_qless && (
              <View>
                <QlessWidget {...props} onPress={() => props.setGlobalLoading(true)} />
                <Divider />
              </View>
            )}

            {!imagesLoading && !eventFocus.is_sections && (
              <View>
                <ImageCarousel
                  {...props}
                  discount={discount}
                  carouselData={textAndImages}
                  onPress={(item) => {
                    setItemDetail(item);
                    setShowProductDetailModal(true);
                  }}
                  onProductAdded={() => scrollToCart()}
                  onMounted={(bool) => setCarouselMounted(bool)}
                />
                <Divider />
              </View>
            )}

            {!imagesLoading && !!eventFocus.is_sections && (
              <View style={{ flex: 1 }}>
                <PosterCarousel
                  {...props}
                  discount={discount}
                  carouselData={imageCollection}
                  onPress={(item) => {
                    setItemDetail(item);
                    setShowProductDetailModal(true);
                  }}
                  onProductAdded={() => scrollToCart()}
                  onMounted={(bool) => setCarouselMounted(bool)}
                />
                <Divider />
              </View>
            )}

            <View>
              <EventSummary {...props} />
              <Divider />
            </View>

            {/* 
        {!imagesLoading && !!eventFocus.is_sections && (
          <ImageCarousel
            // photo gallery mode
            {...props}
            galleryMode
            carouselData={props.imageCollectionSorted}
            onMounted={(bool) => {
              setCarouselMounted(bool);
            }}
          />
        )} */}

            {!!eventFocus.is_sections && !!props.photoGridData.length && (
              <View>
                <PhotoRoll {...props} />
                <Divider />
              </View>
            )}

            {!!eventFocus.event_info && (
              <View>
                <EventInfoCard {...props} />
                <Divider />
              </View>
            )}
            {!!eventFocus.is_lineup && (
              <View>
                <EventLineupCard {...props} />
                <Divider />
              </View>
            )}
            {/* 
        {!!eventFocus.is_accom_carousel && (
          <ImageCarousel
            // photo gallery mode
            {...props}
            galleryMode
            carouselData={props.imageCollectionSorted}
            onMounted={(bool) => {
              setCarouselMounted(bool);
            }}
          />
        )} */}
            {!imagesLoading && !!eventFocus.is_accom_carousel && (
              <View>
                <ImageDescriptionCarousel
                  {...props}
                  discount={discount}
                  carouselData={imageDescriptionCarouselData}
                  onPress={(item) => {
                    setItemDetail(item);
                    setShowProductDetailModal(true);
                  }}
                  onProductAdded={() => scrollToCart()}
                  onMounted={(bool) => setCarouselMounted(bool)}
                />
                <Divider />
              </View>
            )}

            {!!eventFocus.is_sections && (
              <View>
                <ProductCarousel
                  {...props}
                  {...{ cartRef, voucher, discount }}
                  discount={discount}
                  productSectionList={
                    (!!props.productSectionList.length && props.productSectionList) || []
                  }
                  onProductAdded={() => scrollToCart()}
                />
              </View>
            )}

            {productList.length !== 0 && !eventFocus.is_sections && (
              <View>
                <Divider />
                <ProductSelector
                  {...props}
                  productList={productList}
                  discount={discount}
                  onProductAdded={() => scrollToCart()}
                />
              </View>
            )}

            {subCart.length !== 0 && (
              <View ref={cartRef}>
                <Divider />

                <Card>
                  <Cart
                    {...props}
                    {...{ voucher }}
                    routeParams={eventFocus}
                    discount={discount}
                    thisRef={cartRef}
                    setShowTermsModal={(bool) => setShowTermsModal(bool)}
                  />
                </Card>
              </View>
            )}

            <Divider />

            <DirectionsCard {...props} />
            {!!eventFocus.amenities && <AmenityCard {...props} />}

            {!!eventFocus.rewards && user?.isRewards && <Campaign {...props} />}

            <Divider />

            <AboutOrganiser {...props} />
          </View>
          {/* 
      <div style={{ position: "sticky", bottom: 0 }}>
        <SafeAreaView>
          <View
            ref={footer}
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              height: 60,
              padding: 15,
              backgroundColor: colors.bg,
            }}
          >
            <Text>Footer</Text>
            <ButtonX
              type="solid"
              title="Check out"
              addStyle={{
                borderRadius: 10,
                width: "40%",
                height: 40,
              }}
              titleStyle={{ fontWeight: "bold", fontSize: 14 }}
              onPress={() => navigation.navigate("Cart")}
            />
          </View>
        </SafeAreaView>
      </div> */}

          <Modal
            isVisible={showProductVariableModal}
            onBackdropPress={() =>
              dispatch({
                type: SET_SHOW_PRODUCT_VARIABLES_MODAL,
                bool: false,
                subCartProdToEdit: null,
              })
            }
            style={{ justifyContent: 'flex-end' }}
          >
            {!!subCartProdToEdit && (
              <SelectProductVariablesModal
                {...props}
                {...{ discount }}
                onProductAdded={() => scrollToCart()}
                onClose={() =>
                  dispatch({
                    type: SET_SHOW_PRODUCT_VARIABLES_MODAL,
                    bool: false,
                    subCartProdToEdit: null,
                  })
                }
              />
            )}
          </Modal>
        </div>
      </Suspense>
    </>
  );
}

{
  /* {<AmenityCard {...props} />} */
}

const mapStateToProps = (state) => {
  const { user, userPasses } = state.users;
  const { subCart, productList, products, imageCollection, organiser, imagesLoading } = state.store;

  const { dashboard } = state.dashboard;
  const { eventFocus, isInstagram } = state.config;
  const productSectionIds = eventFocus?.product_sections || [];

  const productListDropDown = productList
    .map((prod, i) => {
      const threshold = 20;
      const isSoldOut = prod.available && prod.pax !== 0 && prod.paxSold >= prod.pax;
      const isComingSoon = prod.available && prod.pax === 0;
      const isBelowThreshold = prod.pax - prod.paxSold < threshold;
      const countInStock = prod.pax - prod.paxSold < 0 ? 0 : prod.pax - prod.paxSold;

      return !isComingSoon && !isSoldOut && prod.available
        ? {
            ...prod,
            note: !isComingSoon && !isSoldOut && isBelowThreshold ? `${countInStock} left` : null,
          }
        : isSoldOut
        ? {
            ...prod,
            note: 'Sold Out',
          }
        : isComingSoon
        ? {
            ...prod,
            note: 'Launching soon', // TODO change this back to saying "Launching soon" after APT
          }
        : null;
    })
    .filter((item) => item !== null)
    .sort((a, b) => a.sort_order - b.sort_order);

  const productSectionList = productSectionIds.map((title) => {
    const data = productListDropDown.filter((prod) => prod.section_id === title);
    return data.length
      ? {
          title,
          data,
        }
      : null;
  });

  return {
    user,
    userPasses,
    subCart,
    //
    eventFocus,
    organiser,
    imagesLoading,
    imageCollection,
    imageCollectionSorted: imageCollection.sort((a, b) => a.sort_order - b.sort_order),
    photoGridData: imageCollection
      .filter((img) => !!img.is_grid)
      .sort((a, b) => a.sort_order - b.sort_order),
    imageDescriptionCarouselData: imageCollection
      .filter((img) => !!img.title || !!img.body)
      .sort((a, b) => a.sort_order - b.sort_order),
    productList: productList
      .filter((prod) => prod.available && prod.paxSold < prod.pax && prod.pax !== 0)
      .sort((a, b) => a.sort_order - b.sort_order),
    //
    textAndImages: imageCollection
      .filter((img) => img.prod_id)
      .map((img, i) => {
        const prod = productList
          .filter((prod) => prod.id === img.prod_id && prod.available)
          .reduce((a, b) => b, {});

        const threshold = 20;
        const isSoldOut = prod.available && prod.pax !== 0 && prod.paxSold >= prod.pax;
        const isComingSoon = prod.available && prod.pax === 0;
        const isBelowThreshold = prod.pax - prod.paxSold < threshold;
        const countInStock = prod.pax - prod.paxSold < 0 ? 0 : prod.pax - prod.paxSold;

        return !isComingSoon && !isSoldOut && prod.available
          ? {
              ...prod,
              ...img,
              note: !isComingSoon && !isSoldOut && isBelowThreshold ? `${countInStock} left` : null,
            }
          : isSoldOut
          ? {
              ...prod,
              ...img,
              note: 'Sold Out',
            }
          : isComingSoon
          ? {
              ...prod,
              ...img,
              note: 'Launching soon',
            }
          : null;
      })
      .filter((item) => item !== null)
      .sort((a, b) => a.sort_order - b.sort_order),
    productSectionList: productSectionList.filter((item) => item !== null),
    isInstagram,
  };
};
export default connect(mapStateToProps, {
  setGlobalInitializing,
  setGlobalLoading,
  setEventFocus,
  fetchSingleEvent,
  fetchEventFocusOnce, // quick fetch for promo check
  fetchPromoLink,
  fetchPromoDetails,
  listenUserWallet,
  toggleImagesLoading,
  fetchOrganiser,
  fetchEventImageCollection,
  updateThemeImage,
  setShowTermsModal,
  setRefundPolicy,
  addToCart,
  showAccountFormModal,
  getFrontChatHash,
  getAgentName,
})(EventDetail);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: dimensions.width,
    overflow: 'hidden',
    alignItems: 'center',
    paddingVertical: 15,
  },
  iconStyle: {
    height: 22,
    width: 22,
    color: colors.shade4,
  },
  // stickyFooter: {
  //   position: "absolute",
  //   zIndex: 10,
  // },
});
