import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import {
  View,
  Text,
  Image,
  ImageBackground,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
} from 'react-native';
import { Fontisto, Feather, SimpleLineIcons } from '@expo/vector-icons';
import { dimensions, colors, typography } from '../../utils/theme';
import TextBold from '../../components/Text/TextBold';

const Card = (props) => {
  return (
    <View style={[styles.container, props.style]}>
      <View style={[styles.card, props.childStyle]}>{props.children}</View>
    </View>
  );
};

export { Card };

const styles = StyleSheet.create({
  container: {
    width: dimensions.width - 30,
    backgroundColor: colors.bg,
    borderRadius: 15,
    // marginVertical: 15,
    alignItems: 'center',
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 5 },
    shadowOpacity: 0.1,
    shadowRadius: 20,
  },
  card: {
    flex: 1,
    width: dimensions.width - 30,
    borderRadius: 10,
    backgroundColor: colors.bg1,
    padding: 15,
  },
});
// width: dimensions.width - 30,
// borderRadius: 15,
// margin: 20,
// marginVertical: 10,
// backgroundColor: "white",
// //
// shadowColor: "rgba(0,0,0,0.4)",
// shadowOffset: { width: 0, height: 0 },
// shadowOpacity: 0.5,
// shadowRadius: 20
