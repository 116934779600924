import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { View, Image, StyleSheet, Animated, Platform } from 'react-native';
import { Button } from 'react-native-elements';
import { captureRef } from 'react-native-view-shot-with-web-support';
// import * as htmlToImage from "html-to-image";
// import * as Sharing from "expo-sharing";
import Base64 from 'Base64';
import Modal from 'modal-enhanced-react-native-web';
import { dimensions, colors, typography } from '../../utils/theme';
import * as RootNavigation from '../../RootNavigation.js';
import SignInWidget from '../../components/SignInWidget';
import BackHeader from '../../components/common/NavButtonBack';
import TextBS from '../../components/Text/TextBoldSmall';
import TextInput from '../../components/TextInput';
import InputMat from '../../components/common/MaterialInput';
import PassCard from './PassCard';
import EventPass2 from './EventPass2';
import EventPassMulti from './EventPassMulti';
import EventPassTimeSlot from './EventPassTimeSlot';
import EventPassAccom from './EventPassAccom';
import OwnerFormModal from './modals/OwnerFormModal';

import ticketStack from '../../assets/svg/ticketStack.svg';
//
import compareValues from '../../utils/sortArray';
import {
  showCart,
  showAccountFormModal,
  togglePaymentLoading,
  sendMailToSelf,
  userLogAction,
  updateUserPass,
  // updateCustomer,
  setShowTermsModal,
  listenUserWallet,
  setGlobalLoading,
  setGlobalInitializing,
} from '../../actions';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { color } from 'react-native-reanimated';

const { width, height } = dimensions;
const SPACING = 5;
const ITEM_SIZE = width * 0.9; //Platform.OS === "ios" ? width * 0.72 : width * 0.74;
const EMPTY_ITEM_SIZE = (width - ITEM_SIZE) / 2;

async function takeScreenShot({ ref, emailInput }) {
  const uri = await htmlToImage.toPng(document.getElementById('viewShot'));
  const body = 'Email body'; //ticketHTML;
  // const isSharingAvails = await Sharing.isAvailableAsync();
  // //const uriFormatted = uri.split(",").pop();
  // console.log("isSharingAvails", isSharingAvails, "URI", uri);

  // const encoded = Base64.btoa("sample string");
  // const decoded = Base64.atob(encoded);

  // // const options = {
  // //   UTI: ".png",
  // //   dialogTitle: "Send my pass!",
  // //   mimeType: "image/png",
  // // };
  // // await Sharing.shareAsync(uriFormatted,options);

  return sendMailToSelf({
    body,
    to: emailInput,
    subject: 'AirDosh ticket email.',
    attachments: [
      {
        filename: 'pass.png',
        path: uri,
        encoding: 'base64',
      },
    ],
  });
}

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const RenderItem = (props) => {
  const { item } = props;

  const prod_type = item?.prod_type ? item.prod_type : item.type;

  return prod_type === 'pass' ? (
    <PassCard demo={true} {...props} />
  ) : prod_type === 'event_pass' ? (
    <EventPass2 {...props} />
  ) : prod_type === 'event_agenda' ? (
    <EventPass2 {...props} />
  ) : prod_type === 'time_slot_pass' ? (
    <EventPassTimeSlot {...props} />
  ) : prod_type === 'event_accom' ? (
    <EventPassAccom {...props} />
  ) : prod_type === 'event_multi_pass' ? (
    <EventPassMulti {...props} />
  ) : prod_type === 'event_pass_comp' ? (
    <EventPass2 {...props} />
  ) : null;
};

function Passes(props) {
  const { navigation } = props;
  const { passes, noPasses, user, subCart, showPaymentProcessing } = props;

  const header = useRef();

  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showOwnerModal, setShowOwnerModal] = useState(false);
  const [email, setEmailInput] = useState('');
  const [name, setFirstName] = useState('');
  const [surname, setLastName] = useState('');
  // const [accountFormFields, setAccountFormFields] = useState([]);
  const [emailLoading, setEmailLoading] = useState(false);
  const [emailDelivered, setEmailDelivered] = useState(null);
  const [qrIDtoUpdate, setqrIDtoUpdate] = useState(null);
  const [itemToUpdate, setItemToUpdate] = useState(null);
  const [ref, setRef] = useState(null);

  const scrollX = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    const { passes, noPasses, user, subCart } = props;
    var unsubscribe = () => null;
    try {
      unsubscribe = props.listenUserWallet(user?._id);
    } catch (error) {}
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      props.setGlobalLoading(false);
      props.setGlobalInitializing(false);
    }, 1000);
  }, []);

  const prevPasses = usePrevious(passes);
  useEffect(() => {
    const { passes } = props;

    try {
      prevPasses &&
        showPaymentProcessing &&
        prevPasses !== 'undefined' &&
        passes?.length !== prevPasses?.length &&
        props.togglePaymentLoading(false);
    } catch {
      // console.log("User has no passes");
    }
  }, [passes]);

  useEffect(() => {
    const checkInfo = ['name', 'surname', 'email', 'cellphoneNumber'];
    const missingFields = [];
    // console.log("User in Passes", user);
    !!user &&
      checkInfo.forEach((field) => {
        !user[field] && missingFields.push(field);
      });
    // console.log("missingFields in Passes", missingFields);
    missingFields.length &&
      setTimeout(() => {
        props.showAccountFormModal(true, missingFields);
      }, 1000);
  }, [user]);

  // const emptyOwner = {
  //   owner: {
  //     name: "",
  //     surname: "",
  //     email: "",
  //   },
  // };

  // const hydrateInputs = (item = emptyOwner) => {
  //   if (
  //     item.owner?.name?.length ||
  //     item.owner?.surname?.length ||
  //     item.owner?.email?.length
  //   ) {
  //     setFirstName(item.owner.name);
  //     setLastName(item.owner.surname);
  //     setEmailInput(item.owner.email);
  //   } else {
  //     setFirstName("");
  //     setLastName("");
  //     setEmailInput("");
  //   }
  // };

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: colors.bg,
      }}
    >
      <BackHeader
        {...props}
        headerRef={header}
        subCart={subCart}
        title="My passes"
        onBackPress={() => RootNavigation.goBack()}
        onShowCartPress={() => props.showCart(true)}
        style={{ zIndex: 10 }}
      />
      <View style={{ height: 60 }} />

      {noPasses ? (
        <View
          style={{
            flex: 1,
            justifyContent: 'flex-end',
            zIndex: 10,
          }}
        >
          <Image
            source={{ uri: ticketStack }}
            resizeMode="contain"
            style={{
              height: 180,
              width: dimensions.width,
              marginVertical: 20,
            }}
          />
          {!user ? (
            <View>
              <TextBS style={styles.message}>
                Your event passes are stored here. Sign in to view or purchase passes.
              </TextBS>
            </View>
          ) : (
            <TextBS style={styles.message}>
              You have no passes yet. If you have just purchased a pass, give the app a minute, as
              additional checks may be in progress.
            </TextBS>
          )}
        </View>
      ) : null}

      {showPaymentProcessing ? (
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <TextBS style={styles.message}>
            Your payment was succesful. Wait one moment while we deliver your passes.
          </TextBS>
          <Button
            title="Clear"
            type="outline"
            buttonStyle={{
              height: 60,
              width: 180,
              margin: 20,
              borderRadius: 30,
              borderWidth: 2,
              borderColor: colors.shade4,
            }}
            titleStyle={{
              fontWeight: 'bold',
              fontSize: 12,
              color: colors.shade4,
            }}
            onPress={() => props.togglePaymentLoading(false)}
          />
        </View>
      ) : (
        <Animated.FlatList
          style={{ width: width, marginTop: -170, zIndex: 0 }}
          showsHorizontalScrollIndicator={false}
          data={passes}
          keyExtractor={(item) => item?.qr_id + ''}
          horizontal
          bounces={false}
          decelerationRate={Platform.OS === 'ios' ? 0 : 0.98}
          contentContainerStyle={{ alignItems: 'center' }}
          snapToInterval={ITEM_SIZE}
          snapToAlignment="start"
          pagingEnabled={true}
          onScroll={Animated.event([{ nativeEvent: { contentOffset: { x: scrollX } } }])}
          scrollEventThrottle={16}
          renderItem={({ item, index }) => {
            const inputRange = [
              (index - 2) * ITEM_SIZE,
              (index - 1) * ITEM_SIZE,
              index * ITEM_SIZE,
            ];

            const translateY = scrollX.interpolate({
              inputRange,
              outputRange: [100, 80, 100],
              extrapolate: 'clamp',
            });

            // check if the item is a spacer or an actual item
            return !item.type ? (
              <View
                style={{
                  width: EMPTY_ITEM_SIZE,
                }}
              />
            ) : (
              <View style={{ width: ITEM_SIZE }}>
                <Animated.View
                  style={{
                    marginHorizontal: SPACING,
                    alignItems: 'center',
                    transform: [{ translateY }],
                  }}
                >
                  <RenderItem
                    {...props}
                    item={item}
                    onEmailPress={(ref, qr_id) => {
                      // bypass modal, use this -> takeScreenShot({ ref, emailInput });
                      setShowEmailModal(true);
                      setRef(ref);
                      setqrIDtoUpdate(qr_id);
                    }}
                    emailLoading={emailLoading}
                    emailDelivered={emailDelivered}
                    onOwnerPress={(qr_id) => {
                      setShowOwnerModal(true);
                      // setqrIDtoUpdate(qr_id);
                      setItemToUpdate(item);
                      // hydrateInputs(item);
                    }}
                    onOwnerNamePress={() => {
                      setShowOwnerModal(true);
                      setItemToUpdate(item);
                      //hydrateInputs(item);
                    }}
                  />
                </Animated.View>
              </View>
            );
          }}
        />
      )}

      <Modal isVisible={showOwnerModal} onBackdropPress={() => setShowOwnerModal(false)}>
        <OwnerFormModal
          onModalClose={() => setShowOwnerModal(false)}
          showOwnerModal={showOwnerModal}
          itemToUpdate={itemToUpdate}
        />
      </Modal>

      {/* <Modal
        isVisible={showEmailModal}
        onBackdropPress={() => setShowEmailModal(false)}
      >
        <View
          style={{
            backgroundColor: "white",
            paddingHorizontal: 20,
            paddingVertical: 30,
            borderRadius: 10,
            width: "100%",
          }}
        >
          <InputMat
            label="Email"
            placeholder="Enter email address"
            onChangeText={setEmailInput}
            value={email}
            autoComplete="email"
            autoCompleteType="email"
            addContainerStyle={styles.inputText}
            keyboardType="email-address"
            // autoFocus={true}
          />
          <Button
            title="EMAIL PASS"
            buttonStyle={{
              height: 60,
              borderRadius: 30,
              marginVertical: 20,
              backgroundColor: colors.highlight,
            }}
            titleStyle={{ fontWeight: "bold", fontSize: 14 }}
            onPress={() => {
              takeScreenShot({ ref, emailInput }).then((deliveryStatus) => {
                // console.log("deliveryStatus", deliveryStatus);
                setEmailLoading(false);
                setEmailDelivered(deliveryStatus);
                deliveryStatus &&
                  props.updateUserPass({
                    user,
                    qr_id: qrIDtoUpdate,
                    key: "action_email",
                    value: true,
                  });
                props.userLogAction({
                  user,
                  action: {
                    label: `User emailed pass to ${emailInput}`,
                    notes: [`QR ID - ${qrIDtoUpdate}`],
                  },
                });
              });
              setShowEmailModal(false);
              setEmailLoading(true);
            }}
          />
          <View>
            <TextBS style={styles.message}>
              You are about to share an event pass. Please remain vigilant, and
              do not let your pass be copied.
            </TextBS>
          </View>
        </View>
      </Modal> */}
    </View>
  );
}
{
  /* {<Button onPress={() => setShowEmailModal(true)}>ShowModal</Button>} */
}
{
  /* {<SignInWidget altTitle=" " />} */
}

const mapStateToProps = (state) => {
  //const { passes } = state.pass; // demo passes
  const { user, userPasses } = state.users;
  const { subCart, showPaymentProcessing } = state.store;
  const { isEmailPasses } = state.config;

  //const { purchasedItems } = user;
  //merge more passes here later, like promo vouchers and other voucher that are automatically issued
  //var passes = user?.purchasedItems ? user.purchasedItems : []; // insert demo pass here
  var passes = userPasses.length ? userPasses : []; // insert demo pass here
  var passesByDate = passes.sort((a, b) => b.date?.startTime?.seconds - a.date?.startTime?.seconds);
  // passes.sort(compareValues("createdAt", "desc")); // passes.filter(() => date > xxxx)
  // showPaymentProcessing && passesByDate.push({ loading: true });

  // console.log("showPaymentProcessing", showPaymentProcessing);
  //  console.log("passesByDate", passesByDate);

  return {
    user,
    subCart,
    noPasses: userPasses.length === 0,
    passes: [{ qr_id: 'empty-left' }, ...passesByDate, { qr_id: 'empty-right' }],
    isEmailPasses,
    showPaymentProcessing,
  };
};

export default connect(mapStateToProps, {
  showCart,
  showAccountFormModal,
  togglePaymentLoading,
  sendMailToSelf,
  userLogAction,
  updateUserPass,
  // updateCustomer,
  setShowTermsModal,
  listenUserWallet,
  setGlobalLoading,
  setGlobalInitializing,
})(Passes);

const styles = StyleSheet.create({
  backButton: {
    position: 'absolute',
    top: 20,
    right: 20,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    height: 40,
    width: 40,
    borderRadius: 20,
    shadowColor: 'rgba(0,0,0,0.4)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 20,
  },
  inputText: {
    marginVertical: 10,
    width: '100%',
    textAlign: 'center',
    fontSize: 18,
    outlineStyle: 'none',
    outlineWidth: 0,
    outlineColor: 'transparent',
  },
  message: {
    fontSize: 14,
    paddingHorizontal: 40,
    color: colors.secondary,
    textAlign: 'center',
  },
});
