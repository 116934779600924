import React, { useEffect, useState } from "react";
// import { connect } from "react-redux";
import { TouchableOpacity, StyleSheet, Text, View } from "react-native";
import { withTheme } from "react-native-elements";
import TextBoldSmall from "../../components/Text/TextBoldSmall";
// import {  } from "@expo/vector-icons";
// import HoverView from "../../components/common/HoverView"; // just pass hoverStyle={}
// import { H1 } from "../../components/text";
// import ButtonX from "../../components/buttons/ButtonX";

function Badge(props) {
  const { theme } = props;
  const { count, style } = props;
  //const { primary, secondary, secondary2, white, black } = theme.colors;
  return (
    <View style={[styles.container, style]}>
      <TextBoldSmall style={{ color: "white", fontSize: 10 }}>
        {count}
      </TextBoldSmall>
    </View>
  );
}

export default withTheme(Badge);

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    right: 10,
    top: 10,
    borderRadius: 30,
    backgroundColor: "#FF5842", //"red",
    //margin: 15,
    padding: 3,
    paddingHorizontal: 7,
    alignItems: "center",
    justifyContent: "center"
  }
});
