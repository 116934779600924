import React, { useEffect, useState } from "react";
// import { connect } from "react-redux";
import {
  TouchableOpacity,
  StyleSheet,
  View,
  ActivityIndicator,
} from "react-native";
import { dimensions, colors, typography } from "../../utils/theme";
import { withTheme } from "react-native-elements";
import TextBold from "../../components/Text/TextBold";
import TextBoldSmall from "../../components/Text/TextBoldSmall";
import Text from "../../components/Text/TextLight";
// import {  } from "@expo/vector-icons";
// import HoverView from "../../components/common/HoverView"; // just pass hoverStyle={}
// import { H1 } from "../../components/text";
// import ButtonX from "../../components/buttons/ButtonX";

function LoadingScreen(props) {
  const { theme } = props;
  const { user } = props;

  //const { primary, secondary, secondary2, white, black } = theme.colors;
  return (
    <View
      style={{
        width: "100%",
        height: "50%",
        alignItems: "center",
        padding: 20,
        //  height: dimensions.height / 2,
        borderRadius: 20,
        backgroundColor: "white",
      }}>
      <ActivityIndicator size="large" color={colors.pay} />
      <Text>Placing order...</Text>
      <TextBold style={{ fontSize: 16 }}>Your new Balance: </TextBold>
      <TextBold>R {(user?.credit + user?.pendingCredit).toFixed(2)}</TextBold>
      <Text>Your order will be delivered to your table</Text>
    </View>
  );
}

export default withTheme(LoadingScreen);

const styles = StyleSheet.create({
  container: {
    borderRadius: 15,
    margin: 15,
    padding: 15,
    flexDirection: "row",
    alignItems: "center",
  },
});
