import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import {
  View,
  SafeAreaView,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
  Linking,
  Pressable,
} from 'react-native';
import moment from 'moment';
import { Button } from 'react-native-elements';
import { Fontisto, Feather, SimpleLineIcons, MaterialCommunityIcons } from '@expo/vector-icons';
import ViewShot from 'react-native-view-shot-with-web-support';
import QRCode from 'react-native-qrcode-svg';
import { LinearGradient } from 'expo-linear-gradient';
import { dimensions, colors, typography } from '../../utils/theme';
import TextSemi from '../../components/Text/TextS';
import TextBold from '../../components/Text/TextBold';
import TextBoldSmall from '../../components/Text/TextBoldSmall';
import TextLight from '../../components/Text/TextLight';

const time24hr = (time) => moment(time.seconds * 1000).format('hh:mm a');

const day = (seconds) => moment(seconds * 1000).format('Do');

const dateFormatted = ({ startTime, endTime }) => {
  if (day(startTime) === day(endTime)) {
    return `${moment(startTime.seconds * 1000).format('Do MMM `YY')}`;
  } else {
    return `${moment(startTime.seconds * 1000).format('Do')} - ${moment(
      endTime.seconds * 1000,
    ).format('Do MMM `YY')}`;
  }
};

const Invalid = ({ item }) => {
  return (
    <View
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(255,255,255,0.9)',
        height: '100%',
        width: '90%',
      }}
    >
      <TextBoldSmall>{item?.qr_label || 'Invalid'}</TextBoldSmall>
    </View>
  );
};

const AddOwner = (props) => {
  // console.log("props in AddOwner", props);
  return (
    <TouchableOpacity
      onPress={() => props.onOwnerPress(props.item.qr_id)}
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(255,255,255,0.9)',
        height: '100%',
        width: '90%',
      }}
    >
      <View
        style={{
          padding: 10,
          backgroundColor: colors.black,
          borderRadius: 10,
        }}
      >
        <TextBoldSmall style={{ textAlign: 'center', fontSize: 12, color: 'white' }}>
          To expose, add name of pass owner
        </TextBoldSmall>
      </View>
    </TouchableOpacity>
  );
};

const BlockSeatRowContainer = ({ label, param }) => {
  return (
    <View
      style={{
        width: '100%',
        //alignItems: "center"
        //margin: 5
        //marginHorizontal: 10,
      }}
    >
      <TextSemi
        style={{
          fontSize: 12,
          color: 'white',
          opacity: 0.7,
          marginVertical: 5,
        }}
      >
        {label}
      </TextSemi>
      <TextSemi numberOfLines={2} style={{ fontSize: 16, fontWeight: 'bold', color: 'white' }}>
        {param}
      </TextSemi>
    </View>
  );
};

const TicketNumber = ({ groupQty }) => {
  return (
    <View
      style={{
        backgroundColor: 'white',
        paddingVertical: 2,
        paddingHorizontal: 10,
        borderRadius: 15,
      }}
    >
      <TextBoldSmall style={{ fontSize: 12 }}>{groupQty}</TextBoldSmall>
    </View>
  );
};

export default function (props) {
  const { isEmailPasses, emailLoading } = props;
  const {
    qr_id,
    passes_count,
    seat_number,
    groupQty,
    orgId,
    event_id,
    event_title,
    event_description,
    //date,
    location,
    titleImage,
    //
    productTitle,
    hide_qr,
    price,
    type, // this determines the card type
    purchaseDate,
    owner,
    valid,
    color1,
    color2,
    //
    selected_time_slot,
  } = props.item;
  const { date, startTime, endTime } = props.item;
  // console.log("props.item", props.item);

  const isEmailSent = (props.item.action_email && props.item.action_email) || null;

  const viewShot = useRef();
  const gradientColors = [color1, color2]; // ["#833EB9", "#FA8730"];

  const hasOwner = owner.name && owner.name.length && owner.surname && owner.surname.length;

  const itemDate = () => {
    if (startTime && !endTime) {
      return dateFormatted({ startTime });
    }
    if (startTime && endTime) {
      return dateFormatted({ startTime, endTime });
    } else {
      return dateFormatted(date);
    }
  };

  return (
    <ViewShot
      ref={viewShot}
      options={{ format: 'jpg', quality: 0.1 }}
      style={{ flex: 1, width: '100%' }}
    >
      <SafeAreaView
        // style={styles.cardContainer}
        nativeID={'viewShot'}
        style={{ height: dimensions.height - 80 }}
      >
        <LinearGradient
          colors={gradientColors}
          start={{ x: 0, y: 1 }}
          end={{ x: 1, y: 0 }}
          style={{
            flex: 1,
            width: '100%',
            height: dimensions.height,
            borderRadius: 15,
            padding: 20,
            justifyContent: 'flex-end',
          }}
        >
          <View style={styles.infoContainer}>
            <View
              style={{
                width: '100%',
                flexDirection: 'row',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                marginBottom: 15,
              }}
            >
              <View style={{ width: '60%' }}>
                <BlockSeatRowContainer label="Valid date" param={itemDate()} />
                <BlockSeatRowContainer label="Admit" param={productTitle} />
              </View>
              <View style={{ width: '40%' }}>
                <BlockSeatRowContainer
                  label="Session Start"
                  param={time24hr((!!selected_time_slot && selected_time_slot.startTime) || 'TBC')}
                />
                <BlockSeatRowContainer
                  label="Session End"
                  param={time24hr((!!selected_time_slot && selected_time_slot.endTime) || 'TBC')}
                />
                {passes_count && <BlockSeatRowContainer label="Seat Number" param={seat_number} />}
              </View>
            </View>

            <TextBold
              style={{
                color: 'white',
                fontSize: dimensions.height < 700 ? 20 : 32,
              }}
            >
              {event_title}
            </TextBold>

            <View
              style={{
                padding: 10,
                backgroundColor: 'rgba(255,255,255,0.2)',
                borderRadius: 15,
                marginVertical: 20,
              }}
            >
              {
                //   dimensions.height > 580 && (
                //   <View
                //     style={{
                //       flexDirection: "row",
                //       alignItems: "center",
                //       marginBottom: 5,
                //       marginBottom: 10
                //     }}
                //   >
                //     <Feather
                //       name="calendar"
                //       size={16}
                //       color="white"
                //       style={{ marginRight: 5 }}
                //     />
                //     <TextSemi style={{ color: "white" }}>
                //       {moment(date?.startTime?.seconds * 1000).format(
                //         "dddd Do MMMM YYYY"
                //       )}
                //     </TextSemi>
                //   </View>
                // )
              }
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <SimpleLineIcons
                  name="location-pin"
                  size={16}
                  color="white"
                  style={{ marginRight: 5 }}
                />

                <TextSemi style={{ flex: 1, color: 'white' }}>{location?.address}</TextSemi>
                {location?.mapLink && (
                  <TouchableOpacity
                    style={{
                      backgroundColor: 'rgba(255,255,255, 0.2)',
                      borderRadius: 10,
                      padding: 10,
                      alignItems: 'center',
                      justifyContent: 'center',
                      alignSelf: 'flex-end',
                    }}
                    onPress={() => location?.mapLink && Linking.openURL(location?.mapLink)}
                  >
                    <TextSemi style={{ color: 'white' }}>GO</TextSemi>
                  </TouchableOpacity>
                )}
              </View>
            </View>
          </View>

          <View
            style={{
              backgroundColor: 'transparent',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <View
              style={{
                backgroundColor: 'transparent',
                width: '100%',
                alignItems: 'center',
              }}
            >
              <View
                style={{
                  backgroundColor: 'white',
                  alignItems: 'center',
                  //width: 190,
                  padding: 15,
                  margin: 20,
                  borderRadius: 10,
                }}
              >
                <QRCode value={qr_id} size={150} />
                {!valid && <Invalid {...props} />}
                {!hasOwner && !hide_qr && <AddOwner {...props} />}
              </View>
              <View
                style={{
                  width: '100%',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  // backgroundColor: "yellow",
                }}
              >
                {!!groupQty && !groupQty?.includes('undefined') && (
                  <TicketNumber groupQty={groupQty} />
                )}
                {hasOwner && (
                  <Pressable onPress={props.onOwnerNamePress} style={{ width: '60%' }}>
                    <TextLight
                      style={{
                        color: 'white',
                        textAlign: 'center',
                      }}
                      ellipsizeMode="tail"
                      numberOfLines={1}
                    >
                      {owner.name} {owner.surname}
                    </TextLight>
                  </Pressable>
                )}
                <TouchableOpacity
                  onPress={() => props.setShowTermsModal(true)}
                  // style={{ padding: 20, position: "absolute", bottom: 0, right: 0 }}
                >
                  <MaterialCommunityIcons name="information-outline" size={25} color="white" />
                </TouchableOpacity>
              </View>

              {/* <TextLight
            style={{
              color: "white",
            }}
          >
            {qr_id}
          </TextLight> */}
            </View>
          </View>
          <TouchableOpacity
            onPress={() => props.setShowTermsModal(true)}
            style={{ padding: 20, position: 'absolute', bottom: 0, right: 0 }}
          >
            <MaterialCommunityIcons name="information-outline" size={25} color="white" />
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => props.onEmailPress(viewShot, qr_id)}
            style={{
              padding: 20,
              position: 'absolute',
              bottom: 0,
              left: 0,
            }}
          >
            {isEmailPasses && (
              <View>
                {emailLoading ? (
                  <ActivityIndicator size="small" color="white" />
                ) : (
                  <View>
                    {isEmailSent === true ? (
                      <MaterialCommunityIcons name="email-check-outline" size={25} color="white" />
                    ) : isEmailSent === false ? (
                      <MaterialCommunityIcons name="email-alert-outline" size={25} color="white" />
                    ) : (
                      <MaterialCommunityIcons name="email-outline" size={25} color="white" />
                    )}
                  </View>
                )}
              </View>
            )}
          </TouchableOpacity>
        </LinearGradient>
      </SafeAreaView>
    </ViewShot>
  );
}
{
  /* {<TextBold
                style={{ color: "white", fontSize: 18, alignSelf: "center" }}
              >
                ZAR {price} // also add COMP if a complimetary tikcet
              </TextBold>} */
}
{
  /*  <TextBoldSmall style={{ color: "white", fontSize: 14 }}>
                   {description}
                 </TextBoldSmall> */
}

//<Fontisto name="qrcode" size={150} color="#5E5E5E" />

const styles = StyleSheet.create({
  infoContainer: {
    flex: 1,
    // alignItems: "center",
    // justifyContent: "center",
    // backgroundColor: "yellow"
  },
  cardContainer: {
    flex: 1,
    width: '100%',
    height: dimensions.height - 100, // take the max height of screen
    borderRadius: 15,
    alignItems: 'center',
    //justifyContent: "flex-end",
    backgroundColor: 'white', //colors.pay,
    // marginHorizontal: 5,
    shadowColor: 'rgba(0,0,0,0.4)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 20,
  },
  // image: {
  //   width: "100%",
  //   height: dimensions.width - 80,
  //   borderTopLeftRadius: 15,
  //   borderTopRightRadius: 15
  //   //flex: 1,
  //   //backgroundColor: "yellow",
  //   //height: dimensions.width - 40,
  //   //justifyContent: "flex-end",
  //   // borderRadius: 15
  // },
  // gradient: {
  //   flex: 1,
  //   position: "absolute",
  //   top: 0,
  //   width: dimensions.width - 40,
  //   height: dimensions.width - 80,
  //   justifyContent: "flex-end",
  //   padding: 20
  //   // borderBottomLeftRadius: 9,
  //   // borderBottomRightRadius: 9
  // }
});
