import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import {
  View,
  Image,
  TextInput,
  ScrollView,
  FlatList,
  TouchableHighlight,
  StyleSheet,
  ActivityIndicator,
  Linking,
} from 'react-native';
import Text from '../../components/Text/TextLight';
import ButtonX from '../../components/buttons/ButtonX';
import InputMat from '../../components/common/MaterialInput';
import { SimpleLineIcons, Feather } from '@expo/vector-icons';
import ModalHeader1 from '../../components/headers/Header1';
import { dimensions, colors, typography } from '../../utils/theme';
import generateOTP from '../../utils/generateOTP';
import { cashOut } from '../../actions';
// import { TouchableHighlight } from "react-native-gesture-handler";

// const ewallet = require("../../assets/images/ewallet.png");

const refundGform =
  'https://docs.google.com/forms/d/e/1FAIpQLSf71zd7rQZZXfxcL30IjsL2z2IC9G8ADTunRDt4sO0-Gg05fA/viewform';

const SuccessMessage = (props) => {
  return (
    <View
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <SimpleLineIcons name="check" size={35} color={colors.pay} />
      <Text style={styles.mediumText}>Success</Text>
      <Text style={{ textAlign: 'center', width: '80%', fontSize: 14 }}>
        You will receive a refund within 48 hours.
      </Text>
      <Text style={styles.mediumText}>Thank you!</Text>

      <ButtonX
        type="outline"
        title="Done"
        onPress={() => {
          props.onClearModal();
        }}
      />
    </View>
  );
};

const CashingOutIndicator = () => {
  return (
    <View
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ActivityIndicator size="large" color={colors.pay} />
      <Text style={styles.mediumText}>Requesting refund...</Text>
    </View>
  );
};

const Button = (props) => {
  return <ButtonX {...props} type="solid" addStyle={{ marginVertical: 20 }} />;
};

function CashoutModal(props) {
  const { onClearModal } = props;
  const { user } = props;
  const { userRefundablePasses } = props;
  // const { cellphoneNumber } = user;
  const [phoneNumber, setPhoneNumber] = useState(user?.cellphoneNumber || '+27');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    setLoading(false);
    setSuccess(false);
    setFailed(false);
  }, [onClearModal]);

  return (
    <View style={styles.modalContainer}>
      <View style={{ width: '100%', paddingVertical: 20, paddingBottom: 0 }}>
        <ModalHeader1
          heading="Refund Request"
          subHeading="Ticket Refund"
          body="NOT required to be an FNB client."
          onClose={onClearModal}
        />
      </View>

      <ScrollView
        // stickyHeaderIndices={[2]}
        style={{ paddingBottom: 40, margin: -20 }}
        contentContainerStyle={styles.scrollContainer}
      >
        {loading ? (
          <CashingOutIndicator />
        ) : success ? (
          <SuccessMessage {...props} />
        ) : (
          <View style={{ flex: 1, alignItems: 'center' }}>
            {/* {userRefundablePasses.map((item) => {
              return (
                <MiniPass
                  item={item}
                  onSelect={() => addSelected(item.qr_id)}
                  selected={selected.includes(item.qr_id)}
                />
              );
            })} */}
            {/* {userRefundablePasses?.length && (
              <FlatList
                data={userRefundablePasses}
                ListHeaderComponent={<Text>Select passes to refund</Text>}
                renderItem={({ item }) => (
                  <MiniPass
                    item={item}
                    onSelect={() => addSelected(item.qr_id)}
                    isSelected={selected}
                  />
                )}
                keyExtractor={(item) => item.qr_id}
                extraData={selected}
              />
            )} */}
            {/* {user?.credit + user?.pendingCredit === 0 ? (
              <View style={{ flex: 1, alignItems: 'center' }}>
                <Text style={{ textAlign: 'center', width: '90%', fontSize: 14 }}>
                  You do not have any funds in your cashless wallet. If you would like to request a
                  refund for tickets purchased, please submit your bank details using the form
                  below. You will be notified of the refund status.
                </Text>
                <Text style={styles.mediumText}>
                  Note: Refund policy of the organiser and other fees may apply.
                </Text>
                <Button title="Open Refund Form" onPress={() => Linking.openURL(refundGform)} />
              </View>
            ) : ( */}
            <View
              style={{
                flex: 1,
                alignItems: 'center',
              }}
            >
              {/* <Text style={{ textAlign: 'center', width: '90%', fontSize: 14 }}>
                  You are about to cash out the remaining funds in your AirDosh wallet. You will
                  receive the funds via FNB e-wallet. FNB will send you an SMS to confirm the
                  refund, along with instructions on claiming the funds. E-wallet may be used to
                  withdraw cash from FNB ATMs, buy prepaid airtime, electricity, and more.
                </Text>
                <Text style={styles.mediumText}>Note: Refund fee of R20 applies.</Text>

                <View
                  style={{
                    width: dimensions.width - 60,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginVertical: 20,
                  }}
                >
                  <InputMat
                    label="Phone Number"
                    placeholder="e.g. +27823334444"
                    onChangeText={setPhoneNumber}
                    value={phoneNumber}
                    keyboardType="phone-pad"
                    autoCompleteType="tel"
                  />
                  <Button
                    loading={loading}
                    title="Refund Now"
                    onPress={() => {
                      setLoading(true);

                      props
                        .cashOut(user)
                        .then(() => {
                          setLoading(false);
                          setSuccess(true);
                          RootNavigation.navigate('Wallet');
                        })
                        .catch(() => {
                          setFailed(true);
                        });
                    }}
                  />
                </View> */}

              <br />
              <Text style={{ width: '90%', fontSize: 14 }}>
                Should you no longer be able to attend an event, a ticket refund may be requested by
                completing the form below.
                <br />
                <br />
                Refund status updates may be sent by WhatsApp or SMS.
              </Text>
              <br />
              <br />
              <Button
                title="Open Refund Form"
                onPress={() => Linking.openURL('https://linktr.ee/airdosh')}
              />
            </View>
            {/* )} */}
          </View>
        )}
      </ScrollView>
    </View>
  );
}

const mapStateToProps = (state) => {
  const nowSeconds = new Date().getTime() / 1000;

  const { user, userPasses } = state.users;

  return {
    user,
    userRefundablePasses: userPasses.filter(
      (pass) => pass.date?.startTime?.seconds > nowSeconds && pass.valid && pass.price !== 0,
    ),
  };
};
// phone has to be local humber to accept ewalle
// tickets must be future dated, and non zero value, and valid
// only show cashless if balance is non zero
// total amount below R2000 can be ewallet

export default connect(mapStateToProps, { cashOut })(CashoutModal);
{
  /* {
        <Image
          source={{ uri: ewallet }}
          resizeMode="contain"
          style={{
            // flex: 1,
            marginVertical: 20,
            width: "100%", //dimensions.width,
            height: "100%"
          }}
        />} */
}

// <Image
//   source={{ uri: ewallet }}
//   resizeMode="cover"
//   style={{
//     flex: 1,
//     width: "100%", //dimensions.width,
//     height: "100%"
//   }}
// />

//contentContainerStyle={{ width: "100%", height: "100%" }}

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    backgroundColor: 'white',
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
    margin: -18,
    marginTop: 10,
    paddingHorizontal: 20,
    alignItems: 'center',
  },
  scrollContainer: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mediumText: {
    fontSize: 14,
    textAlign: 'center',
    fontWeight: 'bold',
    color: colors.textSolid,
    width: '100%',
    margin: 10,
  },
  miniPassContainer: {
    width: dimensions.width - 30,
    //backgroundColor: "white",
    borderRadius: 15,
    marginVertical: 10,
    padding: 20,
    //
    shadowColor: 'rgba(0,0,0,0.2)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 20,
  },
  selectedPass: {
    flexDirection: 'row',
    alignItems: 'center',
    shadowColor: 'rgba(0,0,0,0.2)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 20,
    backgroundColor: colors.bg1,
  },
});
