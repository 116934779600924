import React, { useState, useEffect } from 'react';
import { auth } from '../../../firebase/config';
import moment from 'moment';
import {
  Linking,
  ScrollView,
  FlatList,
  TouchableOpacity,
  TouchableHighlight,
  ActivityIndicator,
  StyleSheet,
  View,
  Image,
} from 'react-native';
import InputMat from '../../components/common/MaterialInput';
import ButtonX from '../../components/buttons/ButtonX';
import TextBS from '../../components/Text/TextBoldSmall';
import Text from '../../components/Text/TextLight';
import { connect } from 'react-redux';
import Modal from 'modal-enhanced-react-native-web';
import { Feather } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { dimensions, colors, typography } from '../../utils/theme';
import { formatDate, timeAgo } from '../../utils/timeAgo';
import { Card, Typo2Way } from '../../components/common';
import { listenUserWallet } from '../../actions';
import { SectionList } from 'react-native-web';

const GutterMD = () => <View style={{ height: 20 }} />;

const ItemButton = ({ title, onPress, containerStyle, textStyle }) => {
  return (
    <TouchableOpacity onPress={onPress} style={[styles.itemButton, containerStyle]}>
      <TextBS style={[{ color: colors.textSolid }, textStyle]}>{title}</TextBS>
    </TouchableOpacity>
  );
};

const MiniPass = (props) => {
  const { color1, color2, event_title, prod_title, qr_id, price } = props.item;
  const { onSelect, isSelected, selection } = props;
  return (
    <TouchableHighlight onPress={onSelect}>
      <View style={selection.includes(qr_id) && styles.selectedPass}>
        <LinearGradient
          colors={[color1, color2]}
          start={{ x: 0, y: 1 }}
          end={{ x: 1, y: 0 }}
          style={styles.miniPassContainer}
        >
          <Text style={styles.passText}>{isSelected}</Text>
          <Text style={styles.passText}>{event_title}</Text>
          <Text style={styles.passText}>{prod_title}</Text>
          <Text style={styles.passText}>R {price}</Text>
        </LinearGradient>
        {selection.includes(qr_id) && (
          <Feather name="check" size={24} color={colors.highlight} style={{ marginBottom: 5 }} />
        )}
      </View>
    </TouchableHighlight>
  );
};

const RecipientForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('+27');
  const [surname, setSurname] = useState('+27');
  const [phoneNumber, setPhoneNumber] = useState('+27');
  const [email, setEmail] = useState('+27');

  const formatPhoneNumber = () => {
    phoneNumber.charAt(0) === '0' && setPhoneNumber(phoneNumber.replace('0', '+27'));
  };

  return (
    <View>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          formatPhoneNumber();
          // sendVerification();
        }}
      >
        <InputMat
          required
          label="First Name"
          placeholder="Enter first name"
          onChangeText={setName}
          value={name}
          autoComplete="name"
          textContentType="name"
          checkValidation={(val) => !val.length && 'First name required'}
        />
        <InputMat
          required
          label="Last Name"
          placeholder="Enter last name"
          onChangeText={setSurname}
          value={surname}
          autoComplete="name-family"
          textContentType="familyName"
          checkValidation={(val) => !val.length && 'Last name required'}
        />
        <InputMat
          required
          label="Phone Number"
          placeholder="e.g. +27823334444"
          onChangeText={setPhoneNumber}
          value={phoneNumber}
          onBlur={() => formatPhoneNumber()}
          keyboardType="phone-pad"
          autoComplete="tel"
          autoCompleteType="tel"
          checkValidation={(val) => val.length < 10 && 'Mobile number too short'}
        />
        <InputMat
          required
          label="Email"
          placeholder="Enter email address"
          onChangeText={setEmail}
          value={email}
          autoComplete="email"
          autoCompleteType="email"
          keyboardType="email-address"
          checkValidation={(val) =>
            (val.length < 3 || !val.includes('@') || !val.includes('.')) &&
            'Enter a valid email address'
          }
        />
        <button
          style={{
            border: 'none',
            background: 'none',
            width: '100%',
            padding: 0,
          }}
          type="submit"
          // disabled={signInMethod === 0 && phoneNumber.length < 10}
        >
          <ButtonX
            type="solid"
            loading={loading}
            title="Continue"
            addStyle={{
              borderRadius: 10,
              backgroundColor: phoneNumber.length < 10 ? colors.shade2 : colors.balance,
              marginVertical: 20,
              marginTop: 10,
              width: '100%',
            }}
            titleStyle={{ fontWeight: 'bold', fontSize: 16 }}
          />
        </button>
      </form>
    </View>
  );
};

function TicketTransfer(props) {
  const { navigation } = props;
  const { user, userRefundablePasses } = props;

  const [selection, setSelected] = useState([]);

  useEffect(() => {
    const { user } = props;
    var unsubscribe = () => null;

    if (user) {
      try {
        unsubscribe = props.listenUserWallet(user?._id);
      } catch (error) {
        console.log('Could not listen for user Wallet');
      }
    }

    return () => unsubscribe();
  }, [user]);

  const addSelected = (qr_id) => {
    if (selection?.includes(qr_id)) {
      const qrIndex = selection.findIndex((qr) => qr === qr_id);
      selection.splice(qrIndex, 1);
      setSelected(selection);
    } else {
      selection.push(qr_id);
      setSelected(selection);
    }
  };

  return (
    <View
      style={{
        flex: 1,
        padding: 15,
        backgroundColor: colors.bg,
      }}
    >
      <Typo2Way style={{ marginBottom: 10 }} subTitle="Pass owner" title="Transfer ticket" />

      <ScrollView style={{ flex: 1, marginHorizontal: -15, padding: 15 }}>
        <Text style={{ color: colors.shade5, fontSize: 14 }}>
          Transfer a QR code pass from your wallet account to another person's account.
        </Text>
        <GutterMD />
        {/* <Text>Select passes to refund</Text> */}
        {/* {userRefundablePasses.map((item, i) => (
          <MiniPass
            key={i}
            item={item}
            onSelect={() => addSelected(item.qr_id)}
            selection={selection}
            // isSelected={!!selection?.includes(item.qr_id)}
          />
        ))} */}
        <FlatList
          data={userRefundablePasses}
          ListHeaderComponent={<Text>Select passes to refund {selection}</Text>}
          renderItem={({ item }) => (
            <MiniPass
              item={item}
              onSelect={() => addSelected(item.qr_id)}
              selection={selection}
              // isSelected={!!selection?.includes(item.qr_id)}
            />
          )}
          keyExtractor={(item) => item.qr_id}
          extraData={selection}
        />

        <RecipientForm />

        <ItemButton
          title="Transfer"
          containerStyle={{ backgroundColor: colors.black }}
          textStyle={{ color: 'white' }}
          onPress={() => console.log('Submit selection', selection)}
        />
      </ScrollView>
    </View>
  );
}
const mapStateToProps = (state) => {
  const { user, userPasses } = state.users;

  const nowSeconds = new Date().getTime() / 1000;

  return {
    user,
    userRefundablePasses: userPasses,
    // .filter(
    //   (pass) => pass.date?.startTime?.seconds > nowSeconds
    //  &&
    // pass.valid && pass.price !== 0
    // ),
  };
};
export default connect(mapStateToProps, { listenUserWallet })(TicketTransfer);

const styles = StyleSheet.create({
  itemButton: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 5,
    padding: 20,
    backgroundColor: colors.shade1,
    borderRadius: 5,
  },
  passText: { fontSize: 14, color: 'white' },
  miniPassContainer: {
    width: dimensions.width - 30,
    //backgroundColor: "white",
    borderRadius: 15,
    marginVertical: 5,
    padding: 20,
    //
    shadowColor: 'rgba(0,0,0,0.2)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 20,
  },
  selectedPass: {
    flexDirection: 'row',
    alignItems: 'center',
    shadowColor: 'rgba(0,0,0,0.2)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 20,
    backgroundColor: colors.bg1,
  },
});
