import { connect } from 'react-redux';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import React, { useState, useRef, useEffect } from 'react';
import { Fontisto } from '@expo/vector-icons';
import TextS from '../../../components/Text/TextS';
import ButtonX from '../../../components/buttons/ButtonX';
import { dimensions, colors, typography } from '../../../utils/theme';
import {
  // signInOAuth,
  showAuthModal,
  showEmailAuthModal,
} from '../../../actions';

export function AuthOptions(props) {
  return (
    <View style={{ alignItems: 'center' }}>
      <TextS>Alternative sign in options</TextS>

      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <View style={{ alignItems: 'center' }}>
          <TouchableOpacity
            style={styles.iconStyle}
            onPress={() => {
              props.showEmailAuthModal(true);
              props.showAuthModal({
                showModal: false,
              });
            }}
          >
            <Fontisto name="email" size={22} color={colors.textSolid} />
          </TouchableOpacity>
          <TextS>Email</TextS>
        </View>
        <View style={{ alignItems: 'center' }}>
          <TouchableOpacity style={styles.iconStyle} onPress={() => props.signInOAuth('google')}>
            <Fontisto name="google" size={22} color={colors.textSolid} />
          </TouchableOpacity>
          <TextS>Google</TextS>
        </View>
        {/* <View style={{ alignItems: "center" }}>
          <TouchableOpacity
            style={styles.iconStyle}
            onPress={() => props.signInOAuth("facebook")}
          >
            <Fontisto name="facebook" size={22} color={colors.textSolid} />
          </TouchableOpacity>
          <TextS>Facebook</TextS>
        </View> */}
      </View>
    </View>
  );
}

// const mapStateToProps = (state) => {
//   const { product } = state.store;
//   return {
//     product,
//   };
// };

export default connect(null, {
  // signInOAuth,
  showAuthModal,
  showEmailAuthModal,
})(AuthOptions);

const styles = StyleSheet.create({
  iconStyle: {
    backgroundColor: colors.shade1,
    borderRadius: 35,
    height: 70,
    width: 70,
    padding: 10,
    margin: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
