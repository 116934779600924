import React, { useState, useRef, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { View, StyleSheet } from 'react-native';
import { dimensions, colors, typography } from '../../utils/theme';
import ButtonX from '../../components/buttons/ButtonX';
import Picker from '../../components/common/Picker';
import NumberPicker from '../../components/common/PickerNumber';
import { Card, Typo3Way } from '../../components/common';
import { addToCart } from '../../actions';

const mapImage = require('../../assets/images/map.png');

const qtyAvails = (qty = 10) => {
  const qtyArray = [1];

  for (let i = 0; i < qty + 1; i++) {
    i !== 0 && !qtyArray.includes(i) && qtyArray.push(i);
  }

  return qtyArray;
};

function ProductSelector(props) {
  const { eventFocus } = useSelector((state) => state.config);
  const { productList, discount } = props;
  const { onProductAdded } = props;

  useEffect(() => {
    // this hard refreshed the ProductSelector component to change to a new setSelectedProduct
    //for some reason when we go from event to event, the selected product in the product picker stayed the same and slevcted an undefined product
    setSelectedProduct(productList[0]?.id);
  }, [productList]);

  const [selectedProduct, setSelectedProduct] = useState(productList[0].id); //productList[0].id
  const [qty, setQty] = useState(1);
  const selectedProdIndex = productList.findIndex((prod) => prod.id === selectedProduct);
  const selectedProdMax = productList[selectedProdIndex]?.max_per_cart || null;
  const isProductMaxInCart = false; //  check if the selected product is already in the cart, and check if the qty >= max_per_cart, then diable the add to cart button if true
  //
  return (
    <Card>
      <Typo3Way
        subTitle="My Cart"
        title="Select Tickets"
        body="Select your ticket type & quantity. Add to your cart. Pay with a
          single click. Easiest checkout ever."
      />

      <View style={{ flexDirection: 'row', marginTop: 10 }}>
        <NumberPicker
          label="Qty"
          items={qtyAvails(selectedProdMax)}
          selectedItem={qty}
          onSelect={(val) => setQty(val)}
          addStyle={{
            marginRight: 5,
            width: (dimensions.width - 60) * 0.3,
          }}
        />

        <Picker
          label="Select Tickets"
          items={productList}
          selectedItem={selectedProduct}
          onSelect={(prodId) => setSelectedProduct(prodId)}
          addStyle={{ marginLeft: 5, width: (dimensions.width - 55) * 0.65 }}
        />
      </View>

      <ButtonX
        type="solid"
        title="Add to Cart"
        disabled={productList.length === 0}
        addStyle={{ marginTop: 10, backgroundColor: colors.black }}
        onPress={() => {
          const selectedIndex = productList.findIndex((prod) => prod.id === selectedProduct);
          const item = productList[selectedIndex];

          props.addToCart({
            prod_id: item.id,
            qty,
            discount,
            event_id: eventFocus.id,
            selected_time_slot: timeSlotTime,
            selected_date_slot: timeSlotDay,
          });
          onProductAdded();
        }}
      />
    </Card>
  );
}

export default connect(null, { addToCart })(ProductSelector);

const imageHieght = dimensions.width - 200;

const styles = StyleSheet.create({
  container: {
    //height: 550,
    width: dimensions.width - 30,
    borderRadius: 15,
    // margin: 20,
    marginVertical: 0,
    backgroundColor: 'white',
    //
    shadowColor: 'rgba(0,0,0,0.4)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 20,
  },
  image: {
    width: '100%',
    height: imageHieght,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
  },
  gradient: {
    flex: 1,
    position: 'absolute',
    top: 0,
    width: dimensions.width - 30,
    height: imageHieght,
    justifyContent: 'flex-end',
    padding: 20,
  },
});
