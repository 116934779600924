import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import EnterOTPForm from '../views/Auth/EnterOTPForm';
import ManageDualAccount from '../views/Auth/ManageDualAccount';
import LinkCredentials from '../views/Auth/LinkCredentials';
import EmailLinked from '../views/Auth/EmailLinked';
import SignUpEmail from '../views/Auth/SignUpEmail';
import SignInEmail from '../views/Auth/SignInEmail';
import ForgotPassword from '../views/Auth/ForgotPassword';
import SignUpPhone from '../views/Auth/SignUpPhone';
import BackHeader from '../components/common/NavButtonBack';
import * as RootNavigation from '../RootNavigation.js';

const Stack = createStackNavigator();

export default function usersNav() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="EnterOTPForm"
        component={EnterOTPForm}
        options={{
          header: () => (
            <BackHeader
              title="Sign Up with Phone"
              onBackPress={() => RootNavigation.navigate('Wallet')}
            />
          ),
        }}
      />
      <Stack.Screen
        name="ManageDualAccount"
        component={ManageDualAccount}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="LinkCredentials"
        component={LinkCredentials}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="EmailLinked"
        component={EmailLinked}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="SignInEmail"
        component={SignInEmail}
        options={{
          header: () => (
            <BackHeader
              title="Sign In with Email"
              onBackPress={() => RootNavigation.navigate('Wallet')}
            />
          ),
        }}
      />
      <Stack.Screen
        name="SignUpEmail"
        component={SignUpEmail}
        options={{
          header: () => (
            <BackHeader
              title="Sign Up with Email"
              onBackPress={() => RootNavigation.navigate('Wallet')}
            />
          ),
        }}
      />
      <Stack.Screen
        name="ForgotPassword"
        component={ForgotPassword}
        options={{
          header: () => (
            <BackHeader title="Password Reset" onBackPress={() => RootNavigation.goBack()} />
          ),
        }}
      />

      <Stack.Screen
        name="SignUpPhone"
        component={SignUpPhone}
        options={{
          header: () => (
            <BackHeader
              title="Sign Up with Phone"
              onBackPress={() => RootNavigation.navigate('Wallet')}
            />
          ),
        }}
      />
    </Stack.Navigator>
  );
}
