import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Image, StyleSheet, ActivityIndicator, Linking } from 'react-native';
// import { Button } from 'react-native-elements';
import ButtonX from '../../components/buttons/ButtonX';
import { dimensions, colors, typography } from '../../utils/theme';
import { Card } from '../../components/common';
import ModalHeader1 from '../../components/headers/Header1';
import TextBold from '../../components/Text/TextBold';
import TextBoldSmall from '../../components/Text/TextBoldSmall';
import TextSemi from '../../components/Text/TextS';

const happy = require('../../assets/images/happyPay.png');
const imageDim = dimensions.width / 7;

export default function ({ onSignout, onClearModal }) {
  const { happyRedirectUrl } = useSelector((state) => state.store);

  return (
    <Card style={styles.modalContainer} childStyle={{ alignItems: 'center' }}>
      <ModalHeader1 heading="Happy Pay" subHeading="Checkout" onClose={onClearModal} />
      <TextSemi
        style={{
          // textAlign: "center",
          // width: '90%',
          color: colors.textSolid,
          opacity: 0.7,
        }}
      >
        You are about to navigate away from AirDosh and redirect to the Happy Pay web page, where
        you may sign up and pay for your ticket in installments.
      </TextSemi>

      <Image
        source={{ uri: happy }}
        style={{
          width: imageDim,
          height: imageDim,
          opacity: 0.8,
          borderRadius: 10,
          marginVertical: 30,
        }}
      />

      <ButtonX
        title="Go To Happy Pay"
        type="solid"
        buttonStyle={{
          height: 60,
          width: dimensions.width - 60,
          // margin: 10,
          // marginTop: 20,
          borderRadius: 10,
          backgroundColor: colors.black,
        }}
        titleStyle={{
          // fontWeight: 'bold',
          fontSize: 16,
          color: 'white',
        }}
        onPress={() => {
          Linking.openURL(happyRedirectUrl);
        }}
      />

      {
        // <BackArrow onPress={onClearModal} />
      }
    </Card>
  );
}

const styles = StyleSheet.create({
  modalContainer: {
    alignItems: 'center',
    alignSelf: 'center',
  },
});
