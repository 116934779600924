import React, { useState, useRef, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { View, StyleSheet, ScrollView } from 'react-native';
// import { Button } from "react-native-elements";
import ButtonX from '../../components/buttons/ButtonX';
import { dimensions, colors, typography } from '../../utils/theme';
import { CheckBox, Icon } from 'react-native-elements';
import ModalHeader1 from '../../components/headers/Header1';
import TextBS from '../../components/Text/TextBoldSmall';
import InputMat from '../../components/common/MaterialInput';
import PhoneInput from '../../components/PhoneInput';

import { updateUserPass, updateUserObj, updateAuthForm } from '../../actions';
import { TouchableOpacity } from 'react-native-gesture-handler';

function AccountFormModal(props) {
  const { onModalClose, showAccountModal, userToUpdate, isCovidScreen } = props;
  // const { accountFormFields } = props;

  // TODO: get the missing values for form from the userOBj "state.users" rather, NOT from global state
  const { authError, accountFormFields } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.users);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('');

  accountFormFields;
  const phoneInput = useRef(null);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const isFormValid =
    (accountFormFields.includes('name') && name.length) ||
    (accountFormFields.includes('surname') && surname.length) ||
    (accountFormFields.includes('email') &&
      email.length &&
      email.includes('@') &&
      email.includes('.')) ||
    (accountFormFields.includes('cellphoneNumber') &&
      formattedPhoneNumber.length &&
      formattedPhoneNumber.length > 9 &&
      formattedPhoneNumber.charAt(0) === '+');

  const handleSubmit = async () => {
    if (isFormValid) {
      await props.updateUserObj({
        uid: user._id,
        userObj: {
          name: accountFormFields.includes('name') ? name : user.name,
          surname: accountFormFields.includes('surname') ? surname : user.surname,
          email: accountFormFields.includes('email') ? email : user.email,
          cellphoneNumber: accountFormFields.includes('cellphoneNumber')
            ? formattedPhoneNumber.replace(/\s/g, '')
            : user.cellphoneNumber.replace(/\s/g, ''),
        },
      });
    } else {
      console.log('Form Not valid');
    }
  };

  return (
    <View
      style={{
        backgroundColor: colors.bg,
        borderRadius: 10,
        maxHeight: '100%',
      }}
    >
      <View
        style={{
          flex: 1,
          backgroundColor: colors.bg1,
          padding: 15,
          borderRadius: 10,
          width: '100%',
        }}
      >
        <form
          onSubmit={async (event) => {
            event.preventDefault();
            isFormValid && setLoading(true);
            // if no user name and surname on userObj yet, then update it now
            // (!user.name?.length || !user.surname?.length) &&
            //   props.updateCustomer({ ...user, name, surname });
            handleSubmit();
            onModalClose();
            setLoading(false);
          }}
        >
          <View>
            <ModalHeader1
              heading="Account info"
              subHeading="Name + contact info"
              description="Finish setting up your account, please enter the missing details."
              onClose={onModalClose}
            />
          </View>
          <ScrollView style={{ paddingVertical: 15 }}>
            {accountFormFields.includes('name') && (
              <InputMat
                required
                label="First Name"
                placeholder="Enter first name"
                onChangeText={(value) => setName(value)}
                // onChangeText={(value) => dispatch(updateAuthForm({ key: 'name', value }))}
                value={name}
                autoComplete="name"
                textContentType="name"
                checkValidation={(val) => !val.length && 'First name required'}
              />
            )}
            {accountFormFields.includes('surname') && (
              <InputMat
                required
                label="Last Name"
                placeholder="Enter last name"
                onChangeText={(value) => setSurname(value)}
                // onChangeText={(value) => dispatch(updateAuthForm({ key: 'surname', value }))}
                value={surname}
                autoComplete="name-family"
                textContentType="familyName"
                checkValidation={(val) => !val.length && 'Last name required'}
              />
            )}
            {accountFormFields.includes('email') && (
              <InputMat
                required
                label="Email address"
                placeholder="Enter email address"
                onChangeText={(value) => setEmail(value)}
                // onChangeText={setEmailInput}
                value={email}
                addContainerStyle={styles.inputText}
                keyboardType="email-address"
                autoComplete="email"
                autoCompleteType="email"
                checkValidation={(val) =>
                  (val.length < 3 || !val.includes('@') || !val.includes('.')) &&
                  'Enter a valid email address'
                }
              />
            )}
            {accountFormFields.includes('cellphoneNumber') && (
              <PhoneInput
                ref={phoneInput}
                defaultValue=""
                defaultCode="ZA"
                layout="first"
                onChangeText={(value, code) => {
                  // console.log('Raw phone number and country code separate', { value, code });
                  setFormattedPhoneNumber(`+${code}${value}`);
                }}
                autoFocus
              />
            )}

            <View>
              <TextBS style={styles.message}></TextBS>
            </View>
          </ScrollView>

          <button
            style={{
              border: 'none',
              background: 'none',
            }}
            type="submit"
            // disabled={!isFormValid}
          >
            <ButtonX
              type="solid"
              title="Submit"
              loading={loading}
              addStyle={{
                width: dimensions.width - 85,
                borderRadius: 10,
                marginTop: 20,
                backgroundColor: !isFormValid ? colors.shade2 : colors.black,
              }}
            />
          </button>
        </form>
      </View>
    </View>
  );
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {
  updateUserPass,
  updateUserObj,
  updateAuthForm,
})(AccountFormModal);

const styles = StyleSheet.create({
  backButton: {
    position: 'absolute',
    top: 20,
    right: 20,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    height: 40,
    width: 40,
    borderRadius: 20,
    shadowColor: 'rgba(0,0,0,0.4)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 20,
  },
  inputText: {
    // marginTop: 15,
    // marginBottom: 5,
    width: '100%',
    textAlign: 'center',
    fontSize: 18,
    outlineStyle: 'none',
    outlineWidth: 0,
    outlineColor: 'transparent',
  },
  message: {
    fontSize: 14,
    paddingHorizontal: 40,
    color: colors.secondary,
    textAlign: 'center',
  },
});
