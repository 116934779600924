import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import {
  View,
  Text,
  Image,
  ImageBackground,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
} from 'react-native';
import { Button } from 'react-native-elements';
import * as RootNavigation from '../../RootNavigation.js';
import { Fontisto, Feather, SimpleLineIcons } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { dimensions, colors, typography } from '../../utils/theme';
import TextBold from '../../components/Text/TextBold';

const CardGradient = (props) => {
  const { color1, color2 } = props.eventFocus;
  const gradientColors = [color1, color2];
  return (
    <LinearGradient
      {...props}
      colors={gradientColors}
      start={{ x: 0, y: 1 }}
      end={{ x: 1, y: 0 }}
      style={[styles.container, props.style]}
    >
      {props.children}
    </LinearGradient>
  );
};

export { CardGradient };

const styles = StyleSheet.create({
  container: {
    width: dimensions.width - 30,
    // borderWidth: 1,
    // borderColor: colors.shade3,
    // backgroundColor: "white",
    borderRadius: 15,
    // marginVertical: 10,
    padding: 15,
    //
    shadowColor: 'rgba(0,0,0,0.2)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 20,
  },
});
// width: dimensions.width - 30,
// borderRadius: 15,
// margin: 20,
// marginVertical: 10,
// backgroundColor: "white",
// //
// shadowColor: "rgba(0,0,0,0.4)",
// shadowOffset: { width: 0, height: 0 },
// shadowOpacity: 0.5,
// shadowRadius: 20
