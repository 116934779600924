// AUTH Actions
export const GLOBAL_INITIIALISING = 'global_initialising';
export const SHOW_AUTH_MODAL = 'show_auth_modal';
export const UPDATE_AUTH_FORM = 'update_auth_form';
export const SHOW_ACCOUNT_FORM = 'show_account_form';
export const SHOW_EMAIL_AUTH_MODAL = 'show_email_auth_modal';
export const SHOW_AUTH_LOADING_MODAL = 'show_auth_loading_modal';
export const SET_AUTH_ERROR = 'set_auth_error';
export const SET_REWARDS_AGENT = 'set_rewards_agent';

//SCHEDULE actions
export const LOGGING = 'logging';
export const PASS_LANDED = 'pass_landed';

//USERS actions
export const FETCH_USERS_SUCCESS = 'fetch_users_success';
export const FOUND_USER = 'found_user';
export const UPDATE_USER_OBJ = 'update_user_obj';
export const NEW_USER_CREATED_SUCCESS = 'new_user_created_success';
export const USER_PASSES_UPDATED = 'user_passes_updated';
export const PARSED_CELLNO_SUCCESS = 'parsed_cellno_success';

// Config
export const TOGGLE_INVITE_STATUS_SUCCESS = 'toggle_invite_status_success';
export const UPDATE_THEME_IMAGE = 'update_theme_image';
export const UPDATE_REFUND_POLICY = 'update_refund_policy';
export const SET_SHOW_NEW_CONTENT = 'set_show_new_content';
export const SET_GLOBAL_LOADING = 'set_global_loading';
export const SET_EVENT_FOCUS = 'set_event_focus';
export const UPDATE_EVENT_FOCUS = 'update_event_focus';

// PRODUCT actions
export const FETCH_BAR_ITEMS_SUCCESS = 'fetch_bar_items_success';
// export const UPDATE_PRODUCT_FLOAT_LIST = "update_product_float_list";
// export const UPDATE_PRODUCTS_BY_USER = "update_products_by_user";

// CART actions
export const ADD_TO_SUBCART_SUCCESS = 'add_to_subcart_success';
export const REMOVE_FROM_SUBCART_SUCCESS = 'remove_from_subcart_success';
export const UPDATE_SUBCART_SUCCESS = 'update_subcart_success';
export const SHOW_IN_PROGRESS = 'show_in_progress';
export const SHOW_CART = 'show_cart';
export const SHOW_ALT_PAY_OPTIONS = 'show_alt_pay_options';
export const PAYMENT_RECEIVED_TOPUP = 'payment_received_topup';
export const SHOW_IN_PROGRESS_MODAL = 'show_in_progress_modal';
export const SET_SHOW_PRODUCT_VARIABLES_MODAL = 'set_show_product_variable_modal';

export const PAYMENT_FAILED = 'payment_failed';
//
export const PAYMENT_RECEIVED_TICKET = 'payment_received_ticket';
export const SHOW_BALANCE_SPINNER = 'show_balance_spinner';
export const SHOW_PAYMENT_PENDING_WIDGET = 'show_payment_pending_widget';
export const SHOW_HAPPY_PAY_MODAL = 'show_happy_pay_modal';
// Market / Store ActionButtons
export const FETCH_EVENTS_SUCCESS = 'fetch_events_success';
export const FETCH_SINGLE_EVENT_SUCCESS = 'fetch_single_event_success';
export const FETCH_EVENT_ORGANISER_SUCCESS = 'fetch_event_organiser_success';
export const FETCH_EVENTS_PRODUCTS_SUCCESS = 'fetch_events_products_success';
export const FETCH_EVENTS_PRODUCTS_GLOBAL_SUCCESS = 'fetch_events_products_global_success';
export const FETCH_EVENTS_ALL_PRODUCTS_SUCCESS = 'fetch_events_all_products_success';

export const FETCH_EVENT_IMAGES_SUCCESS = 'fetch_events_images_success';
//
export const IMAGES_LOADED = 'images_loaded';

// MARKET PLACE actions
export const INJECT_EVENT = 'inject_event';

//Modal Actions
export const TAG_LINK_SUCCESS_MODAL = 'tag_link_success_modal';
export const TAG_LINK_FAIL_MODAL = 'tag_link_fail_modal';
export const SET_SHOW_TERMS_MODAL = 'set_show_terms_modal';

// Dashboard Actions

export const DASHBOARD_FETCH_SUCCESS = 'dashboard_fetch_success';
