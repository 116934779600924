import {
  LOGGING,
  //FETCH_USERS_SUCCESS,
  FOUND_USER,
  UPDATE_USER_OBJ,
  PAYMENT_RECEIVED_TICKET,
  USER_PASSES_UPDATED,
  SHOW_BALANCE_SPINNER,
  // UPDATE_PRODUCTS_BY_USER,
  NEW_USER_CREATED_SUCCESS,
  PARSED_CELLNO_SUCCESS,
} from '../actions/types';

const INITIAL_STATE = {
  loading: false,
  user: null,
  parsedCelllNo: '+27',
  parsedEmail: '',
  userPasses: [],
  spinnerBalance: false,
};

const usersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGGING:
      return { ...state, loading: true };
    case NEW_USER_CREATED_SUCCESS:
      return { ...state, user: action.payload };
    case FOUND_USER:
      if (!!action.payload?.rewards && !!action.payload?.rewards?.length) {
        const { rewards } = action.payload;
        var uniqueRewards = [...new Set(rewards?.map((rew) => rew.prod_id))];
        var summedRewards = uniqueRewards.map((prod_id) => ({
          ...rewards?.filter((rew) => rew.prod_id === prod_id)[0],
          qty: rewards?.filter((rew) => rew.prod_id === prod_id)?.reduce((a, b) => a + b.qty, 0),
        }));
        return {
          ...state,
          user: { ...action.payload, rewards: summedRewards || null },
        };
      } else {
        return {
          ...state,
          user: action.payload,
        };
      }

    case UPDATE_USER_OBJ:
      return { ...state, user: { ...state.user, ...action.payload } };
    case USER_PASSES_UPDATED:
      // state.user.purchasedItems = []; // initialise purchasedItems to []
      // state.user.purchasedItems = action.purchasedItems; // then make user.purchasedItems equal to the fetched purchasedItems
      return {
        ...state,
        //user: state.user,
        userPasses: action.purchasedItems.slice(),
      };
    case SHOW_BALANCE_SPINNER:
      return { ...state, spinnerBalance: action.bool };
    case PARSED_CELLNO_SUCCESS:
      return {
        ...state,
        parsedCelllNo: action.cellNo,
        parsedEmail: action.email,
      };
    default:
      return state;
  }
};

export default usersReducer;
