import React, { useState, useEffect } from 'react';
import { auth } from '../../../firebase/config';
import moment from 'moment';
import {
  ScrollView,
  TouchableOpacity,
  TouchableHighlight,
  ActivityIndicator,
  StyleSheet,
  View,
  Image,
} from 'react-native';
import TextBS from '../../components/Text/TextBoldSmall';
import Text from '../../components/Text/TextLight';
import { Avatar, Button, Chip } from 'react-native-elements';
import { connect } from 'react-redux';
import Modal from 'modal-enhanced-react-native-web';
import BackHeader from '../../components/common/NavButtonBack';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import UnlinkTagModal from '../../views/Modals/UnlinkTagModal';
import SignoutModal from '../../views/Modals/SignoutModal';
import CashoutModal from '../../views/Modals/CashoutModal';
import { dimensions, colors, typography } from '../../utils/theme';
import { formatDate, timeAgo } from '../../utils/timeAgo';
import { unlinkTag, signOut, sendCustomerMessage } from '../../actions';

const ItemButton = ({ title, onPress, containerStyle, textStyle }) => {
  return (
    <TouchableOpacity onPress={onPress} style={[styles.itemButton, containerStyle]}>
      <TextBS style={[{ color: colors.textSolid }, textStyle]}>{title}</TextBS>
    </TouchableOpacity>
  );
};

const AuthIcon = ({ authType }) => {
  var { providerData } = auth.currentUser;
  // console.log("Provider data", providerData);

  return providerData.map((provider) => provider.providerId).includes(authType) ? (
    <MaterialCommunityIcons
      name="shield-check"
      size={20}
      color={colors.pay}
      style={{ marginRight: 5, marginVertical: 2 }}
    />
  ) : (
    <MaterialCommunityIcons
      name="shield"
      size={20}
      color={colors.shade2}
      style={{ marginRight: 5, marginVertical: 2 }}
    />
  );
};

const AvatarProfile = (props) => {
  const { user, onPress } = props;

  return (
    <TouchableHighlight
      onPress={onPress}
      activeOpacity={0.8}
      underlayColor="transparent"
      style={{
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 20,
      }}
    >
      <View
        style={{
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Avatar
            size={64}
            rounded
            title="Nn"
            containerStyle={{ backgroundColor: colors.shade2, marginRight: 20 }}
          />
          <View>
            <TextBS style={{ color: colors.shade4, fontSize: 12, marginVertical: 2 }}>
              Signed in as
            </TextBS>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {auth.currentUser && !!user?.email && <AuthIcon authType="password" />}
              <Text
                style={{
                  color: colors.textSolid,
                }}
              >
                {!!user?.email && user?.email}
              </Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {auth.currentUser && <AuthIcon authType="phone" />}
              <Text
                style={{
                  color: colors.textSolid,
                }}
              >
                {!!user?.cellphoneNumber && user?.cellphoneNumber}
              </Text>
            </View>

            {/* {(!user.email || !user.cellphoneNumber) && (
            <Chip
              title={`Add ${!user.email ? "email" : "mobile number"}`}
              onPress={() => console.log("Icon chip was pressed!")}
              containerStyle={{ marginVertical: 5 }}
            />
          )} */}
            {/* <TouchableOpacity
            style={{
              backgroundColor: colors.balance,
              width: 100,
              borderRadius: 15,
              paddingVertical: 5,
              paddingHorizontal: 10,
              marginVertical: 5,
            }}
          >
            <Text style={{ color: "white", fontSize: 12 }}>
              Add {!user.email ? "email" : "mobile number"}
            </Text>
          </TouchableOpacity> */}
          </View>
        </View>
        {/* <Ionicons name="ios-arrow-forward" size={20} /> */}
      </View>

      {/*   source={{ uri: null } || {}} */}
    </TouchableHighlight>
  );
};

function Settings(props) {
  const { navigation, isCashless } = props;
  const { user } = props;
  //
  const [showUnlinkModal, setShowUnlinkModal] = useState(false);
  const [showSignoutModal, setShowSignoutModal] = useState(false);
  const [showCashoutModal, setShowCashoutModal] = useState(false);

  // check if there is a pending credit
  // const pendingCredit =
  // user.pendingCredit !== undefined ? user.pendingCredit : 0;
  // const cashoutMessage = `AirDosh message - You have cashed out your balance of R ${
  // user.credit + pendingCredit
  // }. Within 48hrs you will receive an e-wallet SMS, giving you access to your refund.`;

  const loadJS = (src) => {
    const ref = window.document.getElementsByTagName('script')[0];
    const script = window.document.createElement('script');
    script.src = src;
    script.async = true;
    ref.parentNode.insertBefore(script, ref);
  };

  // useEffect(() => {
  //   // this.loadJS("https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js", 1);
  //   loadJS("https://chat-assets.frontapp.com/v1/chat.bundle.js");
  // }, []);

  return (
    <View style={{ flex: 1 }}>
      <BackHeader {...props} title="User Settings" onBackPress={() => navigation.goBack()} />
      <View style={{ height: 60 }} />

      <View
        style={{
          flex: 1,
          alignItems: 'center',
          padding: 20,
          backgroundColor: colors.bg,
        }}
      >
        <AvatarProfile
          {...props}
          // onPress={() => navigation.navigate("ProfileSettings")}
        />
        {/* <ItemButton title="FAQ + Wallet Help" /> */}
        {!!user?.activeTagId?.length && (
          <ItemButton title="Unlink Tag" onPress={() => setShowUnlinkModal(true)} />
        )}
        <ItemButton title="Request refund" onPress={() => setShowCashoutModal(true)} />
        {/* {isCashless && <ItemButton title="Stop lost tag" />} */}
        {/* <ItemButton
        title="Transfer ticket"
        onPress={() => navigation.navigate("Transfer")}
      /> */}
        <ItemButton title="Contact AirDosh" onPress={() => navigation.navigate('Contact')} />
        <ItemButton title="Terms + Privacy policy" />
        <ItemButton
          title="Sign Out"
          containerStyle={{ backgroundColor: colors.black }}
          textStyle={{ color: 'white' }}
          onPress={() => setShowSignoutModal(true)}
        />
        <Modal isVisible={showUnlinkModal} onBackdropPress={() => setShowUnlinkModal(false)}>
          <UnlinkTagModal
            isAtagLinked={
              user?.activeTagId !== '' &&
              user?.activeTagId.charAt(0) === '0' &&
              user?.activeTagId.charAt(1) === 'x'
            }
            onUnlink={() => unlinkTag(user)}
            onClearModal={() => setShowUnlinkModal(false)}
          />
        </Modal>
        <Modal isVisible={showSignoutModal} onBackdropPress={() => setShowSignoutModal(false)}>
          <SignoutModal
            onSignout={() => {
              props.signOut(user);
              setShowSignoutModal(false);
              navigation.navigate('Wallet');
            }}
            onClearModal={() => setShowSignoutModal(false)}
          />
        </Modal>
        <Modal isVisible={showCashoutModal} onBackdropPress={() => setShowCashoutModal(false)}>
          <CashoutModal
            {...props}
            onCashout={() => {
              //sendCustomerMessage(user.cellphoneNumber, cashoutMessage);
              setShowCashoutModal(false);
            }}
            onClearModal={() => setShowCashoutModal(false)}
          />
        </Modal>
        {/* {loadCashingOut ? <CashingOutIndicator /> : null} */}
      </View>
    </View>
  );
}
const mapStateToProps = (state) => {
  const { inviteVerified, isCashless } = state.config;
  const { user } = state.users;

  return {
    user,
    inviteVerified,
    isCashless,
  };
};
export default connect(mapStateToProps, { signOut })(Settings);

const styles = StyleSheet.create({
  itemButton: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 5,
    padding: 20,
    backgroundColor: colors.shade1,
    borderRadius: 5,
  },
});
