import React, { useState, useEffect, useRef } from 'react';
import { Text, View, TouchableOpacity, ActivityIndicator } from 'react-native';
import * as Permissions from 'expo-permissions';
import { Camera } from 'expo-camera';
import { dimensions, colors, typography } from '../utils/theme';
import { BarCodeScanner } from 'expo-barcode-scanner';

const Cam = ({ onScanned }) => {
  const [scanned, setScanned] = useState(false);
  const [type, setType] = useState(Camera.Constants.Type.back);
  const [permission, askForPermission] = Permissions.usePermissions(Permissions.CAMERA, {
    ask: true,
  });
  const camRef = useRef();
  BarCodeScanner.Constants.BarCodeType.qr;

  const handleBarCodeScanned = ({ type, data }) => {
    setScanned(true);
    data.charAt(0) === 0 && data.charAt(1) === 'x'
      ? onScanned(data) //camRef.current.pausePreview())
      : extractUID(data);
  };

  const extractUID = (data) => {
    var uid = '';
    data.includes('https://airdosh-wallet.firebaseapp.com/') &&
      (uid = data.replace('https://airdosh-wallet.firebaseapp.com/', '0x')); // prefix "0x" as it wasnt written by card manufacturer
    data.includes('https://wallet.airdosh.co.za/') &&
      (uid = data.replace('https://wallet.airdosh.co.za/', '0x')); // prefix "0x" as it wasnt written by card manufacturer

    onScanned(uid);
  };

  if (permission?.permissions?.camera?.granted) {
    return (
      <View style={{ flex: 1 }}>
        <Camera
          ref={camRef}
          style={{ height: dimensions.height, width: dimensions.width }}
          type={type}
          flashMode={Camera.Constants.FlashMode.torch}
          onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
          barCodeScannerSettings={{
            barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
          }}
        />
      </View>
    );
  }
  return (
    <View>
      <ActivityIndicator color={colors.notice} />
    </View>
  );
};

export default Cam;
