import firebase from 'firebase/app';
import '@firebase/messaging';
import 'firebase/functions';
import 'firebase/firestore';
import 'firebase/messaging';
import 'firebase/auth';

// firebase.functions().useEmulator("localhost", 5001);

const { NODE_ENV, APP_MANIFEST } = process.env;
const { extra } = APP_MANIFEST;
const { devConfig, prodConfig } = extra;

process.env.NODE_ENV === 'development' && console.log('dev');

const firebaseConfig = process.env.NODE_ENV === 'development' ? prodConfig : prodConfig;

firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();
const auth = firebase.auth();
const functions = firebase.functions();
// use emulators in DEV mode
// process.env.NODE_ENV === 'development' && firebase.functions().useEmulator('localhost', 5001);

// const messaging = firebase.messaging();

firebase.firestore().settings({
  ignoreUndefinedProperties: true,
  merge: true,
});

firebase
  .firestore()
  .enablePersistence({ experimentalTabSynchronization: true })
  .then(() => console.log('Persisting data'))
  .catch((err) => {
    if (err.code == 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code == 'unimplemented') {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  });

export { firebase, firestore, auth, functions };
