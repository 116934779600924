import 'react-native-gesture-handler';
import React, { useEffect, useState, Suspense } from 'react';
import { Linking, useWindowDimensions } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { navigationRef, isReadyRef } from './RootNavigation';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Provider } from 'react-redux';
import { colors, typography, dimensions, logo } from './utils/theme';
import configureStore from './reducers';
import Navigation from './navigation/navigation.home';

import firebase from 'firebase/app';
import 'firebase/messaging';
// import { messaging, firebase } from "../firebase/config";
// LogBox.ignoreLogs(["Warning : componentWillReceiveProps"]);
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { setShowNewContent } from './actions';

const store = configureStore();

const PERSISTENCE_KEY = 'NAVIGATION_STATE';
const EVENT_URL = 'EVENT_URL';
const prefixes = [
  'http://localhost:19006/',
  'pilotmac.local:19006/',
  'https://airdosh-wallet.firebaseapp.com/',
  'https://event-wallet.web.app/',
  'https://wallet.airdosh.co.za/',
];

const userLinking = {
  ...prefixes,
  config: {
    screens: {
      Wallet: {
        path: 'user/:cellNo?/:email?/:orgId?/:event_id?',
        parse: {
          cellNo: (cellNo) => (cellNo !== 'null' && window.decodeURIComponent(cellNo)) || '',
          email: (email) => (email !== 'null' && window.decodeURIComponent(email)) || '',
          orgId: (orgId) => orgId,
          event_id: (event_id) => event_id,
        },
      },
    },
  },
};
const rewardLinking = {
  ...prefixes,
  config: {
    screens: {
      ['Event Page']: {
        path: 'reward/:orgId?/:event_id?/:uid?/:prod_id?',
        parse: {
          orgId: (orgId) => orgId,
          event_id: (event_id) => event_id,
          userId: (uid) => uid,
          prod_id: (prod_id) => prod_id,
        },
      },
    },
  },
};

const eventLinking = {
  ...prefixes,
  config: {
    screens: {
      ['Event Page']: {
        path: 'event/:orgId?/:event_id?/:prod_id?',
        parse: {
          orgId: (orgId) => orgId,
          event_id: (event_id) => event_id,
          prod_id: (prod_id) => prod_id,
        },
      },
      Desktop: {
        screens: {
          DesktopWallet: {
            path: 'event/:orgId?/:event_id?',
            parse: {
              orgId: (orgId) => orgId,
              event_id: (event_id) => event_id,
            },
          },
        },
      },
    },
  },
};
const authLinking = {
  ...prefixes,
  config: {
    screens: {
      Auth: {
        screens: {
          EmailLinked: {
            path: 'auth/:isSignUp?/:isSignIn?/:toNav?',
            parse: {
              isSignUp: (isSignUp) => isSignUp,
              isSignIn: (isSignIn) => isSignIn,
              toNav: (toNav) => toNav,
            },
          },
        },
      },
    },
  },
};

// const accountLinking = {
//   ...prefixes,
//   config: {
//     screens: {
//       Wallet: {
//         path: "account/:contact?",
//         parse: {
//           contact: (contact) => contact,
//         },
//         // screens: {
//         //   EmailLinked: {
//         //     path: "account/:contact?",
//         //     parse: {
//         //       contact: (contact) => contact,
//         //     },
//         //   },
//         // },
//       },
//     },
//   },
// };

const promoLinking = {
  ...prefixes,
  config: {
    screens: {
      ['Event Page']: {
        path: 'promo/:orgId?/:event_id?/:promo_id?/:link_id?',
        parse: {
          orgId: (orgId) => orgId,
          event_id: (event_id) => event_id,
          promo_id: (promo_id) => promo_id,
          link_id: (link_id) => link_id,
        },
      },
    },
  },
};

const promoMarketLinking = {
  ...prefixes,
  config: {
    screens: {
      PromoMarket: {
        path: 'promo_multi/:orgId?/:event_id?/:promo_id?/:link_id?',
        parse: {
          orgId: (orgId) => orgId,
          event_id: (event_id) => event_id,
          promo_id: (promo_id) => promo_id,
          link_id: (link_id) => link_id,
        },
      },
    },
  },
};

const happyPayLinking = {
  ...prefixes,
  config: {
    screens: {
      HappyPayResult: {
        path: 'happypay/:order_id',
        parse: {
          order_id: (order_id) => order_id,
        },
      },
    },
  },
};

const yocoPayLinking = {
  ...prefixes,
  config: {
    screens: {
      YocoResult: {
        path: 'payment/:order_id',
        parse: {
          order_id: (order_id) => order_id,
        },
      },
    },
  },
};

const { APP_MANIFEST } = process.env;
const { baseUrl } = APP_MANIFEST.extra;

export default function App() {
  const [isReady, setIsReady] = useState(false);
  const [initialState, setInitialState] = useState();
  const [linking, setLinkng] = useState();
  const { width } = useWindowDimensions();

  useEffect(() => {
    var ua = navigator.userAgent || navigator.vendor || window.opera;
    var isInst = ua.indexOf('Instagram') > -1 ? true : false;

    !!isInst && store.dispatch({ type: 'setInstagram', isInstagram: true });
  }, []);

  useEffect(() => {
    const restoreState = async () => {
      try {
        const initialUrl = await Linking.getInitialURL();
        store.dispatch({ type: 'initUrl', initialUrl });
        const savedStateString = await AsyncStorage.getItem(PERSISTENCE_KEY);
        const event_url = await AsyncStorage.getItem(EVENT_URL);

        const isUser = initialUrl.includes('/user/');
        const isEvent = initialUrl.includes('/event/');
        const isAuth = initialUrl.includes('/auth/');
        const isReward = initialUrl.includes('/reward/');
        const isPromo = initialUrl.includes('/promo/');
        const isPromoMarket = initialUrl.includes('/promo_multi/');
        const isHappyPayLink = initialUrl.includes('/happypay/');
        const isPaymentLink = initialUrl.includes('/payment/');

        // const isDualAccount = initialUrl.includes("/account/");

        // const shouldMobile = isMobile || width < 650;
        isUser && setLinkng(userLinking);
        isEvent && setLinkng(eventLinking);
        isAuth && setLinkng(authLinking);
        isReward && setLinkng(rewardLinking);
        isPromo && setLinkng(promoLinking);
        isPromoMarket && setLinkng(promoMarketLinking);
        isHappyPayLink && setLinkng(happyPayLinking);
        isPaymentLink && setLinkng(yocoPayLinking);

        // isUser && setLinkng(userLinking);
        // isUser && AsyncStorage.setItem(EVENT_URL, initialUrl);

        // console.log("savedStateString : ", JSON.parse(savedStateString));

        // isUser && AsyncStorage.setItem(EVENT_URL, initialUrl);
        const isNewEventLink = initialUrl !== event_url && initialUrl.includes('/event/');

        // the incoming link is not one of the below, then recall the savedStateString from async storage
        if (
          !isNewEventLink &&
          !isReward &&
          !isPromo &&
          !isAuth &&
          !isPromoMarket &&
          !isHappyPayLink &&
          !isPaymentLink
        ) {
          savedStateString && setInitialState(JSON.parse(savedStateString));
        }

        // the below does the same as the above???
        // const state = savedStateString ? JSON.parse(savedStateString) : undefined;
        // if (state !== undefined) {
        //   !isNewEventLink && !isReward && !isPromo && !isAuth && setInitialState(state);
        // }
      } finally {
        setIsReady(true);
      }
    };

    if (!isReady) {
      restoreState();
    }
  }, [isReady]);

  useEffect(() => {
    return () => {
      isReadyRef.current = false;
    };
  }, []);

  try {
    let pushToken;
    const messaging = firebase.messaging();
    messaging
      .getToken()
      .then((currentToken) => {
        if (currentToken) {
          pushToken = currentToken;
        }
      })
      .catch((error) => {
        console.log('An error ocurred while retrieving token. ', error);
      });

    messaging.onMessage((payload) => {
      const { title, ...options } = payload.notification;
      navigator.serviceWorker.register('firebase-messaging-sw.js');
      function showNotification() {
        Notification.requestPermission(function (result) {
          if (result === 'granted') {
            navigator.serviceWorker.ready.then(function (registration) {
              registration.showNotification(payload.notification.title, {
                body: payload.notification.body,
                tag: payload.notification.tag,
              });
            });
          }
        });
      }
      showNotification();
    });
  } catch (err) {
    // console.log("Messaging error", err);
  }

  if (!isReady) {
    return null;
  }
  return (
    <Provider store={store}>
      <NavigationContainer
        linking={linking}
        initialState={initialState}
        onStateChange={(state) => AsyncStorage.setItem(PERSISTENCE_KEY, JSON.stringify(state))}
        ref={navigationRef}
        onReady={() => {
          isReadyRef.current = true;
        }}
        documentTitle={{
          formatter: (options, route) => 'AirDosh | Get Tickets',
        }}
      >
        <Navigation linking={linking} />
      </NavigationContainer>
    </Provider>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

// Jaco removed on friday 18th march

// serviceWorkerRegistration.register({
//   onUpdate: (registration) => {
//     console.log("New AirDosh update available");
//     store.dispatch(setShowNewContent(true));
//     onServiceWorkerUpdate(registration);
//   },
// });

// This helped register apple pay domain
// https://stackoverflow.com/questions/63359402/how-to-verify-my-domain-with-apple-in-a-react-app

//Twitter Color pickers
//https://casesandberg.github.io/react-color/

//Native picker with PWA
//https://github.com/react-native-picker/picker#style

//not yet implemented
//for determining if desktop or mobile
//https://github.com/evanbacon/react-responsive#with-hooks

//https://medium.com/@ericmorgan1/upload-images-to-firebase-in-expo-c4a7d4c46d06
// /For anyone having issues returning the downloadURL #12
//https://github.com/expo/firebase-storage-upload-example/issues/12

//Picker (days)
//https://www.npmjs.com/package/react-native-dropdown-picker

//Time Picker
//https://material-ui-pickers.dev/getting-started/usage

//for adjusting elements when window size changes
//https://www.npmjs.com/package/react-native-responsive-dimensions

//medium style hand clap component
//https://react-clap-button.netlify.app/?path=/story/clapbutton--default
