import React from "react";
import { View, StyleSheet } from "react-native";
// import { Fontisto, Feather, SimpleLineIcons } from "@expo/vector-icons";
import { dimensions, colors } from "../../utils/theme";
import TextBold from "../../components/Text/TextBold";
import TextBoldSmall from "../../components/Text/TextBoldSmall";
import Text from "../../components/Text/TextLight";
import TextS from "../../components/Text/TextS";
import TextSemi from "../../components/Text/TextS";

const Typo3Way = (props) => {
  const { subTitle, title, body, style, subTitleStyle, titleStyle, bodyStyle } =
    props;
  return (
    <View style={[{ backgroundColor: "transparent" }, style]}>
      <View>
        <TextBoldSmall
          style={[
            { color: colors.balance, marginLeft: 1, fontSize: 14 },
            subTitleStyle,
          ]}
          numberOfLines={1}
          ellipsizeMode="tail"
        >
          {subTitle}
        </TextBoldSmall>
        <TextBold style={{ color: colors.shade4, fontSize: 24 }}>
          {title}
        </TextBold>
      </View>
      <TextSemi
        style={[
          { color: colors.shade4, fontSize: 14, marginTop: 5 },
          bodyStyle,
        ]}
      >
        {body}
      </TextSemi>
    </View>
  );
};

export { Typo3Way };
