import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { View, Text, Image, TouchableOpacity, StyleSheet, ActivityIndicator } from 'react-native';
import { Button } from 'react-native-elements';
import * as RootNavigation from '../../RootNavigation.js';
import { Fontisto, Feather, MaterialCommunityIcons } from '@expo/vector-icons';
import { dimensions, colors } from '../../utils/theme';
import ButtonX from '../buttons/ButtonX';
// import { Card, Typo3Way } from "../../components/common";
import TextBold from '../Text/TextBold';
import TextBoldSmall from '../Text/TextBoldSmall';
import TextSemi from '../../components/Text/TextS';
import {} from '../../actions';

const location2tone = require('../../assets/svg/location2tone.svg');
const cal2tone = require('../../assets/svg/cal2tone.svg');
const clock2tone = require('../../assets/svg/clock2tone.svg');

const iconWidth = '7%';
const textWidth = '90%';

const day = (seconds) => moment(seconds * 1000).format('Do');

const dateFormatted = (date) => {
  if (!!date) {
    const { startTime, endTime } = date;
    if (!endTime) {
      return `${moment(startTime.seconds * 1000).format('dddd Do MMM `YY')}`;
    } else if (!!endTime && !!startTime && day(startTime) === day(endTime)) {
      return `${moment(startTime.seconds * 1000).format('dddd Do MMM `YY')}`;
    } else {
      return `${moment(startTime.seconds * 1000).format('Do MMM YYYY')} - ${moment(
        endTime.seconds * 1000,
      ).format('Do MMM YYYY')}`;
    }
  } else {
    return 'Date TBC';
  }
};

const DateTimeIcon = (props) => {
  const { oneColor, showTimes, showDate, showSeat } = props;
  const { date, location, seat } = props.item;

  return (
    <View style={{}}>
      {!!showDate && (
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Feather
            name="calendar"
            size={12}
            color={colors.primaryText}
            style={[
              {
                width: iconWidth,
                margin: 2,
                marginHorizontal: 5,
                marginLeft: 0, // cancels out
              },
              oneColor,
            ]}
          />
          <TextSemi
            style={[{ color: colors.primaryText, fontSize: 10, width: textWidth }, oneColor]}
          >
            {dateFormatted(date)}
          </TextSemi>
        </View>
      )}
      {showTimes && (
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Feather
            name="clock"
            size={12}
            color="black"
            style={[
              {
                width: iconWidth,
                margin: 2,
                marginHorizontal: 5,
                marginLeft: 0, // cancels out
              },
              oneColor,
            ]}
          />

          <TextSemi
            style={[
              {
                color: colors.primaryText,
                fontSize: 10,
                width: textWidth,
              },
              oneColor,
            ]}
          >
            {!!date &&
              moment(date?.startTime?.seconds * 1000).format('HH:mm') +
                ' - ' +
                moment(date?.endTime?.seconds * 1000).format('HH:mm')}
          </TextSemi>
        </View>
      )}
      {showSeat && (
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <MaterialCommunityIcons
            name="seat-outline"
            size={12}
            color="black"
            style={[
              {
                width: iconWidth,
                margin: 2,
                marginHorizontal: 5,
                marginLeft: 0, // cancels out
              },
              oneColor,
            ]}
          />

          <TextSemi
            style={[
              {
                color: colors.primaryText,
                fontSize: 10,
                width: textWidth,
              },
              oneColor,
            ]}
          >
            Seat no. {seat}
          </TextSemi>
        </View>
      )}
    </View>
  );
};

export { DateTimeIcon };
