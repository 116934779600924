import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { View, StyleSheet, TouchableHighlight } from 'react-native';
import { Button } from 'react-native-elements';
import * as RootNavigation from '../../RootNavigation.js';
import { LinearGradient } from 'expo-linear-gradient';
import RenderHtml from 'react-native-render-html';
import HTMLView from 'react-native-htmlview';
import Expand from 'react-expand-animated';
import { dimensions, colors } from '../../utils/theme';
import { Card, Typo2Way } from '../../components/common';
import TextBold from '../../components/Text/TextBold';
import TextBoldSmall from '../../components/Text/TextBoldSmall';
import Text from '../../components/Text/TextLight';
import { Ionicons, FontAwesome5, MaterialCommunityIcons } from '@expo/vector-icons';

export default function (props) {
  const { event_info } = useSelector((state) => state.config.eventFocus);
  const [more, setMore] = useState(false);

  return (
    <Card>
      <Typo2Way style={{ marginBottom: 10 }} subTitle="Learn more" title="Event Info" />
      {/* <Text>{event_info}</Text> */}
      {/* <RenderHtml
        contentWidth={dimensions.width}
        source={{ html: event_info }}
      /> */}

      {!more && (
        <>
          <Text style={{ maxHeight: 150, overflow: 'hidden' }}>
            <HTMLView value={event_info} stylesheet={styles} />
          </Text>
          <LinearGradient
            colors={['rgba(255,255,255, 1)', 'rgba(255,255,255, 0.5)']}
            start={{ x: 1, y: 1 }}
            end={{ x: 1, y: 0 }}
            style={{ height: 25, width: '100%', marginTop: -20 }}
          />
        </>
      )}

      <Expand open={more}>
        <Text>
          <HTMLView value={event_info} stylesheet={styles} />
        </Text>
      </Expand>
      <View style={{ alignItems: 'flex-end', marginTop: 20 }}>
        <TouchableHighlight
          activeOpacity={0.9}
          underlayColor="transparent"
          onPress={() => setMore(!more)}
        >
          {more ? (
            <View style={styles.expandButton}>
              <TextBoldSmall style={{ color: colors.primaryText, opacity: 0.7 }}>
                {' '}
                Less{' '}
              </TextBoldSmall>
            </View>
          ) : (
            <View style={styles.expandButton}>
              <TextBoldSmall style={{ color: colors.primaryText, opacity: 0.7 }}>
                {' '}
                More{' '}
              </TextBoldSmall>
            </View>
          )}
        </TouchableHighlight>
      </View>
    </Card>
  );
}

const imageHieght = dimensions.width - 200;

const styles = StyleSheet.create({
  div: {
    fontFamily: 'open-sans-reg',
    fontSize: 14,
    color: colors.shade5,
    opacity: 0.9,
  },
  h3: {
    fontFamily: 'inter-bold',
    fontSize: 22,
    color: colors.shade5,
  },
  h5: {
    fontFamily: 'inter-bold',
    fontSize: 18,
    color: colors.shade5,
  },
  h6: {
    fontFamily: 'open-sans-reg',
    fontSize: 14,
    fontWeight: '600',
    color: colors.highlight,
  },
  ul: {
    listStyleType: 'outside',
  },
  li: {
    listStyleType: 'outside',
  },

  //   p: {
  //     fontFamily: "open-sans-reg",
  //     fontSize: 12,
  //     color: colors.shade5,
  //     opacity: 0.9,
  //   },

  container: {
    width: dimensions.width - 30,
    borderRadius: 15,
    padding: 15,
    margin: 20,
    marginVertical: 10,
    backgroundColor: 'white',
    //
    shadowColor: 'rgba(0,0,0,0.4)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 20,
  },
  image: {
    width: '100%',
    height: imageHieght,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    //flex: 1,
    //backgroundColor: "yellow",
    //height: dimensions.width - 40,
    //justifyContent: "flex-end",
    // borderRadius: 15
  },
  gradient: {
    flex: 1,
    position: 'absolute',
    top: 0,
    width: dimensions.width - 30,
    height: imageHieght,
    justifyContent: 'flex-end',
    padding: 20,
    // borderBottomLeftRadius: 9,
    // borderBottomRightRadius: 9
  },
  iconStyle: {
    height: 22,
    width: 22,
    color: colors.shade4,
  },
  expandButton: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    backgroundColor: 'rgba(0,0,0,0.05)',
    borderRadius: 8,
  },
});
