import { Platform, Dimensions } from "react-native";
// const logo = require("../assets/logo.png");
// const logoLight = require("../assets/logoLight.png");

const dimensions = Dimensions.get("window");

const lightTheme = {
  bg: "white", //"#2E2E2E", // "black", // "white"
  bg1: "rgba(255,255,255,0.1)",
  bg2: "rgba(255,255,255,0.05)",
  textSolid: "#333333", // "white",
  black: "#333333",
  balance: "#3CD7C4", //"#00D6D9", //"#5299F7", //blueish
  primary: "#f6f6f6", // light gray
  secondary: "#9fa5c0",
  primaryLight: "#272220", //"#FFFFFF", // white
  primaryDark: "#eaeaea", // darker gray
  primaryOpaque: (opacity) => `rgba(113, 113, 113, ${opacity})`,
  primaryText: "#2E3E5C", //dark
  labelText: "#FFFFFF", //white
  inactive: "rgba(0,0,0,0.1)", //light gray
  highlight: "#539bf8", //??
  alert: "#FF756A", //pinkish form pink dark theme
  negative: "#FF756A", //pinkish form pink dark theme
  success: "#39F787", //greenish
  notice: "#FFBD00", //yellowish
  warning: "#FFBD00", //yellowish
  pay: "#38bbb1",
  graydient: ["#D8D8D8", "transparent"],
  gradientSuccess: ["#29E3BB", "#037CBA"], // light to dark
  gradientAlert: ["#FCAB26", "#EA4954"],
  gradient1: ["#E77900", "#AE001F"],
  shade1: "#EEEEEE", // light gray
  shade2: "#DBDDE0", // medium light gray
  shade3: "#CACCCF", // darker light gray
  shade4: "#A0A4A8", // even darker light gray
  shade5: "#52575C",
};

const colors = {
  ...lightTheme, //change the entire app theme here!
};

const typography = {
  primary: Platform.select({
    ios: "Gotham Rounded",
    android: "gothamrounded",
  }),
  secondary: Platform.select({
    ios: "Gotham Rounded",
    android: "gothamrounded",
  }),
};

const images = {
  // activeNotificationIcon: require("../assets/Images/icon-notification-active.png"),
  // inactiveNotificationIcon: require("../assets/Images/icon-notification-inactive.png")
};

export {
  colors,
  typography,
  dimensions,
  //logo, logoLight
};
