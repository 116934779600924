import React, { useState, useRef, useEffect } from 'react';
// import { useSelector } from "react-redux";
import { connect } from 'react-redux';
import {
  View,
  ImageBackground,
  Pressable,
  ActivityIndicator,
  StyleSheet,
  ScrollView,
  Image,
  TouchableOpacity,
  LayoutAnimation,
} from 'react-native';
import moment from 'moment';
import * as Animatable from 'react-native-animatable';
import * as RootNavigation from '../../RootNavigation.js';
import { Fontisto, SimpleLineIcons, Octicons, MaterialIcons } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { dimensions, colors } from '../../utils/theme';
import TextBold from '../../components/Text/TextBold';
import TextBoldSmall from '../../components/Text/TextBoldSmall';
import IncrementButton from '../../components/buttons/ButtonInc';
import ButtonAdd from '../../components/buttons/ButtonAdd';
import { Button } from 'react-native-elements';
import arrow_next_left from '../../assets/svg/arrow_next_left.svg';
import arrow_next_right from '../../assets/svg/arrow_next_right.svg';

import { toggleImagesLoading } from '../../actions';
const imageDim = dimensions.width;

const dateFormatted = (seconds) => moment(seconds * 1000).format('MMM Do YYYY');

const ScrollArrows = (props) => {
  const { isStart, isEnd } = props;
  return (
    <View
      style={{
        position: 'absolute',
        top: imageDim / 2,
        bottom: imageDim / 2,
        left: 0,
        right: 0,
        width: '100%',
        padding: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      {isStart && (
        <Animatable.View
          animation="slideInLeft"
          iterationCount="infinite"
          duration={1000}
          iterationDelay={2000}
        >
          <MaterialIcons name="double-arrow" size={18} color={colors.shade4} />
        </Animatable.View>
      )}
      {isEnd && (
        <Animatable.View
          animation="slideInRight"
          iterationCount="infinite"
          duration={1000}
          iterationDelay={1000}
        >
          <MaterialIcons name="double-arrow" size={18} color={colors.shade4} />
        </Animatable.View>
      )}
      {!isStart && !isEnd && (
        <Animatable.View
          animation="slideInLeft"
          iterationCount="infinite"
          duration={1000}
          iterationDelay={1000}
        >
          <Octicons name="triangle-right" size={18} color={colors.shade4} />
        </Animatable.View>
      )}
    </View>
  );
};
const Arrows = (props) => {
  const { isStart, isEnd } = props;
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        // marginVertical: 10,
        opacity: 0.8,
      }}
    >
      {isStart ? (
        <View />
      ) : (
        <TouchableOpacity onPress={props.onPrevious} style={{ padding: 15 }}>
          <Image
            source={{ uri: arrow_next_left }}
            resizeMode="contain"
            style={[styles.iconStyle, {}]}
          />
        </TouchableOpacity>
      )}
      {isEnd ? (
        <View />
      ) : (
        <TouchableOpacity onPress={props.onNext} style={{ padding: 15 }}>
          <Image source={{ uri: arrow_next_right }} resizeMode="contain" style={styles.iconStyle} />
        </TouchableOpacity>
      )}
    </View>
  );
};

function CarouselItem2(props) {
  const [loading, setLoading] = useState(true);
  const [expand, setExpand] = useState(false);
  const [qty, setQty] = useState(1);
  const { style, user, item, disabled, isStart, isEnd } = props;

  return (
    <Pressable
      disabled={item.id === 'expressOrder' && !user && true}
      onPress={() => {
        setExpand(!expand);
      }}
      style={[
        {
          alignItems: 'center',
          width: imageDim,
          height: imageDim,
          // paddingHorizontal: 20,
          // marginRight: isEnd ? 0 : -55,
          backgroundColor: 'transparent',
        },
        style,
      ]}
    >
      <ImageBackground
        style={styles.BGimageStyle}
        source={{ uri: item.url }}
        onLoadEnd={() => setLoading(false)}
      >
        <LinearGradient
          colors={
            // ["yellow", "yellow"]
            expand
              ? ['transparent', 'rgba(0,0,0,0.5)', 'rgba(0,0,0,0.7)']
              : ['transparent', 'rgba(0,0,0,0.3)', 'rgba(0,0,0,0.7)']
          }
          style={{
            // flex: expand ? 1 : null,
            justifyContent: 'flex-end',
            borderRadius: 15,
            padding: 15,
          }}
        >
          {/* <View>
              {item?.note && (
                <View
                  style={{
                    flexDirection: "row",
                    paddingVertical: 4,
                    paddingHorizontal: 12,
                    borderRadius: 15,
                    backgroundColor: "white",
                  }}
                >
                  <TextBold
                    style={{
                      fontSize: 18,
                      color: colors.primaryText,
                    }}
                  >
                    {item?.note}
                  </TextBold>
                </View>
              )}
            </View> */}

          {!!loading && (
            <ActivityIndicator
              size="large"
              color={[colors.black, { opacity: 0.5 }]}
              style={{ padding: 30 }}
            />
          )}
          {!expand && <Arrows {...props} {...{ isStart, isEnd }} />}

          <View style={expand && { maxHeight: 320 }}>
            {/* {!!item?.note && (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginVertical: 5,
                  //marginTop: imageDim / 4,
                }}
              >
                <TextBold
                  style={{
                    fontSize: 12,
                    paddingVertical: 2,
                    paddingHorizontal: 7,
                    borderRadius: 20,
                    color: colors.primaryText,
                    backgroundColor: "white",
                  }}
                >
                  {item?.note}
                </TextBold>

                <Fontisto
                  name="eye"
                  size={18}
                  color="transparent"
                  style={{ alignSelf: "flex-end" }}
                />
              </View>
            )} */}

            {/* <View
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {!!item?.startTime && (
                <TextBoldSmall
                  style={[
                    {
                      color: colors.primary,
                      marginRight: 5,
                    },
                    styles.textShadow,
                  ]}
                >
                  {dateFormatted(item?.startTime?.seconds)} |
                </TextBoldSmall>
              )}

              <TextBoldSmall
                style={[
                  {
                    color: colors.primary,
                  },
                  styles.textShadow,
                ]}
              >
                From R {item?.price}
              </TextBoldSmall>
            </View> */}

            <TextBold
              style={[
                {
                  fontSize: 22,
                  color: colors.primary,
                },
                styles.textShadow,
              ]}
            >
              {item.title}
            </TextBold>

            {expand ? (
              <ScrollView
                style={{
                  // justifySelf: "flex-end",
                  // height: 100, //"30%",
                  // maxHeight:  120,
                  // marginBottom: 0,
                  marginHorizontal: -15,
                  paddingHorizontal: 15,
                }}
              >
                <TextBoldSmall
                  style={{
                    width: '100%',
                    color: colors.primary,
                  }}
                >
                  {item.body}
                </TextBoldSmall>
              </ScrollView>
            ) : (
              <TextBoldSmall style={{ width: '100%', color: colors.primary }} numberOfLines={2}>
                {item.body}
              </TextBoldSmall>
            )}
          </View>
        </LinearGradient>
      </ImageBackground>
      {/* <View>
          {item?.note && (
            <View
              style={{
                marginTop: -25,
                flexDirection: "row",
                paddingVertical: 4,
                paddingHorizontal: 12,
                borderRadius: 15,
                backgroundColor: "white",
                shadowColor: "rgba(0,0,0,0.4)",
                shadowOffset: { width: 0, height: 0 },
                shadowOpacity: 0.5,
                shadowRadius: 20,
              }}
            >
              <TextBold
                style={{
                  fontSize: 18,
                  color: colors.primaryText,
                }}
              >
                {item?.note}
              </TextBold>
            </View>
          )}
        </View> */}
    </Pressable>

    //   <ScrollArrows />
    //  </Animatable.View>
  );
}

export default connect(null, {
  toggleImagesLoading,
})(CarouselItem2);

const styles = StyleSheet.create({
  alertText: {
    textAlign: 'left',
    fontSize: 14,
    color: colors.alert,
    margin: 5,
  },
  textShadow: {
    textShadowColor: 'rgba(0,0,0,0.5)',
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 5,
  },
  BGimageStyle: {
    flex: 1,
    width: imageDim - 30,
    justifyContent: 'flex-end',
    // marginTop: 15,
    // marginBottom: 25,
    borderRadius: 15,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderBottomColor: colors.shade2,
    borderLeftColor: colors.shade2,
    overflow: 'hidden',
    shadowColor: 'rgba(0,0,0,0.4)',
    shadowOffset: { width: 0, height: 8 },
    shadowOpacity: 1,
    shadowRadius: 20,
  },
  imageShadow: {
    shadowColor: 'rgba(0,0,0,1)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 1,
    shadowRadius: 3,
  },
  iconStyle: {
    height: 35,
    width: 35,
  },
});
