import React, { useEffect, useState } from 'react';
import { StyleSheet, View, TouchableOpacity, TextInput } from 'react-native';
import TextBS from '../../components/Text/TextBoldSmall';
import Text from '../../components/Text/TextLight';
import { AntDesign, MaterialIcons } from '@expo/vector-icons';
import { colors, dimensions, typography } from '../../utils/theme';
import EmailAuthModal from '../../views/Modals/auth/EmailAuthModal';

const myTextInput = (props) => {
  const {
    placeholder,
    onMinusPress,
    showMinus,
    onChangeText,
    onFocus,
    onBlur,
    focusStyle,
    editable,
    // autoFocus,
    autoComplete,
    onSubmitEditing,
    onKeyPress,
    multiline,
    addStyle,
    addContainerStyle,
    highlightStyle,
    answerSelected,
    value,
    validation,
    checkValidation,
    input,
    label,
    keyboardType,
    textContentType,
    thisRef,
    required,
    secureTextEntry,
  } = props;
  const [focus, setFocus] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [validationError, setValidationError] = useState(false);
  useEffect(() => {
    setValidationError(false);
    return () => {
      setDirty(false);
      setValidationError(false);
    };
  }, [value]);

  useEffect(() => {
    setValidationError(false);
    return () => {
      setDirty(false);
      setValidationError(false);
    };
  }, []);

  return (
    <View style={{ marginVertical: 10 }}>
      <View
        style={[
          styles.inputContainer,
          {
            borderColor: focus ? colors.balance : !!validationError ? colors.alert : colors.shade3,
            borderWidth: focus ? 2 : !!validationError ? 2 : 1,
          },
          addContainerStyle,
        ]}
      >
        <View
          style={{
            position: 'absolute',
            top: -10,
            left: 15,
            backgroundColor: colors.bg,
            borderRadius: 5,
          }}
        >
          <View
            style={{
              flex: 1,
              borderRadius: 5,
              paddingHorizontal: 5,
              backgroundColor: colors.bg1,
            }}
          >
            <TextBS style={{ color: colors.balance, fontSize: 14 }}>
              {label}
              {required && ' *'}
            </TextBS>
          </View>
        </View>
        <View
          style={{
            flexDirection: 'row',
            marginVertical: 10,
            width: '100%',
            alignItems: 'center',
          }}
        >
          <TextInput
            {...input}
            secureTextEntry={secureTextEntry}
            ref={thisRef}
            style={{
              outline: 'none',
              width: '100%',
              fontSize: 18,
              color: colors.textSolid,
            }}
            multiline={multiline}
            placeholder={placeholder}
            onChangeText={(val) => {
              onChangeText(val);
            }}
            value={value}
            onFocus={() => setFocus(true)}
            onBlur={() => {
              !!onBlur && onBlur();
              setFocus(false);
              setDirty(true);
              !!checkValidation && setValidationError(checkValidation(value));
            }}
            onSubmitEditing={onSubmitEditing}
            onKeyPress={onKeyPress}
            keyboardType={keyboardType}
            textContentType={textContentType}
            autoComplete={autoComplete}
            editable={editable}
          />

          {!!showMinus ? (
            <TouchableOpacity onPress={onMinusPress} style={{ marginLeft: 10 }}>
              <AntDesign name="minuscircle" size={24} color={colors.highlight} />
            </TouchableOpacity>
          ) : null}
        </View>
      </View>

      <Text
        style={{
          color: colors.alert,
          fontSize: 14,
          padding: 5,
          paddingHorizontal: 10,
        }}
      >
        {!!validationError && validationError}
      </Text>
    </View>
  );
};

export default myTextInput;

const styles = StyleSheet.create({
  inputContainer: {
    width: '100%',
    height: 60,
    paddingHorizontal: 30,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
  },
  inputStyle: {
    fontSize: 16,
    outlineStyle: 'none',
    outlineWidth: 0,
    outlineColor: 'transparent',
    color: colors.primaryText,
    borderBottomWidth: 1,
    borderBottomColor: colors.shade1,
  },
  inputTextFocussed: {
    fontSize: 16,
    outlineStyle: 'none',
    outlineWidth: 0,
    outlineColor: 'transparent',
    color: colors.primaryText,
    borderBottomColor: colors.highlight,
    borderBottomWidth: 2,
  },
});
