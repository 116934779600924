import React from "react";
import { View, StyleSheet } from "react-native";
// import { Fontisto, Feather, SimpleLineIcons } from "@expo/vector-icons";
import { dimensions, colors } from "../../utils/theme";
import TextBold from "../../components/Text/TextBold";
import TextBoldSmall from "../../components/Text/TextBoldSmall";

const Typo2Way = (props) => {
  const { subTitle, title, body, style, subTitleStyle, titleStyle } = props;
  return (
    <View style={[{ backgroundColor: "transparent" }, style]}>
      <View>
        <TextBoldSmall
          style={[
            { color: colors.balance, marginLeft: 1, fontSize: 14 },
            subTitleStyle,
          ]}
        >
          {subTitle}
        </TextBoldSmall>
        <TextBold
          style={[{ color: colors.shade4, fontSize: 20 }, props.titleStyle]}
        >
          {title}
        </TextBold>
      </View>
    </View>
  );
};

export { Typo2Way };
