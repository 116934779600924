import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
} from "react-native";
import { Button } from "react-native-elements";
import { Feather } from "@expo/vector-icons";
import { dimensions, colors, typography } from "../../utils/theme";
import BackArrow from "../../components/common/BackArrow";
import { Card } from "../../components/common";
import ModalHeader1 from "../../components/headers/Header1";
import TextBold from "../../components/Text/TextBold";
import TextBoldSmall from "../../components/Text/TextBoldSmall";
import TextSemi from "../../components/Text/TextS";

import generateOTP from "../../utils/generateOTP";

export default function ({ onSignout, onClearModal }) {
  return (
    <Card style={styles.modalContainer} childStyle={{ alignItems: "center" }}>
      <ModalHeader1
        heading="Sign Out"
        subHeading="Until next time"
        onClose={onClearModal}
      />
      <TextSemi
        style={{
          // textAlign: "center",
          width: "90%",
          color: colors.textSolid,
          opacity: 0.7,
        }}
      >
        Note: While signed out of the wallet, access to passes and wallet
        balance is disabled. To continue using wallet, sign in again using the
        passwordless methods, cell number (One Time Pin) or Email verification
        link.
      </TextSemi>

      <Button
        title="SIGN OUT"
        type="solid"
        buttonStyle={{
          height: 60,
          width: dimensions.width - 60,
          // margin: 10,
          marginTop: 20,
          borderRadius: 10,
          backgroundColor: colors.black,
        }}
        titleStyle={{
          fontWeight: "bold",
          fontSize: 14,
          color: "white",
        }}
        onPress={onSignout}
      />

      {
        // <BackArrow onPress={onClearModal} />
      }
    </Card>
  );
}

const styles = StyleSheet.create({
  modalContainer: {
    alignItems: "center",
    alignSelf: "center",
  },
});
