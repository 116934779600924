import React, { useState, useRef, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  View,
  FlatList,
  Image,
  Dimensions,
  Animated,
  Text,
  StyleSheet,
  Platform,
} from 'react-native';
import * as Animatable from 'react-native-animatable';
import CarouselItem4 from './CarouselItem4';
import { dimensions, colors } from '../../utils/theme';

// Carousel inspired by this article
//https://medium.com/code-duo/autoscrolling-list-in-react-native-532a4a1041f9
const itemWidth = Dimensions.get('window').width;
const imageDim = dimensions.width - 30;

const ImageCarousel = (props) => {
  const { eventFocus } = useSelector((state) => state.config);
  const { addStyle, carouselData } = props;

  useEffect(() => {
    setTimeout(() => {
      props.onMounted(true);
    }, 500);
  }, []);

  const [scrollViewWidth, setScrollViewWidth] = React.useState(dimensions.width);
  const boxWidth = dimensions.width;
  const boxDistance = scrollViewWidth - boxWidth;
  const halfBoxDistance = boxDistance / 2;
  //
  const scrollX = useRef(new Animated.Value(0)).current;
  const SPACING = 5;
  const ITEM_SIZE = dimensions.width * 0.7; //Platform.OS === "ios" ? width * 0.72 : width * 0.74;
  const EMPTY_ITEM_SIZE = (dimensions.width - ITEM_SIZE) / 2;

  const pan = React.useRef(new Animated.ValueXY()).current;
  const listRef = useRef(null);

  return (
    <Animatable.View animation="zoomInUp" duration={500}>
      <FlatList
        ref={listRef}
        horizontal
        data={carouselData}
        style={{
          backgroundColor: 'transparent',
          width: dimensions.width,
          height: dimensions.width + 25,
          marginBottom: -25,
        }}
        contentInsetAdjustmentBehavior="never"
        snapToAlignment="center"
        decelerationRate="fast"
        automaticallyAdjustContentInsets={false}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        scrollEventThrottle={1}
        snapToInterval={dimensions.width}
        contentInset={{
          left: halfBoxDistance,
          right: halfBoxDistance,
        }}
        contentOffset={{ x: halfBoxDistance * -1, y: 0 }}
        onScroll={Animated.event([{ nativeEvent: { contentOffset: { x: pan.x } } }], {
          useNativeDriver: false,
        })}
        keyExtractor={(item, index) => `${index}-${item}`}
        pagingEnabled={true}
        getItemLayout={(data, index) => ({
          length: dimensions.width,
          offset: dimensions.width * index,
          index,
        })}
        renderItem={({ item, index }) => (
          <Animated.View
            style={{
              transform: [
                {
                  scale: pan.x.interpolate({
                    inputRange: [
                      (index - 1) * boxWidth - halfBoxDistance,
                      index * boxWidth - halfBoxDistance,
                      (index + 1) * boxWidth - halfBoxDistance, // adjust positioning
                    ],
                    outputRange: [
                      0.8,
                      1 + index / 1000,
                      0.8, //- (index + 1 / 100)
                    ], // scale down when out of scope
                    extrapolate: 'clamp',
                  }),
                },
              ],
            }}
          >
            <CarouselItem4
              {...props}
              item={item}
              isStart={index === 0}
              isEnd={carouselData.length - 1 === index}
              onNext={() => {
                listRef.current.scrollToIndex({ index: index + 1 });
              }}
              onPrevious={() => listRef.current.scrollToIndex({ index: index - 1 })}
            />
          </Animated.View>
        )}
      />
    </Animatable.View>
  );
};

const mapStateToProps = (state) => {
  const { productList } = state.store;
  const { userPasses } = state.users;
  return {
    productList,
    userPassesIds: userPasses.map((pass) => pass.prod_id),
  };
};
export default connect(mapStateToProps, {})(ImageCarousel);

const styles = StyleSheet.create({
  imageShadow: {
    shadowColor: 'rgba(0,0,0,1)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 1,
    shadowRadius: 1,
  },
});
